import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../actions/authActions';
import * as purchaseActions from '../../actions/purchaseActions';
import * as scanActions from '../../actions/scanActions';
import '../../styles/common/Footer/Footer.css';
import { routerActions } from 'connected-react-router';
import { BARCODE, QR_CODE } from '../../middlewares/SignalRMM/scanner';


class Exit extends Component{

	cancel(){
		this.props.actions.auth.cancelOperation();
    switch(this.props.router.location.pathname){
      case "/insert_cash":
        this.props.actions.purch.cancelInsBill();
        break;
      case "/scan_wallet":
        this.props.actions.scan.safeCancelScan(QR_CODE);
        break;
      case "/scan_id":
        this.props.actions.scan.safeCancelScan(BARCODE);
        break
    }
	}
  render(){
    if (this.props.purch.exit)
      return(
        <div className='footer-exit' onClick={this.cancel.bind(this)}/>
      )
    else
      return null
  }
}

function mapStateToProps(state){
  return{
    purch: state.purchase,
    router: state.router
  }
}

function mapDispatchToProps(dispatch){
  return {
    actions: {
    	auth: bindActionCreators(authActions, dispatch),
    	router: bindActionCreators(routerActions,dispatch),
      purch: bindActionCreators(purchaseActions, dispatch),
      scan: bindActionCreators(scanActions, dispatch)
		}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exit)