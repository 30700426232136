import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import ClientCard from '../../../components/cards/ClientCard';
import ButtonBS from '../../../components/common/ButtonBS';
import * as clientActions from '../../../actions/clientActions';
import '../../../styles/IsThisYou/IsThisYou.css';

class IsThisYou extends Component{

  replaceAt(string, index, replacement){
    return string.substr(0, index) + replacement+ string.substr(index + replacement.length);
  }

  hideString(string){
    console.log(string);
    var index = string.indexOf(' ');
    var hided = string;
    if(index + 4 < string.length)           //catch if the string is small
      for (var i = index+4; i < string.length; i++){
        hided = this.replaceAt(hided,i, "*");
      }
    return hided;
  }

  render(){
    return(
      <PageLayout title='Is This You?'>
        <h6 className='bs-subtitle no-margin is-this-you-sub'>We've found this information associated to your phone number.</h6>
        <ClientCard 
          name={this.hideString(this.props.client.name)}
          street={this.hideString(this.props.client.address.street)}
          city={`${this.props.client.address.city}, ${this.props.client.address.state} ${this.props.client.address.zip}`}
        />
        <ButtonBS className='is-this-you-button' content='Yes' onClick={()=>this.props.actions.yesMe()}/>
        <ButtonBS className='is-this-you-button' content='No' onClick={()=>this.props.actions.notMe()}/>
      </PageLayout>
    )
  }
}

function mapStateToProps(state){
  return{
    client: state.client
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: bindActionCreators(clientActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IsThisYou)