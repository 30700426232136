import initialState from './initialState';

export default function tiersReducer(state = initialState.tiers, action){
  switch(action.type){
    case 'SET_TIERS':
      return Object.assign({}, state, {
        tiers: action.tiers
      })

    case 'SET_TIER_INSTRUCTIONS':
      return Object.assign({}, state, {
        instructions: action.instructions
      })

    case 'CANCEL_OPERATION':
      return initialState.tiers

    default:
      return state;
  }
}