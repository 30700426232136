import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Footer from '../Footer';
import Header from '../Header';
import ModalBS from '../../../containers/ModalBS';
import TickerPrice from '../../../containers/Tickers/TickerPrice';
import TickerPower from '../../../containers/Tickers/TickerPower';
import ReminderBS from '../../../containers/ReminderBS';
import ErrorBoundary from '../../../containers/ErrorBoundary';

class PageLayout extends PureComponent {
  render(){
    return(
      <ErrorBoundary>
      <div className='bs-body'>
        <ReminderBS />
        <ModalBS />
        { this.props.price ? <TickerPrice /> : null }
        { this.props.power ? <TickerPower /> : null }
        { this.props.header ? <Header title={this.props.title}/> : null }
          <h6 className='bs-subtitle no-margin error invalid-pin'>{this.props.errorMessage}</h6>
          <div className={this.props.title ? 'page-container' : 'page-container2'}>
            {this.props.children}
          </div>
        <Footer big={this.props.big}/>
      </div>
      </ErrorBoundary>
    )
  }
}

PageLayout.defaultProps={
  big: true,
  errorMessage: '',
  header: true
}

function mapStateToProps(state){
  return{
    maintenance: state.maintenance
  }
}

export default connect(mapStateToProps)(PageLayout);