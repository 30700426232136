import React, { PureComponent } from 'react';
import '../../../../styles/common/input-fields/InputSerialBS.css';
import { isEqual, clone } from 'lodash';

class InputSerialRick extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      serial:''
    }
    
    this.onChange = this.onChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.listenKeyPress = this.listenKeyPress.bind(this);
  }

  onChange(e){
    if (!Number.isNaN(parseInt(e.target.value, 10))){
      this.setState({
        serial: e.target.value
      }, this.sendSerial)
    }
  }

  sendSerial(){
    this.props.sendSerial(this.state.serial)
  }

  submit(){
    this.props.submit(true)
    this.reset()
  }

  reFocus(){
    document.getElementsByClassName('input-serial')[0].focus()
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  listenKeyPress(event){
    switch(event.key){
      case '*':
        this.reset();
        break;
      case '#':
        this.props.submit(true);
        this.reset();
        break;
    }
  }

  reset(){
    if(this.state.serial.length > 0)
      this.setState({ 
        serial: '' 
      },()=>{
        this.sendSerial();
        document.getElementById('0').focus()
      })
  }

  componentDidMount(){
    this.reFocus()
    document.addEventListener('keypress', this.listenKeyPress);
  }

  componentWillUnmount(){
    document.removeEventListener('keypress', this.listenKeyPress)
  }

  render(){
    return(
      <input type='text' id='0' className='input-serial' value={this.state.serial} onChange={ this.onChange } autoComplete="off" onBlur={this.reFocus}/> 
    );
  }
}

InputSerialRick.defaultProps = {
  submited: false
}

export default InputSerialRick;