import * as types from './actionTypes';
import { setSettings } from '../machineActions';
import createAxe from '../../middlewares/Axe'
import { restartPrecioWS } from '../../middlewares/WebSocket-Middleware';

export function retrieveInfo(token = ''){
  return async function (dispatch, getState){
    //current atm id in the state (can be empty if we are staring the app)
    let atm_id = getState().machine.atm_id
    let _data = {
      action: 'get-info'
    }
    if(token){
      //When we start app is when we pass the token because it may be not ready in state
      atm_id = token
      _data = { booting: true, ..._data }
    }
    try{
      let axe = createAxe(getState())
      let res = await axe('/status-station/gas',{
        method: 'POST',
        data: {
          atm_token: atm_id,
          data: _data
        }
      })
      if(res.data.data.fees){
        // Check first if we are in the correct version
        // if next version enabled
        if(res.data.data.settings.next_version){
          let nextHost = res.data.data.next_version_host
          checkNextHost(nextHost)
        }
        // Fees received -> Update settings
        dispatch(networkRetrieved(res.data.data.network))
        dispatch(minBillRetrieved(res.data.data.fees[0].min))
        dispatch(feesRetrieved(res.data.data.fees))
        dispatch(gasKeyRetrieved(res.data.data.gas_station_key))
        dispatch(setSettings(res.data.data.settings))
        return dispatch(infoRetrieved())
      }
      else{
        return dispatch(noNewSettings())
      }
    } catch(error){
    }
  }
}

export function feesRetrieved(fees){
  return function(dispatch){
    // Percent from first range will be the default in machine
    dispatch(feeRetrieved(fees[0].percent))
    if(fees[0].precio_host){
      dispatch(precioHostRetrieved(fees[0].precio_host))
      setTimeout(() => {
        restartPrecioWS()  // we need to restart the precio ws
      }, 5000)
    }
    return dispatch({
      type: types.FEES_RETRIEVED,
      fees
    })
  }
}

export function noNewSettings(){
  return{
    type: types.NO_NEW_SETTINGS
  }
}

export function infoRetrieved(){
  return{
    type: types.INFO_RETRIEVED
  }
}

export function networkRetrieved(network){
  return{
    type: types.NETWORK_RETRIEVED,
    network,
  }
}

export function minBillRetrieved(min){
  return{
    type: types.MIN_RETRIEVED,
    min,
  }
}

export function feeRetrieved(fee){
  return{
    type: types.FEE_RETRIEVED,
    fee,
  }
}

export function gasKeyRetrieved(gas_key){
  return{
    type: types.GAS_KEY_RETRIEVED,
    gas_key
  }
}

function checkNextHost(nextHost){
  if(nextHost && window.location.origin !== nextHost)
    window.location.replace(nextHost)
}

export function precioHostRetrieved(precio_host){
  return{
    type: types.PRECIO_HOST_RETRIEVED,
    precio_host
  }
}