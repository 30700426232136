import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import ButtonBS from '../../components/common/ButtonBS';
import { style } from './style.js';
import CalculatorBS from '../../components/CalculatorBS';
import CalculatorBS2 from '../../components/CalculatorBSv2';
import CalculatorBS3 from '../../components/Morty/CalculatorBS';
import { setTimer } from '../../helpers/timeouts.js'
import '../../styles/Calculator/Calculator.css';
import { Calculator as i18n } from '../../middlewares/i18n'

class ModalCalculator extends PureComponent{

  constructor(props){
    super(props);

    this.state={
      open: false,
      key: '',
      disclaimer: ''
    }

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  closeModal(){
    if(this.state.open){
      setTimeout(()=>{
          this.setState({ open: false }, ()=> this.props.getClose());
          document.getElementsByClassName('ReactModalPortal')[0].classList.remove('fadeOut')
      }, 500)
      document.getElementsByClassName('ReactModalPortal')[0].classList.add('fadeOut')
    }
  }

  openModal(){
    this.setState({ open: true })
  }

  componentDidUpdate(prevProps){
    if(this.props.open && !prevProps.open){
      document.getElementsByClassName('ReactModalPortal')[0].classList.add('fadeIn')
      this.openModal()
    }
    if(this.props.rate.fees !== prevProps.rate.fees){
      this.createFlatDisclaimer()
    }
    if(this.props.mach.name === 'morty'){
      if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
        this.setState({key: this.props.pinpad.key}, ()=>this.setState({key:''}))
      else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)           //Both cancel and clear
        this.setState({key: 'CLEAR'}, ()=>this.setState({key:''}))
    }
  }

  componentDidMount(){
    this.createFlatDisclaimer()
    document.getElementsByClassName('ReactModalPortal')[0].classList.add('animated');
    setTimer(()=>this.closeModal(), 20000)
  }

  afterOpenModal(){
    setTimeout(()=>{
      document.getElementsByClassName('ReactModalPortal')[0].classList.remove('fadeIn')
    }, 500)
  }

  createFlatDisclaimer(){
    let fees = this.props.rate.fees
    let translations = i18n[this.props.i18n.language.current]

    if(fees[0] && fees[0].flat){
      if(fees.length > 1)
        this.setState({disclaimer: `* ${translations.disclaimer1} $${fees[0].max} ${translations.disclaimer2} $${fees[0].flat}`})
      else
        this.setState({disclaimer: `* ${translations.disclaimer3} $${fees[0].flat}.`})
    }
  }

  render(){
    let translations = i18n[this.props.i18n.language.current]

    return(
      <div>
        <Modal
          isOpen={this.state.open}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={style}
          contentLabel="Modal Calculator"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          <div className="calculator-body-cont">
            <div className='bs-logo' />
            <h3 className="no-margin bs-title">{translations.title}</h3>
            <div className="line" />
            <div>
              <h4 className="no-margin bs-subtitle">{`${translations.the} `}<span>{`${translations.message1}`}</span>{` ${translations.message2}`}</h4>
              <h4 className="no-margin bs-subtitle">{translations.message3}</h4>
              <h4 className="no-margin bs-subtitle">{translations.message4}</h4>
            </div>
            <div>
              <h4 className="no-margin bs-subtitle">{`${translations.the} `}<span>{`${translations.message5}`}</span>{` ${translations.is} ${this.props.rate.fee}%* ${translations.message6}`}</h4>
              <h4 className="no-margin bs-subtitle">{translations.message7}</h4>
              <h4 className="no-margin bs-subtitle">{translations.message8}</h4>
              <h4 className="no-margin bs-subtitle">{translations.message9}</h4>
              <h5 className="no-margin bs-subtitle">{this.state.disclaimer}</h5>
            </div>
          </div>
          { this.props.mach.name === 'rick' ? !this.props.mach.tiny ?
            <div>
              <CalculatorBS fee={this.props.rate.fee} price={this.props.rate.price} /> 
              <ButtonBS className='calculator-button-close' content={translations.gotIt} onClick={ this.closeModal } />
            </div> :
            <div>
              <CalculatorBS2 fee={this.props.rate.fee} price={this.props.rate.price}/>
              <ButtonBS className='calculator-button-close-tiny' content={translations.gotIt} onClick={ this.closeModal } />
            </div> :
            <div>
              <CalculatorBS3 fee={this.props.rate.fee} price={this.props.rate.price} keyPress={this.state.key} /> 
              <ButtonBS className='calculator-button-close' content={translations.gotIt} onClick={ this.closeModal } />
            </div>
          }
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    rate: state.rate,
    mach: state.machine,
    pinpad: state.pinpad,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(ModalCalculator);