import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '../Footer'
import TickerPrice from '../../../containers/Tickers/TickerPrice'
import TickerPower from '../../../containers/Tickers/TickerPower'
import ModalBS from '../../../containers/ModalBS'
import ReminderBS from '../../../containers/ReminderBS'

const PageLayout2 = ({ children, big, phone, price, power}) =>{

  const rate = useSelector(state => state.rate)

  return(
    <div className='bs-body'>
      <ReminderBS />
      <ModalBS />
      { price ? <TickerPrice /> : null }
      { power ? <TickerPower /> : null }
      <div>
        { children }
      </div>
      <Footer big={big} phone={phone}/>
    </div>
  )
}

export default PageLayout2