import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageLayout from '../../common/PageLayout/PageLayout3'
import { cancelOperation } from '../../../actions/authActions'
import HardRestricted from './HardRestricted.jsx'
import SoftRestricted from './SoftRestricted.jsx'
import '../../../styles/AccountRestricted/AccountRestricted.css'
import { AccountRestricted as i18n } from '../../../middlewares/i18n';

const AccountRestricted = props => {

    const client = useSelector(state => state.client)
    const _i18n = useSelector(state => state.i18n)

    const dispatch = useDispatch()

    const exit = useCallback(
        () => dispatch(cancelOperation()),
        []
    )

    useEffect(()=>{
        let timer = setTimeout(exit, 45000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return(
        <PageLayout
            title={i18n[_i18n.language.current].title}
            big={false}
            phone={false}
        >
            <div className="restricted-container">
                <div className='bs-restricted' />
                { client.hard_restricted ?
                    <HardRestricted /> :
                    <SoftRestricted />
                }
            </div>
        </PageLayout>

    )
}

export default AccountRestricted;