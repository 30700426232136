import React from 'react';
import { useSelector } from 'react-redux';
import Ticker from '../../../components/common/Ticker';
import { toDollar_NoCents } from '../../../helpers/helperFunctions';
import { TickerPower as i18n } from '../../../middlewares/i18n'
 
const TickerPower = () => {

  const client = useSelector(state => state.client)
  const language = useSelector(state => state.i18n.language.current)

  return(
    <Ticker
      title={i18n[language].title}
      content={`$${toDollar_NoCents(client.spent)}/$${toDollar_NoCents(client.max)}`} 
      left={ true }
    />
  )
}

export default TickerPower;