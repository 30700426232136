import React, { PureComponent } from 'react';
import '../../../../styles/common/input-fields/InputTelBS.css';
import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';
import { clone } from 'lodash';
import SelectCountry from './countries.jsx';
import Flags from './flags.js';

class InputTelMorty extends PureComponent {

  constructor(props){
    super(props);

    this.state={
      phone:'',
      valid: false,
      country: 'US',
      country_code: '1'
    }

    this.handleCountry = this.handleCountry.bind(this);
    this.format = this.format.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    let prevPhone = clone(this.state.phone);             //save prev phone
      if (!Number.isNaN(parseInt(this.props.keyPress, 10))){  //if it is numberic
        if (this.state.phone.length < 20)
          this.setState({
            phone: prevPhone+this.props.keyPress,           //add the number
          },()=>{
            this.checkValid();
            this.sendTel();
          });                                 //send phone
      } else if (this.props.keyPress === 'CLEAR' || this.props.keyPress === 'CANCEL'){
        this.delete()
      }
  }

  handleCountry(e){
    if(e.value==='NO_COUNTRY'){
      this.setState({
        country: 'NO_COUNTRY',
        country_code: ''
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
    else{
      this.setState({
        country: e.value,
        country_code: getCountryCallingCode(e.value)
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
  }

  isValidNumber(phone_number, country_code){
    if(phone_number.length < 7) {
      return false
    }
    return true
  }

  checkValid(){
    if(this.state.country_code){
      if(this.state.valid !== this.isValidNumber(this.state.phone, this.state.country)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone, this.state.country)
        }, this.sendValid)
      }
    }
    else
      if(this.state.valid !== this.isValidNumber(this.state.phone)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone)
        }, this.sendValid)
      }
  }

  sendTel(){
    this.props.sendTel(`+${this.state.country_code}${this.state.phone}`);
  }

  sendValid(){
    this.props.sendValid(this.state.valid)
  }

  submit(){
    this.props.submit(true)
  }

  delete(){
    this.setState({
      phone: this.state.phone.substring(0,this.state.phone.length-1)
    }, ()=>{
      this.checkValid();
      this.sendTel();
      document.getElementsByTagName('input')[0].focus();
    })
  }

  format(){
    const formatter = new AsYouType(this.state.country).input(this.state.phone);
    return formatter;
  }

  render(){
    return(
      <div className={'tel-container '+this.props.className}>
        <div className='select-flag-bs'>{ Flags[this.state.country]() }</div>
        <SelectCountry onChange={this.handleCountry} value={this.state.country} className="select-country-bs" countries={this.props.machine.intlphone}/>
        <div id='no-touch-wrap' />
        <input
          id="input-tel"
          value={this.format()}
          className="input-tel-bs"
          placeholder='Enter Phone Number'
          autoComplete="off"
        />
      </div>
    )
  }
}

InputTelMorty.defaultProps={
  className: ''
}

export default InputTelMorty;