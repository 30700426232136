export default function cashMetricsMM(action, store, _hub, next){

  switch(action.type){

    case 'CLEAR_CASH_METRICS':
      _hub.server.cS_MAS_ClearBillAcceptorCashMetrics().done(response=>{
        console.log(response)
      })
      break;
    
    default:
      next()
  }
}