import { getPlaces } from '../../actions/placesActions';

var socks = {};

const ws = require('ws');

export function StartWebsockets(store){
  WSStart(store);
  MediaWSStart(store);
  PlacesWSStart(store)
}

export function pingPrecioWS() {
  if(socks.price.readyState === WebSocket.OPEN)
    socks.price.send("Wubba Lubba")
  else
    console.log("[ERROR] Trying to ping precio in a closed connection.")
}

export function WSStart(store){

  // console.log('WS PRECIO: I am starting...')

  socks.price = new WebSocket(
    store.getState().rate.precio_host
  )

  socks.price.onmessage = (event) => {
    let data = JSON.parse(event.data);
    if (Math.abs(parseFloat(data['C_RAW']) - parseFloat(store.getState().rate.price)) >= 0.10)
      store.dispatch({
        type: 'PRICE_RETRIEVED',
        price: data['C_RAW']
      })
  }

  socks.price.onerror = (error) => {
    // console.log(`ERROR PRECIO: ${error}\nI will close...`)
    socks.price.close()
  }

  socks.price.onclose = (event) => {
    // console.log(`WS PRECIO: I was closed. Bye.\nReason: ${event}`)
    setTimeout(()=>WSStart(store), 1000)
  }
}

export function restartPrecioWS(){
  if(socks.price)
    socks.price.close() // Just closing it would reninitialize it
}

export function MediaWSSsend(message){
  socks.media.send(message)
}

export function MediaWSStart(store){

  // console.log('WS MEDIA: I am starting...')

  socks.media = new WebSocket(`wss://${window.location.hostname}/fill-station`)

  socks.media.onmessage = (event) => {
    let data = JSON.parse(event.data);
    // console.log('WSS MEDIA: message received:',data)
  }

  socks.media.onerror = (error) => {
    // console.log(`ERROR WS PICS: ${error}\nI will close...`, error)
    socks.media.close()
  }

  socks.media.onclose = (event) => {
    // console.log(`WS PICS: I was closed. Bye.\nReason: ${event}`, event)
    setTimeout(()=>MediaWSStart(store), 1000)
  }
}

export function placesWSSsend(message){
  if(socks.places.readyState === WebSocket.OPEN)
    socks.places.send(message)
}

export function PlacesWSStart(store){

  // console.log('WS MEDIA: I am starting...')
  if(process.env.NODE_ENV === 'development')
    socks.places = new WebSocket(`ws://10.1.10.32:5001/directory/get/`)
  else
    socks.places = new WebSocket(`wss://${window.location.hostname}/directory/get/`)

  // socks.places = new WebSocket(`ws://0.0.0.0:5000/directory/get/`)

  socks.places.onmessage = (event) => {
    let data = JSON.parse(event.data);
    // console.log('WSS PLACES: message received:',data)
    store.dispatch(getPlaces(data.predictions))
  }

  socks.places.onerror = (error) => {
    // console.log(`ERROR WS PLACES: ${error}\nI will close...`, error)
    socks.places.close()
  }

  socks.places.onclose = (event) => {
    // console.log(`WS PLACES: I was closed. Bye.\nReason: ${event}`, event)
    setTimeout(()=>PlacesWSStart(store), 1000)
  }
}