import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as hodlActions from '../../actions/hodlActions';
import ButtonBS from '../../components/common/ButtonBS';
import '../../styles/SendHODL/SendHODL.css';

class DownloadHODL extends PureComponent {

  constructor(props){
    super(props);

    this.sendText = this.sendText.bind(this);
  }

  sendText(){
    this.props.actions.hodl.sendWalletLinkUser(this.props.device)
    this.props.onClick()
  }

  render(){
    return(
      <ButtonBS 
        content={
          <div 
            className={this.props.device+'-logo'}
            onClick={this.sendText}
            disabled={this.props.disabled}
          />
        }
        className="send-hodl-button"
      />
    )
  }
}

DownloadHODL.defautlProps = {
  className: '',
  disabled: false
}

function mapDispatchToProps(dispatch){
  return {
    actions: {
      hodl: bindActionCreators(hodlActions, dispatch)
    }
  }
}

export default connect(null, mapDispatchToProps)(DownloadHODL);