import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '../../../../styles/common/input-fields/InputTelBS.css';
import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';
import SelectCountry from './countries.jsx';
import Flags from './flags.js';
import { EnterPhone as i18n } from '../../../../middlewares/i18n';

class InputTelRick extends PureComponent {

  constructor(props){
    super(props);

    this.state={
      phone:'',
      valid: false,
      country: 'US',
      country_code: '1'
    }

    this.handleCountry = this.handleCountry.bind(this);
    this.format = this.format.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.focus = this.focus.bind(this);
    this.listenKeyPress = this.listenKeyPress.bind(this);
  }

  handleCountry(e){
    if(e.value==='NO_COUNTRY'){
      this.setState({
        country: 'NO_COUNTRY',
        country_code: ''
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
    else{
      this.setState({
        country: e.value,
        country_code: getCountryCallingCode(e.value)
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
  }

  isValidNumber(phone_number, country_code){
    if(phone_number.length < 7) {
      return false
    }
    return true
  }

  checkValid(){
    if(this.state.country_code){
      if(this.state.valid !== this.isValidNumber(this.state.phone, this.state.country)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone, this.state.country)
        }, this.sendValid)
      }
    }
    else
      if(this.state.valid !== this.isValidNumber(this.state.phone)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone)
        }, this.sendValid)
      }
  }

  sendTel(){
    this.props.sendTel(`+${this.state.country_code}${this.state.phone}`);
  }

  sendValid(){
    this.props.sendValid(this.state.valid)
  }

  submit(){
    this.props.submit(true)
  }

  format(){
    const formatter = new AsYouType(this.state.country).input(this.state.phone);
    return formatter;
  }

  listenKeyPress(event){
    switch(event.key){
      case '*':
        if(this.state.phone.length > 0)
          this.delete()
        break;
      case '#':
        if(this.state.valid)
          this.submit();
    }
  }

  delete(){
    this.setState({
      phone: this.state.phone.substring(0,this.state.phone.length-1)
    }, ()=>{
      this.checkValid();
      this.sendTel();
      this.focus();
    })
  }

  focus(){
    document.getElementById('input-tel').focus();
  }

  onChangePhone(e){
    let phone = e.target.value.replace(/\D/g,'')
    if(!isNaN(parseInt(phone, 10)))
      this.setState({
        phone: phone,           //add the number
      },()=>{
        this.checkValid();
        this.sendTel();
      });
  }

  componentDidMount(){
    this.focus();
    document.addEventListener('keypress', this.listenKeyPress);
  }

  componentWillUnmount(){
    document.removeEventListener('keypress', this.listenKeyPress)
  }

  render(){
    return(
      <div className={'tel-container '+this.props.className}>
        <div className='select-flag-bs'>{ Flags[this.state.country]() }</div>
        <SelectCountry onChange={this.handleCountry} value={this.state.country} className="select-country-bs" countries={this.props.machine.intlphone}/>
        <div id='no-touch-wrap' />
        <input
          id="input-tel"
          value={this.format()}
          className="input-tel-bs"
          placeholder={i18n[this.props.i18n.language.current].placeholder}
          onChange={ this.onChangePhone }
          onBlur={ this.focus }
          autoComplete="off"
        />
      </div>
    )
  }
}

InputTelRick.defaultProps={
  className: ''
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(InputTelRick);