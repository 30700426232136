import { persistCombineReducers } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import rate from './reducer_rate';
import auth from './reducer_auth';
import purchase from './reducer_purchase';
import client from './reducer_client';
import coupon from './reducer_coupon';
import signalr from './reducer_signalr';
import maintenance from './reducer_maintenance';
import transaction from './reducer_transaction';
import modal from './reducer_modal';
import machine from './reducer_machine';
import pinpad from './reducer_pinpad';
import loading from './reducer_loading';
import light from './reducer_light';
import qa from './reducer_qa';
import view from './reducer_view';
import db from './reducer_db';
import places from './reducer_places';
import i18n from './reducer_i18n';
import report from './reducer_report';
import tiers from './reducer_tiers';
import scam from './reducer_scam';
import tos from './reducer_terms';

const config = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist:['pinpad','modal', 'qa', 'maintenance', 'machine', 'places', 'i18n', 'view']
}

export default (history) => persistCombineReducers(config, {
  router: connectRouter(history),
  rate,
  auth,
  purchase,
  client,
  coupon,
  signalr,
  maintenance,
  transaction,
  modal,
  machine,
  pinpad,
  loading,
  light,
  qa,
  view,
  db,
  places,
  i18n,
  report,
  tiers,
  scam,
  tos,
})