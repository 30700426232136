import React, { useState, useEffect, useRef } from 'react';

const Countdown = ({ stop, restart, finished, count = 30 }) => {
    /*  When we mount the component
        timer's useEffect will listen to
        this and start the interval when
        state ready. */
    const [timer, setTimer] = useState(count)

    const intervalRef = useRef()

    const clean = () => {
        clearInterval(intervalRef.current)
    }

    const finish = () => {
        clean()
        finished() // Send signal that countdown finished
    }

    const start = () => {
        intervalRef.current = setInterval(() => {
            setTimer(t => t - 1)
        }, 1000)
    }

    useEffect(() => { 
        return () => {
            clean()     // Clean when will unmount
        }
    }, [])

    useEffect(() => {
        if(stop === true) {
            clean()
            setTimer(-1)
        }
    }, [stop])

    useEffect(() => {
        if(restart === true) {
            setTimer(count)
        }
    }, [restart])
    
    useEffect(() => {
        if(timer === 0)     // Countdown reached end
            finish()
        if(timer < 0)
            clean()         // Countdown was stopped
        /*  If we set timer to same as count we restart it,
            we do it like this because we need to wait
            until state is updated, if not, we can start
            a countdown with incorrect count. */
        if(timer === count) {
            clean()     // clear any interval
            start()     // start!
        }
    }, [timer])

    if(timer > 0)
        return <span>{timer}</span> 
    else
        return null

}

export default Countdown;