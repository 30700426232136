import { enterMaintenance, exitMaintenance, noMoreBills, maintenanceAfterWait } from '../../../actions/maintenanceActions';

let storeStatus;

export default function BillAcceptorHandler(store, status){
  if (status.BillAcceptor.HardwareResult === 0){                     
    if(store.getState().maintenance.active || store.getState().router.location.pathname === '/maintenance-mode' || store.getState().router.location.pathname === '/enter_serial' || store.getState().router.location.pathname === '/pre-ok'){          //Check if we are in a maintenance mode
      store.dispatch(exitMaintenance());              //Turn off maintenance mode
    }
  }
    else if (status.BillAcceptor.HardwareResult > 0){
      if(status.BillAcceptor.HardwareResult === 4 && store.getState().transaction.init) {
        // IGNORE
      }
      else {
      if(!store.getState().maintenance.active){             //if maintenance mode is not activated
        //This will trigger even if you are in the middle of a transaction (when cash box is removed)
        if (status.BillAcceptor.MetaData.Reason === "CashBoxRemoved"){ //CASH BOX REMOVED GO TO MAINTENANCE DIRECTLY!
          if (!store.getState().maintenance.active){
            store.dispatch(enterMaintenance(2));
          }
        } 
        else{
          if(store.getState().transaction.init){              //if machine is in a middle of a transaction
            if(store.getState().transaction.stage <= 3){      //If machine is in stage 3 or less
              switch(store.getState().transaction.stage){     //depending in what stage we are
                case 1:
                case 2:
                  //Do something but don't go to maintenance mode because we can solve before
                  break;
                case 3:                                       //in current stage
                  //Give a chance to solve the problem
                    setTimeout(()=>{                               //Give a moment to the mach to solve problem (19 seconds)
                      storeStatus = store.getState().maintenance.statuses.BillAcceptor.HardwareResult; //Save current state
                      if (storeStatus > 0){
                        if(store.getState().purchase.subtotal > 0){      //If user already inserted money
                          store.dispatch(noMoreBills())                   //No accept more bills
                        }
                        else                                          //If user didn't insert money
                          store.dispatch(maintenanceAfterWait(5))      //Go to maintenance mode
                      }
                      //Else don't do anything because error was solved
                    }, 19000)
                    break;
                default:
                  console.error('I should never trigger. Current Stage:',store.getState().transaction.stage)
              }
            }
            else{                                             //If machine already passed stage 3
              //Do not enter to maintenance mode but do...?
            }
          }
          else{                                   //if machine is not in middle of a transaction
            store.dispatch(enterMaintenance(5));   //enter in maintenance mode
          }
        }
      }
      }
    }
    else {
      console.error('STATUS NOT REGISTERED')
    }
  }