import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

function ModalError({ message }) {
  const style={
    height: "100px",
    width: "120px",
    margin: "60px auto 40px auto"
  }
  return(
    <div style={{width: "1000px"}}>
      <div className="warning-icon" style={style}/>
      <h5 className="bs-title no-margin">OOPS!</h5>
      <h6 style={{marginTop:'30px', marginBottom: '60px'}}>{message}</h6>
    </div>
  )
}

ModalError.propsTypes = exact({
  message: PropTypes.string.isRequired
})

export default ModalError;