import React, { useState, useEffect } from 'react';
import '../../../styles/MembershipTrack/MembershipTrack.css';
import { MembershipTrack as i18n } from '../../../middlewares/i18n';
import {
    buildStyles,
    CircularProgressbarWithChildren
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ButtonBS from '../../../components/common/ButtonBS'
import { toDollar_NoCents } from '../../../helpers/helperFunctions';

import { useSelector } from 'react-redux';

const CircularProgress = ({ tier, fulfilled, lang, handleSelect, last }) => {
    // Tier structure
    // {
    //     name: "Silver",
    //     type: "silver",
    //     policies: [
    //       {
    //         name: "Name",
    //         type: "name"
    //       },
    //       {
    //         name: "Address",
    //         type: "address"
    //       }
    //     ],
    //     max: 1000
    // }

    // Fulfille structure
    // [
    //     {
    //         name: "Name",
    //         type: "name"
    //     }
    // ]
    const [percentage, setPercentage] = useState(null)
    const [curProgress, setCurProgress] = useState([])

    const view = useSelector(state => state.view)

    useEffect(() => {
        let len = tier.required_policies.length
        let done = 0
        let progress = curProgress
        for(let i = 0; i < len; i++) {
            let policy = { done: false, ...tier.required_policies[i] }
            for(let j = 0; j < fulfilled.length; j++) {
                if(fulfilled[j].type === tier.required_policies[i].type) {
                    done++
                    policy.done = true
                    break;
                }
            }
            progress.push(policy)
        }
        let percent = done * 100 / len
        setCurProgress(progress)
        setPercentage(percent)
        if(percent === 100) {
            let lock = document.getElementsByClassName(`rl-${tier.type}`)[0]
            let bar = document.getElementsByClassName(`pb-${tier.type}`)[0]
            let check = document.getElementsByClassName(`cm-${tier.type}`)[0]
            lock.className = lock.className.replace("lock-icon", "lock-open-icon")
            bar.style.background = '#FF1720'
            check.style.display = "unset"
        }
    }, [])

    const buildPolicies = () => {
        return curProgress.map((policy, i) => {
            let td = "none"
            if(policy.done)
                td = "line-through"
            return <p key={`p-${i}`} style={{ textDecoration: td }}>{ policy.name }</p>
        })
    }

    const showDescription = () => {
        return <p>{tier.description}</p>
    }

    const isPersonaRequested = () => {
        return (tier.type == 'gold') && view.req_upgrade_persona
    }

    const isManualRequested = () => {
        return ((tier.type == 'diamond') || (tier.type == 'gold')) && view.req_upgrade_manual
    }

    return (
        <div className="circular-prog-cont">
            <div className="progress-container">
                <div>
                    <div>
                        <CircularProgressbarWithChildren
                            value={percentage}
                            strokeWidth={50}
                            styles={buildStyles({
                                strokeLinecap: "butt"
                            })}
                        >
                            <div className={`cm-track cm-${tier.type} checkmark`}></div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <h3>{tier.name}</h3>
                </div>
                <div>
                    <div className={`progress-bar pb-${tier.type}`}/>
                    <div>
                        <h5>{i18n[lang].daily_buy_limit}</h5>
                        <h2 className="no-margin">{`$${toDollar_NoCents(tier.limit_max)}`}</h2>
                    </div>
                </div>
            </div>
            <div className="requirements-completed">
                <div>
                    <div className={`req-lock rl-${tier.type} lock-icon`}/> 
                    <h6 className="no-margin">{i18n[lang].requirements}</h6>
                </div>
                { buildPolicies() }
            </div>
            <div className="progress-action">
                { (percentage < 100) ?
                    <ButtonBS
                        className="cir-progress-button"
                        content={i18n[lang].select}
                        onClick={() => handleSelect(tier.id)}
                        disabled={isPersonaRequested() || isManualRequested()}
                    /> : null

                }
            </div>
            {
                !last ? <div className="border-progress-card"/> : null
            }
        </div>
    )
}

export default CircularProgress;