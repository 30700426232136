import axios from 'axios';

export default function createAxe(state){
  let gt = ''                 //robot proxy token
  if(state.machine.gas_key){
    gt = state.machine.gas_key
  }
  else{
    gt = 'meeseks'
  }
  let config = {
    headers:{
      'Authorization': `Bearer ${gt}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    timeout: 50000,
  }
  if(process.env.NODE_ENV === 'development'){
    config = Object.assign({}, config, {
      baseURL: 'http://192.168.88.40:5001'
    })
  }
  return axios.create(config)
}