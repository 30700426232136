import { exitMaintenance } from '../../../../actions/maintenanceActions';

export default function CardReaderHandler(store, status){
  switch(status.CardReaderService){
    case 'HEALTHY':

      break;

    case 'FATAL':
    case 'UNKNOWN':
      if(store.getState().maintenance.active)
        store.dispatch(exitMaintenance())
      break;

    default:
      console.error('STATUS NOT REGISTERED')
  }
}