import { readCard, cancelReadCard } from '../../actions/cardReaderActions';
import { setLightStat, lightOff } from '../../actions/lightActions';
import { errorHardware } from '../../actions/maintenanceActions';
import { updateIDwDip } from '../../actions/updateActions';

export default function cardReaderMM(action, store, _hub, next){

  switch(action.type){

    case 'READ_CARD':
      store.dispatch(setLightStat("CRS"))
      _hub.server.cS_CRS_Read(60).done(res => {
        store.dispatch(cancelReadCard())
        if(res.Data){
          if(res.Data.Track1MaskedData){
            store.dispatch(updateIDwDip(res.Data))
          }
          else{
            store.dispatch(readCard())
          }
        }
      })
      .fail(err => {
        console.log('error in crs', err)
        //error handler for this situation
        store.dispatch(errorHardware(err))
      });
      break;

    case 'CANCEL_READ_CARD':
        store.dispatch(lightOff("CRS"))
        _hub.server.cS_CRS_Cancel().done(res => {
        })
        .fail(err => {
          console.log('error canceling card reader', err)
        })
    
    default:
      next()
  }
}