import initialState from './initialState';

export default function terms(state = initialState.tos, action){
  switch(action.type){
    case 'SET_TERMS_AND_CONDITIONS':
      return Object.assign({}, state, {
        content: action.content
      })

    default:
      return state;
  }
}