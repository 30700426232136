import React, { PureComponent } from 'react';
import { isEqual, clone } from 'lodash';

class InputPinMorty extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      pin: ['','','','']
    }
  }

  componentDidUpdate(){
      if(this.props.submited){
        this.reset()
      }
      if(this.props.keyPress === 'CLEAR'){ //no numeric key pressed
        this.reset()
      } else if (!Number.isNaN(parseInt(this.props.keyPress, 10))){
        if(!document.activeElement.id){
          for(let i = 0; i < 4 ; i++){
            if(this.state.pin[i].length === 0){
              document.getElementById(i).focus();
              break;
            }
          }
        }
        else{
          let index = parseInt(document.activeElement.id, 10);        //get the index of the active field
          let pin = clone(this.state.pin);                        //clone pin for new version
          let oldPin = clone(pin);                                //clone again pin for mantain old version
          if(pin[index].length === 0){
            pin[index] = this.props.keyPress;                        //add pin in that field according with the index)
            this.setState({
              pin
            }, ()=>{
              setTimeout(()=>document.getElementById(index).type = 'password',250);     //Hide pwd
              if(!isEqual(this.state.pin, oldPin)){                 //check if the old and new pin are the same
                if (index < 3){
                  setTimeout(()=>document.getElementById(String(index+1)).focus(),30); //go to next field
                }
                else { 
                  document.getElementById(3).blur();
                  this.sendPin();                                //last field fill -> send
                }
              }
            })
          }
          else{
            if (index < 3){
              setTimeout(()=>document.getElementById(String(index+1)).focus(),30); //go to next field
            }
            else { 
              document.getElementById(3).blur();
              this.sendPin();                                //last field fill -> send
            }
          }
        }
      }
  }

  sendPin(){
    this.props.sendPin(this.state.pin);
  }

  reset(){
    this.setState({
      pin: ['','','','']
    },()=>{
      this.sendPin();
      document.getElementById('0').focus();
      for(let i = 0; i < 4; i++){
        document.getElementById(i).type = 'text'
      }
    })
  }

  componentDidMount(){
    document.getElementById('0').focus()
  }

  render(){
    return (
      <div className='input-code'>
        <form id='pin-form'>
          <input type='text' maxLength={1} id='0' className='input-square' value={this.state.pin[0]} autoComplete="off"/>
          <input type='text' maxLength={1} id='1' className='input-square' value={this.state.pin[1]} autoComplete="off"/>
          <input type='text' maxLength={1} id='2' className='input-square' value={this.state.pin[2]} autoComplete="off"/>
          <input type='text' maxLength={1} id='3' className='input-square' value={this.state.pin[3]} autoComplete="off"/>
        </form>
      </div>
    )
  }
}

InputPinMorty.defaultProps = {
  submited: false
}

export default InputPinMorty;