import React, { useEffect } from 'react';
import Coupon from './Coupon';
import qrSvg from '../../../styles/assets/myaccount-qrcode.svg';
import mobileAppSvg from '../../../styles/assets/BitstopMobileApp.svg';
import sketchedArrow from '../../../styles/assets/sketched-arrow.png';
import './RewardsCenter.css';
import useTranslations from '../../../hooks/useTranslations';
import translations from '../../../middlewares/i18n/RewardsCenter.json';

const RewardsCenter = ({ openModal, setOpenModal }) => {
  const i18n = useTranslations(translations);
  const isOpen = openModal === 'rewards';

  const openRewardsCenter = () => setOpenModal('rewards');
  const closeRewardsCenter = () => setOpenModal(null);

  useEffect(() => {
    const pulseAnimation = () => {
      const element = document.getElementById('pulse-element');
      if (element) {
        element.classList.add('animate__custom-pulse');
        setTimeout(() => {
          element.classList.remove('animate__custom-pulse');
        }, 1000);
      }
    };

    pulseAnimation();
    const intervalId = setInterval(pulseAnimation, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`rewards-center__wrapper ${isOpen ? 'open' : ''}`}>
      {isOpen && <div className="rewards-center__modal-backdrop" onClick={closeRewardsCenter} />}
      {!isOpen ? (
        <>
          <button className="rewards-center__button" onClick={openRewardsCenter}>
            <div id="pulse-element" className="rewards-center__icon-open">🎁</div>
          </button>
          <div className="modal-button-label">{i18n.btnLabel}</div>
        </>
      ) : (
        <button className="rewards-center__button help-center__button--close" onClick={closeRewardsCenter}>
          <div className="rewards-center__icon-close" />
        </button>
      )}
      {isOpen && (
        <div className="rewards-center">
          <center>
            <h1>{i18n.haveACoupon}</h1>
            <Coupon />
            <small className="disclaimer" style={{marginBottom: "8px"}}>{i18n.enterCouponCode}</small>
            <small className="disclaimer"><em>{i18n.takePicReminder}</em></small>
          </center>
          <hr />
          <center class="want-more__wrapper">
            <h1>{i18n.wantMore}</h1>
            <img width={'300px'} style={{ display: 'block', margin: '0 auto' }} src={qrSvg} alt="Login to Bitstop MyAccounts" />
            <img className="arrow" src={sketchedArrow} alt="Scan Me!" />
            <p>{i18n.signUpOrLink}</p>
          </center>
          <br />
          <br />
          <img className="mobile-app" src={mobileAppSvg} alt="Bitstop Mobile App" />
        </div>
      )}
    </div>
  );
};

export default RewardsCenter;