import * as types from './actionTypes.js';
import createAxe from '../../middlewares/Axe';
import receiptCashReport from './receipt-cash-report';
import { push } from 'connected-react-router';
import { openNewModal } from '../modalActions';
import { expRetry } from '../../helpers/timeouts';

export function getReport(retry = 0) {
    return async function(dispatch, getState){
        let axe = createAxe(getState())
        try{
            let { status, data: { data } } = await axe('/athena/v2/atm/report', {
                method: 'GET',
                headers: {
                    'Atm-Token': `Bearer ${getState().machine.atm_id}`
                }
            })

            // Failed -> try again
            if(status !== 200){
                // max 3 retries
                return expRetry(
                    (_retry) => {
                        dispatch(getReport(_retry))
                        return dispatch(errorReport())
                    },
                    errorReport,
                    retry
                )
            }
            
            return dispatch(reportRetrieved(data))
        }
        catch(error){
            // try again
            return expRetry(
                (_retry) => {
                    dispatch(getReport(_retry))
                    return dispatch(errorReport())
                },
                errorReport,
                retry
            )
        }
    }
}

export function reportRetrieved(report) {
    return {
        type: types.REPORT_RETRIEVED,
        report
    }
}

export function errorReport() {
    return{
        type: types.ERROR_REPORT
    }
}

export function printReport() {
    return function(dispatch, getState){
        dispatch({
            type: types.PRINT_CLEAR_CASH,
            signalR: true,
            report: receiptCashReport(getState())
        })
        return dispatch(openNewModal('clearCash'))
    }
}

export function exit() {
    return function(dispatch, getState) {
        dispatch(exitClearCash())
        let direction = '/'
        if(getState().maintenance.active)
            direction = '/maintenance-mode'
        return dispatch(push(direction))
    }
}

export function exitClearCash() {
    return {
        type: types.EXIT_CLEAR_CASH
    }
}