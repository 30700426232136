import createAxe from '../../middlewares/Axe'

// moment js
import moment from 'moment';
moment.locale('en') // secure that date is English

import { push } from 'connected-react-router';

import * as types from './actionTypes';
import { triggerError } from '../maintenanceActions';
import { goLoading, outLoading } from '../loadingActions';

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';

// Function to validate and set a coupon code
export function applyCouponCode(coupon_code){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current;
    dispatch(goLoading(i18n[lang].validatingCoupon));
    
    let axe = createAxe(getState());
    try{
      let res = await axe('/unity-k8s/coupons/apply', {
        method: 'POST',
        headers: {
          'Customer-Token': `Bearer ${getState().client.client_id}`,
          'Atm-Token': `Bearer ${getState().machine.atm_id}`
        },
        data: {
          coupon_code,
          idtx: getState().purchase.idtx,
        }
      });
      if(res.status === 200){
        // Set to coupon true
        dispatch(setCouponApplied(true))
        // Coupon data
        dispatch(setCouponCode(res.data.data.applied_coupon.coupon))
        dispatch(setCouponType(res.data.data.applied_coupon.discount_type))
        dispatch(setCouponValue(res.data.data.applied_coupon.discount_value))
        // Fees
        dispatch(setCouponNewFees(res.data.data.new_fees));
        dispatch(setCouponOldFees(res.data.data.old_fees));
        setTimeout(() => { return dispatch(outLoading('coupon-applied')) }, 1000);
      } else {
        setTimeout(() => {
          dispatch(outLoading('add-coupon'));
          setTimeout(()=>{return dispatch(triggerError(i18nErrors[lang].invalidCoupon))}, 1000)
        }, 2000)
      }
    } catch(error){
      setTimeout(() => {
        dispatch(outLoading('add-coupon'));
        setTimeout(()=>{return dispatch(triggerError(i18nErrors[lang].invalidCoupon))}, 1000)
      }, 2000)
    }
  }
}

// Action to set a valid coupon code
export function setCouponCode(code){
  return {
    type: types.SET_COUPON_CODE,
    code
  }
}

export function setCouponType(coupon_type){
  return {
    type: types.SET_COUPON_TYPE,
    coupon_type
  }
}

export function setCouponValue(value){
  return {
    type: types.SET_COUPON_VALUE,
    value
  }
}

export function setCouponApplied(applied){
  return {
    type: types.SET_COUPON_APPLIED,
    applied
  }
}

export function setCouponOldFees(old_fees){
  return {
    type: types.SET_OLD_FEES,
    old_fees
  }
}

export function setCouponNewFees(new_fees){
  return {
    type: types.SET_NEW_FEES,
    new_fees
  }
}

export function setCurrentFee(curr_fee){
  return {
    type: types.SET_CURR_FEE,
    curr_fee
  }
}

export function setOldFee(old_fee){
  return {
    type: types.SET_OLD_FEE,
    old_fee
  }
}

export function setOldCharges(old_charges){
  return {
    type: types.SET_OLD_CHARGES,
    old_charges
  }
}

export function setSavings(savings){
  return {
    type: types.SET_SAVINGS,
    savings
  }
}