import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import TransCard from '../../../components/cards/TransCard';
import ButtonBS from '../../../components/common/ButtonBS';
import '../../../styles/Receipt/Receipt.css';
import * as purchaseActions from '../../../actions/purchaseActions';
import { setStage } from '../../../actions/transactionActions';
import { routerActions } from 'connected-react-router';
import svgNeil from '../../../styles/assets/neil-headshot.svg';
import svgQrcode from '../../../styles/assets/receipt-myaccount-qrcode.svg';
import { stateToReceipt } from './receipt-design.js';
import 'moment/locale/es-us';
import { Receipt as i18n } from '../../../middlewares/i18n';

import { track } from '../../../helpers/analytics';

class Receipt extends Component{

  constructor(props){
    super(props);

    this.exit = this.exit.bind(this)
    this.print = this.print.bind(this)
  }

  componentDidMount(){
    this.props.actions.setStage(4)
    this.props.actions.purchase.getPurchaseReceipt();
  }

  exit(){
    track('Exit Without Printing');
    
    this.props.actions.router.push('/finish-screen')
  }

  print(){
    track('Print Receipt Clicked');

    let html = stateToReceipt(this.props.storeState)

    this.props.actions.purchase.printReceipt(html)
  }

  render(){
    const translations = i18n[this.props.i18n.language.current];
    return(
      <div className="receipt-view">
        <PageLayout
          title={translations.title}
          power
        >
          <div className='receipt-cont'>
            <TransCard
              bills={this.props.purchase.bills}
              subtotal={this.props.purchase.subtotal}
              fee={parseFloat(this.props.purchase.fee)}
              totalFee={this.props.purchase.totalfee}
              total={this.props.purchase.total}
              btc={this.props.purchase.btc}
              receipt={true}
              address={this.props.purchase.address}
              btcPrice={this.props.purchase.btcprice}
              flat={this.props.purchase.flat}
              language={this.props.i18n.language.current}
              couponApplied={this.props.coupon.applied}
              oldFee={this.props.coupon.old_fee}
              oldTotalFee={this.props.coupon.old_charges}
              savings={this.props.coupon.savings}
            />
          </div>
          <h5 className='bs-subtitle no-margin copy-sent'>{translations.copy}</h5>
          <div className="myaccount-cta">
            <img src={svgNeil} />
            <p className="cta">{translations.scanQr}</p>
            <img src={svgQrcode} />
          </div>
          <div className='receipt-button-container'>
            <ButtonBS content={translations.print} className='receipt-button' onClick={this.print} />
            <ButtonBS content={translations.exit} className='receipt-button' onClick={this.exit}/>
          </div>
          <div className='send-hodl-new__support'>
            <h6 className='send-hodl-new__support-subtitle--weight no-margin'>{translations.footerTitle}</h6>
            <h6 className='send-hodl-new__support-subtitle no-margin'>{translations.contactUs}</h6>
            <ul>
              <li className="send-hodl-new__support-subtitle--weight">{translations.supportEmail}</li>
              <li className="send-hodl-new__support-subtitle--spacing">{translations.supportPhone}</li>
            </ul>
          </div>
        </PageLayout>
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    purchase: state.purchase,
    client: state.client,
    qa: state.qa,
    machine: state.machine,
    i18n: state.i18n,
    coupon: state.coupon,
    storeState: state
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      purchase: bindActionCreators(purchaseActions, dispatch),
      router: bindActionCreators(routerActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)