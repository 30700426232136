import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import '../../styles/SendHODL/SendHODL.css'
import { SendHODL as i18n } from '../../middlewares/i18n';
import { BITSTOP_OPERATOR_NAME } from '../../constants/app';

const SendHODL = () =>{
  const dispatch = useDispatch()
  const _i18n = useSelector(state => state.i18n)
  const operatorImage = useSelector(state => state.machine.operator_image)
  const operatorName = useSelector(state => state.machine.operator_name)
  
  const isBitstopOperator = operatorName.includes(BITSTOP_OPERATOR_NAME)
  const showOperatorInfoWithLogo = !isBitstopOperator && operatorImage
  const showOperatorInfoJustName = !isBitstopOperator && !operatorImage && operatorName
  const containerHeightPixels = (showOperatorInfoWithLogo || showOperatorInfoJustName) ? '665px' : '600px'
  
  const sendLink = useCallback(
    () => {
      dispatch(push('/send_hodl'))
    },
    []
  )

  return(
    <div className="send-hodl-starting" style={{  height: containerHeightPixels }}>
      <div className="bitcoin-wallet-icon btc-wallet-send-hodl" />
      <div className='rounded-red' />
      <h4 className="bs-title send-hodl-text">{i18n[_i18n.language.current].needAWallet}</h4>
      <div onClick={sendLink}>
        <h4 className="bs-subtitle send-hodl-link-text">{i18n[_i18n.language.current].downloadFromHere}</h4>
        <div className='right-arrow-black arrow-send-hodl' />        
      </div>
      {showOperatorInfoWithLogo && (
        <div className="operator-info">
          <img src={operatorImage} alt="Operator" className="operator-image" />
          <p>{i18n[_i18n.language.current].operatedBy} <b>{operatorName}</b></p>
        </div>
      )}
      {showOperatorInfoJustName && (
        <div className="operator-info">
          <p style={{ marginTop: 150 }}>
            {i18n[_i18n.language.current].operatedBy} <b>{operatorName}</b>
          </p>
        </div>
      )}
    </div>
  )
}

export default SendHODL
