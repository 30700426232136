import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as rateActions from '../../actions/rateActions';
import PriceDisplay from './PriceDisplay';

class PriceBS extends PureComponent {

  render(){
    return(
      <div className='price-disp-cont'>
        <PriceDisplay price={this.props.rate.price} className={this.props.className}/>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    rate: state.rate
  } 
}

export default connect(mapStateToProps)(PriceBS);