import React, { useEffect, useState } from 'react';

import merge from 'lodash/merge';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';

import PageLayout from '../../../components/common/PageLayout/PageLayout5';
import InputCodeBS from '../../../components/common/input-fields/InputCodeBS';

import { invalidInput, verifyOtpCode } from '../../../actions/authActions';

import { EnterCodeOtp as _translations, Errors as _errorTranslations } from '../../../middlewares/i18n';

import useTranslations from '../../../hooks/useTranslations';

import '../../../styles/EnterCodeOtp/EnterCodeOtp.css';

const DEFAULT_CODE = Array(5).fill('');
const TRANSLATIONS = merge(_translations, _errorTranslations);

const EnterCodeOtp = () => {
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const i18n = useTranslations(TRANSLATIONS);

  const { completePhone, errorMessage } = useSelector((state) => state.auth);

  const [_, setKey] = useState('');
  const [code, setCode] = useState(DEFAULT_CODE);

  const handleSubmit = () => {
   if (code.includes('')) {
      dispatch(invalidInput(i18n.shortCode));
      return;
    }
    
    dispatch(verifyOtpCode(code.join('')));
    setReset(true);
  };

  useEffect(() => {
    reset && setReset(false);
  }, [reset])

  return (
    <PageLayout title={i18n.title} errorMessage={errorMessage}>
      <div className="enter-code-otp">
        <div className="enter-code-otp__text">{i18n.enterCode}</div>
        <div className="enter-code-otp__phone">{parsePhoneNumber(completePhone).format("NATIONAL")}</div>
        <div className="enter-code-otp__subtext" dangerouslySetInnerHTML={{ __html: i18n.weWillSend }} />
        <div className="enter-code-otp__input">
          <InputCodeBS sendCode={setCode} keyPress={setKey} submit={handleSubmit} submited={reset} />
        </div>
      </div>
    </PageLayout>
  );
};

export default EnterCodeOtp;