import { sendPicture } from '../../actions/cameraActions';
import { resizeBase64 } from '../../helpers/resizer';

export default function imagingDeviceMM(action, store, _hub, next){

  switch(action.type){

    case 'TAKE_PICTURE':
      _hub.server.cS_IDS_CaptureAndSave().done(response=>{
        let idtx = store.getState().purchase.idtx
        let view = action.view

        // We try to resize the image
        // If we suceed we send the resized image
        // If we fail, we send the original (shouldn't happen)
        resizeBase64(response.Data)
          .then(image => store.dispatch(sendPicture(image, idtx, view)))
          .catch(err => store.dispatch(sendPicture(response.Data, idtx, view)))
      })
      .fail(err => {
      });
      break;

    case 'TAKE_VIDEO':
      _hub.server.cS_IDS_StreamVideo().done(response=>{ //register action
        console.log(response)
      })
      break;

    default:
      next()

  }
}