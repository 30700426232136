import React, { PureComponent } from 'react';
import { isEqual, clone } from 'lodash';

class InputPinRick extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      pin: ['','','','']
    }

    this.listenKeyPress = this.listenKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    if (!Number.isNaN(parseInt(e.target.value, 10))){
      let index = parseInt(document.activeElement.id, 10);        //get the index of the active field
      let pin = clone(this.state.pin);                        //clone pin for new version
      let oldPin = clone(pin);                                //clone again pin for mantain old version
      pin[index] = e.target.value;                        //add pin in that field according with the index)
      this.setState({
        pin
      }, ()=>{
        setTimeout(()=>document.getElementById(index).type = 'password',250);            //Hide pwd
        if(!isEqual(this.state.pin, oldPin)){                 //check if the old and new pin are the same
          if (index < 3){
            document.getElementById(String(index+1)).focus(); //go to next field
          }
          else { 
            document.getElementById(3).blur();
            this.sendPin();                                //last field fill -> send
          }
        }
      })
    }
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  listenKeyPress(event){
    switch(event.key){
      case '*':
        this.reset();
        break;
      case '#':
        this.props.submit();
        this.reset();
        break;
    }
  }

  sendPin(){
    this.props.sendPin(this.state.pin);
  }

  reset(){
    this.setState({
      pin: ['','','','']
    }, this.sendPin)
    document.getElementById('0').focus();
    for(let i = 0; i < 4; i++){
      document.getElementById(i).type = 'text'
    }
  }

  componentDidMount(){
    document.addEventListener('keypress', this.listenKeyPress);
    document.getElementById('0').focus();
  }

  componentWillUnmount(){
    document.removeEventListener('keypress', this.listenKeyPress)
  }

  render(){
    return (
      <div className='input-code'>
        <form id='pin-form'>
          <input type='text' maxLength={1} id='0' className='input-square' value={this.state.pin[0]} onChange={this.onChange} autoComplete="off"/>
          <input type='text' maxLength={1} id='1' className='input-square' value={this.state.pin[1]} onChange={this.onChange} autoComplete="off"/>
          <input type='text' maxLength={1} id='2' className='input-square' value={this.state.pin[2]} onChange={this.onChange} autoComplete="off"/>
          <input type='text' maxLength={1} id='3' className='input-square' value={this.state.pin[3]} onChange={this.onChange} autoComplete="off"/>
        </form>
      </div>
    )
  }
}

InputPinRick.defaultProps = {
  submited: false
}

export default InputPinRick;