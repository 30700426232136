import { countries } from './countriesObj';
import { find, orderBy } from 'lodash';

export default function supportCountries(support_countries) {
  let countriesF = []
  if(support_countries.length){
    countriesF = support_countries.map(support_country => {
      return find(countries, { 'value': support_country.country_code })
    })
  }
  else{
    countriesF = countries
  }

  return countriesF
}