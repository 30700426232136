import React, { useMemo, useCallback, useEffect } from 'react';

import shuffle from 'lodash/shuffle';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router'

import ButtonBS from '../../common/ButtonBS';
import ScamsListSender from '../../common/ScamsListSender';
import BeingScammedCard from '../../common/BeingScammedCard';

import ScamLayout from '../../common/PageLayout/ScamLayout';

import translations from '../../../middlewares/i18n/ScamScreen.json';

import { openNewModal } from '../../../actions/modalActions';
import { getDirectionFromList } from '../../../actions/viewActions'

import useSteps from '../../../hooks/useSteps';
import useTranslations from '../../../hooks/useTranslations';

import '../../../styles/ScamScreen/ScamScreen.new.css';
import { track } from '../../../helpers/analytics';

const ScamScreen = () => {
  const dispatch = useDispatch();
  const i18n = useTranslations(translations);

  const { step, changeStep, isFirstStep, isLastStep } = useSteps(i18n.scams.length - 1);
  
  const scams = useMemo(() => shuffle([...i18n.scams]), [i18n.scams]);
  const currentScam = scams[step];

  useEffect(() => {
    dispatch(openNewModal('scamAdvise'));
    // Track user first enter scam screen
    track('Scam education flow started', { total_scams: scams.length });
  }, []);

  useEffect(() => {
    track('Scam example viewed', {
      scam_type: currentScam.title,
      step: step + 1,
      total_steps: scams.length
    })
  }, [step, currentScam]); // triggered if changes to these states

  const handleNextPage = useCallback(() => {
    dispatch(push(dispatch(getDirectionFromList())));
  }, []);

  return (
    <>
      <ScamLayout showSender={isLastStep} showBeingScammed={false}>
        <div className="scam-screen">
          <div className="scam-logo scam-screen-logo" />

          <h6 className="scam-screen__title">{i18n.title}</h6>
          <p className="scam-screen__subtitle">{i18n.subtitle}</p>
          <div className="scam-layout__divider" /> 

          <div className="scam-screen__card">
            <div className="scam-screen__card-title-container">
              <div className="scam-screen__card-title">{currentScam.title}</div>
              <p className="scam-screen__card-subtitle">{currentScam.subtitle}</p>
            </div>

            <div className="scam-screen__card-example-container">
              <div className="scam-screen__card-example">{i18n.example}:</div>
              <p className="scam-screen__card-example-text">
                <q>{currentScam.example}</q>
              </p>
            </div>
          </div>
          
          <div className="scam-screen__being-scammed-card">
            <BeingScammedCard />
          </div>

          {isLastStep && (
            <div className="scam-screen__sender">
              <ScamsListSender />
            </div> 
          )}

        </div>
      </ScamLayout>

      <div className={`scam-screen__buttons ${!isFirstStep ? 'back': ''} ${!isLastStep ? 'next': ''} ${isLastStep ? 'continue': ''}`}>
        {!isFirstStep && (
          <ButtonBS v2 content={i18n.back} onClick={() => changeStep(-1)} />
        )}
        {!isLastStep && (
          <ButtonBS v2 className="v2--red" content={i18n.nextExample} onClick={() => changeStep()}  />
        )}
        {isLastStep && (
          <ButtonBS v2 className="v2--red last" content={i18n.continue} onClick={handleNextPage} />
        )}
      </div>
    </>
  )
}

export default ScamScreen;