import React from 'react';
import '../../../styles/MaintenanceMode/MaintenanceMode.css';

const MaintenanceMode = (props) =>{
  return(
    <div className='bs-body maint-body'>
      <div className='bs-logo-white' />
      <h3 className='sorry'>Sorry.</h3>
      <h3>Machine temporarily closed.</h3>
      {/*<h3>Please visit:</h3>
      <h3>Address 1</h3>
      <h3>Address 2</h3>
      <h3>Address 3</h3>*/}
    </div>
  )
}

export default MaintenanceMode;