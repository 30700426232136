import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import '../../../styles/TermsConditions/TermsConditions.css';
import * as purchaseActions from '../../../actions/purchaseActions';
import * as cameraActions from '../../../actions/cameraActions';
import { canceledOperation } from '../../../actions/authActions';
import { setStage } from '../../../actions/transactionActions';
import { routerActions } from 'connected-react-router';
import { TermsConditions as i18n } from '../../../middlewares/i18n'
import { TermsConditionsFallback } from './TermsConditionsFallback';

import { track } from '../../../helpers/analytics';
import * as termsAndConditionsActions from '../../../actions/termsAndConditions';

class TermsConditions extends Component {

  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
  }

  componentDidMount() {
    this.props.actions.purchase.unlockExit();
    this.props.actions.setStage(1);
    // Once getOperatorDetails is completed, dispatch getContractContent
    this.props.actions.termsAndConditions.getContractContent();
    if(!this.props.qa.qa_partial)
      this.props.actions.canceledOperation()
  }

  handleAccept() {
    track('Accept Terms Clicked');
    this.props.actions.router.push('/enter-phone');
  }

  render() {
    const termsContent = this.props.terms.content;
    return (
      <PageLayout title={i18n[this.props.i18n.language.current].title} price>
        <div id="term-container" className="centered">
          <div id="terms-and-conditions" style={{ textAlign: "left" }}>
            <div
              className="col-xs-offset-1 width-83"
              style={{ padding: "50px 25px" }}
            >
              {termsContent ? (
                <span
                  dangerouslySetInnerHTML={{ __html: termsContent }}
                />
              ) : (
                <span>
                  <TermsConditionsFallback />
                </span>
              )}
            </div>
          </div>
          <ButtonBS
            onClick={this.handleAccept}
            content={i18n[this.props.i18n.language.current].accept}
          />
        </div>
      </PageLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      termsAndConditions: bindActionCreators(termsAndConditionsActions, dispatch),
      purchase: bindActionCreators(purchaseActions, dispatch),
      camera: bindActionCreators(cameraActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      canceledOperation: bindActionCreators(canceledOperation, dispatch),
      router: bindActionCreators(routerActions, dispatch),
    }
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    terms: state.tos, // relevant reducer
    qa: state.qa
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
