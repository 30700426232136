import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../../components/common/PageLayout';
import * as scanActions from '../../../../actions/scanActions';
import { setStage } from '../../../../actions/transactionActions';
import videoScanAdd from '../../../../styles/assets/PHONE_2.webm';
import { ScanWallet as i18n } from '../../../../middlewares/i18n';
import { track } from '../../../../helpers/analytics';

class ScanWallet extends Component{

  componentDidMount(){
    this.props.actions.setStage(2);
    this.props.actions.scan.scanQRCode();
    track('[Scan Wallet] Navigated to Scan Wallet')
  }

  render(){
      return(
        <PageLayout title={i18n[this.props.i18n.language.current].title}>
          <video controls={false} autoPlay loop width='1200' height='1150'>
            <source src={videoScanAdd} type='video/webm'/>
          </video>
        </PageLayout>
      )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      scan: bindActionCreators(scanActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch)
    }
  }
}

function mapStateToProps(state){
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanWallet)