import React from 'react';
import { useSelector } from 'react-redux';
import Ticker from '../../../components/common/Ticker';
import { toDollar } from '../../../helpers/helperFunctions';

 
const TickerPrice = () => {

  const rate = useSelector(state => state.rate)

  return(
    <Ticker
      content={`1 BTC = $${toDollar(rate.price)}`} 
    />
  )
}

export default TickerPrice;