import initialState from './initialState';
import { routerActions } from 'connected-react-router';

export default function machineReducer(state = initialState.machine, action){

  switch(action.type){

    case 'SET_MACHINE':
      return Object.assign({}, state, {
        name: action.name
      })

    case 'MACHINE_SIZE':
      return Object.assign({}, state, {
        tiny: action.tiny
      })

    case 'NETWORK_RETRIEVED':
      return Object.assign({}, state, {
        network: action.network
      })

    case 'SET_ATM_ID':
      return Object.assign({}, state, {
        atm_id: action.atm_id
      })

    case 'LOADING_MESSAGE':
      return Object.assign({}, state, {
        loadingMes: action.loadingMes
      })

    case 'UPDATE_AVAILABLE':
      return Object.assign({}, state, {
        updateAvailable: action.status
      })

    case 'SET_STATE':
      return Object.assign({}, state, {
        state: action.state
      })

    case 'SET_LANG':
      return Object.assign({}, state, {
        lang: action.lang
      })
      
    case 'SET_INTLPHONE':
      return Object.assign({}, state, {
        intlphone: action.intlphone
      })

    case 'SET_TIMEZONE':
      return Object.assign({}, state, {
        timezone: action.timezone
      })

    case 'SET_KYC_REQ':
      return Object.assign({}, state, {
        kyc: action.kyc
      })

    case 'SET_PRIMARY':
      return Object.assign({}, state, {
        primary: action.primary
      })
    
    case 'GAS_KEY_RETRIEVED':
      return Object.assign({}, state, {
        gas_key: action.gas_key
      })

    case 'SET_SETTING_ID':
      return Object.assign({}, state, {
        setting_id: action.setting_id
      })

    case 'SET_OPERATOR_ID':
      return Object.assign({}, state, {
        operator_id: action.operator_id
      })

    case 'SET_OPERATOR_NAME':
      return Object.assign({}, state, {
        operator_name: action.operator_name
      })
    
    case 'SET_OPERATOR_ADDRESS':
      return Object.assign({}, state, {
        operator_address: action.operator_address
      })

    case 'SET_OPERATOR_IMAGE':
      return Object.assign({}, state, {
        operator_image: action.operator_image
      })
    
    case 'SET_LOCAL_STATE':
      return Object.assign({}, state, {
        local_state: action.local_state
      })

    case 'SET_OPERATOR_NMLS':
      return Object.assign({}, state, {
        operator_nmls: action.operator_nmls
      })

    default:
      return state
  }
}