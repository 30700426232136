import { cardReaderDown, cardReaderWorking, exitMaintenance } from '../../../actions/maintenanceActions';

export default function CardReaderHandler(store, status){
  console.log('Card Reader Handler')
    if(status.CardReader.HardwareResult === 0){             //No errors
    if(!store.getState().maintenance.cardReaderWorking)     //if we have the state set in card reader down
        store.dispatch(cardReaderWorking())                 //Change to working
  }
  else if(status.CardReader.HardwareResult > 0){             //There are errors

    //we ignore this ones
    if(store.getState().maintenance.active || store.getState().router.location.pathname === '/maintenance-mode' || store.getState().router.location.pathname === '/enter_serial' || store.getState().router.location.pathname === '/pre-ok'){          //Check if we are in a maintenance mode
      store.dispatch(exitMaintenance());              //Turn off maintenance mode
    }

    if(store.getState().maintenance.cardReaderWorking)      //If we have the state set in card reader working
      store.dispatch(cardReaderDown())                      //Change to down
  }
  else
    console.error('STATUS NOT REGISTERED')
}