import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ButtonBS from '../../ButtonBS';

import ModalSmsAcceptAlert from './ModalSmsAcceptAlert';
import ModalSmsAcceptOptInItem from './ModalSmsAcceptOptInItem';

import { closeModal as closeModalAction} from '../../../../actions/modalActions';

import useTranslations from '../../../../hooks/useTranslations';

import translations from '../../../../middlewares/i18n/SmsAccept';

import '../../../../styles/common/Modals/ModalSmsAccept.css';

const DEFAULT_OPT_IN_VALUES = new Array(translations.en.optIns.length).fill(false);

const ModalSmsAccept = ({ closeModal }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [optInsValues, setOptInsValues] = useState(DEFAULT_OPT_IN_VALUES);

  const dispatch = useDispatch();
  const i18n = useTranslations(translations);

  const handleCancel = () => {
    dispatch(closeModalAction());
  }

  const handleContinue = () => {
    if(optInsValues.includes(false)) {
      setIsAlertVisible(true);
      return;
    }

    closeModal();
  }

  const handleOptIn = (index) => (value) => {
    const newOptInsValues = [...optInsValues];
    newOptInsValues[index] = value;

    setOptInsValues(newOptInsValues);
    setIsAlertVisible(false);
  }

  const handleTerms = () => {
    dispatch(closeModalAction());
  }

  return (
    <div className="modal-sms-accept">
      <div className="phone-icon modal-sms-accept__icon" />
      <h4 className="modal-sms-accept__title">{i18n.title}</h4>
      <div className="modal-sms-accept__subtitle">{i18n.subtitle}</div>

      <div className="modal-sms-accept__opt-ins">
        {i18n.optIns.map(({ title, content }, index) => (
          <ModalSmsAcceptOptInItem key={`modal-sms-accept__opt-in-${index}`} title={title} content={content} onChangeOptIn={handleOptIn(index)} />
        ))}
      </div>

      <ModalSmsAcceptAlert show={isAlertVisible} title={i18n.alertTitle} />

      <hr className="modal-sms-accept__hr" />

      <div className="modal-sms-accept__text-bottom">{i18n.messageRateApply}</div>

      <div className="modal-sms-accept__text-bottom">
        {i18n.wantToReadMore}{' '}
        <Link to={'/terms-conditions'} onClick={handleTerms}>{i18n.readTermsConditions}</Link>
        <a></a>
      </div>

      <div className="modal-sms-accept__buttons">
        <ButtonBS v2 className="v2--red" content={i18n.cancel} onClick={handleCancel}/>
        <ButtonBS v2 className="v2--red" content={i18n.continue} onClick={handleContinue}/>
      </div>
    </div>
  );
};

export default ModalSmsAccept;