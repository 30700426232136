import * as types from './actionTypes.js';
import createAxe from '../../middlewares/Axe';

export function getContractContent() {
  /**
   * Responsibility:
   * 1) Get me operator id from state
   * 2) Get me the atm id from state (I need the ids above to fulfill the endpoint request to operator service to fetch terms)
   * 3) Create axios object to fetch terms
   * 4) Fetch terms
   * 5) Remove style tags
   * 6) Set terms in state
   */
  return async function (dispatch, getState) {
    try {
      const operatorId = getState().machine.operator_id; // 1
      const atmId = getState().machine.atm_id; // 2
      const axios = createAxe(getState()); // 3
      let res = await axios.get(`/operator/v2/operator/${operatorId}/terms_and_conditions`, {
        headers: {
          'Atm-Token': `Bearer ${atmId}` 
        }
      });
      const sanitizedContent = removeStyleTags(res.data.data);
      const disabledLinksContent = disableLinks(sanitizedContent);
      dispatch(setTermsAndConditionsContent(disabledLinksContent));
    } catch (error) {
      console.error(error);
    }
  }
}

/**
 * Responsibility: 
 * 1) Create regexp to grab style tags from the string in a specific.
 * 2) After correctly selecting style tag bit in the string remove it...
* */
function removeStyleTags(html) {
  // Regular expression to match <style> tags anywhere in the HTML string
  const styleTagRegex = /<style[^>]*>[\s\S]*?<\/style>/gi;
  // Remove all <style> tags from the HTML content
  return html.replace(styleTagRegex, '');
}

/**
 * Responsibility: 
 * 1) Create regexp to match <a> tags in the string.
 * 2) After correctly selecting <a> tags in the string, add the disabled attribute to each of them.
 */
function disableLinks(html) {
  // Regular expression to match <a> tags anywhere in the HTML string
  const anchorTagRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/gi;
  // Replace all <a> tags with the same tag but with the disabled attribute added
  return html.replace(anchorTagRegex, '<a $1 $2 disabled>');
}

export function setTermsAndConditionsContent(content) {
  return {
    type: types.SET_TERMS_AND_CONDITIONS,
    content
  }
}
