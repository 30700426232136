import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import * as scanActions from '../../../actions/scanActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/EnterID/EnterID.css';
import InputFieldBS from '../../../components/common/input-fields/InputFieldBS';
import KeyboardBS from '../../../components/KeyboardBS';
import InputBirthBS from '../../../components/common/input-fields/InputBirthBS';
import moment from 'moment'

class EnterID extends Component{

  constructor(props){
    super(props);
    this.state={
      id_number: '',
      exp_date:  '',
      inputNode: null
    }
    this.baseState = this.state;

    this.handleDate = this.handleDate.bind(this);
    this.submit = this.submit.bind(this);
    this.closeKeyboard = this.closeKeyboard.bind(this);
  }

  handleChangeField(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleDate(exp_date){
    this.setState({exp_date: exp_date})
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  closeKeyboard(){
    this.setState({inputNode: null})
  }

  submit(){
    let exp_date = moment(this.state.exp_date,'MM/DD/YYYY');
    if (exp_date.isValid() && this.state.id_number)
      this.props.actions.scan.addIdInfo(this.state.id_number, exp_date)
    else
      console.error('INVALID DATE FORMAT')
  }

  componentDidMount(){
    document.getElementById('id_number').focus();
    this.props.actions.setStage(2)
  }

  render(){
    return(
      <PageLayout title='Enter ID Info'>
        <InputFieldBS
          id='id_number'
          value={this.state.id_number}
          onFocus={()=>this.openKeyboard(document.getElementById('id_number'))}
          onInput={this.handleChangeField}
          className='enter-id-num'
          title='ID Number'
        />
        <div className='enter-exp-date'>
          <InputBirthBS date={this.handleDate} language={this.props.i18n.language.current} />
        </div>
        <ButtonBS className='button-info-id' content='Submit' onClick={this.submit}/>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.closeKeyboard}
            showNumber = {true}
          /> : null }
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      scan: bindActionCreators(scanActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch)
    }
  }
}

export default connect(null, mapDispatchToProps)(EnterID);