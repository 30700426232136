import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../../components/common/PageLayout';
import * as scanActions from '../../../../actions/scanActions';
import * as modalActions from '../../../../actions/modalActions';
import { setStage } from '../../../../actions/transactionActions';
import videoScanID from '../../../../styles/assets/ID_1.webm';
import { ScanID as i18n } from '../../../../middlewares/i18n';

class ScanID extends Component{

componentDidMount(){
  this.props.actions.setStage(2);
  
  setTimeout(() => {
    this.props.actions.scan.scanBarcode();
  }, 1000)
}

render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        <video controls={false} autoPlay loop width='1200' height='1150'>
          <source src={videoScanID} type='video/webm'/>
        </video>
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      scan: bindActionCreators(scanActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      modal: bindActionCreators(modalActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanID)