import React, { memo } from 'react';

import ButtonBS from '../../ButtonBS';
import AlertCard from '../../AlertCard/AlertCard';
import ScamsListSender from '../../ScamsListSender/ScamsListSender';

import useTranslations from '../../../../hooks/useTranslations';

import translations from '../../../../middlewares/i18n/ScanWallet';

import '../../../../styles/common/Modals/ModalUrWallet.new.css';

const ModalWallet = ({ address, closeModal, openModalScam }) => {
  const i18n = useTranslations(translations);

  return (
    <div className="modal-wallet">
      <div className="question-red-icon modal-wallet__icon" />
      <h4 className="modal-wallet__title">{i18n.controlThisAddress}</h4>

      <div className="modal-wallet__address">{address}</div>

      <div className="modal-wallet__buttons">
        <ButtonBS v2 className="v2--red" content={i18n.yes} onClick={closeModal}/>
        <ButtonBS v2 className="v2--red" content={i18n.no} onClick={openModalScam}/>
      </div>

      <div className="modal-wallet__alert-card">
        <AlertCard title={i18n.cardTitle} content={i18n.cardContent1} subContent={i18n.cardContent2} />
      </div>

      <div className="modal-wallet__footer">
        <ScamsListSender />
      </div>
    </div>
  ); 
};

export default memo(ModalWallet);