import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as purchaseActions from '../../../actions/purchaseActions';
import { routerActions } from 'connected-react-router';
import '../../../styles/StartingScreen/StartingScreen.css';
import { FinishScreen as i18n } from '../../../middlewares/i18n';

// This should be promoted later to a reusable component
// but not today, because we need to create a new store state
// to determine type of finish screen
class PasswordChanged extends Component {

  constructor(props){
    super(props);
    this.state={
      address:'',
      timer: null
    }
  }

  componentDidMount(){
    let timer = setTimeout(()=>{
      this.props.actions.router.push('/');
    }, 5000);

    this.setState({
      timer
    })
  }

  componentWillUnmount(){
    clearTimeout(this.state.timer)
  }

  render(){
    return(
      <div className='starting-container bs-body centered'>
        <br />
        <div className='bs-logo-screen starting-logo'/>
        <div>
          <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{i18n[this.props.i18n.language.current].passwordChanged}</h6>
          <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{i18n[this.props.i18n.language.current].relogin}</h6>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    qa: state.qa,
    i18n: state.i18n
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      router: bindActionCreators(routerActions, dispatch),
      purch: bindActionCreators(purchaseActions, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChanged);