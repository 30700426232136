import initialState from './initialState';

export default function loadingReducer(state = initialState.loading, action){

  switch(action.type){

    case 'LOADING_MESSAGE':
      return Object.assign({}, state, {
        message: action.message
      })

    case 'LOADING_STATUS':
      return Object.assign({}, state, {
        status: action.status
      })

    default:
      return state
  }
}