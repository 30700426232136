export function addEventListenNonPassive(element, event, fun){
  element.addEventListener(event, fun, {passive: false})
}

export function addEventListen(element, event, fun){
  element.addEventListener(event, fun)
}

export function addEventsListen(element, listEvents, fun, passive){
  if(!passive){
    for(let i = 0; i < listEvents.length; i++){
      addEventListenNonPassive(element, listEvents[i], fun)
    }
  } else{
    for(let i = 0; i < listEvents.length; i++){
      addEventListen(element, listEvents[i], fun)
    }
  }
}

export function removeEvent(element, event, fun){
  element.removeEventListener(event, fun)
}

export function removeEvents(element, listEvents, fun){
  for(let i = 0; i < listEvents.length; i++){
    removeEvent(element, listEvents[i], fun)
  }
}