import React from 'react';
import '../../styles/PriceBS/PriceDisplay.css';
import { toDollar } from '../../helpers/helperFunctions';

const PriceDisplay = (props) =>{
  return(
    <div>
      <h4 className={'price-display no-margin '+props.className}>1 BTC = ${toDollar(props.price)}</h4>
    </div>
  )
}

export default PriceDisplay;