import { push } from 'connected-react-router';

export function BillAcceptorHandler(store, code, description){
  switch(code){
    case -11:
      console.log(description);
      break;

    case -12:
      console.log(description);
      break;

    case -13:
      console.log(description);
      break;

    case -14:
      console.log(description);
      break;

    case -15:
      console.log(description);
      break;

    case -16:
      console.log(description);
      break;

    case -17:
      console.log(description);
      break;

    case -18:
      console.log(description);
      break;

    case -19:
      console.log(description);
      store.dispatch({type: 'MAINTENANCE_MODE_ON'})
      setTimeout(()=>store.dispatch(push('/maintenance-mode')),500);
    break;

    case -20:
      console.log(description);
      store.dispatch({type: 'MAINTENANCE_MODE_ON'})
      setTimeout(()=>store.dispatch(push('/maintenance-mode')),500);
    break;

    default:
      console.log('Code not recognized');
  }
}