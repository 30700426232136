import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import PageLayout from '../../../components/common/PageLayout/PageLayout4';
import ButtonBS from '../../../components/common/ButtonBS';
import * as placesActions from '../../../actions/placesActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/EnterAddress/EnterAddress.css';
import InputAddressBS from '../../../components/common/input-fields/InputAddressBS';
import InputFieldBS from '../../../components/common/input-fields/InputFieldBS';
import KeyboardBS from '../../../components/KeyboardBS';
import { routerActions } from 'connected-react-router';
import { placesWSSsend } from '../../../middlewares/WebSocket-Middleware'
import { EnterAddress as i18n } from '../../../middlewares/i18n';
import { updateAddress } from '../../../actions/updateActions';
import { enforceScanID } from '../../../actions/viewActions';

import { track } from '../../../helpers/analytics';

class EnterAddress extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      address:'',
      street_number:'',
      route:'',
      street:'',
      locality:'',
      administrative_area_level_1:'',
      country:'',
      postal_code:'',
      lat:'',
      lng:'',
      inputNode: null,
    }
    this.baseState = this.state;
    this.nextField = this.nextField.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
  }
  
  handleChangeAddress(value){
    if(value){
      this.setState(this.baseState, 
        ()=>{
          this.setState({
            address: ''
          },()=>{
            this.props.actions.places.selectPlace(value)
          })
        });
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(!isEqual(prevProps.places.place, this.props.places.place)){
      if(!isEmpty(this.props.places.place)){
        this.setFields(this.props.places.place)
      }
    }
    if(!isEqual(prevState.route, this.state.route) || !isEqual(prevState.street_number, this.state.street_number)){
      this.setState({street: `${this.state.street_number} ${this.state.route}`})
    }
  }

  setFields(fields){
    let field = {}
    for(let i = 0; i < fields.length; i++){
      field = fields[i]
      if (field.types[0] in this.state){
        this.setState({ [field.types[0]]: field.short_name })
      }
    }
  }

  handleChangeField(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleLocation(location){
    if(location)
      placesWSSsend(location)
    else
      this.props.actions.places.setPlaces([])
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  closeKeyboard(){
    this.setState({inputNode: null})
  }

  nextField(){
    if(this.state.inputNode.id==='country'){
      this.closeKeyboard();
    } else{
      for(let i = 0; i < document.getElementsByTagName('input').length; i++){
        if(document.getElementsByTagName('input')[i].id === this.state.inputNode.id){
          document.getElementsByTagName('input')[i+1].focus();
          break
        }
      }
    }
  }

  submit(){
    if(this.state.street && this.state.street !== ' ' && this.state.locality && this.state.administrative_area_level_1 && this.state.country && this.state.postal_code){
      track('Address Entered');
      
      this.button1.setAttribute("disabled", "disabled");
      this.props.actions.updateAddress(this.state);
    }
  }

  componentDidMount(){
    document.getElementsByTagName('input')[0].focus();
    this.props.actions.setStage(2)
  }

  render(){
    return(
      <PageLayout
        title={i18n[this.props.i18n.language.current].title}
        price={false}
        big={false}
        noPadHead={true}
      >
        <div className="enter-address-container">
          { this.props.kyc ?
          <div className='or-scan-id-addr'>
            <h2 className="bs-subtitle no-margin">{i18n[this.props.i18n.language.current].skipStep}</h2>
            <ButtonBS
              className='scan-id-button-add'
              content={i18n[this.props.i18n.language.current].scanID}
              onClick={this.props.actions.enforceScanID}
            />
          </div> : null }
          <InputAddressBS
            address={this.handleChangeAddress.bind(this)}
            onFocus={()=>this.openKeyboard(document.getElementById('location'))}
            sendLocation={this.handleLocation.bind(this)}
            options={this.props.places.nearest}
          />
          <InputFieldBS
            id='street'
            className='street'
            value={this.state.street}
            onFocus={()=>this.openKeyboard(document.getElementById('street'))}
            onInput={this.handleChangeField}
            title={i18n[this.props.i18n.language.current].street}
          />
          <div className="city-state-zip">
            <InputFieldBS
              id='locality'
              value={this.state.locality}
              onFocus={()=>this.openKeyboard(document.getElementById('locality'))}
              onInput={this.handleChangeField}
              className='city'
              title={i18n[this.props.i18n.language.current].city}
            />
            <InputFieldBS
              id='administrative_area_level_1'
              value={this.state.administrative_area_level_1}
              onFocus={()=>this.openKeyboard(document.getElementById('administrative_area_level_1'))}
              onInput={this.handleChangeField}
              className='state'
              title={i18n[this.props.i18n.language.current].state}
            />
            <InputFieldBS
              id='postal_code'
              value={this.state.postal_code}
              onFocus={()=>this.openKeyboard(document.getElementById('postal_code'))}
              onInput={this.handleChangeField}
              className='zip-code'
              title={i18n[this.props.i18n.language.current].zipCode}
            />
          </div>
          <InputFieldBS
            id='country'
            value={this.state.country} 
            onFocus={()=>this.openKeyboard(document.getElementById('country'))}
            onInput={this.handleChangeField}
            className='country'
            title={i18n[this.props.i18n.language.current].country}
          />
          <ButtonBS
            className='enter-add-button'
            content={i18n[this.props.i18n.language.current].submit}
            onClick={this.submit.bind(this)}
            refButton={(button) => {this.button1 = button}}
          />
          { this.state.inputNode ?
            <KeyboardBS
              inputNode={this.state.inputNode}
              close={this.closeKeyboard.bind(this)}
              showNumber = {true}
              next={this.nextField}
            /> : null }
        </div>
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      setStage: bindActionCreators(setStage, dispatch),
      router: bindActionCreators(routerActions, dispatch),
      places: bindActionCreators(placesActions, dispatch),
      updateAddress: bindActionCreators(updateAddress, dispatch),
      enforceScanID: bindActionCreators(enforceScanID, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    places: state.places,
    kyc: state.machine.kyc,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterAddress);