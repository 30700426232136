import _ from 'lodash';
import BillAcceptorHandler from './BillAcceptorHandler.jsx';
import QRHandler from './QRHandler.jsx';
import ImagingDeviceHandler from './ImagingDeviceHandler.jsx';
import PrinterHandler from './PrinterHandler.jsx';
import CardReaderHandler from './CardReaderHandler.jsx';
import IndicatorHandler from './IndicatorHandler.jsx';
import PinPadHandler from './PinPadHandler.jsx';


export default function AnalyzeStatus(store){
  let status = store.getState().maintenance.statuses; //get all statuses
  let hardware = ''                                   //declare hardware

  if(Object.values(status).indexOf('FATAL') > -1){    //Check if there is a fatal error
    hardware =_.findKey(status,_.partial(_.isEqual, 'FATAL'));  //Find in what Hardware
  }

  else if(Object.values(status).indexOf('UNKNOWN') > -1){  //Check if there is a Unknown error
    hardware = _.findKey(status,_.partial(_.isEqual, 'UNKNOWN')); //Find in what Hardware
  }

  else{                                 //If there is no errors
    hardware='';                        //Set the hardware to empty and then check every hardware (will help to get out of any maintenance mode)
    BillAcceptorHandler(store, status);
    QRHandler(store, status);
    PinPadHandler(store, status);
    ImagingDeviceHandler(store, status);
    PrinterHandler(store, status);
    CardReaderHandler(store, status);
    IndicatorHandler(store, status);
  }

  switch(hardware){                       //If there is a hardware error, trigger it respective function
    case 'BillAcceptorService':
      BillAcceptorHandler(store, status);
      break;
    case 'QRService':
      QRHandler(store, status);
      break;
    case 'PinPadService':
      PinPadHandler(store, status);
      break;
    case 'ImagingDeviceService':
      ImagingDeviceHandler(store, status);
      break;
    case 'PrinterService':
      PrinterHandler(store, status);
      break;
    case 'CardReaderService':
      CardReaderHandler(store, status);
      break;
    case 'IndicatorService':
      IndicatorHandler(store, status);
      break;
  }
}