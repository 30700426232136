import React from 'react';
import '../../../styles/common/Ticker/Ticker.css'

function Ticker({ title, content, left}){
  return(
    <div className={ left ? 'ticker-container ticker-left' : 'ticker-container ticker-right'}>
      <div className='ticker-content'>
        <div className='ticker-tab'>
          { title ? <h6 className='ticker-tit no-margin'>{ title }</h6> : null }
          <h5 className='tick-cont no-margin'>{ content }</h5>
        </div>
      </div>
    </div>
  )
}

export default Ticker;