import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import * as sellActions from '../../../actions/sellActions';
import ButtonBS from '../../../components/common/ButtonBS';

class DispenseCash extends PureComponent {

  constructor(props){
    super(props);
    this.state={
      quantity: 0,
      bills: 0
    }
  }

  addQuantity(e){
    this.setState({
      quantity: parseInt(e.target.value, 10)
    },()=>console.log('state', this.state.quantity))
  }

  addBills(e){
    
    // let value = e.target.value.replace(/ /g,'');
    // let array = value.split(',').map( num => {
    //   return parseInt(num);
    // });

    // this.setState({
    //   bills: array
    // }, ()=>console.log('number of bills', this.state.bills))
    this.setState({
      bills: parseInt(e.target.value, 10)
    },()=>console.log('state', this.state.bills))
  }

  // componentDidMount(){
  //   this.props.actions.sell.setCassetes([this.state.bills]);
  // }

  render(){
    return(
      <PageLayout title='Dispenser'>
        <div style={{margin: '200px auto', textAlign: 'center', display: 'inline-block'}}>
          <input onChange={this.addQuantity.bind(this)} style={{margin: '60px auto', display: 'block', width: '70%', fontSize: '100px'}}/>
          <ButtonBS style={{display: 'block'}} content='Dispense' onClick={()=>this.props.actions.sell.dispenseCash(this.state.quantity)}/>
          <input onChange={this.addBills.bind(this)} style={{margin: '60px auto', display: 'block', width: '70%', fontSize: '99px'}}/>
          <ButtonBS style={{display: 'block'}} content='Set Bills' onClick={()=>this.props.actions.sell.setCassetes(this.state.bills)}/>
        </div>
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      sell: bindActionCreators(sellActions, dispatch)
    }
  }
}

export default connect(null, mapDispatchToProps)(DispenseCash)