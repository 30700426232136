import React from 'react';

const ModalSmsAccept = ({ show, title }) => {
  if(!show) {
    return false;
  }

  return (
    <div className="modal-sms-accept-alert">
      <div className="error-icon modal-sms-accept-alert__icon" />
      <div className="modal-sms-accept-alert__title">{title}</div>
    </div>
  );
};

export default ModalSmsAccept;