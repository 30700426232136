import initialState from './initialState';

export default function scamReducer(state = initialState.scam, action){

  switch(action.type){
    case 'SCAM_LINK_STATUS':
      return Object.assign({}, state, {
        link_status: action.status
      })

    default:
      return state
  }
}