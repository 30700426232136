import moment from 'moment';

/**
 * Get UTC Date String for app
 * @param {String} date 
 * @param {String} [format_in=null] - Format of the string `date`
 * @returns {String} A valid string that can be passed to momentjs without format_in
 */
export function getUTCDateString(date, format_in = null){
    let _date = getMoment(date, format_in)

    // If no valid, just get current moment of browser
    if (!_date.isValid())
        return moment().toISOString()

    return _date.toISOString()
}

/**
 * Get moment object
 * @param {String} date 
 * @param {String} [format_in=null] - Format of the string `date`
 * @returns {moment} A moment object
 */
export function getMoment(date, format_in = null){
    if(format_in)
        return moment(date, format_in)
    return moment(date)
}

/**
 * 
 * @param {String} date 
 * @param {String} format_out - How date should be formatted
 * @param {String} [format_in=null] - Format of the string `date`
 * @returns {String} Date Formatted
 */
export function getFormattedDate(date, format_out, format_in = null){
    return getMoment(date, format_in).format(format_out)
}

/**
 * 
 * @param {String} offset - Offset (format ex. "-0400")
 * @param {String} date 
 * @param {String} format_out - How date should be formatted
 * @param {String} [format_in=null] - Format of the string `date`
 * @returns {String} Date Formatted
 */
export function getFormattedDateWithTz(offset, date, format_out, format_in = null){
    return getMoment(date, format_in).utcOffset(offset).format(format_out)
}