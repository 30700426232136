import * as types from './actionTypes';

export function dispenseCash(dispense_cash){
  return{
    type: types.DISPENSE_CASH,
    dispense_cash,
    signalR: true
  }
}

export function setCassetes(cassetes){
  return{
    type: types.SET_CASSETES,
    signalR: true,
    cassetes
  }
}