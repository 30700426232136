export default {
  rate:{
    price: '99999.99',
    fees: [               // Default Fees
      {
        min: 10,
        max: 99,
        flat: 1.99,
        percent: 13.5
      },
      {
        min: 100,
        max: 3000,
        flat: null,
        percent: 13.5
      }
    ],
    fee: 13.5,
    min: 10,
    precio_host: 'wss://precio-ws.bitstop.co/v1/precio/btc-price?with_atm_fee=true'
  },
  coupon: {
    applied: false,
    new_fees: null,
    old_fees: null,
    code: '',
    coupon_type: '',
    value: 0,
    curr_fee: null,
    old_fee: null,
    old_charges: null,
    savings: null
  },
  auth:{
    phone: '',
    areaCode: '',
    completePhone:'',
    code: 0,
    errorMessage:''
  },
  client:{
    name: '',
    firstName:'',
    lastName:'',
    dob:'',
    id:'',
    tier:'',
    address:{
      street: '',
      city:'',
      state: '',
      country:'',
      zip: ''
    },
    max: '',
    pictures: [],
    spent: 0,
    hodlLink: true,
    client_id:'',
    range_fees: [],
    tiers: [],
    listTx: [],
    dashboard_message: "",
    transactional: '',
    hard_restricted: false,
    policies_fulfilled: [],
    max_tier: false
  },
  purchase:{
    tiers: [],
    transactional: '',
    date: '',
    tier: '',
    num_bills: 0,
    bills: {
      1: 0,
      2: 0,
      5: 0,
      10: 0,
      20: 0,
      50: 0,
      100: 0
    },
    fee: 13.5,
    subtotal: 0,
    totalfee: 0,
    total: 0,
    btc: 0,
    btcprice: 0,
    message:'',
    address:'',
    address_id:'',
    exit: true,
    idtx:'',
    idMachine:'',
    flat: 0,
    time_sent: 'Thu, 30 Jul 2015 15:26:13 GMT',
    spotPrice: 0,
    spread: 0,
    quotedExchange: 'Bitstop'
  },
  signalr:{
    active: false,
  },
  pinpad:{
    enter: false,
    clear: false,
    key:''
  },
  maintenance:{
    active: true,
    code: 0,
    hardware:'',
    description:'',
    statuses: {},
    printerWork: true,
    BAConnected: true,
    cameraWorking: true,
    cardReaderWorking: true,
    dispenserWorking: true,
    errorMessage:'',
    errorCode: 0
  },
  transaction:{
    init: false,
    stage: 0,
    scanningGenerator: null
  },
  modal:{
    open: false,
    open_reminder: false,
    modalType: ''
  },
  machine:{
    name: 'morty',
    tiny: false,
    network: '',
    updateAvailable: false,
    atm_id: '',
    state: 9,
    lang: [
      {
        language: 'English',
        locale: 'en-us'
      }
    ],
    intlphone: [
      { "value": "US", "label": "United States" }
    ],
    timezone: '-0400',
    kyc: true,
    gas_key: '',
    primary: {
      country: 'US',
      language: 'en-us'
    },
    setting_id: 0,
    operator_id: null,
    operator_name: "Bitstop",
    operator_image: '',
    operator_address: {
      street_address: null,
      street_address2: null,
      city: null,
      state: null,
      zipcode: null,
      country: null,
      full_address: null
    },
    operator_nmls: null,
    local_state: null
  },
  loading:{
    status: false,
    message: 'Loading'
  },
  qa:{
    serial: '',
    qa_partial: false,
    start: false
  },
  light:{
    service: '',
    state: 0
  },
  view :{
    scam_view: false,
    screen: '',
    title: '',
    content: '',
    list: [],
    valid_agree_req: false,
    req_upgrade_persona: false,
    req_upgrade_manual: false
  },
  db:{
    sell:{
      date: "",
      address: "",
      custom_token: "",
      address_id: "",
      idtx: "",
      bills: {
        "one": "0",
        "two": "0",
        "five": "0",
        "ten": "0",
        "twenty": "0",
        "fifty": "0",
        "one_hundred": "0"
      },
      num_bills: "",
      subtotal: "",
      totalfee: "",
      total: "",
      btc: "",
      btcprice: "",
      fee: ""
    }
  },
  places:{
    nearest:[],
    place:{}
  },
  i18n: {
    language: {
      defLang: 'en',
      current: 'en',
      available: ['en', 'es']
    }
  },
  report: {
    pick_up_id: "",
    operator: "",
    atm_name: "",
    atm_slug: "",
    location: "",
    bills: {
      one: 0,
      two: 0,
      five: 0,
      ten: 0,
      twenty: 0,
      fifty: 0,
      hundred: 0
    },
    total_bills: 0,
    total_usd: 0
  },
  tiers: {
    tiers: [
      {
        id: 5,
        name: "Basic",
        type: "basic",
        required_policies: [
          {
            id: 1,
            name: "Basic Info",
            type: "basic_info"
          },
          {
            id: 2,
            name: "Date of Birth",
            type: "address"
          },
          {
            id: 2,
            name: "Address",
            type: "address"
          }
        ],
        limit_max: 1000
      },
      {
        id: 6,
        name: "Silver",
        type: "silver",
        required_policies: [
          {
            id: 1,
            name: "Basic Info",
            type: "basic_info"
          },
          {
            id: 2,
            name: "Date of Birth",
            type: "address"
          },
          {
            id: 2,
            name: "Address",
            type: "address"
          },
          {
            id: 3,
            name: "Scan ID",
            type: "government_id_number"
          }
        ],
        limit_max: 3000
      },
      {
        id: 7,
        name: "Gold",
        type: "gold",
        required_policies: [
          {
            id: 1,
            name: "Basic Info",
            type: "basic_info"
          },
          {
            id: 2,
            name: "Date of Birth",
            type: "address"
          },
          {
            id: 2,
            name: "Address",
            type: "address"
          },
          {
            id: 4,
            name: "ID and Selfie",
            type: "government_id_and_selfie"
          },
          {
            id: 5,
            name: "SSN",
            type: "ssn"
          }
        ],
        limit_max: 5000
      },
      {
        id: 8,
        name: "Premium",
        type: "premium",
        required_policies: [
          {
            id: 1,
            name: "Basic Info",
            type: "basic_info"
          },
          {
            id: 2,
            name: "Date of Birth",
            type: "address"
          },
          {
            id: 2,
            name: "Address",
            type: "address"
          },
          {
            id: 4,
            name: "ID and Selfie",
            type: "government_id_and_selfie"
          },
          {
            id: 5,
            name: "SSN",
            type: "ssn"
          },
          {
            id: 6,
            name: "Manual Approval",
            type: "manual_review"
          }
        ],
        limit_max: 10000
      }
    ],
    instructions: {
      title: "",
      instructions: ""
    }
  },
  scam: {
    link_status: false
  },
  tos: {
    content: ""
  }
}