import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonBS from '../../../../components/common/ButtonBS';
import PageLayout from '../../../../components/common/PageLayout';
import InputPinBS from '../../../../components/common/input-fields/InputPinBS';
import * as authActions from '../../../../actions/authActions';
import { setStage } from '../../../../actions/transactionActions';
import * as lightActions from '../../../../actions/lightActions';
import '../../../../styles/Pin/Pin.css';
import { SetPin as i18n, Errors as i18nErrors } from '../../../../middlewares/i18n';

class SetPin extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      pin:['','','',''],
      submitting: false,
      key: '',
      title: '',
      message: '',
      submitContent: '',
      tempPin: ''
    }
    this.submit = this.submit.bind(this);
    this.getPin = this.getPin.bind(this);
  }

  resetSubmitting() {
    this.setState({ submitting: false })
  }

  createPin() {
    let pin = this.getStatePinStr()
    if(pin)
      this.setState({
        tempPin: pin,
        title: i18n[this.props.i18n.language.current].confirmPin,
        message: i18n[this.props.i18n.language.current].confirmMessage,
        submitContent: i18n[this.props.i18n.language.current].confirm
      }, () => this.resetSubmitting())
  }

  confirmPin() {
    let confirmPin = this.getStatePinStr()

    if(confirmPin) {
      // Pin doesn't match
      if(confirmPin !== this.state.tempPin) {
        this.props.actions.auth.invalidInput(i18nErrors[this.props.i18n.language.current].unmatchPin)
        this.resetSubmitting()
      }
      else {
        this.props.actions.auth.updatePin(confirmPin);
      }
    }
  }

  getStatePinStr() {
    // If we don't have a 4 pin digit -> Error
    if (this.state.pin.includes('')){
      this.props.actions.auth.invalidInput(i18nErrors[this.props.i18n.language.current].shortPin);
      this.resetSubmitting()

      return
    }

    let pin='';
    for (var i=0; i < this.state.pin.length; i++){
      pin += this.state.pin[i];
    }

    return pin
  }

  submit(){
    if(!this.state.submitting) {
      this.setState({
        submitting: true
      }, () => {
        // We haven't set a temp pin
        if(!this.state.tempPin)
          this.createPin()
        else
          this.confirmPin()

        this.setState({ submitting: false })
      })
    }
  }

  getPin(value){
    this.setState({
      pin: value
    })
  }

  componentWillMount(){
    this.setState({
      title: i18n[this.props.i18n.language.current].setPin,
      message: i18n[this.props.i18n.language.current].setMessage,
      submitContent: i18n[this.props.i18n.language.current].submit
    })
  }

  componentDidMount(){
    if(!this.props.mach.tiny)
      this.props.actions.light.setLight(1,"PPS")
  }

  // componentDidUpdate(prevProps){
  //   if(this.props.mach.name === 'morty'){
  //     if (this.props.pinpad.enter && this.props.pinpad.enter !== prevProps.pinpad.enter)
  //       this.submit()
  //     else if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
  //       this.setState({ key: this.props.pinpad.key }, () => this.setState({ key: '' }))
  //     else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)      //Both cancel and clear
  //       this.setState({ key: 'CLEAR' }, () => this.setState({ key:'' }))
  //   }
  // }

  render(){
    return(
      <PageLayout title={this.state.title} errorMessage={this.props.auth.errorMessage}>
        <div className='pin-container'>
          <InputPinBS sendPin={this.getPin} submited={this.state.submitting}  submit={this.submit} keyPress={this.state.key}/>
          <h6 className='bs-subtitle no-margin remember'>{this.state.message}</h6>
        </div>
        { !this.props.mach.tiny ?
          <ButtonBS content={this.state.submitContent} onClick={this.submit} disabled={this.state.submitting}/> :
        null }
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      auth: bindActionCreators(authActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      light: bindActionCreators(lightActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    auth: state.auth,
    mach: state.machine,
    pinpad: state.pinpad,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPin)