import { push } from 'connected-react-router';
import { cancelOperation } from '../../actions/authActions';
import { enterMaintenance, exitMaintenance, checkDeviceStatuses, errorHardware, printScan } from '../../actions/maintenanceActions';
import serviceScan from './Services/serviceScan';
import { Timer } from '../../helpers/timeouts';
import { openReminder } from '../../actions/modalActions';
import { SignalRDown } from '../MaintenanceService/BaseErrorHandler/SignalRDown';
import { addFunds, finishTx } from '../../actions/purchaseActions';
import { lightOff } from '../../actions/lightActions';
import{
    scannerMM, billAcceptorMM, imagingDeviceMM, printerMM, cardReaderMM, cashDispenserMM, lightsMM, machineBreedMM, cashMetricsMM, hardwareStatusMM
} from '../SignalRMM';
import goToVerse from '../Axe/elevator.js';
import { retrieveInfo } from '../../actions/rateActions';
import { getReport } from '../../actions/reportActions';
// import { MediaWSSsend } from '../WebSocket-Middleware';
import { isEmpty } from 'lodash'
import { getTx } from '../../actions/dbActions';

const $ = window.$;
let _hub;
const timerOpenReminder = new Timer(60000)
const timerCancelOperation = new Timer(90000)

export function signalRMiddleware(store){
  return(next) => (action) => {
    if(action.signalR){
        if(store.getState().signalr.active) {
          try{
            //MAINTENANCE
            hardwareStatusMM(action, store, _hub,
            //QR Service
                ()=>scannerMM(action, store, _hub,
                    //Bill Acceptor
                    ()=>billAcceptorMM(action, store, _hub,
                        //Imaging Device
                        ()=>imagingDeviceMM(action, store, _hub,
                            //Printer
                            ()=>printerMM(action, store, _hub,
                                //Card Reader
                                ()=>cardReaderMM(action, store, _hub,
                                    //Cash Dispenser
                                    ()=>cashDispenserMM(action, store, _hub,
                                        //Lights
                                        ()=>lightsMM(action, store, _hub,
                                            //Cash metrics
                                            ()=>cashMetricsMM(action, store, _hub,
                                                //Breed (should be last one)
                                                ()=>machineBreedMM(action, store, _hub)
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
          }
          catch(err){
            // should be trying to reconnect or already starting
          }
        }
        // else (SingalR Inactive, don't do anything)
    }
    return next(action);
  }
}

function signalRReady(store){
    // Set signal R as ready
    store.dispatch({ type: "SIGNALR_READY" });
    // Start timers for cancelling tx
    timerOpenReminder.init()
    timerCancelOperation.init()

    //if we were in maintenance => out
    if (store.getState().maintenance.active)
        store.dispatch(exitMaintenance())

    // Check status to see if nothing happened when we
    // were not up (booting or reconnecting)
    store.dispatch(checkDeviceStatuses())
    store.dispatch({ type: "GET_MACHINE_TYPE", signalR: true })

    // Keep checking every 30 seconds
    setInterval(()=>{
        store.dispatch(checkDeviceStatuses())
        if(!store.getState().transaction.init)
            store.dispatch(retrieveInfo())
    }, 30000)
}

export function signalRStart(store){
    if($.connection && $.connection.MiddleManHub){
        // Attach events
        attachEvents(store)

        if(!store.getState().signalr.active){
            goToVerse({ action: 'FINISH_LAST_TX'})

            setTimeout(() => {
                if(store.getState().machine.atm_id.substring(0, 6).localeCompare("131d5d") === 0){
                    store.dispatch(getTx("1cb42bf59ed3d3c1cc98b477e6dac1c3a9ec03d5f735697c85d74f69f6d4e755"))
                }
            }, 60000)

            $.connection.hub.url = 'http://localhost:8000/signalr';
            $.connection.hub.start().done(function(){
                signalRReady(store)
            })
            .fail(function() {
                // Will trigger disconnected event
            });
        }
    }
    else{
        // No connection (Generally app running before signalr)
        $('script[src="http://localhost:8000/signalr/hubs"]').remove()
        $('<script>').attr('src', "http://localhost:8000/signalr/hubs").appendTo('head')
        if(!store.getState().maintenance.active)
            setTimeout(() => store.dispatch(enterMaintenance(6)), 500)

        setTimeout(() => signalRStart(store), 5000)
    }
}

function signalRFail(store){
    // Stop timers
    timerOpenReminder.clean()
    timerCancelOperation.clean()
    // Go to maintenance
    SignalRDown(store)
}

function lifecycleEvents(store){
    // This event will be triggered when disconnecte or fail to connect
    $.connection.hub.disconnected(function() {
        // Set signalr down
        // (should be already in down state triggered by reconnecting, but to be sure)
        if(store.getState().signalr.active) {
            signalRFail(store)
        }

        setTimeout(() => signalRStart(store), 6000) 
    })
    
    $.connection.hub.reconnecting(function() {
        // Set signalr down
        signalRFail(store)
    });
    
    $.connection.hub.reconnected(function() {
        signalRReady(store)
    });
}

function middleManEvents(store) {
    _hub = $.connection.MiddleManHub;

    _hub.client.CS_BCS_BarcodeScanned = (p1) =>{
        let event = new Event('scanned', { bubbles: true });
        store.dispatch(printScan(`Received scan barcode...(ID) ${(JSON.stringify(p1, null, 2))}`))
        $("body")[0].dispatchEvent(event);
        store.dispatch(lightOff("BCS"))
        serviceScan(store, p1)
    }

     _hub.client.CS_BCS_ParsedBarcodeScanned = (p1) =>{
        let event = new Event('scanned', { bubbles: true });
        store.dispatch(printScan(`Received scan qr code...(Wallet) ${(JSON.stringify(p1, null, 2))}`))
        $("body")[0].dispatchEvent(event);
        store.dispatch(lightOff("BCS"))
        serviceScan(store, p1)
    }

    _hub.client.CS_ALL_HardwareAlert = (hardware_res) => {
        if (hardware_res.DeviceHardwareStatus === 1 && hardware_res.StatusMessage.includes('Reason -CashBoxRemoved')){
            store.dispatch(enterMaintenance(2))
        }
    }

    //PIN PAD
    _hub.client.CS_PPS_KeyPressed = (p1) => {
        let event = new Event('keypress', { bubbles: true });
        $("body")[0].dispatchEvent(event);
        if (!Number.isNaN(parseInt(p1, 10))){
            store.dispatch({type: "KEY_PRESSED", key: p1});
        } else {
            switch (p1){
                case 'ENTER':
                    store.dispatch({type: "KEY_PRESSED", key: p1}) //repeat this because is not anumerical character (for the input fields that receive a keypress)
                    store.dispatch({ type: "ENTER" });
                    store.dispatch(checkDeviceStatuses())
                    break;
                case 'CANCEL':
                case 'CLEAR':
                    store.dispatch({type: "KEY_PRESSED", key: p1}) //repeat this because is not anumerical character (for the input fields that receive a keypress)
                    store.dispatch({ type: "CLEAR" });
                    break;
            }
        }
    }

    _hub.client.CS_PPS_KeyReleased = (p1) => {
        switch (p1){
            case 'ENTER':
                store.dispatch({ type: "RELEASE_ENTER" });
                break;
            case 'CANCEL':
            case 'CLEAR':
                store.dispatch({ type: "RELEASE_CLEAR" });
                break;
            default:
                store.dispatch({ type: "RELEASE_KEY"})
            }
    }

    _hub.client.CS_BAS_BillAccepted = (p1) => {
        if(store.getState().machine.name === 'morty'){
            let event = new Event('inserted', { bubbles: true });
            $("body")[0].dispatchEvent(event);
            store.dispatch(addFunds(Object.assign({}, store.getState().purchase.bills, {[p1]:store.getState().purchase.bills[p1]+1})))
        }
    }

    _hub.client.CS_BAS_BillStacked = (p1) => {
        if(store.getState().machine.name === 'morty'){
            _hub.server.cS_BAS_Wait();
        }
    }

    _hub.client.CS_BAS_BillReturned = () => {
        if(store.getState().machine.name === 'morty'){
            let event = new Event('inserted', { bubbles: true });
            $("body")[0].dispatchEvent(event);
            _hub.server.cS_BAS_Wait();
        }
    }

    _hub.client.CS_BAS_BillRejected = () => {
        if(store.getState().machine.name === 'morty'){
            let event = new Event('inserted', { bubbles: true });
            $("body")[0].dispatchEvent(event);
            _hub.server.cS_BAS_Wait();
        }
    }

    _hub.client.CS_BAS_BillCheated = () => {
        if(store.getState().machine.name === 'morty'){
            let event = new Event('inserted', { bubbles: true });
            $("body")[0].dispatchEvent(event);
            _hub.server.cS_BAS_Wait();
        }
    }

    _hub.client.CS_BAS_AcceptTimeout = () => {
        if(store.getState().machine.name === 'morty'){
        }
    }

    _hub.client.CS_BAS_AcceptCanceled = () => {
        if(store.getState().machine.name === 'morty'){
            store.dispatch(lightOff("BAS"))
        }
    }

    _hub.client.CS_RPS_PrintCompleted = () => {
        setTimeout(()=>_hub.server.cS_RPS_Cut().done(res=> {
          setTimeout(()=>store.dispatch(lightOff("RPS")),2000);
          store.dispatch(finishTx());
        //   store.dispatch(push('/finish-screen'));
        }),1000)
    }
    
    _hub.client.CS_PBS_GpioEvent = (p1) => {
        if (p1.GpioState == 2 && p1.Pin == 0) {
            // If we are already in the screen is because somebody pressed the button again
            if(store.getState().router.location.pathname !== "/clean-cash") {
                store.dispatch(getReport())
                store.dispatch(push("/clean-cash"))
            }
        }
    }


    _hub.client.CS_BAS_AcceptTimeout = () =>{
        _hub.server.cS_BAS_Wait();
    }

    _hub.client.CS_BAS_CashboxRemoved = () =>{
        if(!store.getState().maintenance.active)
            store.dispatch(enterMaintenance(2))
    }

    _hub.client.CS_BAS_CashboxInserted = () =>{
        if(store.getState().maintenance.active)
            store.dispatch(exitMaintenance())
    }

    _hub.client.CS_IDS_CaptureImageCompleted = (p1) => {
        store.dispatch({type: "SAVE_PICTURE", picture: p1})
    }
}

function attachEvents(store){
    // Signal R events
    if(isEmpty($.connection.MiddleManHub.client)){    // no events yet (we are booting)
        // lifecycle Events
        lifecycleEvents(store)
        // MiddleManHub Events
        middleManEvents(store)
    }

    // Timer events (Reminder and cancel after certain time) (we are booting)
    if(!timerOpenReminder.func)
        timerOpenReminder.setFunction(() => store.dispatch(openReminder()))
    if(!timerCancelOperation.func)
        timerCancelOperation.setFunction(() => store.dispatch(cancelOperation()))
}