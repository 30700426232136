import React, { Component } from 'react';
import '../../../../styles/common/input-fields/InputSerialBS.css';
import { isEqual, clone } from 'lodash';

class InputSerialMorty extends Component{

  constructor(props){
    super(props);
    this.state={
      serial:''
    }
    
    this.onChange = this.onChange.bind(this);
    this.delete = this.delete.bind(this);
  }

  onChange(e){
    this.setState({
      serial: e.target.value
    }, this.sendSerial)
  }

  sendSerial(){
    this.props.sendSerial(this.state.serial)
  }

  reset(){
    this.setState({ 
      serial: '' 
    },()=>{
      this.sendSerial();
      document.getElementById('0').focus()
    })
  }

  delete(){
    this.reset()
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  componentDidMount(){
    document.getElementById('0').focus()
  }

  render(){
    return(
      <div>
        <div className='input-serial-cont'>
          <h1>THIS IS A MORTY!!</h1>
          <input type='text' id='0' className='input-serial' value={this.state.serial} onInput={ this.onChange } autoComplete="off"/> 
        </div>
      </div>
    );
  }
}

InputSerialMorty.defaultProps = {
  submited: false
}

export default InputSerialMorty;