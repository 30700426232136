import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonBS from '../../../../components/common/ButtonBS';
import PageLayout from '../../../../components/common/PageLayout';
import * as qaActions from '../../../../actions/qaActions';
import * as lightActions from '../../../../actions/lightActions';
import { setStage } from '../../../../actions/transactionActions';
import '../../../../styles/QA/QA.css';
import { clone } from 'lodash';
import InputSerial from '../../../../components/common/input-fields/InputSerialBS';
import { addEventsListen, removeEvents } from '../../../../helpers/listenFunctions.js';

class EnterSerial extends PureComponent {
  constructor(props){
    super(props);

    this.state={
      title: 'Enter Machine Serial Number',
      verifiying: false,
      serial: '',
      verify: '',
      key: '',
      submited: false,
      message: ''
    }

    this.initialData = clone(this.state)

    this.sendSerial = this.sendSerial.bind(this);

    this.getSerial = this.getSerial.bind(this);
    this.getVerify = this.getVerify.bind(this);
    this.getSubmit = this.getSubmit.bind(this);

  }

  sendSerial(){
    if(!this.state.verifiying && this.state.serial){
      this.setState({
        title: 'Please Verified Serial Number',
        verifiying: true,
        submited: true
      }, ()=>{
        this.setState({
          submited: false
        })
      })
    }
    else{
      if(this.state.serial === this.state.verify)
        this.props.actions.qa.sendSerial(this.state.serial)
      else
        this.setState({
          message: 'Serial Number mismatch!'
        })
    }
  }

  getSerial(value){
    this.setState({
      serial: value
    })
  }

  getVerify(value){
    this.setState({
      verify: value
    })
  }

  getSubmit(value){
    if(value)
      this.sendSerial()
  }

  contextMenu(e){
    e.preventDefault();
  }

  componentDidMount(){
    let elem = document.getElementsByClassName('input-serial')[0];
    let listEvents = ['touchstart', 'touchend', 'touchmove', 'contextmenu']

    document.getElementsByTagName('input')[0].focus();
    this.props.actions.setStage(1);
    if(!this.props.mach.tiny)
      this.props.actions.light.setLight(1,"PPS")

    addEventsListen(elem, listEvents, this.contextMenu, false)
  }

  componentDidUpdate(prevProps){
    if(this.props.mach.name === 'morty'){
      if (this.props.pinpad.enter && this.props.pinpad.enter !== prevProps.pinpad.enter)
        this.sendCode()
      else if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
        this.setState({key: this.props.pinpad.key}, ()=>this.setState({key:''}))
      else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)           //Both cancel and clear
        this.setState({key: 'CLEAR'}, ()=>this.setState({key:''}))
    }
  }

  componentWillUnmount(){
    let elem = document.getElementsByClassName('input-serial')[0];
    let listEvents = ['touchstart', 'touchend', 'touchmove', 'contextmenu']

    removeEvents(elem, listEvents, this.contextMenu)
  }

  render(){
    return(
      <PageLayout title={this.state.title} errorMessage={this.state.message}>
        <div className='enter-serial-cont'>
        {this.state.verifiying ?
          <InputSerial
            id='0' 
            sendSerial={this.getVerify}
            submit={this.getSubmit}
            send={this.sendCode}
            keyPress={this.state.key}
            submited={this.state.submited}
            /> :
          <InputSerial
            id='1'
            sendSerial={this.getSerial}
            submit={this.getSubmit}
            send={this.sendCode}
            keyPress={this.state.key}
            submited={this.state.submited}
            />
        }
        </div>
        { !this.props.mach.tiny ? 
          <ButtonBS className='button-serial' content='Enter' onClick={this.sendSerial} /> 
          : null 
        }
      </PageLayout>
    );
  }

}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      qa: bindActionCreators(qaActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      light: bindActionCreators(lightActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    qa: state.qa,
    mach: state.machine,
    pinpad: state.pinpad
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterSerial);