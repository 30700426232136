import React, { useEffect }from 'react';
import ButtonBS from '../../ButtonBS';
import '../../../../styles/common/Modals/ModalClearCash.css';

const ModalClearCash = ({ closeModal, stayOnScreen }) => {

    let timer = null

    const startTimer = () => {
        timer = setTimeout(closeModal, 10000)
    }

    useEffect(startTimer, [])

    const stay = () => {
        clearTimeout(timer)
        stayOnScreen()
    }

    return (
        <div>
            <h4>Stay on this screen?</h4>
            <ButtonBS className="modal-clear-button" content="Yes" onClick={stay} />
            <ButtonBS className="modal-clear-button" content="No" onClick={closeModal} />
        </div>
    )
}

export default ModalClearCash;