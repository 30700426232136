import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InputFieldBS from '../InputFieldBS';
import { EnterAddress as i18n } from '../../../../middlewares/i18n';

const InputLocation = ({ sendLocation, address, options, onFocus }) => {

  const places = useSelector(state => state.places)
  const _i18n = useSelector(state => state.i18n)
  const trans = i18n[_i18n.language.current]

  const [location, setLocation] = useState('')
  const [option, selectOption] = useState('')

  const handeChangeInput = (event) => {
    setLocation(event.target.value)
  }

  const handleClick = (event) => {
    selectOption(event.target.value)
  }

  const createOptions = () => {
    let selectOpt =  options.map((sel)=>{
      return <option value={sel.value}>{sel.label}</option>
    })
    return selectOpt
  }

  useEffect(() => {
    sendLocation(location)
  },[location])

  useEffect(() => {
    address(option)
    setLocation("")
  }, [option])

  return(
    <div className="input-loc-container">
      <InputFieldBS
        id='location'
        className='location-field'
        onInput={handeChangeInput}
        value={location}
        onFocus={onFocus}
        title={trans.searchAddress}
      />
      <select
        size={5}
        onChange={handleClick}
        className="select-location"
      >
        {createOptions()}
      </select>
    </div>
  )
}

export default InputLocation