import React, { Component } from 'react';
import DropdownBS from '../../DropdownBS';
import { years, days, months, months_es } from './dates.js';
import '../../../../styles/common/input-fields/InputBirthBS.css';

class InputBirthBS extends Component {
  constructor(props){
    super(props);

    this.state={
      day: days[0].value,
      month: months[0].value,
      year: years()[0].value
    }

    this.changeDay = this.changeDay.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.changeYear = this.changeYear.bind(this);
  }

  changeDay(day){
    this.setState({
      day: day.value
    },this.handleChanges)
  }
  changeMonth(month){
    this.setState({
      month: month.value
    },this.handleChanges)
  }
  changeYear(year){
    this.setState({
      year: year.value
    },this.handleChanges)
  }
  
  handleChanges(){
    this.props.date(`${this.state.month}/${this.state.day}/${this.state.year}`)
  }

  render(){
    let _months = months
    if (this.props.language === 'es')
      _months = months_es

    return(
      <div className='inputs-dob-container'>
        <DropdownBS className='input-dob-container select-month' onChange={this.changeMonth} options={_months} value={this.state.month} placeholder='Select month' closeOnSelect={true} onClick={this.props.onClick}/>
        <DropdownBS className='input-dob-container select-day' onChange={this.changeDay} options={days} value={this.state.day} placeholder='Select day' closeOnSelect={true} onClick={this.props.onClick}/>
        <DropdownBS className='input-dob-container select-year' onChange={this.changeYear} options={years()} value={this.state.year} placeholder='Select year' closeOnSelect={true} onClick={this.props.onClick}/>
      </div>
    )
  }
}

InputBirthBS.defaultProps={
  onClick: function(){}
};

export default InputBirthBS;