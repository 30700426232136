import $ from 'jquery';

export function setTimer(func, timeout, events = 'mousemove keydown click scanned inserted keypress customized'){

  let timer;

  function reset(){
    clearTimeout(timer);
    timer = setTimeout(func, timeout);
  }

  function start(){
    timer = setTimeout(func, timeout);
  }

  start();

  $('body').bind(events, reset);

}

export class Timer {
  constructor(
    timeout = 5000,
    func = null,
    events = 'mousemove keydown click scanned inserted keypress customized'
  ) {
    this.timeout = timeout
    this.func = func
    this.events = events
    this.timer = null
  }

  setFunction(func) {
    this.func = func
  }

  stop = () => {
    clearTimeout(this.timer)
    this.timer = null
  }

  start = () => {
    this.timer = setTimeout(this.func, this.timeout)
  }

  clean = () => {
    if(this.timer) {
      $('body').unbind(this.events, this.reset);
      this.stop()
    }
  }

  reset = () => {
    this.stop()
    this.start()
  }

  init = () => {
    if(!this.timer) {
      this.start()
      $('body').bind(this.events, this.reset);
    }
  }
}
/*
  params:
  - "funRetry": function that will retry
  - "funShortCircuit": function for the short circuit (when max retries are reached)
  - "retries": how many retries
*/
export function expRetry(funRetry, funShortCircuit, retries, maxRetries = 3){
  if(retries < maxRetries){
    let ms = Math.pow(2, retries)*1000
    setTimeout(() => {
        return funRetry(retries + 1)
    }, ms)
  }
  else
      return funShortCircuit()
  }
