import axios from 'axios';

async function goToVerse(visit){
  try{
    let res = await axios({
      method: 'post',
      url: 'http://localhost:8080/world/teenyverse',
      data: visit,
      headers:{
        'Access-Control-Allow-Origin': '*'
      }
    })
    return res
  } catch(err){
    return "Error going to verse"
  }
}

export default goToVerse;