import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import '../../styles/common/ButtonBS.css';

function ButtonBS({ className, onClick, disabled, content, refButton, v2 }) {
  return (
    <button 
      className={`button-bs ${v2 ? 'v2 ' : ''}${className}`}
      onClick={onClick}
      disabled={disabled}
      ref={refButton}
    >
      {content}
    </button>
  );
};

ButtonBS.propTypes=exact({
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  content: PropTypes.string.isRequired,
  refButton: PropTypes.func,
  v2: PropTypes.bool
});

ButtonBS.defaultProps={
  className: '',
  onClick: ()=> null,
  disabled: false,
  v2: false
};

export default ButtonBS;