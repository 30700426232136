import { lightsDict } from './lightsDictionary'

export default function lightsMM(action, store, _hub, next){

  switch(action.type){
    
    case 'SET_LIGHT':
      let pin = lightsDict[store.getState().machine.name][action.service]
      _hub.server.cS_PBS_SetGpioState(action.state, pin).done(response=>{
      })
      .fail(err => {
      })
      break;

    default:
      next()
  }
}