import { enterMaintenance, exitMaintenance, noMoreBills, maintenanceAfterWait } from '../../../../actions/maintenanceActions';

let storeStatus;

export default function BillAcceptorHandler(store, status){

  switch(status.BillAcceptorService){                   //Check status of the hardware
    case 'HEALTHY':                                     
      if(store.getState().maintenance.active){          //Check if we are in a maintenance mode
        store.dispatch(exitMaintenance());              //Turn off maintenance mode
      }
      break;

    case 'UNKNOWN':
    case 'FATAL':
      if(!store.getState().maintenance.active){             //if maintenance mode is not activated
        if(store.getState().transaction.init){              //if machine is in a middle of a transaction
          if(store.getState().transaction.stage <= 3){      //If machine is in stage 3 or less
            switch(store.getState().transaction.stage){     //depending in what stage we are
              case 1:
              case 2:
                //Do something but don't go to maintenance mode because we can solve before
                break;
              case 3:                                       //in current stage
                //Give a chance to solve the problem
                  setTimeout(()=>{                               //Give a moment to the mach to solve problem (19 seconds)
                    storeStatus = store.getState().maintenance.statuses.BillAcceptorService; //Save current state
                    if (storeStatus === 'FATAL' || storeStatus === 'UNKNOWN'){
                      if(store.getState().purchase.subtotal > 0){      //If user already inserted money
                        store.dispatch(noMoreBills())                   //No accept more bills
                      }
                      else                                          //If user didn't insert money
                        store.dispatch(maintenanceAfterWait(5))      //Go to maintenance mode
                    }
                    //Else don't do anything because error was solved
                  }, 19000)
                  break;
              default:

                console.error('I should never trigger. Current Stage:',store.getState().transaction.stage)
            }
          }
          else{                                             //If machine already passed stage 3
            //Do not enter to maintenance mode but do...?
          }
        }
        else{                                   //if machine is not in middle of a transaction
          store.dispatch(enterMaintenance(5));   //enter in maintenance mode
        }
      }
      break;

    default:
      console.error('STATUS NOT REGISTERED')
  }
}