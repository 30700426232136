import React, { useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PageLayout from '../../../components/common/PageLayout/PageLayout4'
import '../../../styles/MembershipTrack/MembershipTrack.css';
import CircularProgress from './CircularProgress';
import { selectTier } from '../../../actions/tiersActions';
import ButtonBS from '../../../components/common/ButtonBS';
import { push } from 'connected-react-router';
import translations from '../../../middlewares/i18n/MembershipTrack.json';
import useTranslations from '../../../hooks/useTranslations';

const MembershipTrack = () => {
  const tierRef = useRef()
  const dispatch = useDispatch()
  const i18n = useTranslations(translations)

  const client = useSelector(state => state.client)
  const tiers = useSelector(state => state.tiers.tiers)
  const language = useSelector(state => state.i18n.language.current)

  const limit = client.max || '';
  const message = `${i18n.sh1}${limit}.`;

  const selectMembership = useCallback(
    () => {
      dispatch(selectTier(tierRef.current))
    },
    []
  )

  const _selectTier = (tier) => {
    tierRef.current = tier
    selectMembership()
  }

  const not_now = useCallback(
    () => dispatch(push('/dashboard')),
    []
  )

  const createTrack = () => {
    return tiers.map((tier, i) => {
      return (
        <CircularProgress
          tier={tier}
          fulfilled={client.policies_fulfilled}
          key={`cir-${i}`}
          lang={language}
          handleSelect={_selectTier}
        />
      )
    })
  }

  return(
    <PageLayout
        title={i18n.title}
        price
        big={false}
        noPadHead={true}
        message={message}
        messageSubtext={i18n.sh2}
    >
      <div className='mem-track-container'>
        {
          createTrack()
        }
        <ButtonBS
            className="mem-track-button"
            content={i18n.not_now}
            onClick={not_now}
        />
      </div>
    </PageLayout>
  )
}

export default MembershipTrack