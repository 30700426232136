import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Dashboard as i18n } from '../../../middlewares/i18n';

const RecentActivity = ({ listTransactions }) => {

  const _i18n = useSelector(state => state.i18n)

  const listTxs = listTransactions.map((transaction)=>{
    return(
      <tr key={transaction.created_at}>
        <td>
          ${transaction.enviando}/<br/>
          {parseFloat(transaction.btc).toFixed(4)} BTC
        </td>
        <td>
          {transaction.address.substring(0, 12)}<br/>
          ...{transaction.address.substring(transaction.address.length-11, transaction.address.length-1)}
        </td>
        <td>
          {
            //2020-04-22 19:35:51 UTC -> YYYY-MM-DD HH:mm:ss Z
            moment(transaction.created_at, 'YYYY-MM-DD HH:mm:ss Z').format('MM/DD/YY')
          }
        </td>
        {transaction.stage === 'completed' ?
          <td style={{color: '#00AF53'}}>{i18n[_i18n.language.current].confirmed}</td> :
          <td style={{color: '#FF1720'}}>{i18n[_i18n.language.current].pending}</td>
        }
      </tr>
    )
  })

  return(
    <div className="act-table-cont">
      <h5 className="no-margin">{i18n[_i18n.language.current].recentActivity}</h5>
      <table>
      <tbody>
        <tr>
          <th>{i18n[_i18n.language.current].purchased}</th>
          <th>{i18n[_i18n.language.current].address}</th>
          <th>{i18n[_i18n.language.current].date}</th>
          <th>{i18n[_i18n.language.current].status}</th>
        </tr>
        { listTxs }
      </tbody>
      </table>
      <h6 className="no-margin">{i18n[_i18n.language.current].footerActivity}</h6>
    </div>
  )                                                                                  
}

export default RecentActivity