import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/TellUsAbout/TellUsAbout.css';
import InputFieldBS from '../../../components/common/input-fields/InputFieldBS';
import KeyboardBS from '../../../components/KeyboardBS';
import { TellUsAbout as i18n } from '../../../middlewares/i18n';
import { updateBasicInfo } from '../../../actions/updateActions';
import { enforceScanID } from '../../../actions/viewActions';

import { track } from '../../../helpers/analytics';

class TellUsAbout extends Component{
  constructor(props){
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      message: '',
      inputNode: null,
      disabled: false
    }
    this.openKeyboard = this.openKeyboard.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.closeKeyboard = this.closeKeyboard.bind(this);
    this.nextField = this.nextField.bind(this);
    this.submit = this.submit.bind(this);
  }

  sendNames(){
    const firstName = this.state.firstName.trim();
    if(!firstName){
      this.setState({
        disabled: false,
        message: i18n[this.props.i18n.language.current].invalidFN
        });
      return;
    }

    const lastName = this.state.lastName.trim();
    if(!lastName){
      this.setState({
        disabled: false,
        message: i18n[this.props.i18n.language.current].invalidLN
        });
      return;
    }

    this.setState({ message: '' });
    track('First Name and Last Name Entered');
    this.props.actions.updateBasicInfo(firstName, lastName);
  }  

  submit(){
    this.setState({
      disabled: true
    }, this.sendNames)
  }

  handleInput(e){
    this.setState({[e.target.id]: e.target.value})
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  closeKeyboard(){
    this.setState({inputNode: null})
  }

  closeAndOpen(){
    this.setState({
      inputNode: null
    }, () => document.getElementsByTagName('input')[1].focus())
  }

  nextField(){
    if(this.state.inputNode.id === 'lastName'){
      this.closeKeyboard();
    } else if (this.state.inputNode.id === 'firstName'){
      this.closeAndOpen()
    }
  }

  componentDidMount(){
    document.getElementsByTagName('input')[0].focus();
    this.props.actions.setStage(2)
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        {this.state.message ? <h5 className='message-client-reg'>{this.state.message}</h5> : null}
        { this.props.kyc ?
        <div className='or-scan-id'>
          <h2 className="bs-subtitle no-margin">{i18n[this.props.i18n.language.current].skipStep}</h2>
          <ButtonBS
            className='scan-id-button-add'
            content={i18n[this.props.i18n.language.current].scanID}
            onClick={this.props.actions.enforceScanID}
          />
        </div>
        : null }
        <div className='input-name'>
          <InputFieldBS 
            id='firstName'
            className='name-input' 
            title={i18n[this.props.i18n.language.current].firstName}
            onFocus={()=>this.openKeyboard(document.getElementById('firstName'))} 
            onInput={this.handleInput} 
          />
          <InputFieldBS
            id='lastName'
            className='name-input'
            title={i18n[this.props.i18n.language.current].lastName}
            onFocus={()=>this.openKeyboard(document.getElementById('lastName'))}
            onInput={this.handleInput}
          />
        </div>
        <ButtonBS className='tell-us-button' content={i18n[this.props.i18n.language.current].submit} onClick={this.submit} disabled={this.state.disabled}/>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.closeKeyboard}
            next={this.nextField}
          /> : null }
      </PageLayout>
    )
  }
}

function mapStateToProps(state){
  return{
    kyc: state.machine.kyc,
    i18n: state.i18n
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      updateBasicInfo: bindActionCreators(updateBasicInfo, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      enforceScanID: bindActionCreators(enforceScanID, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TellUsAbout);