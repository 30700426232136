export const PIN_VERIFIED = 'PIN_VERIFIED';
export const ADD_TIER = 'ADD_TIER';
export const SET_TRANSACTIONAL = 'SET_TRANSACTIONAL';
export const ADD_COMPLETE_PHONE = 'ADD_COMPLETE_PHONE';
export const CODE_RESENT = 'CODE_RESENT';
export const ADD_CODE = 'ADD_CODE';
export const ADD_AREA_CODE = 'ADD_AREA_CODE';
export const ADD_ACC_TOKEN = 'ADD_ACC_TOKEN';
export const VALID_PHONE = 'VALID_PHONE';
export const CANCEL_OPERATION = 'CANCEL_OPERATION';
export const INVALID_PIN = 'INVALID_PIN';
export const VALID_CODE = 'VALID_CODE';