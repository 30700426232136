import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputSerialMorty from './InputSerialMorty.jsx';
import InputSerialRick from './InputSerialRick.jsx';
import InputSerialTinyRick from './InputSerialTinyRick.jsx';

class InputSerial extends PureComponent{
  constructor(props){
    super(props);
  }

  render(){
    if(this.props.machine.name === 'rick')
      if(!this.props.machine.tiny)
        return <InputSerialRick {...this.props}/> 
      else 
        return <InputSerialTinyRick {...this.props}/>
    else
      return <InputSerialMorty {...this.props}/>
  }
}

function mapStateToProps(state){
  return{
    machine: state.machine
  }
}

export default connect(mapStateToProps)(InputSerial)