import React from 'react';

import translations from '../../../middlewares/i18n/ScamScreen.json';
import useTranslations from '../../../hooks/useTranslations';

import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../constants/support';

import '../../../styles/BeingScammed/BeingScammed.css';

const BeingScammedCard = () => {
  const i18n = useTranslations(translations);

  return (
    <div className="being-scammed">
      <div className="being-scammed__title">{i18n.scamConcern}</div>
      <div className="being-scammed__subtitle">{i18n.getInTouch}</div>
      <ul>
          <li>
            {SUPPORT_EMAIL}
          </li>
          <li>
            {SUPPORT_PHONE}
          </li>
        </ul>
    </div>
  );
}

export default BeingScammedCard;
