import React from 'react';
import { useSelector } from 'react-redux';
import LoadingAnimation from '../../../styles/assets/LoadingScreen_Symbol_F.webm';
import '../../../styles/LoadingScreen/LoadingScreen.css';

const LoadingScreen = () =>{

  const loading = useSelector(state => state.loading)
  
  return(
    <div className="bs-body loading-cont">
      <div className='loading-vid'>
        <video controls={false} autoPlay loop width='800' height='800'>
          <source src={LoadingAnimation} type='video/webm'/>
        </video>
      </div>
      <h4 className="no-margin loading-mes">{loading.message}<span>.</span><span>.</span><span>.</span></h4>
    </div>
  )
}

export default LoadingScreen