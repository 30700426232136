import React, { PureComponent } from "react";

export class TermsConditionsFallback extends PureComponent {
  render() {
    return (
      <>
        <p>
          <strong>Bitstop Terms of Service</strong>
        </p>
        <p>
          <strong>Effective Date: April 16, 2024</strong>
        </p>
        <p>
          Welcome to Bitstop! By using our services, you are agreeing to our
          Terms of Service. Please read these terms carefully before using our
          platform. If you do not agree to these terms, you should not use our
          services.
        </p>

        <p>
          <strong>1. Overview</strong> Bitstop provides a secure and compliant
          platform for buying and selling virtual currency through both our
          proprietary software and a network of automated kiosks. These kiosks
          are owned and operated by affiliated operators across various states,
          ensuring broad access and compliance with local regulations.
        </p>
        <p>
          <strong>2. Services Provided</strong> Bitstop offers services related
          to the purchase and sale of virtual currency through:
        </p>
        <ul>
          <li>
            Automated kiosks operated by Bitstop and affiliated operators.
          </li>
          <li>
            A software platform that facilitates these transactions in a
            compliant and user-friendly manner.
          </li>
        </ul>
        <p>
          <strong>3. Amendments to Terms</strong> We reserve the right to modify
          these Terms of Service at any time. Changes will become effective
          immediately upon posting on our website. Your continued use of our
          services after such posting will constitute your acceptance of these
          changes.
        </p>
        <p>
          <strong>4. Regulatory Compliance</strong> Bitstop is a federally
          registered Money Service Business (MSB) and complies with all
          applicable federal and state laws. We are licensed as a Money
          Transmitter in states where required and ensure that all transactions
          meet regulatory standards.
        </p>
        <p>
          <strong>5. User Agreement</strong> This agreement is legally binding
          between you (the user) and Bitstop upon your use of our services. It
          includes all associated policies such as our Privacy Policy and
          Acceptable Use Policy, which govern the use of our services.
        </p>
        <p>
          <strong>6. Scam Prevention</strong> To protect our consumers from
          scams, Bitstop implements several layers of security measures:
        </p>
        <ul>
          <li>
            <strong>Education:</strong> We prioritize educating our users about
            the risks of scams and how to avoid them. Our website and kiosks
            feature easy-to-understand guides and alerts about common fraud
            tactics and safe transaction practices.
          </li>
          <li>
            <strong>Transaction Monitoring:</strong> Our systems continuously
            monitor transaction patterns for signs of suspicious activity. Any
            transaction that triggers our fraud detection algorithms is subject
            to further scrutiny and may be temporarily halted for additional
            verification.
          </li>
          <li>
            <strong>User Verification:</strong> To prevent unauthorized access
            and misuse of our services, we enforce strict identity verification
            processes during account creation and prior to executing
            transactions. This includes requiring documents and biometric data
            as needed.
          </li>
          <li>
            <strong>Secure Technology:</strong> We utilize state-of-the-art
            encryption and cybersecurity technologies to protect all data
            transmitted through our services. Our security infrastructure is
            regularly updated to guard against emerging threats.
          </li>
          <li>
            <strong>Customer Support:</strong> Our dedicated support team is
            trained to assist users with security concerns and to respond
            promptly to reports of potential scams. We provide multiple channels
            for users to contact us, ensuring that support is accessible and
            effective.
          </li>
        </ul>
        <p>
          <strong>7. Communication</strong> Bitstop will communicate updates to
          these Terms of Service and other important information via the website
          and email. It is your responsibility to maintain current contact
          information in your user profile and to regularly check for updates on
          our website.
        </p>
        <p>
          <strong>8. Account Management</strong> Users are responsible for
          maintaining the confidentiality of their account information and for
          all activities that occur under their account. Bitstop is not liable
          for any loss or damage arising from your failure to protect your
          account information.
        </p>
        <p>
          <strong>9. Intellectual Property</strong> All content on Bitstop's
          website, mobile applications, and kiosks, including text, graphics,
          logos, and software, is the property of Bitstop or its licensors and
          is protected by copyright and other intellectual property laws.
        </p>
        <p>
          <strong>10. Privacy</strong> Protecting your privacy is crucial to us.
          Please review our Privacy Policy, which explains how we collect, use,
          and share information about you.
        </p>
        <p>
          <strong>11. Termination and Account Closure</strong> You may close
          your account at any time. Bitstop reserves the right to terminate your
          account or restrict service access if you violate these Terms of
          Service or engage in any activity that may harm Bitstop or its users.
        </p>
        <p>
          <strong>12. Limitation of Liability</strong> Bitstop will not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages resulting from the use of, or inability to use, our
          services, even if Bitstop has been advised of the possibility of such
          damages.
        </p>
        <p>
          <strong>13. Dispute Resolution</strong> Any disputes arising under
          these terms will be handled in the jurisdiction where Bitstop is
          headquartered, under the applicable laws of that jurisdiction.
        </p>
        <p>
          <strong>14. Contact Information</strong> If you have any questions
          about these Terms of Service, please contact us at{" "}
          <a href="#">support@bitstop.co</a>.
        </p>
        <p>
          We thank you for choosing Bitstop and look forward to providing you
          with safe and reliable virtual currency transaction services.
        </p>
      </>
    );
  }
}
