import { setMachine } from '../../actions/machineActions'

export default function machineBreedMM(action, store, _hub){

  switch(action.type){

    case 'GET_MACHINE_TYPE': //0 tiny rick, 1 rick, 2 morty, 9 custom
      _hub.server.cS_MAS_GetMachineType().done(response=>{
        switch(response){

          case 0:
            console.log('I am a Tiny Rick')
            store.dispatch(setMachine('rick'))
            store.dispatch({type: 'MACHINE_SIZE', tiny: true})
            break;

          case 1:
            console.log('I am a Rick')
            store.dispatch(setMachine('rick'))
            store.dispatch({type: 'MACHINE_SIZE', tiny: false})
            break;

          case 2:
            console.log('I am a Morty')
            store.dispatch(setMachine('morty'))
            store.dispatch({type: 'MACHINE_SIZE', tiny: false})
            break;

          case 9:
            console.log('I am no-one')
            store.dispatch(setMachine('custom'))
            store.dispatch({type: 'MACHINE_SIZE', tiny: false})
            break;
          }
      })
      break;
    
    default:
      ()=>console.log('FINISH DONE')
  }
}