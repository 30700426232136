import initialState from './initialState';

export default function maintenanceReducer(state = initialState.maintenance, action){

  switch(action.type){

    case 'MAINTENANCE_MODE_ON':
      return Object.assign({}, state, {
        active: true
      })

    case 'MAINTENANCE_MODE_OFF':
      return Object.assign({}, state, {
        active: false
      })

    case 'SET_MAINTENANCE_CODE':
      return Object.assign({}, state, {
        code: action.code
      })

    case 'SET_HARDWARE_NAME':
      return Object.assign({}, state, {
        hardware: action.hardware_name
      })

    case 'SET_DESCRIPTION_ERROR':
      return Object.assign({}, state, {
        description: action.description
      })

    case 'SET_STATUSES':
      return Object.assign({}, state, {
        statuses: action.statuses
      })

    case 'PRINTER_WORKING':
      return Object.assign({}, state, {
        printerWork: true
      })

    case 'PRINTER_DOWN':
      return Object.assign({}, state, {
        printerWork: false
      })

    case 'CAMERA_WORKING':
      return Object.assign({}, state, {
        cameraWorking: true
      })

    case 'CAMERA_DOWN':
      return Object.assign({}, state, {
        cameraWorking: false
      })

    case 'CARD_READER_WORKING':
      return Object.assign({}, state, {
        cardReaderWorking: true
      })

    case 'CARD_READER_DOWN':
      return Object.assign({}, state, {
        cardReaderWorking: false
      })

    case 'DISPENSER_WORKING':
      return Object.assign({}, state, {
        dispenserWorking: true
      })

    case 'DISPENSER_DOWN':
      return Object.assign({}, state, {
        dispenserWorking: false
      })

    case 'ERROR_MESSAGE_MODAL':
      return Object.assign({}, state, {
        errorMessage: action.message
      })

    case 'CANCEL_INS_BILL':
      return Object.assign({}, state, {
        BAConnected: false
      })

    case 'SET_ERROR_CODE':
      return Object.assign({}, state, {
        errorCode: action.code
      })

    case 'CANCEL_OPERATION':
      return Object.assign({}, state, {
        errorMessage: ''
      })

    default:
      return state;

  }
}