import createAxe from '../../middlewares/Axe'

import { triggerError, digestErrorCode } from '../maintenanceActions';
import { goLoading, outLoading } from '../loadingActions';
import { getDirectionFromList } from '../viewActions';

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';
import { push } from 'connected-react-router';
import * as types from './actionTypes.js';

/*
  - Basic Info Policy
    data: {
      first_name: "Benito",
      last_name: "Camelo"
    }
  
  - Date Of Birth Policy
    data: {
      dob: 01/16/1993
    }
  
  - Address Policy
    data: {
      address: "1871 NW"
      city: "Miami",
      state: "FL",
      zipcode: "33123",
      "country": "USA"
    }
*/

export function updateBasicInfo(first_name, last_name) {
  return function(dispatch) {
    let data = {
      first_name,
      last_name
    }

    return dispatch(updateData(
      data,
      '/basic-info',
      'processFailed'
    ))
  }
}

export function updateDOB(dob) {
  return function(dispatch) {
    let data = {
      dob
    }

    return dispatch(updateData(
      data,
      '/dob',
      'processFailed'
    ))
  }
}

export function updateAddress(address) {
  return function(dispatch) {
    let data = {
      address: address.street,
      city: address.locality,
      state: address.administrative_area_level_1,
      zipcode: address.postal_code,
      country: address.country,
    }

    return dispatch(updateData(
      data,
      '/address',
      'processFailed'
    ))
  }
}

export function updateID(original, parsed) {
  return function(dispatch) {
    let data = {
      original,
      parsed
    }

    return dispatch(updateData(
      data,
      '/scan-id',
      'invalidID'
    ))
  }
}

export function updateIDwDip(card_info) {
  return function(dispatch) {
    let cardInfo = pick(card_info, ['CardEncodeType', 'Track1MaskedData', 'Track2MaskedData', 'Track3MaskedData'])
    let data = mapKeys(cardInfo, function(value, key) {
      return lowerFirst(key)
    })

    return dispatch(updateData(
      data,
      '/dip-id',
      'invalidID'
    ))
  }
}

// Error Messages: ['processFailed', 'invalidID']
function updateData(
  data, 
  path,
  errorMesssageKey
){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    let url = '/customer/update' + path

    try{
      let res = await updateReq(
        getState(),
        url,
        data
      )
      if (res.status === 200 || res.status === 204){
        let direction = dispatch(getDirectionFromList())
        setTimeout(() => {
          return dispatch(outLoading(direction))
        }, 500)
      }
      else{
        setTimeout(() => {
          dispatch(outLoading())
          return dispatch(triggerError(i18nErrors[lang][errorMesssageKey]));
        }, 2000)
      }
    }
    catch(error){
      return dispatch(digestErrorCode(error.response.status, () => {
        setTimeout(() => {
          dispatch(outLoading())
          return dispatch(triggerError(i18nErrors[lang][errorMesssageKey]))
        }, 2000)
      }))
    }
  }
}

async function updateReq(state, path, data) {
  try {
    let axe = createAxe(state)
    let url = '/v2/unity' + path
    return await axe(url, {
      method: 'PUT',
      headers: {
        'Customer-Token': `Bearer ${state.client.client_id}`,
        'Atm-Token': `Bearer ${state.machine.atm_id}`
      },
      data
    })
  }
  catch(err) {
    throw err
  }
}

export function agreeToContact(){
  return async function(dispatch, getState) {
    try{
        let axe = createAxe(getState())
        let res = await axe('/v2/unity/tiers/agree', {
            method: 'POST',
            headers: {
                'Customer-Token': `Bearer ${getState().client.client_id}`,
                'Atm-Token': `Bearer ${getState().machine.atm_id}`
            }
        })
        if(res.status === 200 || res.status === 204){
          return dispatch(validAgreeReq())
        }
    }
    catch(error){
      if(error.response && error.response.status === 409){
        return dispatch(push('manual_review_rejected'))
      }
      else {
        dispatch(validAgreeReq())
        return dispatch(triggerError("Couldn't request for manual verification."))
      }
    }
  }
}

export function validAgreeReq() {
  return {
    type: types.VALID_AGREE_REQ
  }
}