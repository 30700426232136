import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { StartingScreen, FinishScreen, MaintenanceMode, MenuDemo, ScamScreen, CommonScams, PreOkScreen, AccountRestricted, PasswordChanged, CouponApplied } from './components/Views';
import { TermsConditions, EnterPhone, EnterCode, EnterPin, CreatePin, VerifyPin, ScanID, ScanWallet, InsertCash, Receipt, IsThisYou, EnterDOB, TellUsAbout, EnterAddress, CalculatorPurch, DispenseCash, CleanCash, CardAcceptor, LoadingScreen, EnterSerial, ConfirmSerial, EnterID, Dashboard, AboutYou, SendHODL, ForceScanId, SetPin, VerifyCode, SelectMembership, MembershipTrack, MessageSent, MessageSentContact, ManualReviewRejected, EnterCodeOtp, AddCoupon } from './containers/Views';
import ScanCoupon from './containers/Views/Scan/ScanCoupon/ScanCoupon';

class Routes extends PureComponent{

  constructor(props){
    super(props);

    this.getStatus = this.getStatus.bind(this)
  }

  getStatus(){
    return this.props.machine.state
  }
  
  render(){
    return(
      <Switch>
        <Route exact path='/' render={({ location })=>(
          this.getStatus() === 0 ? 
            <StartingScreen />
            : ( this.getStatus() === 1 ?
                <Redirect to='/pre-ok' />
              : ( this.getStatus() === 2 ? (
                this.props.qa.qa_partial ?
                <StartingScreen />
              : <Redirect to='/enter_serial' /> )
            : ( this.getStatus() === 9 ?
                <Redirect to='/maintenance-mode' />
                : <StartingScreen />)
              )
            )
        )}/>
        <Route path='/common-scams' component={CommonScams} />
        <Route path='/terms-conditions' component={TermsConditions} />
        <Route path='/enter-phone' component={EnterPhone} />
        <Route path='/verify_phone' component={EnterCode} />
        <Route path='/enter_pin' component={EnterPin} />
        <Route path='/create_pin' component={CreatePin} />
        <Route path='/verify_pin' component={VerifyPin} />
        <Route path='/set_pin' component={SetPin} />
        <Route path='/scan_id' component={ScanID} />
        <Route path='/scan_wallet' component={ScanWallet} />
        <Route path='/scan_coupon' component={ScanCoupon} />
        <Route path='/add-coupon' component={AddCoupon} />
        <Route path='/coupon-applied' component={CouponApplied} />
        <Route path='/insert_cash' component={InsertCash} />
        <Route path='/receipt' component={Receipt} />
        <Route path='/is-this-you' component={IsThisYou} />
        <Route path='/enter_dob' component={EnterDOB} />
        <Route path='/tell_us_about' component={AboutYou} />
        <Route path='/about_you' component={AboutYou} />
        <Route path='/enter-address' component={EnterAddress} />
        <Route path='/finish-screen' component={FinishScreen} />
        <Route path='/calculator' component={CalculatorPurch} />
{/*       <Route path='/menu-demo' component={MenuDemo} />*/}
        <Route path='/dispense-cash' component={DispenseCash} />
        <Route path='/card_acceptor' component={CardAcceptor} />
        <Route path='/clean-cash' component={CleanCash} />
        <Route path='/scam_screen' component={ScamScreen} />
        <Route path='/loading' component={LoadingScreen} />
        <Route path='/pre-ok' render={()=> (
          this.getStatus() === 1 ? <PreOkScreen /> 
            : <Redirect to='/' />
          )}/>
        <Route path='/enter_serial' render={()=>(
          this.getStatus() === 2 ? <EnterSerial /> 
            : <Redirect to='/' />
          )}/>
        <Route path='/enter_id' component={EnterID} />
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/account_restricted' component={AccountRestricted} />
        <Route path='/send_hodl' component={SendHODL} />
        <Route path='/force_scan_id' component={ForceScanId} />
        <Route path='/password_changed' component={PasswordChanged} />
        <Route path='/verify_code' component={VerifyCode} />
        <Route path='/select_membership' component={SelectMembership} />
        <Route path='/membership_track' component={MembershipTrack} />
        <Route path='/message_sent' component={MessageSent} />
        <Route path='/message_sent_manual' component={MessageSentContact} />
        <Route path='/manual_review_rejected' component={ManualReviewRejected} />
        <Route path='/validate_otp' component={EnterCodeOtp} />
        <Route path='/maintenance-mode' render={()=>(
          this.getStatus() === 9 ? <MaintenanceMode /> 
            : <Redirect to='/' />
          )}/>
      </Switch>
    );
  }
}

function mapStateToProps(state){
  return{
    machine: state.machine,
    qa: state.qa
  }
}

export default connect(mapStateToProps)(Routes)