export function MaintenanceHandler(store, code, description){
  switch(code){
    case 0:
      console.log(description);
      break;

    case -1:
      console.log(description);
      break;

    case -2:
      console.log(description);
      break;

    case -3:
      console.log(description);
      break;

    case -4:
      console.log(description);
      break;

    case -5:
      console.log(description);
      break;

    case -6:
      console.log(description);
      break;

    case -7:
      console.log(description);
      break;

    case -8:
      console.log(description);
      break;

    case -9:
      console.log(description);
      break;

    case -10:
      console.log(description);
      break;

    default:
      console.log('Code not recognized');
  }
}