import createAxe from '../../middlewares/Axe'

import * as types from './actionTypes.js';
import { goLoading, outLoading } from '../loadingActions';
import { triggerError } from '../maintenanceActions'

export function sendSerial(serial){
  return async function(dispatch, getState){
    dispatch(goLoading("Validating serial"))
    try{
      let axe = createAxe(getState())
      let res = await axe('/boro/v2/Atm/serial/set',{
        method: 'POST',
        headers: {
          'Atm-Token': `Bearer ${getState().machine.atm_id}`
        },
        data:{
          serial_number: serial
        }
      })
      if(res.status === 200){
        dispatch(serialSent(serial))
        dispatch(setPartialQA())
        setTimeout(()=>{return dispatch(outLoading('/'))},1000)
      }
    }
    catch(error){
      if(error.response.status === 403){
        dispatch(serialSent(serial))
        dispatch(setPartialQA())
        setTimeout(()=>{return dispatch(outLoading('/'))},1000)
      }
      else{
        dispatch(outLoading())
        return dispatch(triggerError('Error in the serial'));
      }
    }
  }
}

export function setPartialQA(){
  return{
    type: types.SET_PARTIAL_QA
  }
}

export function setQAStatus(status){
  return{
    type: types.SET_QA_STATUS,
    status
  }
}

export function serialSent(serial){
  return{
    type: types.SERIAL_SENT,
    serial
  }
}

export function errorQA(){
  return{
    type: types.ERROR_QA
  }
}