import React from 'react';
import Keyboard, {KeyboardButton, LatinLayout} from 'react-screen-keyboard';
import NumLayout from './NumLayout.js';
import '../../styles/KeyboardBS/KeyboardBS.css';

const KeyboardBS = ({inputNode, numeric=false, showNumber=false, close, next, rightTitle="next", leftTitle="close", disablePlus=false}) =>{

  if(!numeric){
    setTimeout(()=>{          //wait until is render to change name and sizes (if not, it would never found them)
      document.getElementsByClassName('keyboard-spaceButton')[0].textContent = 'space';
      document.getElementsByTagName('svg')[0].attributes.width.value = 50;
      document.getElementsByTagName('svg')[0].attributes.height.value = 50;
      document.getElementsByTagName('svg')[1].attributes.width.value = 50;
      document.getElementsByTagName('svg')[1].attributes.height.value = 50;
      document.getElementsByClassName('keyboard-row')[0].style.marginLeft = '100px';
      if (showNumber === false){
        var x = document.getElementsByClassName('keyboard-numberButton');
        for (var i = 0 ; i<x.length ; i++){
          x[i].style.visibility = 'hidden'
        }
      }
    },30);
  }

  return(
    <Keyboard
      inputNode={inputNode}
      isFirstLetterUppercase={true}
      isNumeric={numeric}
      leftButtons={[
        <KeyboardButton
          onClick={close}
          value={leftTitle}
        />
      ]}
      rightButtons={[
        <KeyboardButton
          onClick={next}
          value={rightTitle}
        />
      ]}
      layouts={[LatinLayout]}
    />
  )
}
export default KeyboardBS;
