import * as types from './actionTypes.js';
import createAxe from '../../middlewares/Axe'
import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';
import { nextView, setDirectionList, getDirectionFromList } from '../viewActions';
import { goLoading, outLoading } from '../loadingActions';
import { triggerError } from '../maintenanceActions';

export function setTiers(tiers) {
    return {
        type: types.SET_TIERS,
        tiers
    }
}

export function setTierInstructions(instructions) {
    return {
        type: types.SET_TIER_INSTRUCTIONS,
        instructions
    }
}

export function getTiers(){
    return async function(dispatch, getState){
        try{
            let axe = createAxe(getState())
            let res = await axe('/v2/unity/tiers/atms', {
                method: 'GET',
                headers: {
                    'Atm-Token': `Bearer ${getState().machine.atm_id}`
                }
            })
            if(res.status === 200){
                return dispatch(setTiers(res.data.data))
            }
        }
        catch(error){
            console.log("Error")
        }
    }
}

export function selectTier(tier_id){
    return async function(dispatch, getState){
        let lang = getState().i18n.language.current
        dispatch(goLoading(i18n[lang].processing))
        try{
            let axe = createAxe(getState())
            let res = await axe(`/v2/unity/tiers/${tier_id}/customers`, {
                method: 'POST',
                headers: {
                    'Customer-Token': `Bearer ${getState().client.client_id}`,
                    'Atm-Token': `Bearer ${getState().machine.atm_id}`
                }
            })
            if(res.status === 200){
                let directions = res.data.data
                let instructions = directions[0].instructions
                if(instructions) {
                    dispatch(nextView(instructions.next_view))
                    // We may want to ignore the instructions for now because
                    // they come in english, and we need other languages too
                    dispatch(setTierInstructions(instructions))
                }
                dispatch(setDirectionList(directions))
                setTimeout(() => {
                    let direction = dispatch(getDirectionFromList())
                    return dispatch(outLoading(direction))
                }, 500)
            }
        }
        catch(error){
            dispatch(outLoading())
            return dispatch(triggerError(i18nErrors[lang].tryLater))
        }
    }
}