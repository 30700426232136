import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import InputBirthBS from '../../../components/common/input-fields/InputBirthBS';
import '../../../styles/EnterDOB/EnterDOB.css';
import moment from 'moment'
import { EnterDOB as i18n } from '../../../middlewares/i18n';
import { updateDOB } from '../../../actions/updateActions';
import { enforceScanID } from '../../../actions/viewActions';

import { track } from '../../../helpers/analytics';
import { years, days, months } from '../../../components/common/input-fields/InputBirthBS/dates';

class EnterDOB extends Component{
  constructor(props){
    super(props);

    // Initialize state.date with the same value of InputBirthBS component.
    // The best would have been to be able to initialize the component with a given value, but the component does not have that feature,
    // so we have to replicate the init logic here
    var defaultDob=  `${months[0].value}/${days[0].value}/${years()[0].value}`;  
    this.state={
      date: defaultDob,
      disabled: false,
      message: ''
    }

    this.handleDate = this.handleDate.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleDate(date){
    this.setState({date: date})
  }

  sendDOB(){
    track('DOB Entered');
    this.props.actions.updateDOB(this.state.date);    
  }

  validateDOB(){
    const dob = moment(this.state.date,'MM/DD/YYYY');  
    if(!dob.isValid()) {
      this.setState({
        message: i18n[this.props.i18n.language.current].invalidDob
      });
      return false;
    }

    if(moment().diff(dob, 'years') > 17){
      this.setState({message: ''})
      return true;
    }
    else {
      this.setState({
        message: i18n[this.props.i18n.language.current].underage
      });
      return false;
    }     
  }

  submit(){
    this.setState({
      disabled: true
    },()=>{
        if(this.validateDOB()){
          this.sendDOB();
        }else{
          this.setState ({disabled: false});
        }}
    );
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        {this.state.message ? <h5 className='message-client-reg'>{this.state.message}</h5> : null}
        { this.props.kyc ?
        <div className='or-scan-id'>
          <h2 className="bs-subtitle no-margin">{i18n[this.props.i18n.language.current].skipStep}</h2>
          <ButtonBS
            className='scan-id-button-add'
            content={i18n[this.props.i18n.language.current].scanID}
            onClick={this.props.actions.enforceScanID}
          />
        </div>
        : null }
        {/* <div className='enter-dob-remind'>
          <h6 className='bs-subtitle no-margin'>{i18n[this.props.i18n.language.current].instructions}</h6>
        </div> */}
        <div className='enter-dob-container'>
          <InputBirthBS date={this.handleDate} language={this.props.i18n.language.current}/>
        </div>
        <ButtonBS className='enter-dob-button' content={i18n[this.props.i18n.language.current].submit} onClick={this.submit} disabled={this.state.disabled}/>
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      updateDOB: bindActionCreators(updateDOB, dispatch),
      enforceScanID: bindActionCreators(enforceScanID, dispatch)
    }
  }
}

function mapStateToProps(state){
  return {
    i18n: state.i18n,
    kyc: state.machine.kyc,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterDOB);