import { addFunds, checkLimits, returnBill } from '../../actions/purchaseActions';
import { errorHardware } from '../../actions/maintenanceActions';
import { setLightStat, lightOff } from '../../actions/lightActions';
import { sendErrorPurch } from '../../actions/machineActions';

import { track } from '../../helpers/analytics';

let message;
const $ = window.$;

export default function billAcceptorMM(action, store, _hub, next){

  switch(action.type){

    case 'CANCEL_INS_BILL':
      store.dispatch(lightOff("BAS"))
      _hub.server.cS_BAS_CancelWait().done(response=>{
      })
      .fail(err=> {
        store.dispatch(errorHardware(err))
      });
      break;

    case 'CHECK_LAST_BOX_STATE': //need to register it in actions
      _hub.server.cS_BAS_LastKnownCashBoxState().done(response=>{
      })
      .fail(err=> {
        store.dispatch(errorHardware(err))
      });
      break;

    case 'RETURN_BILL':
      _hub.server.cS_BAS_Return().done(response=>{
        store.dispatch({ type: 'INSERT_BILL', signalR: true})
        store.dispatch({ type: "ERROR_PURCHASE", message: message})
      })
      .fail(err=> {
        store.dispatch(errorHardware(err))
      });
      message = action.message;
      break;

    case 'STACK_BILL':
      _hub.server.cS_BAS_Stack().done(res=>{
        if(!res.HardwareResult){                // NO ERROR IN HARDWARE ( res.HardwareResult === 0)
          // We have a Denomination and ValidatorState === 2 (Stacked) (It's in the bill acceptor)
          if (res.Data.Denomination && res.Data.ValidatorState === 2){
            let p1 = res.Data.Denomination  // bill inserted denomination
            // funds to be added
            let funds = Object.assign({}, store.getState().purchase.bills, {[p1]:store.getState().purchase.bills[p1]+1})
            store.dispatch(addFunds(funds, p1))
          }
          else {
            store.dispatch(sendErrorPurch(res))
          }
        }
        else
          store.dispatch(sendErrorPurch(res))
      })
      .fail(err=> {
        store.dispatch(errorHardware(err))
      });
      store.dispatch({type: "LOCK_EXIT"})
      break;

    case 'FUNDS_ADDED':
      store.dispatch({ type: "INSERT_BILL", signalR: true})
      break

    case 'INSERT_BILL':
      _hub.server.cS_BAS_Wait().done(res=>{
        if(res === -15)                   // GENMEGA 
          store.dispatch({
            type: 'RETURN_BILL',
            signalR: true
          })
        if(store.getState().machine.name === 'rick'){   //IF MACHINE IS A RICK
          let event = new Event('inserted', { bubbles: true });
            $("body")[0].dispatchEvent(event);
          if(!res.HardwareResult){                      // NO ERROR IN HARDWARE ( res.HardwareResult === 0)
            // We have a Denomination and ValidatorState === 1 (Escrowed) (It's in the bill acceptor)
            if (res.Data.Denomination && res.Data.ValidatorState === 1){
              let p1 = res.Data.Denomination // bill inserted denomination
              // Funds to be added
              let funds = Object.assign({}, store.getState().purchase.bills, {[p1]:store.getState().purchase.bills[p1]+1})
              let checker = store.dispatch(checkLimits(funds, p1))     // Check limits and min bill
              if(checker) {                
                if(store.getState().purchase.num_bills < 1) {
                  track('First Bill inserted');
                }                 // good to go
                store.dispatch({ type: 'STACK_BILL', signalR: true })       // Stack it!
              }
              else {  // Not valid limit check (Should be returned)
                store.dispatch(returnBill())
              }
            }
            else{
              if(res.Data.ValidatorState !== 20 || res.HardwareResult)  //!== Canceled or hardware error > 0
                store.dispatch(sendErrorPurch(res))
              if(res.MetaData.Reason === "Canceled" || res.MetaData.Description === "Canceled"){
              }
              else{
                store.dispatch({ type: 'INSERT_BILL', signalR: true})
              }
            }
          }
          else 
            console.error('There was a wait already')
        }
      })
      .fail(err=> {
        store.dispatch(errorHardware(err))
      });
      break;

    default:
      next()
  }
}