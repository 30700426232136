import { enterMaintenance } from '../../../actions/maintenanceActions';
import BillAcceptorHandler from './BillAcceptorHandler';
import QRHandler from './QRHandler';
import ImagingDeviceHandler from './ImagingDeviceHandler';
import PrinterHandler from './PrinterHandler';
import CardReaderHandler from './CardReaderHandler';
// import BillDispenserHandler from './BillDispenserHandler';


export default function AnalyzeStatus(store){
  let { maintenance } = store.getState()
  // if(maintenance.active && maintenance.errorCode === 1) {
  //    don't check status because we are without internet
  // }
  // else {
  //   ... do the check statuses
  // }
  // it's equivalent to:
  if(!maintenance.active || maintenance.errorCode !== 1) {
    let status = maintenance.statuses; //get all statuses
    let hardware = ''                                   //declare hardware
    for (let prop in status){
      if (status[prop].HardwareResult > 0 && prop !=='TouchScreen' && prop !=='BillDispenser'){
        hardware = prop;
        break;
      }
    }
    switch(hardware){                       //If there is a hardware error, trigger it respective function
      case 'BillAcceptor':
        BillAcceptorHandler(store, status);
        break;
      case 'BarcodeScanner':
        QRHandler(store, status);
        break;
      case 'ImagingDevice':
        ImagingDeviceHandler(store, status);
        break;
      case 'Printer':
        PrinterHandler(store, status);
        break;
      case 'CardReaderService':
        CardReaderHandler(store, status);
        break;
      // case 'BillDispenser':
      //   BillDispenserHandler(store, status);
      //   break;
      default:
        //Just triggered when we don't have any
        // BillAcceptorHandler(store, status);
        // QRHandler(store, status);
        // ImagingDeviceHandler(store, status);
        // PrinterHandler(store, status);
        // CardReaderHandler(store, status);
        // // BillDispenserHandler(store, status)
        for(let prop in status){
          switch(prop){
            case 'BillAcceptor':
              BillAcceptorHandler(store, status);
              break;
            case 'BarcodeScanner':
              QRHandler(store, status);
              break;
            case 'ImagingDevice':
              ImagingDeviceHandler(store, status);
              break;
            case 'Printer':
              PrinterHandler(store, status);
              break;
            case 'CardReaderService':
              CardReaderHandler(store, status);
              break;
          }
        }
    }
  }
}