const { createCanvas, loadImage } = require('canvas')

const WIDTH = 400
const HEIGHT = 300

const canvas = createCanvas(WIDTH, HEIGHT)
const ctx = canvas.getContext('2d')

export const resizeBase64 = (imgbase, width = WIDTH, height = HEIGHT) => {
    return new Promise((resolve, reject) => {

        loadImage(`data:image/jpeg;base64,${imgbase}`).then(image => {
            ctx.drawImage(image, 0, 0, width, height);

            let final = canvas.toDataURL()
            final = final.replace("data:image/png;base64,","")

            resolve(final);
        })
        .catch(err => reject(err))
    })
}