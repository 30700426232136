import { useState } from 'react';

const useSteps = (steps) => {
  const [step, setStep] = useState(0);

  const isFirstStep = step === 0;
  const isLastStep = step >= steps;

  const changeStep = (delta = 1) => {
    setStep(step + delta);
  }

  return { step, changeStep, isFirstStep, isLastStep };
};

export default useSteps;