import * as types from './actionTypes';
import { push, goBack } from 'connected-react-router';

export function goLoading(message){
  return function(dispatch){
    dispatch(setLoadingStatus(true))
    dispatch(setLoadingMessage(message))
    setTimeout(()=>{return dispatch(push('/loading'))},500)
  }
}

export function outLoading(direction){
  return function(dispatch, getState){
    if(getState().loading.status){
      dispatch(resetLoading())
      if(direction)
        return dispatch(push(direction))
      else
        return dispatch(goBack())
    }
  }
}


export function resetLoading(){
  return function(dispatch){
    dispatch(setLoadingMessage('Loading'))
    return dispatch(setLoadingStatus(false))
  }
}

export function setLoadingMessage(message){
  return{
    type: types.LOADING_MESSAGE,
    message
  }
}

export function setLoadingStatus(status){
  return{
    type: types.LOADING_STATUS,
    status
  }
}