import React, {useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../components/common/Footer';
import ButtonBS from '../../../components/common/ButtonBS';
import '../../../styles/ScamScreen/ScamScreen.css';
import { push } from 'connected-react-router'
import { MessageSent as i18n } from '../../../middlewares/i18n';
import { getDirectionFromList } from '../../../actions/viewActions'

import { track } from '../../../helpers/analytics';

const ManualReviewRejected = () => {

  const language = useSelector(state => state.i18n.language.current)

  const dispatch = useDispatch()

  const advance = useCallback(
    () => {
      track('Upgrade Rejected');
      let direction = dispatch(getDirectionFromList())
      dispatch(push(direction))
  }, [])

  return(
    <div className="bs-body scam-body">
      <div className="scam-header">
        <div className="scam-logo" />
        <div>
          <h6 className="bs-title centered scam-header-title">{i18n[language].title_rejected}</h6>
          <div className="line" /> 
        </div>
      </div>
      <div className="scam-container">
        <div className="scam-cont">
          <h5 className="no-margin">{i18n[language].rejected_reason}</h5>
          <br />
          <div>
            <h5 className="no-margin">{i18n[language].contact_support1}</h5>
            <h5 className="no-margin">{i18n[language].contact_support2}</h5>
          </div>
        </div>
        <ButtonBS className="scam-button" content={i18n[language].continue} onClick={advance}/>
      </div>
      <Footer />
    </div>
  )
}

export default ManualReviewRejected;