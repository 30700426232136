export const SCAN_QR_CODE = 'SCAN_QR_CODE';
export const SCAN_CARD = 'SCAN_CARD';
export const CANCEL_SCAN = 'CANCEL_SCAN';
export const SCAN_BARCODE = 'SCAN_BARCODE';
export const BARCODE_SCANNED = 'BARCODE_SCANNED';
export const SCAN_ID = 'SCAN_ID';
export const ID_SCANNED = 'ID_SCANNED';
export const ADD_ADDRESS_ID = 'ADD_ADDRESS_ID';
export const TAKE_PICTURE_SCAN = 'TAKE_PICTURE_SCAN';
export const CANCEL_SCAN_CARD = 'CANCEL_SCAN_CARD';
export const SAFE_CANCEL_SCAN = 'SAFE_CANCEL_SCAN';