import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ButtonBS from '../../../components/common/ButtonBS'
import PageLayout from '../../../components/common/PageLayout/PageLayout4'
import { getDirectionFromList, reqUpgradeManual } from '../../../actions/viewActions'
import '../../../styles/MessageSent/MessageSent.css'
import { MessageSent as i18n } from '../../../middlewares/i18n';
import { push } from 'connected-react-router'
import { agreeToContact } from '../../../actions/updateActions'
import LoadingScreen from '../LoadingScreen';
import { track } from '../../../helpers/analytics';

const MessageSentContact = () => {
    const _i18n = useSelector(state => state.i18n)
    const view = useSelector(state => state.view)

    const dispatch = useDispatch()
    const advance = useCallback(() => {
        track('Application Continue Clicked (Manual review)');
        let direction = dispatch(getDirectionFromList())
        dispatch(push(direction))
    }, [])

    const agreeContact = useCallback(() => {
        dispatch(agreeToContact())
    }, [])

    const reqManual = useCallback(() => {
        dispatch(reqUpgradeManual())
    }, [])

    useEffect(() => {
        reqManual()
        if(!view.valid_agree_req)
            agreeContact()
    }, [])

    if (view.valid_agree_req)
        return(
            <PageLayout
                title={i18n[_i18n.language.current].title}
                price
                big
            >
                <div className='mess-sent-container'>
                    <div className='paper-plane-red mess-sent-plane' />
                    <p>{i18n[_i18n.language.current].instructions_1}</p>
                    <br />
                    <br />
                    <p>{i18n[_i18n.language.current].instructions2}</p>
                    <ButtonBS
                        className ='mess-sent-button'
                        content={i18n[_i18n.language.current].continue}
                        onClick={ advance }
                    />
                </div>
            </PageLayout>
        )
    else
        return <LoadingScreen />
}

export default MessageSentContact;