import React from 'react';
import { useSelector } from 'react-redux';
import ErrorCode from '../../../containers/ErrorCode';
import '../../../styles/MaintenanceMode/MaintenanceMode.css';
import { MaintenanceMode as i18n } from '../../../middlewares/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';

const MaintenanceScreen = () => {
  const _i18n = useSelector(state => state.i18n)

  return(
    <div className='bs-body maint-sc-body'>
      <h6 className='bs-subtitle no-margin'>{i18n[_i18n.language.current].welcome}</h6>
      <div className="social-container">
        <div className="social-icon">
          <FontAwesomeIcon icon={faFacebookF} />
        </div>
        <div className="social-icon">
          <FontAwesomeIcon icon={faXTwitter} />
        </div>
        <div className="social-icon">
          <FontAwesomeIcon icon={faInstagram} />
        </div>
        <span className="social-text">
          <span className="separator"></span>
          <span className="bitstopofficial">@bitstopofficial</span>
        </span>
      </div>
      <div className='bs-logo-maint' />
      <h4 className="maint-title no-margin">{i18n[_i18n.language.current].title}</h4>
      <div className="maint-info-cont">
        <h4 className="maint-info no-margin">{i18n[_i18n.language.current].message1}</h4>
        <h4 className="maint-info no-margin">{i18n[_i18n.language.current].message2}</h4>
        <h4 className="maint-info no-margin">{i18n[_i18n.language.current].message3}</h4>
        <h4 className="maint-info no-margin">{i18n[_i18n.language.current].message4}</h4>
        <ErrorCode />
      </div>
      <div className='maint-cont-logo'>
        <div className='rounded' />
        <div className='maint-logo' />
      </div>
    </div>
  )
}

export default MaintenanceScreen