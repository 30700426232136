import * as types from './actionTypes';

export function changeLanguage(current) {
    return {
        type: types.CHANGE_LANGUAGE,
        current
    }
}

export function setDefaultLanguage(defLang) {
    return {
        type: types.SET_DEFAULT_LANGUAGE,
        defLang
    }
}

export function setAvailableLanguage(available) {
    return {
        type: types.SET_AVAILABLE_LANGUAGES,
        available
    }
}