import * as types from './actionTypes.js';
import createAxe from '../../middlewares/Axe'
import { push } from 'connected-react-router';
import { setModalType, openModal, closeModal } from '../modalActions';
import { setState } from '../machineActions';
import { setHardRest } from '../clientActions';
import { outLoading } from '../loadingActions';
import { cancelOperation } from '../authActions';
import { sendToWallet, printReceipt } from '../purchaseActions';
import { stateToReceipt } from '../../containers/Views/Receipt/receipt-design.js' ;

import { Errors as i18nErrors } from '../../middlewares/i18n';

// Enter Maintenance Mode function
export function enterMaintenance(code){
  return function(dispatch, getState){
    // Dispatch change main actions
    dispatch(setState(9))             // Set app state to 9 (maintenance)
    dispatch({ type: types.MAINTENANCE_MODE_ON})
    dispatch(setErrorCode(code))      // Set the error code for maintenance screen
    let location = getState().router.location.pathname
    // Let's prevent exit in print clear cash
    if(location !== '/clean-cash') {
      switch(location){
        case '/insert_cash':
          if(!getState().purchase.exit){                  //the user already inserted a bill
            dispatch(sendToWallet())
            dispatch(printReceipt(
              stateToReceipt(getState())
            ))
          }
          break;
      }
      setTimeout(()=>{
        dispatch(push('/maintenance-mode'))
        return dispatch(cancelOperation())             //work to see is we in middle of tx
      }, 500)
    }
  }
}

export function enterPreOk(){
  return function(dispatch){
    dispatch(setState(1))
    setTimeout(()=>{
      dispatch(push('/pre-ok'))
      return dispatch(cancelOperation())
    }, 500)
  }
}

export function exitMaintenance(){
  return function(dispatch, getState){
    dispatch(setState(0))
    dispatch(setErrorCode(0))
    dispatch({ type: types.MAINTENANCE_MODE_OFF})
    // Let's prevent exit in print clear cash
    if(getState().router.location.pathname !== '/clean-cash')
      setTimeout(()=> {return dispatch(push('/'))}, 500)
  }
}

export function noMoreBills(){
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(triggerError(i18nErrors[lang].noMoreBills, 9000))
    setTimeout(()=>{return dispatch(sendToWallet())}, 10000)
  }
}

export function maintenanceAfterWait(code){
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(triggerError(i18nErrors[lang].cancelledTx))
    setTimeout(()=>{return dispatch(enterMaintenance(code))},6000)
  }
}

export function triggerError(message, timer = 5500){
  return function(dispatch, getState){
    dispatch(errorMessage(message))
    dispatch(setModalType('error'))
    setTimeout(()=>dispatch(openModal()),2500)
    if(getState().router.location.pathname === '/card_acceptor')
      return setTimeout(()=>dispatch(closeModal()), 9000)
    else
      return setTimeout(()=>dispatch(closeModal()), timer)
  }
}

export function setPrinterDown(){
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(printerDown())
    return triggerError(i18nErrors[lang].cannotPrint)
  }
}

export function digestErrorCode(error_code, callback){
  return function(dispatch){
    switch(error_code){
      case 405:
        return dispatch(outLoading('account_restricted'))
      
      case 406:
        dispatch(setHardRest())
        return dispatch(outLoading('account_restricted'))
        
      default:
        callback()
    }
  }
}

export function errorHardware(error){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      let res = await axe('/report-station/error-report',{
        method: 'POST',
        data:{
          atm_token: getState().machine.atm_id,
          data:{
            error
          }
        }
      })
    }
    catch(error){}
  }
}

export function printScan(data){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      let res = await axe('/report-station/print-scan',{
        method: 'POST',
        data:{
          atm_token: getState().machine.atm_id,
          data:{
            data
          }
        }
      })
    }
    catch(error){}
  }
}

export function printerDown(){
  return{
    type: types.PRINTER_DOWN
  }
}

export function errorMessage(message){
  return{
    type: types.ERROR_MESSAGE_MODAL,
    message,
  }
}

export function printerWorking(){
  return{
    type: types.PRINTER_WORKING
  }
}

export function cameraDown(){
  return { 
    type: types.CAMERA_DOWN
  }
}

export function cameraWorking(){
  return{
    type: types.CAMERA_WORKING
  }
}

export function cardReaderDown(){
  return { 
    type: types.CARD_READER_DOWN
  }
}

export function cardReaderWorking(){
  return{
    type: types.CARD_READER_WORKING
  }
}

export function dispenserDown(){
  return { 
    type: types.DISPENSER_DOWN
  }
}

export function dispenserWorking(){
  return{
    type: types.DISPENSER_WORKING
  }
}

export function setErrorCode(code){
  return{
    type: types.SET_ERROR_CODE,
    code
  }
}

export function clearCash(){
  return{
    type: types.CLEAR_CASH_METRICS,
    signalR: true
  }
}

export function checkDeviceStatuses(){
  return{
    type: types.GET_DEVICE_STATUS,
    signalR: true
  }
}