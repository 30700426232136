import reduxThunk from 'redux-thunk';
// import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from '../reducers';
import initialState from '../reducers/initialState';
import InternetConnect from '../middlewares/InternetConnect';
import { signalRMiddleware } from '../middlewares/Kiosk-Middleware';
import MaintenanceMiddleware from '../middlewares/MaintenanceService/MaintenanceMiddleware';

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const reducer = rootReducer(history);

export const store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(
    reduxThunk,
    // logger,
    routeMiddleware,
    MaintenanceMiddleware,
    signalRMiddleware,
    InternetConnect
  ))
);