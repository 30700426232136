import initialState from './initialState';

export default function signalrReducer(state = initialState.signalr, action){

  switch(action.type){
	
  	case 'SIGNALR_READY':
  		return Object.assign({}, state, {
  			active: true
		})
			
	case 'SIGNALR_DOWN':
		return Object.assign({}, state, {
  			active: false
		})

  	default:
  		return state;
  }
}