import {
  checkVersion,
  getOperatorDetails,
  heartbeat
} from '../../actions/machineActions';
import { clearConsole } from '../../helpers/memory';
import { getTxsMaint } from '../../actions/dbActions';
import { pingPrecioWS } from '../../middlewares/WebSocket-Middleware'

var interval30sec = null       // 30 sec interval
var interval1min = null         // 1 min interval
var interval5min = null        // 5 min interval
var interval8hours = null      // 8 hours interval

/*
* All this actions need to be repeated x amount of time
* when they have a machine token available.
* This event should be triggered once.
*/

export function initTokenActions(store) {
  // Event should be triggered once
  console.log("Added events for initTokenActions")
  window.addEventListener('tokenRetrieved', () => {

    console.log("Received event tokenRetrieved", store.getState().machine.atm_id)

    clearConsole()                        // Removed all messages from console

    if(interval30sec)                     // If we have a previous interval
      clearInterval(interval30sec)        // Clear it

    if(interval1min)                     // If we have a previous interval
      clearInterval(interval1min)        // Clear it

    if(interval5min)                      // If we have a previous interval
      clearInterval(interval5min)         // Clear it

    if(interval8hours)                    // If we have a previous interval
      clearInterval(interval8hours)       // Clear it

    store.dispatch(checkVersion())        // Check version
    store.dispatch(heartbeat())           // First HB
    store.dispatch(getTxsMaint())         // Send unsent txs
    store.dispatch(getOperatorDetails())  // Ensure operator info

    // store.dispatch(getTiers())            // getTiers

    interval30sec = setInterval(() => {   // Fresh interval (30sec)
      store.dispatch(checkVersion())
    }, 30000)                             // 30 seconds

    interval1min = setInterval(() => {   // Fresh interval (1 min)
      pingPrecioWS()
    }, 30000)                             // 1 min

    interval5min = setInterval(() => {    // Fresh interval (5min)
      store.dispatch(heartbeat())
    }, 300000)                            // 5 minutes

    interval8hours = setInterval(() => {  // Fresh interval (8hours)
      store.dispatch(getTxsMaint())
    }, 28800000)                          // 8 hours

    clearConsole(600000)                  //Clear console every 10 min
  });
}

export function tokenRetrieved(token) {
  console.log("Dispatching event tokenRetrieved", token)
  if(token) {
    let event = new Event('tokenRetrieved', { bubbles: true });
    window.dispatchEvent(event);
  }
  else {
    console.log("ERROR Retrieving token.")
  }
}

export function tokenSubscriber(store) {
  console.log("Change in the store", store.getState())
  if(store.getState().machine.atm_id) {
    tokenRetrieved(store.getState().machine.atm_id)
  }
}