import * as types from './actionTypes';
import createAxe from '../../middlewares/Axe'
import { expRetry } from '../../helpers/timeouts';

export function takePicture(view){
  return{
    type: types.TAKE_PICTURE,
    signalR: true,
    view
  }
}


export function takeVideo(){
  return{
    type: types.TAKE_VIDEO,
    signalR: true
  }
}

export function failedToSend(){
  return{
    type: types.FAILED_TO_SEND
  }
}

export function picSent(){
  return{
    type: types.PIC_SENT
  }
}

export function sendPicture(image_base64, idtx, view, retry = 0){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      await axe('/gas-station/fill',{
        method: 'POST',
        data:{
          url: '/image/new',
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          data: {
            image_base64,
            idtx,
            view
          }
        }
      })
      return dispatch(picSent())
    }
    catch(error){
      // if we fail
      return expRetry(
        (_retry) => dispatch(sendPicture(image_base64, idtx, view, _retry)),
        failedToSend,
        retry
      )
    }
  }
}
