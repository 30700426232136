import initialState from './initialState';

export default function pinpadReducer(state = initialState.pinpad, action){

  switch(action.type){

    case 'ENTER':
      return Object.assign({}, state, {
        enter: true,
      })

    case 'RELEASE_ENTER':
      return Object.assign({}, state, {
        enter: false,
        key:''
      })

    case 'CLEAR':
      return Object.assign({}, state, {
        clear: true,
      })

    case 'RELEASE_CLEAR':
      return Object.assign({}, state, {
        clear: false,
        key:''
      })

    case 'KEY_PRESSED':
      return Object.assign({}, state, {
        key: action.key,
      })

    case 'RELEASE_KEY':
      return Object.assign({}, state, {
        key: ''
      })

    default: 
      return state;

  }
}