export const SET_MACHINE = 'SET_MACHINE';
export const MACHINE_SIZE = 'MACHINE_SIZE';
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
export const SET_ATM_ID = 'SET_ATM_ID';
export const SET_STATE = 'SET_STATE';
export const SET_LANG = 'SET_LANG';
export const SET_INTLPHONE = 'SET_INTLPHONE';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_KYC_REQ = 'SET_KYC_REQ';
export const SETTINGS_CHANGED = 'SETTINGS_CHANGED';
export const NEW_SET = 'NEW_SET';
export const SET_PRIMARY = 'SET_PRIMARY';
export const SET_SETTING_ID = 'SET_SETTING_ID';
export const HEARTBEAT_SENT = 'HEARTBEAT_SENT';
export const HEARTBEAT_ERROR = 'HEARTBEAT_ERROR';
export const ERROR_SENT_PURCH = 'ERROR_SENT_PURCH';
export const INTERNET_DOWN = 'INTERNET_DOWN';
export const SET_OPERATOR_ID = 'SET_OPERATOR_ID';
export const SET_OPERATOR_NAME = 'SET_OPERATOR_NAME';
export const SET_OPERATOR_ADDRESS = 'SET_OPERATOR_ADDRESS';
export const SET_OPERATOR_IMAGE = 'SET_OPERATOR_IMAGE';
export const SET_OPERATOR_NMLS = 'SET_OPERATOR_NMLS';
export const SET_LOCAL_STATE = 'SET_LOCAL_STATE';
