import React, { Component } from 'react';
import '../../../../styles/common/input-fields/InputCodeBS.css';
import { isEqual, clone } from 'lodash';
import KeyboardBS from '../../../KeyboardBS';

class InputCodeTinyRick extends Component{

  constructor(props){
    super(props);
    this.state={
      code:['','','','',''],
      inputNode: null,
      lastIndex: 0
    }
    
    this.onChange = this.onChange.bind(this);
    this.openKeyboard = this.openKeyboard.bind(this);
    this.delete = this.delete.bind(this);
  }

  onChange(e){
    if (!Number.isNaN(parseInt(e.target.value, 10))){
      if(this.state.lastIndex === '4' && this.state.code[4].length === 1){
        e.target.value = this.state.code[4]
      }
      let index = parseInt(this.state.lastIndex, 10);        //get the index of the active field
      let code = clone(this.state.code);                        //clone code for new version
      let oldCode1 = clone(code);                                //clone again code for mantain old version
      if(code[index].length === 0){
        code[index] = e.target.value;                        //add code in that field according with the index)
        this.setState({
          code
        }, ()=>{
          if(!isEqual(this.state.code, oldCode1)){                 //check if the old and new code are the same
            if (index < 4){
              if(!this.state.code[index+1])               //exist next empty field -> go to next
                setTimeout(()=>document.getElementById(String(index+1)).focus(),30);
              else {
                document.getElementById(4).blur()
                this.sendCode();                                //last field fill -> send 
              }
            }
            else {
              document.getElementById(4).blur();
              this.sendCode();                                //last field fill -> send
            }
          }
        })
      }
      else{
        if (index < 4){
          setTimeout(()=>document.getElementById(String(index+1)).focus(),30); //go to next field
        }
        else { 
          document.getElementById(4).blur();
          this.sendCode();                                //last field fill -> send
        }
      }
    }
  }

  sendCode(){
    this.props.sendCode(this.state.code)
  }

  reset(){
    this.setState({ 
      code: ['','','','',''] 
    },()=>{
      this.sendCode();
      document.getElementById('0').focus()
    })
  }

  openKeyboard(node){
    if(document.activeElement.value.length > 0){
      if(document.activeElement.id !== '4'){
        document.getElementById(String(parseInt(document.activeElement.id, 10)+1)).focus();
      }
    } else{
      this.setState({
        inputNode: node,
        lastIndex: document.activeElement.id
      })
    }
  }

  delete(){
    this.reset()
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  componentDidMount(){
    document.getElementById('0').focus()
  }

  render(){
    return(
      <div>
        <div className='input-code'>
          <input type='text' id='0' className='input-square' value={this.state.code[0]} onFocus={()=>this.openKeyboard(this.code1)} onInput={ this.onChange } ref={(input) => {this.code1 = input}} autoComplete="off"/>
          <input type='text' id='1' className='input-square' value={this.state.code[1]} onFocus={()=>this.openKeyboard(this.code2)} onInput={ this.onChange } ref={(input) => {this.code2 = input}} autoComplete="off"/> 
          <input type='text' id='2' className='input-square' value={this.state.code[2]} onFocus={()=>this.openKeyboard(this.code3)} onInput={ this.onChange } ref={(input) => {this.code3 = input}} autoComplete="off"/> 
          <input type='text' id='3' className='input-square' value={this.state.code[3]} onFocus={()=>this.openKeyboard(this.code4)} onInput={ this.onChange } ref={(input) => {this.code4 = input}} autoComplete="off"/>
          <input type='text' id='4' className='input-square' value={this.state.code[4]} onFocus={()=>this.openKeyboard(this.code5)} onInput={ this.onChange } ref={(input) => {this.code5 = input}} autoComplete="off"/>  
        </div>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.delete}
            showNumber = {true}
            numeric = {true}
            rightTitle = {"enter"}
            leftTitle = {"delete"}
            next = {this.props.submit}
            disablePlus = {true}
          /> : null }
      </div>
    );
  }
}

InputCodeTinyRick.defaultProps = {
  submited: false
}

export default InputCodeTinyRick;