import initialState from './initialState';

export default function viewReducer(state = initialState.view, action){

  switch(action.type){

    case 'NEXT_VIEW':
      return Object.assign({}, state, {
        screen: action.view
      })

    case 'SET_DIRECTION_LIST':
      return Object.assign({}, state, {
        list: action.list
      })

    case 'SET_TITLE':
      return Object.assign({}, state, {
        title: action.title
      })

    case 'SET_CONTENT':
      return Object.assign({}, state, {
        content: action.content
      })

    case 'VALID_AGREE_REQ':
      return Object.assign({}, state, {
        valid_agree_req: true
      })

    case 'REQ_UPGRADE_PERSONA':
      return Object.assign({}, state, {
        req_upgrade_persona: true
      })

    case 'REQ_UPGRADE_MANUAL':
      return Object.assign({}, state, {
        req_upgrade_manual: true
      })

    case 'CANCEL_OPERATION':
      return initialState.view

    default:
      return state;

  }
}