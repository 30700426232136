import * as types from './actionTypes';
import { scanBarcode, scanQRCode, safeCancelScan } from '../scanActions';
import { cancelReadCard, readCard } from '../cardReaderActions';
import { insertBill, cancelInsBill } from '../purchaseActions';
import { BARCODE, QR_CODE } from '../../middlewares/SignalRMM/scanner';

export function closeModal(){
  return function(dispatch){
    dispatch(setModalType(''))
    return dispatch(modalClosed())
  }
}

export function openNewModal(type){
  return function(dispatch){
    dispatch(modalClosed());
    dispatch(setModalType(type));
    dispatch(openModal());
    return dispatch({
      type: types.NEW_MODAL_OPEN
    })
  }
}

export function openReminder(){
  return function(dispatch, getState){
    if(getState().transaction.init){
      switch(getState().router.location.pathname) {
        case '/scan_wallet':
          dispatch(safeCancelScan(QR_CODE))
          break;
        case '/scan_id':
          dispatch(safeCancelScan(BARCODE))
          break;
        case '/card_acceptor':
          dispatch(cancelReadCard())
          break;
        case '/insert_cash':
          dispatch(cancelInsBill())
          break;
      }
      return dispatch({ 
        type: types.OPEN_REMINDER
      })
    }
  }
}

export function closeReminder(active){
  return function(dispatch, getState){
    if(active){
      let route = getState().router.location.pathname;
      if (route === '/scan_wallet'){
        dispatch(scanQRCode())
      } else if (route === '/scan_id'){
        dispatch(scanBarcode())
      } else if (route === '/card_acceptor'){
        dispatch(readCard())
      } else if (route === '/insert_cash'){
        dispatch(insertBill())
      }
    }
    return dispatch({
      type: types.CLOSE_REMINDER
    })
  }
}

export function reminderTimedOut(){
  return{
    type: types.REMINDER_TIMED_OUT
  }
}

export function modalClosed(){
  return{
    type: types.CLOSE_MODAL
  }
}

export function setModalType(modalType){
  return{
    type: types.SET_MODAL_TYPE,
    modalType
  }
}

export function openModal(){
  return{
    type: types.OPEN_MODAL
  }
}