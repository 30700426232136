import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as machineActions from '../../../actions/machineActions';

class Menu extends PureComponent{

  constructor(props){
    super(props);
    this.printMenu = this.printMenu.bind(this);
    this.changeSize = this.changeSize.bind(this);
  }

  printMenu(){
    let routes = [
      '/',
      '/Terms-Conditions',
      '/Enter-Phone',
      '/verify_phone',
      '/enter_pin',
      '/create_pin',
      '/verify_pin',
      '/select_tiers',
      '/scan_id',
      '/scan_wallet',
      '/insert_cash',
      '/Receipt',
      '/Is-This-You',
      '/enter_dob',
      '/about_you',
      '/Enter-Address',
      '/Finish-Screen',
      '/Calculator',
      '/Maintenance-Mode',
      '/Dispense-Cash',
      '/Card_Acceptor',
      '/Clean-Cash',
      '/scam_screen'
    ]

    const menu = routes.map((route)=>{
      return(
        <Link to={route} style={{textDecoration: 'none', color: '#000000', fontSize: '30px'}}><div style={{border: 'solid 7px #FF1720', padding: '20px', margin: '20px auto', width: '300px'}}>{route.replace(/-/g,' ').replace(/\//g,'').replace(/_/g,' ')}</div></Link>
      )
    })

    return menu;
  }

  changeSize(){
    if(this.props.mach.tiny){
      this.props.actions.mach.setSize(false)
    }
    else{
      this.props.actions.mach.setSize(true)
    }
  }

  render(){
    return(
      <div className="bs-body centered" style={{textAlign: 'center'}}>
        <h2 style={{fontSize: '50px', marginBottom: '110px'}}>{this.props.mach.tiny ? 'TINY ' : ''}RICK MACHINE VIEWS MENU</h2>
        { this.printMenu() }
        {this.props.mach.tiny ? <h2>I am tiny rick</h2> : <h2>I am old rick</h2>}
        <div style={{border: 'solid 7px #FF1720', padding: '20px', margin: '20px auto', width: '300px', fontSize: '30px'}} onClick={this.changeSize}>Set Tiny</div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      mach: bindActionCreators(machineActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    mach: state.machine
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu)