export const style = {

   overlay: {
    zIndex: 10
  },

  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-20%',
    transform: 'translate(-50%, -50%)',
    padding: '0px 5px 35px 5px',
    textAlign: 'center',
    boxShadow: '0px 0px 25px #BDBDBD',
    borderRadius: 'none',
    fontSize: '60px',
    fontFamily: 'PT-Regular',
    wordWrap: 'break-word',
  },
  
};