import { store } from './store';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { minimizeToken } from './helperFunctions';

const PROTOCOL = 'https';

const set_machine_name = (machine_name, tiny) => {
  if(machine_name == 'rick') {
    if(tiny)
      return 'windfall'
    else
      return 'benchmark'
  }
  else if(machine_name == 'morty')
    return 'genmega'
  else
    return machine_name
}

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    cdnURL: `${PROTOCOL}://${process.env.REACT_APP_SEGMENT_API_HOST}`,
  },
  {
    integrations: {
      'Segment.io': {
        apiHost: `${process.env.REACT_APP_SEGMENT_API_HOST}/v1`,
        protocol: PROTOCOL,
      },
    },
  },
);

export const track = (event, properties = {}) => {
  try {
    const state = store.getState();

    return analytics.track(event, {
      atm_id: minimizeToken(state.machine.atm_id),
      atm_type: set_machine_name(state.machine.name, state.machine.tiny),
      robotface_version: process.env.REACT_APP_VERSION,
      customer_id: minimizeToken(state.client.client_id),
      ...properties,
    });
  } catch {
    //
  }
};