import React, { PureComponent } from 'react';
import { isEqual, clone } from 'lodash';
import KeyboardBS from '../../../KeyboardBS';

class InputPinTinyRick extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      pin: ['','','',''],
      inputNode: null,
      lastIndex: 0
    }

    this.onChange = this.onChange.bind(this);
    this.openKeyboard = this.openKeyboard.bind(this);
    this.delete = this.delete.bind(this);
  }

  onChange(e){
    if(this.state.lastIndex === '3' && this.state.pin[3].length === 1){
      e.target.value = this.state.pin[3]
    }
    if (!Number.isNaN(parseInt(e.target.value, 10))){
      let index = parseInt(this.state.lastIndex, 10);        //get the index of the active field
      let pin = clone(this.state.pin);                        //clone pin for new version
      let oldPin = clone(pin);                                //clone again pin for mantain old version
      if(pin[index].length === 0){
        pin[index] = e.target.value;                        //add pin in that field according with the index)
        this.setState({
          pin
        }, ()=>{
          setTimeout(()=>document.getElementById(index).type = 'password',250);            //Hide pwd
          if(!isEqual(this.state.pin, oldPin)){                 //check if the old and new pin are the same
            if (index < 3){
              setTimeout(()=>document.getElementById(String(index+1)).focus(),30); //go to next field
            }
            else { 
              document.getElementById(3).blur();
              this.sendPin();                                //last field fill -> send
            }
          }
        })
      }
      else{
        if (index < 3){
          setTimeout(()=>document.getElementById(String(index+1)).focus(),30); //go to next field
        }
        else { 
          document.getElementById(3).blur();
          this.sendPin();                                //last field fill -> send
        }
      }
    }
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  sendPin(){
    this.props.sendPin(this.state.pin);
  }

  reset(){
    this.setState({
      pin: ['','','','']
    },()=>{
      this.sendPin();
      document.getElementById('0').focus();
      for(let i = 0; i < 4; i++){
        document.getElementById(i).type = 'text'
      }
    })
  }

  openKeyboard(node){
    if(document.activeElement.value.length > 0){
      if(document.activeElement.id !== '3'){
        document.getElementById(String(parseInt(document.activeElement.id, 10)+1)).focus();
      }
    } else{
      this.setState({
        inputNode: node,
        lastIndex: document.activeElement.id
      })
    }
  }

  delete(){
    this.reset()
  }

  componentDidMount(){
    document.getElementById('0').focus()
  }

  render(){
    return (
      <div className='input-code'>
        <form id='pin-form'>
          <input type='text' maxLength={1} id='0' className='input-square' value={this.state.pin[0]} onInput={this.onChange} onFocus={()=>this.openKeyboard(this.pin1)} ref={(input) => {this.pin1 = input}} autoComplete="off"/>
          <input type='text' maxLength={1} id='1' className='input-square' value={this.state.pin[1]} onInput={this.onChange} onFocus={()=>this.openKeyboard(this.pin2)} ref={(input) => {this.pin2 = input}} autoComplete="off"/>
          <input type='text' maxLength={1} id='2' className='input-square' value={this.state.pin[2]} onInput={this.onChange} onFocus={()=>this.openKeyboard(this.pin3)} ref={(input) => {this.pin3 = input}} autoComplete="off"/>
          <input type='text' maxLength={1} id='3' className='input-square' value={this.state.pin[3]} onInput={this.onChange} onFocus={()=>this.openKeyboard(this.pin4)} ref={(input) => {this.pin4 = input}} autoComplete="off"/>
        </form>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.delete}
            showNumber = {true}
            numeric = {true}
            rightTitle = {"enter"}
            leftTitle = {"delete"}
            next = {this.props.submit}
            disablePlus = {true}
          /> : null }
      </div>
    )
  }
}

InputPinTinyRick.defaultProps = {
  submited: false
}

export default InputPinTinyRick;