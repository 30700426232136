import initialState from './initialState';

export default function dbReducer(state = initialState.db, action){

  switch(action.type){

    case 'SET_SELL_DB':
      return Object.assign({}, state, {
        sell: action.sell
      })

    case 'CANCEL_OPERATION':
      return initialState.db

    default:
      return state
  }
}