export const lightsDict = {
  rick:{
    BCS: 1, //Scanner(Barcode)
    BAS: 2, //Bill Acceptor
    BDS: 3, //Bill Dispenser
    PPS: 4, //Pin Pad
    RPS: 5, //Receipt Printer
    CRS: 6, //Card Reader
  },
  morty:{
    ALL: -1, //All lights
    RPS: 1, //Receipt Printer
    CRS: 2, //Card Reader
    BAS: 3, //Bill Acceptor
    BDS: 4, //Bill Dispenser
    BDSL: 5, //Bill Dispenser LED
    PPS: 6, //Pin Pad
  },
  jerry:{
  }
}