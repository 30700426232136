import createAxe from '../../middlewares/Axe'

// moment js
import moment from 'moment';
moment.locale('en') // secure that date is english

import { push } from 'connected-react-router';
import { parsePhoneNumber } from 'libphonenumber-js';

import goToVerse from '../../middlewares/Axe/elevator.js';
import { getUTCDateString } from '../../helpers/time';

import * as types from './actionTypes';
import { startTransaction, endTransaction, setStage } from '../transactionActions'
import { sendToWallet, setDate, setIdTx, TIERS, setTimeSent } from '../purchaseActions'
import { triggerError, digestErrorCode } from '../maintenanceActions';
import { setLimit, setPower, setClientID, setFirst, setFees, setDashMess, setSales, setFulfilledPolicies, setMaxTierStatus } from '../clientActions';
import { setLight, turnOFFLights } from '../lightActions';
import { closeModal, closeReminder } from '../modalActions';
import { retrieveInfo } from '../rateActions';
import { goLoading, outLoading } from '../loadingActions';
import { takePicture } from '../cameraActions'
import { saveDBSell } from '../dbActions'
import { getTiers } from '../tiersActions';
import { getDirectionFromList, setDirectionList } from '../viewActions'

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';
import { track } from '../../helpers/analytics';

const LOCATIONS = ['/','/finish-screen', '/maintenance-mode', '/clean-cash']; //Screen that shouldn't redirect

// 2 types of pin
// login = when user is logging in
// confirm = when user is confirming the pin
export const PinType = {
  Login: 'login',
  Confirm: 'confirm'
}

export function validPhone(phone){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].loading))
    let phoneNumber = parsePhoneNumber(phone);
    let axe = createAxe(getState())
    try{
      let res = await axe('/gas-station/octanage',{
        method: 'POST',
        data:{
          url: '/auth/phone',
          atm_token: getState().machine.atm_id,
          data:{
            country_code: phoneNumber.countryCallingCode,
            phone_number: phoneNumber.nationalNumber
          }
        }
      })
      if(res.status === 200){
        let dateTx = moment().toISOString();
        goToVerse({ action: 'START_TX', date: dateTx })
        dispatch(setLight(0,"PPS"))
        dispatch(setDate(dateTx))
        dispatch(startTransaction())
        dispatch(retrieveInfo())
        dispatch(addAreaCode(phoneNumber.countryCallingCode));
        dispatch(addPhone(phoneNumber.nationalNumber));
        dispatch(addCompletePhone(phone))
        dispatch(setClientID(res.data.data.access_token))
        dispatch(addTier(res.data.data.enrollment_tier));
        if(res.data.data.direction === 'verify_phone'){
          dispatch(addCode(res.data.data.verification_code));
        }
        setTimeout(()=>{return dispatch(outLoading(res.data.data.direction))},1000)
      }
      else {
        setTimeout(() => {
          dispatch(outLoading())
          setTimeout(()=>{
            return dispatch(triggerError(i18nErrors[lang].invalidPhone))}, 500)
        }, 1000)
      }
    }
    catch(error){
      setTimeout(() => {
        dispatch(outLoading())
        setTimeout(()=>{
          return dispatch(triggerError(i18nErrors[lang].invalidPhone))}, 500)
      }, 1000)
    }
  }
}

function defaultError(){
  return function(dispatch, getState){
    setTimeout(() => {
      let lang = getState().i18n.language.current
      dispatch(outLoading())
      dispatch(triggerError(i18nErrors[lang].tryLater));
      setTimeout(()=>{ return dispatch(cancelOperation()) },4700)
    }, 1000)
  }
}

export function addTier(tier){
  return{
    type: types.ADD_TIER,
    tier
  }
}

export function addAccessToken(token){
  return{
    type: types.ADD_ACC_TOKEN,
    token
  }
}

export function addAreaCode(areaCode){
  return {
    type: types.ADD_AREA_CODE,
    areaCode
  }
}

export function addPhone(phone){
  return{
    type: types.VALID_PHONE,
    phone
  }
}

export function addCompletePhone(completePhone){
  return{
    type: types.ADD_COMPLETE_PHONE,
    completePhone
  }
}

export function addCode(code){
  return{
    type: types.ADD_CODE,
    code
  }
}

export function cancelOperation(cleanFinish = false){
  return async function (dispatch, getState){
    let currRoute = getState().router.location.pathname
    if(currRoute != '/' && !cleanFinish)
      track(`Cancel Operation from ${currRoute}`)
    dispatch(closeModal())
    if(getState().machine.name === 'rick')
      dispatch(turnOFFLights())
    else if(getState().machine.name === 'morty')
      dispatch(setLight(0,"ALL"))
    if(getState().transaction.init){
      dispatch(endTransaction())
      if(getState().modal.open_reminder){          //user left without canceling
        dispatch(closeReminder(false))
      }
      switch(currRoute){ //Basically this will happen if user left
        case '/insert_cash':
          if(!getState().purchase.exit){                  //the user already inserted a bill
            dispatch(sendToWallet())
          }
          break;
      }
      goToVerse({ action: 'FINISH_LAST_TX'})
      dispatch(canceledOperation())
      if(currRoute === '/loading')
        return dispatch(outLoading('finish-screen'));
      else
        return dispatch(push('/finish-screen'))
    }
    else {
      if(getState().transaction.stage !== 0)
        dispatch(setStage(0))
      if(LOCATIONS.indexOf(getState().router.location.pathname) === -1)
        return dispatch(push('/'))
      return dispatch(push('/'))
    }
  }
}

export function canceledOperation(){
  return{
    type: types.CANCEL_OPERATION
  }
}

//CODE VALIDATION
export function sendCode(code){
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    if (code === getState().auth.code){
      dispatch(goLoading(i18n[lang].sendingCode))
      dispatch(setLight(0,"PPS"))
      dispatch(invalidInput(''))
      dispatch(validCode())
      setTimeout(()=>{return dispatch(outLoading('create_pin'))},1000);
    }
    else{
      return dispatch(invalidInput(i18nErrors[lang].wrongCode)); 
    }
  }
}

//RESEND CODE TO PHONE
export function resendCode(){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/octanage',{
        method: 'POST',
        data:{
          url: '/auth/new_verification_code',
          atm_token: getState().machine.atm_id,
          data:{
            country_code: getState().auth.areaCode,
            phone_number: getState().auth.phone
          }
        }
      })
      dispatch(addCode(res.data.data.verification_code));
      return dispatch(codeResent())
    }
    catch(error) {
      return dispatch(triggerError(i18nErrors[lang].tryLater))
    }
  }
}

//CODE RESENT
export function codeResent(){
  return{
    type: types.CODE_RESENT
  }
}

export function invalidInput(errorMessage){
  return{
    type: types.INVALID_PIN,
    errorMessage
  }
}

//CREATE PIN ACTION
export function createPin(pin){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/fuel',{
        method: 'POST',
        data: {
          url: '/auth/pin/new',
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          data: {
            pin: pin
          }
        }
      })
      dispatch(setLight(0,"PPS"))
      dispatch(invalidInput(''))
      setTimeout(()=>{return dispatch(outLoading(res.data.data.direction))},1000)
    }
    catch(error){
      dispatch(outLoading())
      return dispatch(triggerError(i18nErrors[lang].tryLater))
    }
  }
}

//CONTINUE THE VALID AUTH FLOW
export function validAuthFlow(data, headerDate) {
  return function(dispatch, getState) {
    const directions = data.directions;
    const { total, transactional } = data.limits;
    
    goToVerse({ action: 'ADD_TX_INFO', tx_id: data.idtx })
    // let instructions = directions[0].instructions
    // if(instructions) {
    //     dispatch(nextView(instructions.next_view))
    //     // We may want to ignore the instructions for now because
    //     // they come in english, and we need other languages too
    //     dispatch(setTierInstructions(instructions))
    // }
    dispatch(setDirectionList(directions))

    dispatch(saveDBSell({ idtx: data.idtx, custom_token: getState().client.client_id, date: getState().purchase.date }))
    dispatch(setLight(0,'PPS'))
    dispatch(pinVerified())
    dispatch(invalidInput(''))
    dispatch(setFirst(data.customer.first_name))
    dispatch(setDashMess(data.dashboard_message))
    dispatch(setFees(data.range_fees))
    dispatch(setSales(data.sales))
    dispatch(setPower(parseInt(data.limits.current, 10)))
    dispatch(setDate(data.local_created_at))
    dispatch(setTimeSent(getUTCDateString(headerDate)))
    dispatch(setFulfilledPolicies(data.customer.policies_fulfilled))
    dispatch(setMaxTierStatus(data.customer.max_tier))
    dispatch(setTransactional(transactional))
    dispatch(setLimit(total))
    dispatch(getTiers())            // getTiers
    setTimeout(() => {
      let direction = dispatch(getDirectionFromList())
      return dispatch(outLoading(direction))
    }, 500)
  }
}

//VERIFY PIN
export function verifyPin(pin, pin_type){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
      dispatch(goLoading(i18n[lang].loggingIn))
      try {
        let axe = createAxe(getState())
        let res = await axe('/v2/unity/auth/pin/v2/validate', {
          method: 'POST',
          headers: {
              'Customer-Token': `Bearer ${getState().client.client_id}`,
              'Atm-Token': `Bearer ${getState().machine.atm_id}`
          },
          data:{
            pin: pin
          }
        });
        const data = res.data.data;

        if(res.status === 200) {
          dispatch(takePicture('enter_pin'))
          let next_view = data.directions[0].direction;

          if(next_view.indexOf('validate_otp') !== -1) {
            dispatch(invalidInput(''));
            dispatch(setIdTx(data.idtx));
            setTimeout(() => {
              return dispatch(outLoading(next_view))
            }, 500)
            return;
          }

          if(data.verify) {
            dispatch(setIdTx(data.idtx))
            return dispatch(validAuthFlow(data, res.headers.date))
          }
          
          console.log('Error validation front-end');        //Should never happen
        } else {
          setTimeout(() => {
            dispatch(outLoading())
            return dispatch(invalidInput(i18nErrors[lang].tryAgain))
          }, 1000)
        }
      }
      catch(error){
        if (error.response.status === 440){
          setTimeout(() => {
            return dispatch(invalidPin(pin_type))
          }, 1000)
        }
        else
          setTimeout(() => {
            return dispatch(digestErrorCode(
              error.response.status,
              () => { return dispatch(defaultError()) }
            ))
          }, 1000)
      }
  }
}

//VERIFY 2FA CODE
export function verifyOtpCode(code){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current;
    dispatch(goLoading(i18n[lang].loggingIn));

    try {
      let axe = createAxe(getState());
      let res = await axe('/v2/unity/authn/otp/verify', {
        method: 'POST',
        headers: {
          'Customer-Token': `Bearer ${getState().client.client_id}`,
          'Atm-Token': `Bearer ${getState().machine.atm_id}`
        },
        data: {
          code,
          idtx: getState().purchase.idtx,
        }
      });

      const data = res.data.data;

      if(res.status === 200) {
        if(data.verify) {
          return dispatch(validAuthFlow(data, res.headers.date));
        }
      } else {
        setTimeout(() => {
          dispatch(outLoading())
          return dispatch(invalidInput(i18nErrors[lang].tryAgain))
        }, 1000)
      }
    }
    catch(error){
      if (error.response.status === 401) {
        setTimeout(() => dispatch(invalid2FACode()), 1000);
      } else {
        setTimeout(() => {
          return dispatch(digestErrorCode(
            error.response.status,
            () => { return dispatch(defaultError()) }
          ))
        }, 1000)
      }
    }
  }
}

function invalid2FACode() {
  return function(dispatch, getState) {
    let lang = getState().i18n.language.current
    dispatch(outLoading())
    return dispatch(invalidInput(i18nErrors[lang].wrongCode));
  }
}

function invalidPin(pin_type) {
  return function(dispatch, getState) {
    let lang = getState().i18n.language.current
    dispatch(outLoading())
    if(pin_type == PinType.Login)
      return dispatch(invalidInput(i18nErrors[lang].wrongPin));
    else
      return dispatch(invalidInput(i18nErrors[lang].unmatchPin));
  }
}

export function updatePin(pin){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    try{
      let axe = createAxe(getState())
      let res = await axe('/auth',{
        method: 'PUT',
        data: {
          url: '/pin',
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          data: {
            pin: pin
          }
        }
      })
      dispatch(setLight(0,"PPS"))
      dispatch(invalidInput(''))
      if(res.data.data && res.data.data.direction)
        return dispatch(outLoading(res.data.data.direction))
      return dispatch(outLoading('password_changed'))
    }
    catch(error){
      dispatch(outLoading())
      return dispatch(triggerError(i18nErrors[lang].tryLater))
    }
  }
}

// Request for challenge
function authChallenge(url, data, verification = false) {
  return async function(dispatch, getState){
    try{
      dispatch(invalidInput(''))
      let axe = createAxe(getState())
      let res = await axe('/auth',{
        method: 'POST',
        data: {
          url: url,
          atm_token: getState().machine.atm_id,
          data: data
        }
      })
      dispatch(setLight(0,"PPS"))
      if(verification)
        dispatch(setClientID(res.data.data.access_token))
      setTimeout(() => {
        return dispatch(outLoading(res.data.data.direction))
      }, 1000)
    }
    catch(error){
      let lang = getState().i18n.language.current
      let message = i18nErrors[lang].tryLater
      if(error.response && error.response.status)
        message = i18nErrors[lang].wrongCode

      dispatch(outLoading())
      return dispatch(triggerError(message))
    }
  }
}

// Verifies the challenge (we go to next screen)
export function verifyChallenge(code) {
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))

    let data  = {
      country_code: getState().auth.areaCode,
      phone_number: getState().auth.phone,
      verification_code: code
    }

    return dispatch(authChallenge('n/challenge/verify', data, true))
  }
}

// Sends a challenge to a phone number
export function sendChallenge(){
  return function(dispatch, getState){
    let data  = {
      country_code: getState().auth.areaCode,
      phone_number: getState().auth.phone
    }

    return dispatch(authChallenge('n/challenge', data))
  }
}

// Starts the challenge (generally triggered when start process)
export function startChallenge() {
  return function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].sendingCode))

    return dispatch(sendChallenge())
  }
}

export function setTransactional(transactional){
  return{
    type: types.SET_TRANSACTIONAL,
    transactional
  }
}

export function pinVerified(){
  return{
    type: types.PIN_VERIFIED
  }
}

export function validCode(){
  return{
    type: types.VALID_CODE
  }
}