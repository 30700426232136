import * as types from './actionTypes';
import createAxe from '../../middlewares/Axe'
import { triggerError, digestErrorCode } from '../maintenanceActions';
import { goLoading, outLoading } from '../loadingActions';
import { saveDBSell } from '../dbActions';
import { QR_CODE, BARCODE, removeIntervalScanner } from '../../middlewares/SignalRMM/scanner';

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';
import { track } from '../../helpers/analytics';

export function scanBarcode(){
  return{
    type: types.SCAN_BARCODE,
    signalR: true
  }
}

export function scanQRCode(){
  return{
    type: types.SCAN_QR_CODE,
    signalR: true
  }
}

export function cancelScan(){
  return{
    type: types.CANCEL_SCAN,
    signalR: true
  }
}

export function takePicturScan(){
  return{
    type: types.TAKE_PICTURE_SCAN,
    signalR: true
  }
}

export function safeCancelScan(type) {
  return{
    type: types.SAFE_CANCEL_SCAN,
    signalR: true,
    scannerType: type
  }
}

export function restartQRScan(){
  return function(dispatch){
    return dispatch(restartScan(scanQRCode, QR_CODE))
  }
}

export function restartBarcodeScan(){
  return function(dispatch){
    return dispatch(restartScan(scanBarcode, BARCODE))
  }
}

function restartScan(startScanner, type) {
  return function(dispatch, getState){
    dispatch(cancelScan())
    setTimeout(() => {
      if(getState().transaction.scanningGenerator === type) {
        return dispatch(startScanner())
      }
      // This will prevent that we don't leave a interval running
      else {
        return removeIntervalScanner(type, dispatch)
      }
    }, 750)
  }
}

export function sendAddress(){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/fuel', {
        method: 'POST',
        data:{
          url:'/address/validate',
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          data:{
            address: getState().purchase.address
          }
        }
      })
      switch(res.status){
        case 200:
          dispatch(addAddressID(res.data.data.address_id))
          dispatch(saveDBSell({ address_id: res.data.data.address_id, address: getState().purchase.address }))
          setTimeout(()=>{return dispatch(outLoading(res.data.data.direction))}, 1000)
          break;
        case 204:
          dispatch(addAddressID(res.data.data.address_id))
          dispatch(saveDBSell({ address_id: res.data.data.address_id, address: getState().purchase.address }))
          setTimeout(()=>{return dispatch(outLoading(res.data.data.direction))}, 1000)
          break;
      }
    }
    catch(error){
      return dispatch(digestErrorCode(error.response.status, () => {
        if(error.response.status === 470) {
          track('Address Scan Error', {
            address_scan_error: 'Address already used by other customer'
          });

          setTimeout(() => {
            return dispatch(errorScanningAddress(i18nErrors[lang].invalidAddress, 0))
          }, 1000)
        }
        else {
          track('Address Scan Error', {
            address_scan_error: 'Backend Error'
          });

          setTimeout(() => {
            return dispatch(errorScanningAddress(i18nErrors[lang].scanWalletFailed, 0))
          }, 1000)
        }
      }))
    }
  }
}

export function addAddressID(address_id){
  return{
    type: types.ADD_ADDRESS_ID,
    address_id
  }
}

export function errorScanningAddress(reason, scanAgain){
  return function(dispatch){
    if(scanAgain)
      setTimeout(()=>dispatch(scanQRCode()), 4000)
    else {
      dispatch(outLoading())
    }
    setTimeout(()=>{return dispatch(triggerError(reason))}, 1000)
  }
}


export function addIdInfo(id_number,exp_date){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/diesel', {
        method: 'POST',
        data:{
          url: '/customer/update',
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          data:{
            id_number: id_number,
            exp_date: exp_date
          }
        }
      })
      setTimeout(()=>{
        return dispatch(outLoading(res.data.data.direction))
      }, 5000)
    }
    catch(error) {
        dispatch(outLoading())
        return dispatch(triggerError(i18nErrors[lang].invalidID))
    }
  }
}