import React, { memo } from 'react';

import { useDispatch } from 'react-redux';

import ButtonBS from '../../../common/ButtonBS';
import ScamsListSender from '../../../common/ScamsListSender';

import DownloadHODL from '../../../../containers/DownloadHODL';

import translations from '../../../../middlewares/i18n/ScanWallet';

import useTranslations from '../../../../hooks/useTranslations';

import { cancelOperation } from '../../../../actions/authActions';

import '../../../../styles/common/Modals/ModalScam.new.css';

const ModalScam = ({ scanAgain }) => {
  const dispatch = useDispatch();
  const i18n = useTranslations(translations);

  const handleCancelOperation = () => {
    dispatch(cancelOperation());
  };

  return (
    <div className="modal-scam">
      <div className="scam-logo" />
      <div className="modal-scam__title">{i18n.attention}</div>

      <div className="scam-layout__divider" /> 

      <div className="modal-scam__text" dangerouslySetInnerHTML={{ __html: i18n.warningText }} />

      <br />
      <div className="modal-scam__sub-text">{i18n.warningSubText}</div>

      <div className="modal-scam__buttons">
        <ButtonBS v2 className="v2--red" content={i18n.backToScanAddress} onClick={scanAgain} />
        <ButtonBS v2 className="v2--red" content={i18n.cancelTransaction} onClick={handleCancelOperation} />
      </div>

      <div className="modal-scam__sender">
        <ScamsListSender />
      </div>

      <div className="modal-scam__wallet">
        <div className="modal-scam__wallet-line" />
        <div className="bitcoin-wallet-icon wallet-icon-scam" />
        <div className="modal-scam__wallet-line last" />
      </div>


      <div className="modal-scam__store">
        <h4 className="modal-scam__store-title">{i18n.doYouNeedWallet}</h4>
        <div className="modal-scam__store-text" dangerouslySetInnerHTML={{ __html: i18n.hodlWallet }} />

        <div className="modal-scam__store-buttons">
          <DownloadHODL
            device='ios'
            onClick={scanAgain}
          />
          <DownloadHODL
            device='android'
            onClick={scanAgain}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ModalScam);