import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'connected-react-router';
import PriceBS from '../../../containers/PriceBS';
import ButtonBS from '../../common/ButtonBS';
import Calculator from '../../../containers/Calculator';
import SendHODL from '../../../containers/SendHODL';
import { StartingScreen as i18n } from '../../../middlewares/i18n';
import * as i18nActions from '../../../actions/i18nActions';
import { track } from '../../../helpers/analytics';
import RewardsCenter from '../../common/RewardsCenter/RewardsCenter';
import HelpCenter from '../../../components/common/HelpCenter';
import '../../../styles/StartingScreen/StartingScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import * as Sentry from '@sentry/browser';

class StartingScreen extends Component {
  constructor(props){
    super(props);
    this.state = {
      openCalculator: false,
      // 'rewards' or 'help' or null 
      // rewards opens left side modal and help opens right side modal
      openModal: null,
    };
  }

  openCalculator = () => {
    track('Calculator opened');
    this.setState({ openCalculator: true });
  };

  getCloseSignal = () => {
    this.setState({ openCalculator: false });
  };

  changeLanguage = (lang) => {
    track('Language changed:', { lang });
    this.props.actions.i18n.changeLanguage(lang);
  };

  handleBuyBitcoin = () => {
    Sentry.captureException(new Error("Test Sentry error"));
    // track('Buy Bitcoin Clicked');
    
    // this.props.actions.router.push('/terms-conditions');
  };

  setOpenModal = (modalName) => {
    track('Starting screen modal opened:', { modalName });
    this.setState({ openModal: modalName });
  };

  componentDidMount() {
    Sentry.captureException(new Error("Test Sentry error"));
  }
  
  render(){
    const { openModal } = this.state;
    const { i18n: i18nProps } = this.props;

    return(
      <>
        <div className="modal-button-container rewards-center-container">
          <RewardsCenter openModal={openModal} setOpenModal={this.setOpenModal} />          
        </div>
        <div className="modal-button-container help-center-container">
          <HelpCenter openModal={openModal} setOpenModal={this.setOpenModal} />
        </div>
        <div className='starting-container bs-body centered'>
          <Calculator open={this.state.openCalculator} getClose={this.getCloseSignal} />          
          <h6 className={`bs-subtitle starting-screen-subtitle-${i18nProps.language.current} no-margin`}>
            {i18n[i18nProps.language.current].title}
          </h6>
          <div className="social-container">
            <div className="social-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </div>
            <div className="social-icon">
              <FontAwesomeIcon icon={faXTwitter} />
            </div>
            <div className="social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </div>
            <span className="social-text">
              <span className="separator"></span>
              <span className="bitstopofficial">@bitstopofficial</span>
            </span>
          </div>
          <div className='bs-logo-screen starting-logo' />
          <div className='price-cont'>
            <PriceBS className='bs-title' withFee={false}/>
            <div className='help help-sc' onClick={this.openCalculator}>
              <FontAwesomeIcon icon={faCalculator} size="3x" />
            </div>
          </div>
          <ButtonBS onClick={this.handleBuyBitcoin} content={i18n[i18nProps.language.current].action} className='starting-button' />
          <h6 className='bs-subtitle no-margin'>
            <span onClick={() => this.changeLanguage('en')}>English </span>
            | 
            <span onClick={() => this.changeLanguage('es')}> Español</span>
          </h6>
          <h6 className='bs-subtitle no-margin phone'>{i18n[i18nProps.language.current].message}</h6>
          <SendHODL />
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      i18n: bindActionCreators(i18nActions, dispatch),
      router: bindActionCreators(routerActions, dispatch),
    }
  }
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartingScreen);