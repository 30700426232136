import * as types from './actionTypes';
import compareVersions from 'compare-versions';
import packageJson from '../../../package.json';
import createAxe from '../../middlewares/Axe'
import supportCountries from '../../components/common/input-fields/InputTelBS/countrySupported'
import { exitMaintenance } from '../maintenanceActions';
import {expRetry} from "../../helpers/timeouts";

function createBase64Image(base64String) {
  return `data:image/png;base64,${base64String}`
}

export function getOperatorImage(operatorId) {
  return async function(dispatch, getState) {
    try {
      let axe = createAxe(getState())
      let response = await axe.get(`/operator/v2/Operator/${operatorId}/image`)
      
      dispatch({
        type: 'SET_OPERATOR_IMAGE',
        operator_image: createBase64Image(response.data.data)
      })
    } catch (error) {
      console.error('Error fetching operator image:', error)
    }
  }
}

export function checkVersion(){
  return async function(dispatch, getState){
    let version = packageJson.version
    // console.log(`v${version} - Checking for new version...`)
    try{
      let maint = getState().maintenance
      let axe = createAxe(getState())
      let versionAPI = await axe('/status-station/grade')
      
      // We were in maintenance with no internet connection
      if(maint.active && maint.errorCode === 1)
        dispatch(exitMaintenance())

      if(compareVersions(versionAPI.data, version) === 1){
        if(getState().transaction.init){
          return dispatch(updateAvailable(true))
        }
        else{
          if(getState().machine.updateAvailable){
            dispatch(updateAvailable(false))
            setTimeout(()=>window.location.reload(), 1000)
          }
          else{
            window.location.reload()
          }
        }
      }
      else{
        if(getState().machine.updateAvailable){
          return dispatch(updateAvailable(false))
        }
      }
    }
    catch(e){
      // No Internet connection
      if(!e.response) {
        return dispatch(internetDown())
      }
    }
  }
}

export function heartbeat(){
  return async function(dispatch, getState){
    let { machine, maintenance, rate, ...rest } = getState()
    if(machine.atm_id.length){
      try{
        let axe = createAxe(getState())
        await axe('/status-station/heartbeat', {
          method: 'POST',
          data: {
            atm_token: machine.atm_id,
            data: {
              version: packageJson.version,
              status: maintenance.errorCode,
              setting_id: machine.setting_id,
              fees: rate.fees
            }
          }
        })
        return dispatch(heartbeatSent())
      }
      catch(e){
        return dispatch(heartbeatError())
      }
    }
  }
}

export function setSettings(settings){
  return function(dispatch){
    dispatch(setLang(settings.languages))
    dispatch(setIntlPhone(settings.phones, settings.primary_count))
    dispatch(setTimezone(settings.timezone))
    dispatch(setKycRequired(settings.kyc))
    dispatch(setSettingId(settings.setting_id))
    dispatch(setPrimary(settings.primary_count, settings.primary_lang))
    return dispatch(settingsChanged())
  }
}

export function setIntlPhone(phones, primary_count){
  return function(dispatch){
    let countries = supportCountries(phones, primary_count)
    return dispatch(intlPhoneSet(countries))
  }
}

export function sendErrorPurch(data){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      let res = await axe('/report-station/error-report',{
        method: 'POST',
        data: {
          atm_token: getState().machine.atm_id,
          data: data
        }
      })
      dispatch(errorSent())
    }
    catch(error){}
  }
}

export function setMachine(name){
  return{
    type: types.SET_MACHINE,
    name
  }
}

export function setSize(tiny){
  return{
    type: types.MACHINE_SIZE,
    tiny
  }
}

export function setState(state){
  return{
    type: types.SET_STATE,
    state
  }
}

export function updateAvailable(status){
  return{
    type: types.UPDATE_AVAILABLE,
    status
  }
}

export function setAtmID(atm_id){
  return{
    type: types.SET_ATM_ID,
    atm_id
  }
}

export function setLang(lang){
  return{
    type: types.SET_LANG,
    lang
  }
}

export function intlPhoneSet(intlphone){
  return{
    type: types.SET_INTLPHONE,
    intlphone
  }
}

export function setPrimary(country, language){
  return{
    type: types.SET_PRIMARY,
    primary:{
      country,
      language
    }
  }
}

export function setTimezone(timezone){
  return{
    type: types.SET_TIMEZONE,
    timezone
  }
}

export function setKycRequired(kyc){
  return{
    type: types.SET_KYC_REQ,
    kyc
  }
}

export function settingsChanged(){
  return{
    type: types.SETTINGS_CHANGED
  }
}

export function setSettingId(setting_id){
  return{
    type: types.SET_SETTING_ID,
    setting_id
  }
}

export function heartbeatSent(){
  return{
    type: types.HEARTBEAT_SENT
  }
}

export function heartbeatError(){
  return{
    type: types.HEARTBEAT_ERROR
  }
}

export function errorSent(){
  return{
    type: types.ERROR_SENT_PURCH
  }
}

export function internetDown(){
  return{
    type: types.INTERNET_DOWN
  }
}

export function setOperatorId(operator_id){
  return{
    type: types.SET_OPERATOR_ID,
    operator_id
  }
}

export function setOperatorName(operator_name){
  return{
    type: types.SET_OPERATOR_NAME,
    operator_name
  }
}

export function setOperatorAddress(operator_address) {
  return {
    type: types.SET_OPERATOR_ADDRESS,
    operator_address,
  };
}

export function setLocalState(local_state){
  return{
    type: types.SET_LOCAL_STATE,
    local_state
  }
}

export function setOperatorNmls(operator_nmls){
  return{
    type: types.SET_OPERATOR_NMLS,
    operator_nmls
  }
}

function failedToGetOperatorDetails(error) {
  console.log(`${Date()} - Error getting operator details. Last error: ${error}`);
  return getOperatorDetails()
}

export function getOperatorDetails(retry = 0){

  return async function(dispatch, getState){
    let axe = createAxe(getState());
    try{
      let defaultName = "Bitstop";
      let { status, data } = await axe('/athena/v2/Atm/operator/details', {
        method: 'GET',
        headers: {
          'Atm-Token': `Bearer ${getState().machine.atm_id}`
        }
      })
      if(status === 200){
        const operatorData = data.data;
        dispatch(setOperatorId(operatorData.operator_id));
        dispatch(setOperatorName(operatorData.legal_name || defaultName));
        dispatch(getOperatorImage(operatorData.operator_id));

        const operatorAddress = {
          street_address: operatorData.legal_address_street_address,
          street_address2: operatorData.legal_address_street_address2,
          city: operatorData.legal_address_city,
          state: operatorData.legal_address_state,
          zipcode: operatorData.legal_address_zipcode,
          country: operatorData.legal_address_country,
          full_address: operatorData.legal_full_address
        };
        console.log(`${Date(Date.now())} - Got operator details: ${JSON.stringify(operatorData)}`);
        return dispatch(setOperatorAddress(operatorAddress));
      }
    }
    catch(error){
      return expRetry(
        (_retry) => dispatch(getOperatorDetails(_retry)),
        failedToGetOperatorDetails(error),
        retry, 
        50
      )
    }
  }
}