import initialState from './initialState';

export default function priceReducer(state = initialState.client, action){

  switch(action.type){

    case 'NOT_ME':
      console.log('not me');
      return state

    case 'YES_ME':
      console.log('it is me')
      return state

    case 'SET_DOB':
      return Object.assign({}, state, {
        dob: action.dob
      })

    case 'SET_NAME':
      return Object.assign({}, state, {
        name: action.name,
      })

    case 'SET_FIRST':
      return Object.assign({}, state, {
        firstName: action.firstName,
      })

    case 'SET_LAST':
      return Object.assign({}, state, {
        lastName: action.lastName,
      })

    case 'SET_ADDRESS':
      return Object.assign({}, state, {
        address: action.address
      })

    case 'ID_SCANNED':
      return Object.assign({}, state, {
        id: action.id
      })
    
    case 'SAVE_PICTURE':
      return Object.assign({}, state, {
        pictures: [...state.pictures, action.picture]
      })

    case 'POWER_DEC':
      return Object.assign({}, state, {
        spent: action.spent
      })

    case 'ADD_TIER':
      return Object.assign({}, state, {
        tier: action.tier
      })

    case 'SET_LIMIT':
      return Object.assign({}, state, {
        max: action.limit
      })

    case 'SET_POWER':
      return Object.assign({}, state, {
        spent: action.power
      })

    case 'WALLET_LINK_SENT':
      return Object.assign({}, state, {
        hodlLink: false
      })

    case 'SET_CLIENT_ID':
      return Object.assign({}, state, {
        client_id: action.client_id
      })

    case 'SET_TRANSACTIONAL':
      return Object.assign({}, state, {
        transactional: action.transactional
      })

    case 'SET_FEES':
      return Object.assign({}, state, {
        range_fees: action.range_fees
      })

    case 'SET_SALES':
      return Object.assign({}, state, {
        listTx: action.listTx
      })

    case 'SET_DASH_MESS':
      return Object.assign({}, state, {
        dashboard_message: action.dashboard_message
      })

    case 'SHOW_SCAM_VIEW':
      return Object.assign({}, state, {
        scam_view: true
      })

    case 'SET_HARD_REST':
      return Object.assign({}, state, {
        hard_restricted: true
      })

    case 'SET_FULFILLED_POLICIES':
      return Object.assign({}, state, {
        policies_fulfilled: action.policies_fulfilled
      })

    case 'SET_MAX_TIER_STATUS':
      return Object.assign({}, state, {
        max_tier: action.max_tier
      })

    case 'CANCEL_OPERATION':
      return initialState.client

    default:
      return state;
  }
}