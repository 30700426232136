import React, { Component } from 'react';
import CalculatorBS from '../../../components/CalculatorBS';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class CalculatorPurch extends Component{
  constructor(props){
    super(props);
    this.state={}
  }

  render(){
    return(
      <div className='bs-body' style={{background:'#FF1720', textAlign: 'center'}}>
        <Link to={'/'}>X</Link>
        <CalculatorBS fee={this.props.rate.fee} price={this.props.rate.price}/>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    rate: state.rate
  }
}

export default connect(mapStateToProps)(CalculatorPurch);