import React from 'react';
import { toDollar_NoCents } from '../../../helpers/helperFunctions'
import { useSelector } from 'react-redux'
import { Dashboard as i18n } from '../../../middlewares/i18n';

const FeeBreakdown = ({ range_fees, title = null, clickable = null }) =>{

  const _i18n = useSelector(state => state.i18n)
  const tiers = useSelector(state => state.tiers.tiers)

  const fees = range_fees.map((range) => {
    // Hacky way until we find another solution
    let max = range.max
    if (!range.flat) {
      max = tiers[tiers.length - 1].limit_max
    }
    return(
      <tr key={range.min}>
        <td>${toDollar_NoCents(range.min)} - ${toDollar_NoCents(max)}</td>
        {range.flat ? 
          <td>{range.percent}% + ${range.flat}</td> :
          <td>{range.percent}%</td>
        }
      </tr>
    )
  })

  return(
    <div className="fee-table-cont">
      { title ? <h5 className="no-margin">{title}</h5>
      : <h5 className="no-margin">{i18n[_i18n.language.current].feeBreakdown}</h5> }
      { clickable ? clickable : null}
      <table>
      <tbody>
        <tr>
          <th>{i18n[_i18n.language.current].purchase}</th>
          <th>{i18n[_i18n.language.current].fee}</th>
        </tr>
        { fees }
      </tbody>
      </table>
    </div>
  )
}

export default FeeBreakdown