import * as types from './actionTypes';
import createAxe from '../../middlewares/Axe'
import { omit, update } from 'lodash';
import { triggerError } from '../maintenanceActions';
import { cancelOperation } from '../authActions';
import goToVerse from '../../middlewares/Axe/elevator.js';
import { goLoading, outLoading } from '../loadingActions';
import { saveDBSell } from '../dbActions';
import { takePicture } from '../cameraActions';
import { setLightStat } from '../lightActions';

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';
import { setStage } from '../transactionActions';
import { setFirst, setLast } from '../clientActions';
import { setLocalState, setOperatorNmls } from '../machineActions/index.js';
import { setCurrentFee, setOldFee, setOldCharges, setSavings } from '../couponActions/index.js';

export const TIERS = ['partial_telesign', 'telesign', 'id'];
export const BILLS = [1,2,5,10,20,50,100]; 

export function finishTx(){
  return function(dispatch){
    dispatch(cancelOperation(true))
    return dispatch({type: types.FINISH_TX})
  }
}

function createBillString(bills){
  let billString = {
    'one': bills['1'].toString(),
    'two': bills['2'].toString(),
    'five': bills['5'].toString(),
    'ten': bills['10'].toString(),
    'twenty': bills['20'].toString(),
    'fifty': bills['50'].toString(),
    'one_hundred': bills['100'].toString()
  }
  return billString
}

// Send to wallet function
export function sendToWallet(){
  return function(dispatch, getState){
    // create a new stage 3.5 that is going to be "sending"
    // between stage 3 (insert cash) and stage 4 (receipt)
    // we prevent double noMoreBills
    dispatch(setStage(3.5))           // stage sending cash
    dispatch(takePicture('send_btc'))
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].sendingTx))
    dispatch(unlockExit())
    dispatch(cancelInsBill())           // Tell signalr not to get more bills
    // Prepare object purchase (purch) and ommit unnecesary values
    let purch = omit(getState().purchase, ['exit', 'message','bills', 'tiers', 'fee', 'address', 'tier', 'idMachine', 'flat', 'transactional', 'spotPrice', 'spread', 'quotedExchange']);
    //turn all numbers into strings
    for (var key in purch){
      if (typeof purch[key] === 'number'){
        update(purch, key, ()=>{
          return purch[key].toString()
        })
      }
    }
    //change bill format
    let billString = createBillString(getState().purchase['bills'])
    // FINAL REQUEST: same object purch but add the bills formatted
    // (this may change with new unity)
    let finalpurch = Object.assign({}, purch, {
      bills: billString
    })
    dispatch(saveDBSell(finalpurch))
    return dispatch(sendToBE(finalpurch, getState().purchase.idtx, getState().client.client_id))
  }
}

export function selectFee(amount){
  return function(dispatch, getState){
    let fees = getState().rate.fees
    let flat = 0
    let fee = 13.5

    if(getState().coupon.applied) {
      // applied current coupon
      fees = getState().coupon.new_fees
    }

    for(let i = 0; i < fees.length; i++){
      if(amount >= fees[i].min && amount <= fees[i].max){   // we are in that range
        fee = fees[i].percent
        if(fee !== getState().purchase.fee){
          // Set the fees for UI if coupon is applied ---
          if(getState().coupon.applied) {
            dispatch(setCurrentFee(fee))
            dispatch(setOldFee(getState().rate.fees[i].percent)) // using old fee
          }
          // -----------------------
          dispatch(setFee(fee))
          dispatch(saveDBSell({ fee: fee }))
        }
        if(fees[i].flat){                         // we have a flat fee
          flat = parseFloat(fees[i].flat)
          if(flat !== getState().purchase.flat){  // flat fee is different to current
            dispatch(setFlat(flat))
            dispatch(saveDBSell({ flat: flat }))
          }
        }
        else{                                     // not flat fee
          if(getState().purchase.flat){           // we had a previous flat fee
            dispatch(setFlat(0))                  // remove flat fee from state
            dispatch(saveDBSell({ flat: 0 }))     // remove flat fee from db aswell
          }
        }
      }
    }
    dispatch(doneFeeSet())
    return {
      fee,
      flat
    }
  }
}

export function doneFeeSet(){
  return{
    type: types.DONE_FEE_SET
  }
}

export function setFlat(flat){
  return{
    type: types.SET_FLAT,
    flat
  }
}

export function sendToBE(finalpurch, idtx, token){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/fuel', {
        method: 'POST',
        data:{
          url: '/sale/new',
          access_token: token,
          atm_token: getState().machine.atm_id,
          data: finalpurch
        }
      })
      // Request good
      if(res.status === 200){
        goToVerse({ action: 'SELL_SENT', tx_id: idtx })     // set tx sent in miniverse
        if(!finalpurch.manual) {                            // No manual (regular flow)
          if(res.data.data.first_name)
            dispatch(setFirst(res.data.data.first_name))
          if(res.data.data.last_name)
            dispatch(setLast(res.data.data.last_name))
          if(res.data.data.state)
            dispatch(setLocalState(res.data.data.state))
          if(res.data.data.nmls_id)
            dispatch(setOperatorNmls(res.data.data.nmls_id))
          setTimeout(()=>{
            return dispatch(outLoading(res.data.data.direction))  // Follow direction backend
          }, 5000)
        }
        // else unnecesary because we did it in the background
      }
      // Failed request
      else{
        if(!finalpurch.manual)                                // No manual (regular flow)
          return dispatch(outLoading('receipt'))              // Force receipt
      }
    }
    // Any other error/fail during sending tx
    catch(error) {
      // 506 error code (duplicated tx)
      if(error.response && error.response.status === 506)
        goToVerse({ action: 'SELL_SENT', tx_id: idtx })
      // any other error is a failed sale
      else{
        if(!finalpurch.manual)
          return dispatch(outLoading('receipt'))
        // else unnecesary because we did it in the background
      }
    }
  }
}

export function setTimeSent(time){
  return function(dispatch){
    return dispatch({
      type: types.SET_TIME_SENT,
      time_sent: time
    })
  }
}

function getSubtotal(funds) {
  let subtotal = 0
  for(let i = 0; i < BILLS.length; i++){
    subtotal += funds[BILLS[i]] * BILLS[i];
  }

  return subtotal
}

export function checkLimits(funds, added) {
  return function(dispatch, getState) {
    let lang = getState().i18n.language.current
    let minBill = parseInt(getState().rate.min, 10);
    let actualSubtotal = getState().purchase.subtotal;        //subtotal in middle of tx (real)
    let subtotal = getSubtotal(funds)
    
    if(actualSubtotal === 0 && subtotal < minBill){ //No min bill inserted
      document.getElementById('min-bill').classList.add('shake');           //add animation to remind
      setTimeout(()=>document.getElementById('min-bill').classList.remove('shake'), 1000);  //remove animation after remind
      return false
    } 
    else if(subtotal > parseInt(getState().client.transactional,10)) { //Not enough buy power
      dispatch(triggerError(`${i18nErrors[lang].maxLimit1} $${getState().client.transactional} ${i18nErrors[lang].maxLimit2}`))
      return false
    } 
    else if(added + getState().client.spent > getState().client.max) { //Not enough buy power
      dispatch(triggerError(`${i18nErrors[lang].maxLimit1} $${getState().client.max} ${i18nErrors[lang].maxLimit2}`))
      return false
    }
    else
      return true       // ACCEPTED !
  }
}

export function addFunds(funds, added){
  return function(dispatch, getState){
    let totalFee = 0;
    let total = 0;
    let btc = 0;
    let subtotal = getSubtotal(funds)
    
    if(getState().num_bills){                 //if num_bills is greater than 0 (min 1)
      document.getElementById('continue-insert').classList.add('pulse');           //add animation to remind
      setTimeout(()=>document.getElementById('continue-insert').classList.remove('pulse'), 1000);  //remove animation after remind
    }
    let fees = dispatch(selectFee(subtotal))
    totalFee = (Math.floor((subtotal * parseFloat(fees.fee) / 100)*100))/100 + fees.flat;
    total = (subtotal - totalFee).toFixed(2);
    btc = (total / parseFloat(getState().purchase.btcprice));
    btc = (Math.floor(btc * 100000000))/100000000;
    let num_bills = getState().purchase.num_bills + 1;
    dispatch(decreasePower(added))
    let billString = createBillString(funds)
    // --------------
    if(getState().coupon.applied) {
      let oldFeesCharged = (Math.floor((subtotal * parseFloat(getState().coupon.old_fee) / 100)*100))/100 + fees.flat;
      dispatch(setOldCharges(oldFeesCharged))
      dispatch(setSavings((oldFeesCharged - totalFee).toFixed(2)))
    }
    dispatch(saveDBSell({ bills: billString, subtotal: subtotal, totalfee: totalFee, total: total, btc: btc, num_bills: num_bills }))
    goToVerse({ action: 'ADD_BILL', bills: funds, tx_id: getState().purchase.idtx })
    return dispatch(fundsAdded(funds, subtotal, totalFee, total, btc, num_bills))
  }
}

export function insertBill(){
  return function(dispatch, getState){
    // TODO: This is a temporary switch, this is going to be handled
    // by the backend to prevent weird amount, but for now this
    if(getState().purchase.btcprice) {
      dispatch(setLightStat("BAS"))
      return dispatch({
        type: types.INSERT_BILL,
        signalR: true
      })
    }
    else {
      if(getState().purchase.subtotal > 0){      //If user already inserted money
        return dispatch(noMoreBills())           //No accept more bills
      }
      else 
        return dispatch(cancelOperation())
    }
  }
}

export function decreasePower(inserted){
  return function(dispatch, getState){
    let spent = getState().client.spent + inserted;
    return dispatch(powerDecreased(spent))
  }
}

export function powerDecreased(spent){
  return{
    type: types.POWER_DEC,
    spent
  }
}

export function fundsAdded(funds, subtotal, totalFee, total, btc, num_bills){
  return{
    type: types.FUNDS_ADDED,
    funds, subtotal, totalFee, total, btc, num_bills,
    signalR: true
  }
}

export function returnBill(){
  return{
    type: types.RETURN_BILL,
    signalR: true
  }
}

export function printReceipt(content){
  return{
    type: types.PRINT_RECEIPT,
    content,
    signalR: true
  }
}

export function cancelInsBill(){
  return{
    type: types.CANCEL_INS_BILL,
    signalR: true
  }
}

export function lockPrice(){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    try {
      let axe = createAxe(getState())
      let res = await axe(`/v2/unity/sale/${getState().purchase.idtx}/lock-rate`, {
        method: 'POST',
        headers: {
          'Customer-Token': `Bearer ${getState().client.client_id}`,
          'Atm-Token': `Bearer ${getState().machine.atm_id}`
        },
        data: {
          coin_type: "btc",
          fiat_type: "usd"
        }
      })
      if(res.status === 200) {
        let price = res.data.bitstop_rate
        dispatch(saveDBSell({ btcprice: price }))
        dispatch(lockedPrice(price))
        if(res.data.spot_rate)
          dispatch(lockedSpotPrice(res.data.spot_rate))
        if(res.data.spread)
          dispatch(setSpread(res.data.spread))
        if(res.data.quoted_exchange)
          dispatch(setQuotedExchange(res.data.quoted_exchange))
        return dispatch(insertBill())
      }
      else {
        dispatch(triggerError(i18nErrors[lang].tryLater))
        setTimeout(()=>{return dispatch(cancelOperation())}, 4700)
      }
    }
    catch(error){
      dispatch(triggerError(i18nErrors[lang].tryLater))
      setTimeout(()=>{return dispatch(cancelOperation())}, 4700)
    }
  }
}

export function lockedPrice(btcPrice){
  return{
    type: types.LOCK_PRICE,
    btcPrice
  }
}

export function lockedSpotPrice(spotPrice){
  return{
    type: types.LOCK_SPOT_PRICE,
    spotPrice
  }
}

export function setQuotedExchange(quotedExchange){
  return{
    type: types.SET_QUOTED_EXCHANGE,
    quotedExchange
  }
}

export function setSpread(spread){
  return{
    type: types.SET_SPREAD,
    spread
  }
}

export function lockExit(){
  return{
    type: types.LOCK_EXIT
  }
}

export function unlockExit(){
  return{
    type: types.UNLOCK_EXIT
  }
}

export function setIdTx(id){
  return{
    type: types.SET_ID_TX,
    idTx: id
  }
}

export function setFee(fee){
  return{
    type: types.SET_FEE,
    fee
  }
}

export function setDate(date){
  return{
    type: types.SET_DATE,
    date
  }
}

export function getPurchaseReceipt(){
  return async function(dispatch, getState){
    let axe = createAxe(getState());
    try {
      let {status, data: { data }} = await axe(`/athena/v2/Sale/${ getState().purchase.idtx }/receipt`, { method: 'GET' });
      // Adding that condition of fiat, beecause the event maybe took too long
      // to propagate
      if (status !== 200 || !data || !data.fiat) {
        console.log(`${Date(Date.now())} - Failed fetching purchase receipt, status: ${status}`)
        return;
      }
      let purchaseReceipt = {
        subtotal : data.fiat,
        totalfee : data.fee,
        total : data.enviando,
        btc : data.btc,
        address: data.address,
        spotPrice: data.rate.spot_rate,
        spread: data.rate.spread,
        quotedExchange: data.rate.quoted_exchange
      }
      dispatch(setPurchaseReceipt(purchaseReceipt));
    } catch (error) {
      console.log(`${Date(Date.now())} - Error fetching purchase receipt:`, error);
    }
  }
}

export function setPurchaseReceipt(purchaseReceipt){
  return {
    type: types.SET_PURCHASE_RECEIPT,
    purchaseReceipt
  }
}