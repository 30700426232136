import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import * as scanActions from '../../../actions/scanActions';
import * as cardActions from '../../../actions/cardReaderActions';
import { CardAcceptor as i18n } from '../../../middlewares/i18n';
import { BARCODE } from '../../../middlewares/SignalRMM/scanner';

class CardAcceptor extends PureComponent {

  componentDidMount(){
    this.props.actions.scan.safeCancelScan(BARCODE)
    this.props.actions.card.readCard()
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        <div style={{margin: '400px auto', width: '80%', textAlign: 'center', display: 'inline-block'}}>
          <h4 className="bs-title">{i18n[this.props.i18n.language.current].instructions}</h4>
        </div>
      </PageLayout>
    )  
  }

}


function mapDispatchToProps(dispatch){
  return{
    actions:{
      scan: bindActionCreators(scanActions, dispatch),
      card: bindActionCreators(cardActions, dispatch)

    }
  }
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardAcceptor)