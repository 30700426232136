import initialState from './initialState';

export default function priceReducer(state = initialState.rate, action){

  switch(action.type){

    case 'PRICE_RETRIEVED':
      return Object.assign({}, state, {
        price: action.price,
      })

    case 'FEE_RETRIEVED':
      return Object.assign({}, state, {
        fee: action.fee,
      })

    case 'FEES_RETRIEVED':
      return Object.assign({}, state, {
        fees: action.fees,
      })

    case 'MIN_RETRIEVED':
      return Object.assign({}, state, {
        min: action.min,
      })

    case 'PRECIO_HOST_RETRIEVED':
      return Object.assign({}, state, {
        precio_host: action.precio_host,
      })

    default:
      return state;
  }
}