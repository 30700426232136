import React from 'react';
import { toDollar_NoCents } from '../../../helpers/helperFunctions'
import { useSelector } from 'react-redux'
import { Dashboard as i18n } from '../../../middlewares/i18n';
import ButtonBS from '../../../components/common/ButtonBS'

const CurrentTier = ({ 
  enrollmentTier,
  limit,
  isMaxTier,
  handleSelect
}) =>{

  const lang = useSelector(state => state.i18n).language.current

  return(
    <div className="current-tier-cont">
      <div>
        <h4 className="no-margin"><span>{i18n[lang].membership}:</span> {enrollmentTier}</h4>
        <h4 className="no-margin"><span>{i18n[lang].current_limit}:</span> ${toDollar_NoCents(limit)}</h4>
      </div>
      
      { !isMaxTier ? 
        <div>
          <ButtonBS
            className="current-tier-button"
            content={i18n[lang].upgrade}
            onClick={ handleSelect }
          />
        </div>
        : null
      }
    </div>
  )
}

export default CurrentTier