import React from 'react';

import translations from '../../../middlewares/i18n/CommonScams.json';

import useTranslations from '../../../hooks/useTranslations';

const CommonScamsItem = ({ title, subtitle, example }) => {
  const i18n = useTranslations(translations);

  return (
    <div className="common-scams__item">
      <div className="common-scams__item-title">{title}</div>
      <div className="common-scams__item-subtitle">{subtitle}</div>
      <div className="common-scams__item-example-title">{i18n.example}</div>
      <div className="common-scams__item-example"><q>{example}</q></div>
    </div>
  );
}

export default CommonScamsItem