import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import * as reportActions from '../../../actions/reportActions';
import ButtonBS from '../../../components/common/ButtonBS';
import '../../../styles/CleanCash/CleanCash.css';
import { toDollar_NoCents } from '../../../helpers/helperFunctions';
import Countdown from '../../../components/common/Countdown';

class CleanCash extends PureComponent {

  constructor(props){
    super(props)
    this.state = {
      disabled: false,
      restartCount: false,
      stopCount: false,
      countStopped: false
    }

    // Countdown timer in seconds
    this.COUNTDOWN = 300  // 5 minutes

    this.printReport = this.printReport.bind(this)
    this.exit = this.exit.bind(this)
    this.restartCountdown = this.restartCountdown.bind(this)
    this.countdownFinished = this.countdownFinished.bind(this)
  }
  
  disableButtons(){
    this.setState({
      disabled: true
    }, () => this.setState({
      disabled: false
    }))
  }

  printReport(){
    this.disableButtons()
    this.stopCountdown()
    this.props.actions.report.printReport()
  }

  exit(){
    this.disableButtons()
    this.props.actions.report.exit()
  }

  stopCountdown(){
    this.setState({
      stopCount: true,
      countStopped: true
    }, () => this.setState({
      stopCount: false
    }))
  }

  restartCountdown(){
    this.setState({
      restartCount: true
    }, () => this.setState({
      restartCount: false
    }))
  }

  countdownFinished(){
    this.props.actions.report.exit()
  }

  componentDidUpdate(prevProps){
    if(prevProps.isModalOpen && !this.props.isModalOpen){
      this.setState({
        countStopped: false
      }, this.restartCountdown)
    }
  }

  render(){
    let { report } = this.props

    return(
      <PageLayout title='Clear Cash'>
        <div className="clean-cash-container">
          <div className="receipt">
            <h1>Pick-up ID:</h1>
            <h2>{report.pick_up_id}</h2>
            <h1>Operator:</h1>
            <h2>{report.operator}</h2>
            <h1>Machine Name:</h1>
            <h2>{report.atm_name}</h2>
            <h1>Machine ID:</h1>
            <h2>{report.atm_slug}</h2>
            <h1>Location:</h1>
            <h2>{report.location}</h2>
            <table>
              <tbody>
                <tr>
                  <th>Denomination</th>
                  <th>Amount</th>
                  <th>USD Amount</th>
                </tr>
                <tr>
                  <td>One's ($1's)</td>
                  <td>{report.bills.one}</td>
                  <td>${toDollar_NoCents(report.bills.one * 1)}</td>
                </tr>
                <tr>
                  <td>Two's ($2's)</td>
                  <td>{report.bills.two}</td>
                  <td>${toDollar_NoCents(report.bills.two * 2)}</td>
                </tr>
                <tr>
                  <td>Five's ($5's)</td>
                  <td>{report.bills.five}</td>
                  <td>${toDollar_NoCents(report.bills.five * 5)}</td>
                </tr>
                <tr>
                  <td>Ten's ($10's)</td>
                  <td>{report.bills.ten}</td>
                  <td>${toDollar_NoCents(report.bills.ten * 10)}</td>
                </tr>
                <tr>
                  <td>Twenties ($20's)</td>
                  <td>{report.bills.twenty}</td>
                  <td>${toDollar_NoCents(report.bills.twenty * 20)}</td>
                </tr>
                <tr>
                  <td>Fifties ($50's)</td>
                  <td>{report.bills.fifty}</td>
                  <td>${toDollar_NoCents(report.bills.fifty * 50)}</td>
                </tr>
                <tr>
                  <td>Hundred's ($100's)</td>
                  <td>{report.bills.hundred}</td>
                  <td>${toDollar_NoCents(report.bills.hundred * 100)}</td>
                </tr>
              </tbody>
            </table>
            <table className="totals">
              <tbody>
                <tr>
                  <td>Total Amount USD:</td>
                  <td>${toDollar_NoCents(report.total_usd)}</td>
                </tr>
                <tr>
                  <td>Total Bills:</td>
                  <td>{report.total_bills}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clean-cash-buttons">
            <ButtonBS
              content='Print'
              onClick={ this.printReport }
              disabled={ this.state.disabled }
            />
            <ButtonBS
              content='Exit'
              onClick={ this.exit }
              disabled={ this.state.disabled }
            />
          </div>
          {
            !this.state.countStopped ?
              <div className="clean-cash-countdown">
                <span>This screen will be closed in</span>&nbsp;
                  <Countdown 
                    stop={ this.state.stopCount }
                    restart={ this.state.restartCount }
                    finished={ this.countdownFinished }
                    count={ this.COUNTDOWN }
                  />
              </div>
            : null
          }
        </div>
      </PageLayout>
    )
  }
}

function mapStateToProps(state){
  return{
    report: state.report,
    isModalOpen: state.modal.open
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      report: bindActionCreators(reportActions, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CleanCash);