import initialState from './initialState';

export default function authReducer(state = initialState.auth, action){

  switch(action.type){

    case 'VALID_PHONE':
      return Object.assign({}, state, {
        phone: action.phone
      });

    case 'INVALID_PIN':
      return Object.assign({}, state, {
        errorMessage: action.errorMessage
      });

    case 'ADD_CODE':
      return Object.assign({}, state, {
        code: action.code
      });

    case 'ADD_AREA_CODE':
      return Object.assign({}, state, {
        areaCode: action.areaCode
      });

    case 'ADD_COMPLETE_PHONE':
      return Object.assign({}, state, {
        completePhone: action.completePhone
      })

    // case 'ADD_ACC_TOKEN':
    //   return Object.assign({}, state, {
    //     token: action.token
    //   })

    // case 'ADD_MIND':
    //   return Object.assign({}, state, {
    //     mind: action.mind
    //   })

    // case 'SAVED_PIN':
    //   return initialState.auth;

    // case 'VALID_CODE':
    //   return initialState.auth;

    case 'CANCEL_OPERATION':
      return initialState.auth;

    default:
      return state;

  }
}