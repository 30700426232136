import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import InputCodeBS from '../../../components/common/input-fields/InputCodeBS';
import * as authActions from '../../../actions/authActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/EnterCode/EnterCode.css';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as lightActions from '../../../actions/lightActions';
import { EnterCode as i18n, Errors as i18nErrors } from '../../../middlewares/i18n';

class VerifyCode extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      code: ['','','','',''],
      show: false,
      submiting: false,
      key: '',

    }
    this.submit = this.submit.bind(this);
    this.getCode = this.getCode.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  submit(){
    if (this.state.code.includes('')){
      this.props.actions.auth.invalidInput(i18nErrors[this.props.language].shortCode);
    } else {
      let code='';
      for (var i=0; i < this.state.code.length; i++){
        code=code+this.state.code[i];
      }
      this.props.actions.auth.verifyChallenge(code);
    }
    this.setState({ 
      submiting: true
    }, ()=> this.setState({ submiting: false }))
  }

  getCode(value){
    this.setState({
      code: value
    })
  }

  resendCode(){
    this.setState({
        show: false
    }, () => this.props.actions.auth.sendChallenge())
  }

  componentDidMount(){
    document.getElementsByTagName('input')[0].focus();
    this.timeout = setTimeout(()=>{
        this.setState({show: true})
    }, 15000)
    this.props.actions.setStage(1)
    if(!this.props.mach.tiny)
      this.props.actions.light.setLight(1,"PPS")
  }

  componentDidUpdate(prevProps){
    if(this.props.mach.name === 'morty'){
      if (this.props.pinpad.enter && this.props.pinpad.enter !== prevProps.pinpad.enter)
        this.submit()
      else if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
        this.setState({key: this.props.pinpad.key}, () => this.setState({ key:'' }))
      else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)      //Both cancel and clear
        this.setState({key: 'CLEAR'}, () => this.setState({ key:'' }))
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.language].title} errorMessage={this.props.auth.errorMessage} >
        <div className='phone-sent-remind'>
          { this.props.auth.completePhone ?
            <h6 className='bs-subtitle no-margin'>{`${i18n[this.props.language].message} ${parsePhoneNumber(this.props.auth.completePhone).format("NATIONAL")}.`}</h6> : null
          }
          <h6 className='bs-subtitle no-margin'>
            {i18n[this.props.language].resetPin}
          </h6>
        </div>
        <div id='enter-code'>
          <InputCodeBS sendCode={ this.getCode } submited={this.state.submiting} submit={ this.submit } keyPress={this.state.key}/>
        </div>
        { this.state.show ? 
            <div id='resend-code' className={ this.props.language === "es" ? "resend-code-es" : "resend-code-en" }>
              <h6 className='bs-subtitle no-margin resend-code'>{i18n[this.props.language].notReceivedCode}</h6>
              <h6 className='bs-link' onClick={ this.resendCode }>{i18n[this.props.language].resendCode}</h6>
            </div> 
        : null }
        { !this.props.mach.tiny ?
          <ButtonBS id='enter-code' content={i18n[this.props.language].submit} onClick={ this.submit }/> :
        null }
      </PageLayout>
    );
  }
}

function mapDispatchToProps(dispatch){
  return {
    actions: {
      auth: bindActionCreators(authActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      light: bindActionCreators(lightActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    mach: state.machine,
    pinpad: state.pinpad,
    language: state.i18n.language.current
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);