export function ImagingDeviceHandler(store, code, description){
  switch(code){
    case -11:
      console.log(description);
      break;

    case -12:
      console.log(description);
      break;

    case -13:
      console.log(description);
      break;

    case -14:
      console.log(description);
      break;

    default:
      console.log('Code not recognized');
  }
}