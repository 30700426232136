import * as types from './actionTypes';
import createAxe from '../../middlewares/Axe'

export function getPlaces(places){
  return function(dispatch){
    let placesClean = places.map((place)=>{
      return {
        value: place.place_id, 'label': place.description
      }
    })
    return dispatch(setPlaces(placesClean))
  }
}

export function selectPlace(place_id){
  return async function(dispatch, getState){
    try {
      let axe = createAxe(getState())
      let response = await axe('/directory/get/location',{
        method: 'POST',
        data:{
          data:{
            place_id
          }
        }
      })
      if(response.data.data.status !== "INVALID_REQUEST"){
        dispatch(placeSelected(response.data.data.result.address_components))
        return dispatch(setPlaces([]))
      }
      else
        return dispatch({})
    }
    catch(error){
      return dispatch(errorSelecting())
    }
  }
}

export function setPlaces(places){
  return{
    type: types.SET_PLACES,
    places
  }
}

export function placeSelected(place){
  return{
    type: types.PLACE_SELECTED,
    place
  }
}

export function errorSelecting(){
  return{
    type: types.ERROR_SELECTING
  }
}