import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions';
import * as modalActions from '../../actions/modalActions';
import * as scanActions from '../../actions/scanActions';
import * as reportActions from '../../actions/reportActions';
import { routerActions } from 'connected-react-router';
import { style } from './style.js';
import { ModalWallet, ModalError, ModalScam, ModalScamAdvise, ModalClearCash, ModalSmsAccept } from '../../components/common/Modals';
import { track } from '../../helpers/analytics';

class ModalBS extends PureComponent{

  constructor(props){
    super(props);

    this.state={
      children: <h5 style={{margin: '30px 30px 5px'}}>ERROR!</h5>,
      modalType: ''
    }

    this.closeModal = this.closeModal.bind(this);
    this.openOther = this.openOther.bind(this);
    this.scanAgain = this.scanAgain.bind(this);
  }

  closeModal(){
    this.props.actions.modal.closeModal();

    if (this.props.router.location.pathname === '/scan_wallet') {
      track("Customer Attested Wallet")
      this.props.actions.scan.sendAddress();
    }

    if (this.props.router.location.pathname === '/clean-cash') 
      this.props.actions.report.exit()

    if (this.props.router.location.pathname === '/enter-phone')
      this.props.actions.auth.validPhone(this.props.auth.phone)
  }

  scanAgain(){
    this.props.actions.modal.closeModal();
    this.props.actions.scan.scanQRCode();
  }

  openOther(type){
    if(type == 'scam')
      track('Customer Declined Wallet Attestation')
    this.props.actions.modal.openNewModal(type);
  }

  componentDidUpdate(){
    if(this.props.modal.modalType !== this.state.modalType)
      switch(this.props.modal.modalType){
        case 'error':
          this.setState({ 
            children: <ModalError message={this.props.maintenance.errorMessage} />,
            modalType: 'error' 
          })
          break;
        case 'wallet':
          this.setState({
            children: <ModalWallet closeModal={this.closeModal} openModalScam={()=>this.openOther('scam')} address={this.props.purchase.address} />,
            modalType: 'wallet'
          })
          break;
        case 'scam':
          this.setState({
            children: <ModalScam scanAgain={this.scanAgain} onBack={() => this.openOther('wallet')} closeModal={this.closeModal} />,
            modalType: 'scam'
          })
          break;
        case 'clearCash':
          this.setState({ 
            children: <ModalClearCash closeModal={this.closeModal} stayOnScreen={this.props.actions.modal.closeModal}/>,
            modalType: 'clearCash' 
          })
          break;
        case 'scamAdvise':
          this.setState({ 
            children: <ModalScamAdvise closeModal={this.closeModal} />,
            modalType: 'scamAdvise' 
          })
          break;
        case 'smsAccept':
          this.setState({ 
            children: <ModalSmsAccept closeModal={this.closeModal} />,
            modalType: 'smsAccept' 
          })
          break;
      }
  }

  render(){
    return(
      <div>
        <Modal
          isOpen={this.props.modal.open}
          //onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={style}
          contentLabel="Modal BS"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
        { this.state.children }
        </Modal>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      modal: bindActionCreators(modalActions, dispatch),
      router: bindActionCreators(routerActions, dispatch),
      scan: bindActionCreators(scanActions, dispatch),
      report: bindActionCreators(reportActions, dispatch),
      auth: bindActionCreators(authActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    auth: state.auth,
    modal: state.modal,
    purchase: state.purchase,
    maintenance: state.maintenance,
    router: state.router
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBS);