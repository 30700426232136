export const NOT_ME = 'NOT_ME';
export const YES_ME = 'YES_ME';
export const SET_DOB = 'SET_DOB';
export const SET_NAME = 'SET_NAME';
export const SET_FIRST = 'SET_FIRST';
export const SET_LAST = 'SET_LAST';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_POWER = 'SET_POWER';
export const SET_CLIENT_FEE = 'SET_CLIENT_FEE';
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_FEES = 'SET_FEES';
export const SET_DASH_MESS = 'SET_DASH_MESS';
export const SET_SALES = 'SET_SALES';
export const SET_HARD_REST = 'SET_HARD_REST';
export const SET_FULFILLED_POLICIES = 'SET_FULFILLED_POLICIES';
export const SET_MAX_TIER_STATUS = 'SET_MAX_TIER_STATUS';