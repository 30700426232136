import React, { useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import Footer from "../../common/Footer";
import ButtonBS from "../../common/ButtonBS";

import { AddCoupon as i18nC } from "../../../middlewares/i18n";

import "../../../styles/CouponApplied/CouponApplied.css";

const CouponApplied = () => {
  const language = useSelector((state) => state.i18n.language.current);
  const coupon = useSelector((state) => state.coupon);

  const dispatch = useDispatch();

  const advance = useCallback(() => {
    dispatch(push("scan_wallet"));
  }, []);

  return (
    <div className="coupon-applied bs-body">
      <div className="coupon-applied__header">
        <div className="scam-logo" />
        <div>
          <h6 className="bs-title centered coupon-applied__title">
            {i18nC[language].couponApplied}
          </h6>
          <div className="line" />
        </div>
      </div>
      <div className="coupon-applied__discount">
        <h6 className="bs-title centered coupon-applied__title">
          {coupon.value * 100}%{i18nC[language].applied}
        </h6>
      </div>
      <div className="coupon-applied__container">
        <div className="coupon-applied__proceed">
          <h5 className="no-margin">{i18nC[language].couponAppliedMes}</h5>
          <br />
        </div>
        <ButtonBS
          className="coupon-applied__continue-btn"
          content={i18nC[language].continue}
          onClick={advance}
        />
      </div>
      <Footer />
    </div>
  );
};

export default CouponApplied;
