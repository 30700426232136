import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as purchaseActions from '../../../actions/purchaseActions';
import { routerActions } from 'connected-react-router';
import svgQr from '../../../styles/assets/finish-screen-qrcode.png';
import '../../../styles/FinishScreen/FinishScreen.css'
import { FinishScreen as i18n } from '../../../middlewares/i18n';
import Footer from '../../../components/common/Footer';

class FinishScreen extends Component {

  constructor(props){
    super(props);
    this.state={
      address:'',
      timer: null
    }
  }

  componentDidMount(){
    let timer = setTimeout(()=>{
      this.props.actions.router.push('/');
    }, 90000);
    this.setState({
      timer
    })
  }

  componentWillUnmount(){
    clearTimeout(this.state.timer)
  }

  render(){
    const translations = i18n[this.props.i18n.language.current];
    const spanishClassIdentifier = this.props.i18n.language.current == 'es' ? 'spanish-lang' : '';
    return(
      <div className={`finish-screen__wrapper ${spanishClassIdentifier && spanishClassIdentifier}`}>
        <div className='starting-container bs-body centered'>
          <br />
          <div className='bs-logo-screen starting-logo'/>
          {!this.props.qa.qa_partial ? 
            <div>
              <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{translations.greet1}</h6>
              <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{translations.greet2}</h6>
            </div>
          : <div>
              <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{translations.qa}</h6>
              <h6 className='bs-subtitle no-margin' style={{fontSize: '50px'}}>{translations.thankYou}</h6>
            </div>
          }
        </div>
        <div className='finish-screen__footer-container'>
          <div className="column">
            <h2>{translations.welcomeHeader}</h2>
            <p>{translations.scanInstruction}</p>
            <ul>
              <li>{translations.benefitsList[0]}</li>
              <li>{translations.benefitsList[1]}</li>
              <li>{translations.benefitsList[2]}</li>
            </ul>
          </div>
          <div className="column">
            <img src={svgQr} className="qrimage" />
            <div dangerouslySetInnerHTML={{ __html: translations.qrInstruction}}></div>
          </div>
        </div>
        <Footer big={true} />
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    qa: state.qa,
    i18n: state.i18n
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      router: bindActionCreators(routerActions, dispatch),
      purch: bindActionCreators(purchaseActions, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishScreen);