import React, { Component } from 'react';
import '../../../../styles/common/input-fields/InputCodeBS.css';
import { isEqual, clone } from 'lodash';

class InputCodeRick extends Component{

  constructor(props){
    super(props);
    this.state={
      code:['','','','',''],
    }
    this.listenKeyPress = this.listenKeyPress.bind(this);
    this.onFocused = this.onFocused.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    if (!Number.isNaN(parseInt(e.target.value, 10))){
      let index = parseInt(document.activeElement.id, 10);        //get the index of the active field
      let code = clone(this.state.code);                        //clone code for new version
      let oldCode1 = clone(code);                                //clone again code for mantain old version
      code[index] = e.target.value;                        //add code in that field according with the index)
      this.setState({
        code
      }, ()=>{
        if(!isEqual(this.state.code, oldCode1)){                 //check if the old and new code are the same
          if (index < 4){
            if(!this.state.code[index+1])               //exist next empty field -> go to next
              document.getElementById(String(index+1)).focus();
            else {
              document.getElementById(4).blur()
              this.sendCode();                                //last field fill -> send 
            }
          }
          else { 
            document.getElementById(4).blur();
            this.sendCode();                                //last field fill -> send
          }
        }
      })
    }
  }

  onFocused(index){
    if(this.state.code[index]){
      let code = clone(this.state.code);
        code[index]='';
      this.setState({
        code
      });
    }
  }

  listenKeyPress(event){
    switch(event.key){
      case '*':
        this.reset();
        break;
      case '#':
        this.props.submit();
        break;
    }
  }

  sendCode(){
    this.props.sendCode(this.state.code)
  }

  reset(){
    this.setState({ 
      code: ['','','','',''] 
    },()=>{
      this.sendCode();
      document.getElementById('0').focus()
    })
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  componentDidMount(){
    document.addEventListener('keypress', this.listenKeyPress);
    document.getElementById('0').focus();
  }

  componentWillUnmount(){
    document.removeEventListener('keypress', this.listenKeyPress)
  }

  render(){
    return(
      <div>
        <div className='input-code'>
          <input type='text' maxLength={1} id='0' className='input-square' value={this.state.code[0]} onFocus={()=>this.onFocused('0')} onChange={ this.onChange } autoComplete="off"/>
          <input type='text' maxLength={1} id='1' className='input-square' value={this.state.code[1]} onFocus={()=>this.onFocused('1')} onChange={ this.onChange } autoComplete="off"/> 
          <input type='text' maxLength={1} id='2' className='input-square' value={this.state.code[2]} onFocus={()=>this.onFocused('2')} onChange={ this.onChange } autoComplete="off"/> 
          <input type='text' maxLength={1} id='3' className='input-square' value={this.state.code[3]} onFocus={()=>this.onFocused('3')} onChange={ this.onChange } autoComplete="off"/>
          <input type='text' maxLength={1} id='4' className='input-square' value={this.state.code[4]} onFocus={()=>this.onFocused('4')} onChange={ this.onChange } autoComplete="off"/>  
        </div>
      </div>
    );
  }
}

InputCodeRick.defaultProps = {
  submited: false
}

export default InputCodeRick;