import React, { Component } from 'react';
import '../../../../styles/common/input-fields/InputSerialBS.css';
import { isEqual, clone } from 'lodash';
import KeyboardBS from '../../../KeyboardBS';

class InputSerialTinyRick extends Component{

  constructor(props){
    super(props);
    this.state={
      serial:'',
      inputNode: null
    }
    
    this.onChange = this.onChange.bind(this);
    this.openKeyboard = this.openKeyboard.bind(this);
    this.reset = this.reset.bind(this);
  }

  onChange(e){
    this.setState({
      serial: e.target.value
    }, this.sendSerial)
  }

  sendSerial(){
    this.props.sendSerial(this.state.serial)
  }

  submit(){
    this.props.submit(true);
    this.reset()
  }

  reset(){
    this.setState({ 
      serial: '' 
    }, this.sendSerial)
  }

  openKeyboard(node){
    this.setState({
      inputNode: node
    })
  }

  componentDidUpdate(){
    if (this.props.submited){
      this.reset();
    }
  }

  render(){
    return(
      <div>
        <div className='input-serial-cont'>
          <input type='text' id= {this.props.id} className='input-serial' value={this.state.serial} onFocus={()=>this.openKeyboard(this.serialInput)} onInput={ this.onChange } ref={(input) => {this.serialInput = input}} autoComplete="off"/> 
        </div>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.reset}
            showNumber = {true}
            numeric = {true}
            rightTitle = {"enter"}
            leftTitle = {"delete"}
            next = {this.props.submit}
            disablePlus = {true}
          /> : null }
      </div>
    );
  }
}

InputSerialTinyRick.defaultProps = {
  submited: false
}

export default InputSerialTinyRick;