import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputTelMorty from './InputTelMorty.jsx';
import InputTelRick from './InputTelRick.jsx';
import InputTelTinyRick from './InputTelTinyRick.jsx';

class InputTel extends PureComponent{
  constructor(props){
    super(props);
  }

  render(){
    if(this.props.machine.name === 'rick')
      if(!this.props.machine.tiny)
        return <InputTelRick {...this.props}/> 
      else 
        return <InputTelTinyRick {...this.props}/>
    else
      return <InputTelMorty {...this.props}/>
  }
}

function mapStateToProps(state){
  return{
    machine: state.machine
  }
}

export default connect(mapStateToProps)(InputTel)