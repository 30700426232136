import React from 'react';
import { useSelector } from 'react-redux'
import { AccountRestricted as i18n } from '../../../middlewares/i18n';

const HardRestricted = () => {
    const _i18n = useSelector(state => state.i18n)

    return(
        <div>
            <div className='rest-sub-cont'>
                <h4 className="no-margin">{i18n[_i18n.language.current].hr1}</h4>
                <h4 className="no-margin">{i18n[_i18n.language.current].hr2}</h4>
                <h4 className="no-margin">{i18n[_i18n.language.current].hr3}</h4>
            </div>
            <div className='rest-sub-cont'>
                <h5 className="no-margin">{i18n[_i18n.language.current].hr4}</h5>
                <h5 className="no-margin">{i18n[_i18n.language.current].hr5}<span>support@bitstop.co</span>.</h5>
            </div>
        </div>
    )
}

export default HardRestricted