import React, { useRef } from 'react';
import '../../../../styles/common/input-fields/InputFieldBS.css';

const InputFieldBS = (props) => {

    const inputEl = useRef(null);

    const focusOnClick = () => {
      inputEl.current.focus();
    };

    return(
      <div className={'input-bs-container '+props.className}>
        {props.title ? <h6 className='input-bs-title no-margin'>{props.title}</h6> : null}
        <div className="outside-wrapper" onClick={focusOnClick} />
        <input id={props.id}
          onInput={props.onInput}
          className='input-bs'
          //onChange={props.onChange}
          value={props.value}
          autoComplete="input-field"
          onFocus={props.onFocus}
          //onBlur={props.onBlur}
          ref={props.inputRef}
          onClick={props.onClick}
          ref={inputEl}
        />
      </div>
    )
}

export default InputFieldBS;