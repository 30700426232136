import React, { useState } from 'react';

import '../../../styles/common/CustomCheckbox/CustomCheckbox.css'

const CustomCheckbox = ({ onChange, initialChecked = false, checkedColor = '#FF1720' }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);

    if (onChange) onChange(event.target.checked);
  };

  return (
    <div className="custom-checkbox">
      <input 
        type="checkbox" 
        id="customCheckboxInput"
        className="custom-checkbox__input"

        checked={checked} 
        onChange={handleCheckboxChange}
      />
      <label htmlFor="customCheckboxInput" className="custom-checkbox__label">
        {checked ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none" className="custom-checkbox__checked">
            <rect width="73" height="73" transform="translate(0 0.683594)" fill={checkedColor} />
            <path d="M12 34.5482L29.3074 51.8555L60 21.1631" stroke="white" strokeWidth="4.68779" />
          </svg>
        ) : (
          <div className="custom-checkbox__box" />
        )}
      </label>
    </div>
  );
};

export default CustomCheckbox;