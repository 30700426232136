import React, { Component } from 'react';
import '../../../styles/TransCard/TransCard.css';
import { toDollar } from '../../../helpers/helperFunctions';
import { InsertCash as i18n } from '../../../middlewares/i18n';

class TransCard extends Component{

  constructor(props){
    super(props);

    this.state={
      size: '34px'
    }
    this.createTable.bind(this);
  }

  createTable(){
    const bills = [1,2,5,10,20,50,100];
    var billProp = this.props.bills;
    const table = bills.map((bill)=>{
      return(
        <div key={bill}>
        {billProp[bill] > 0 ?
          <div className='amount-container am-bills'>
            <h6 className='no-margin trans-font trans-sub pull-left'>{billProp[bill]+' x $'+bill}</h6>
            <h6 className='no-margin trans-font trans-sub pull-right'>${toDollar(billProp[bill]*bill)}</h6>
          </div>
        : null}
        </div>
      )
    })
    return table;
  }

  setHeight(){
    if(this.props.address.length > 34){
      this.setState({ size: '29px'})
    }
    else{
      this.setState({ size: '34px'})
    }
  }

  componentDidMount(){
    this.setHeight()
  }

  render(){
    return(
      <div className='trans-card-container'>
        <div className="trans-card-inner-wrapper" />
        { !this.props.receipt ? <div className='trans-sub-cont'>
          <h5 className='no-margin trans-font bills-ins'>{i18n[this.props.language].billsInserted}</h5>
          {this.createTable()}
        </div> : null }
        { this.props.couponApplied ?
        <div className='trans-sub-cont' style={{height: "224px", padding: '0px'}}>
          <div className='amount-container'>
            <h5 className='pull-left no-margin trans-font'>{i18n[this.props.language].totalInserted}</h5>
            <h5 className='pull-right no-margin trans-font trans-sub'>${toDollar(this.props.subtotal)}</h5>
          </div>
          <div className='amount-container fee-cont' style={{margin: "0px"}}>
            <h5 className='pull-left no-margin trans-font' style={{
              fontSize: "25px"
            }}><s>{i18n[this.props.language].previousFee} ({this.props.oldFee}%){ this.props.flat ? <span> + ${this.props.flat}</span> : null }</s></h5>
            <h5 className='pull-right no-margin trans-font trans-sub' style={{
              fontSize: "25px"
            }}><s>-${toDollar(this.props.oldTotalFee)}</s></h5>
          </div>
          <div className='amount-container fee-cont' style={{margin: "0px"}}>
            <h5 className='pull-left no-margin trans-font' style={{
              fontSize: "25px",
              color: "#564bff"
            }}>{i18n[this.props.language].savings}🤑🎉</h5>
            <h5 className='pull-right no-margin trans-font trans-sub' style={{
              fontSize: "25px",
              color: "#564bff"
            }}>${toDollar(this.props.savings)}</h5>
          </div>
          <div className='amount-container fee-cont' style={{margin: "0px"}}>
            <h5 className='pull-left no-margin trans-font'>{i18n[this.props.language].fee} ({this.props.fee}%){ this.props.flat ? <span> + ${this.props.flat}</span> : null }</h5>
            <h5 className='pull-right no-margin trans-font trans-sub'>-${toDollar(this.props.totalFee)}</h5>
          </div>
        </div> :
        <div className='trans-sub-cont'>
          <div className='amount-container'>
            <h5 className='pull-left no-margin trans-font'>{i18n[this.props.language].totalInserted}</h5>
            <h5 className='pull-right no-margin trans-font trans-sub'>${toDollar(this.props.subtotal)}</h5>
          </div>
          <div className='amount-container fee-cont'>
            <h5 className='pull-left no-margin trans-font'>{i18n[this.props.language].fee} ({this.props.fee}%){ this.props.flat ? <span> + ${this.props.flat}</span> : null }</h5>
            <h5 className='pull-right no-margin trans-font trans-sub'>-${toDollar(this.props.totalFee)}</h5>
          </div>
        </div>
      }
        <div className='trans-sub-cont'>
            <div className='amount-container2'>
              <h4 className='pull-left no-margin trans-font purchased'>{i18n[this.props.language].purchased}</h4>
              <h4 className='pull-right no-margin trans-font trans-sub purch-total'><span>{this.props.btc} BTC</span> / ${toDollar(this.props.total)}</h4>
            </div>
            <div className='amount-container'>
              <h6 className='pull-left no-margin trans-rate'>{i18n[this.props.language].exchangeRate}</h6>
              <h6 className='pull-right no-margin trans-rate'>1 BTC = ${toDollar(this.props.btcPrice)}</h6>
            </div>
        </div>
        <div className='add-cont'>
          <h5 className='no-margin trans-font'>{ !this.props.receipt ? i18n[this.props.language].sendingTo : i18n[this.props.language].sentTo }</h5>
          <h6 className='no-margin trans-add' style={{fontSize: this.state.size}}>{this.props.address}</h6>
        </div>
      </div>
    );
  }
}

TransCard.defaultProps ={
  btcPrice: '22,000.00',
  address: '1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX'
}

export default TransCard;