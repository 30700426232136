import { address, networks } from 'bitcoinjs-lib';
import { errorScanningAddress, scanBarcode } from '../../../actions/scanActions'
import { triggerError } from '../../../actions/maintenanceActions';
import { openNewModal } from '../../../actions/modalActions';
import { update } from 'lodash';

import { Errors as i18nErrors } from '../../i18n'
import { updateID } from '../../../actions/updateActions';

import { QR_CODE, BARCODE, removeIntervalScanner } from '../../SignalRMM/scanner';

import { track } from '../../../helpers/analytics';

export default function serviceScan(store, response){
    let path = store.getState().router.location.pathname;
    let network_mach = store.getState().machine.network === 'mainnet' ? networks.bitcoin : networks.testnet
    let lang = store.getState().i18n.language.current

    if (path === '/scan_wallet') {
        removeIntervalScanner(QR_CODE, store.dispatch)
        if (response.ParsedAddress !== null) {
            try{
                address.toOutputScript(response.ParsedAddress, network_mach)
                track(`Bitcoin Wallet Address Scanned: ${response.ParsedAddress}`);
                store.dispatch({ type: "ADDRESS_SCANNED", address: response.ParsedAddress})
                return store.dispatch(openNewModal('wallet'))
            } catch(e){
                track('Address Scan Error', {
                    address_scan_error: `bitcoinjs failed to parse address: parsed: ${response.ParsedAddress} original: ${response.Original}`
                });
                return store.dispatch(errorScanningAddress(i18nErrors[lang].scanWalletFailed, 1))
            }
        } else {
            track('Address Scan Error', {
                address_scan_error: `Middleman failed to parse address: ${response.Original}`
            });
            return store.dispatch(errorScanningAddress(i18nErrors[lang].scanWalletFailed, 1))
        }
    } else if (path === '/scan_id') {
        removeIntervalScanner(BARCODE, store.dispatch)
        let force = false
        if(store.getState().router.location.state)
            force = !!store.getState().router.location.state.force;

        if(response.ParsedID){
            track(`${force ? 'Force ' : ''}ID Scan Completed`);

            let parse = JSON.parse(response.ParsedID)
            if(!parse.name.middle){
                parse.name.middle = ""
            }
            for (var key in parse){
              if (typeof parse[key] === 'number'){
                update(parse, key, ()=>{
                  return parse[key].toString()
                })
              }
            }
            store.dispatch(updateID(response.Original, parse))
            return store.dispatch({ type: "ID_SCANNED", id: response})
        }
        else{
            track(`${force ? 'Force ' : ''}ID Scan Error`, {
                id_scan_error: `Middleman failed to parse ID ${response.Original}`
            });
            store.dispatch(triggerError(i18nErrors[lang].invalidID))
            setTimeout(()=>{ return store.dispatch(scanBarcode())}, 4500)
        }
    }
}