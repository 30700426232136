import React from 'react';
import { useSelector } from 'react-redux';
import '../../../styles/common/Footer/Footer.css'
import Exit from '../../../containers/Exit';
import { Footer as i18n } from '../../../middlewares/i18n';

const Footer = ({ big = true, phone = true }) => {

  const _i18n = useSelector(state => state.i18n)
  
  if (big) 
    return(
      <div className='footer-container f-y-title'>
        <h6 className='bs-subtitle no-margin centered footer-call'>{i18n[_i18n.language.current].message}</h6>
        <div className='footer'/>
        <Exit/>
      </div>
    )
  else
    return(
      <div className='footer-container f-n-title'>
        { phone ? <h6 className='bs-subtitle no-margin centered footer-call'>{i18n[_i18n.language.current].message}</h6> : null }
        <div className='footer'/>
        <Exit/>
      </div>
    )
}

export default Footer