export const COLORS = {
  success: {
    lighter: '#E1F7E9',
    light: '#E1F7E9',
    main: '#4CAF50',
    dark: '#2E7D32',
    line: 'rgba(76, 175, 80, 0.33)'
  },
  //TODO: add error colors
  error: {
    lighter: '#F9E2E2',
    light: '#F6B3B2',
    main: '#EF5350',
    dark: '#D32F2F',
    line: '#EF5350'
  }
}