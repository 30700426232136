import React, { useMemo, useState } from 'react';

import ButtonBS from '../../common/ButtonBS';

import ScamLayout from '../../common/PageLayout/ScamLayout';

import CommonScamsItem from './CommonScamsItem';

import translations from '../../../middlewares/i18n/CommonScams.json';

import useTranslations from '../../../hooks/useTranslations';

import '../../../styles/CommonScams/CommonScams.css';

const PAGE_SIZE = 2;
const paginate = (array, page_number, page_size) => array.slice((page_number - 1) * page_size, page_number * page_size);

const CommonScams = () => {
  const i18n = useTranslations(translations);
  const [page, setPage] = useState(1);
  
  const { scams, first, second } = useMemo(() => {
    const scams = paginate([...i18n.scams], page, PAGE_SIZE);

    const first = ((page - 1) * PAGE_SIZE) + 1;
    const second = first + scams.length - 1;

    return { scams, first ,second };
  }, [page]);

  const isFirstPage = page <= 1;
  const isLastPage = page >= Math.ceil(i18n.scams.length / PAGE_SIZE);

  const handlePage = (delta = 1) => {
    const nextPage = page + delta;
    if(nextPage <= 0 || nextPage > Math.ceil(i18n.scams.length / PAGE_SIZE)) {
      return;
    }
    setPage(nextPage);
  }

  return (
    <ScamLayout>
      <div className="common-scams">
        <div className="scam-logo" />

        <h6 className="common-scams__title">{i18n.title}</h6>
        <div className="scam-layout__divider" />

        <div className="common-scams__showing">
          {i18n.showing} {first} - {second} {i18n.of} {i18n.scams.length} {i18n.examples}
        </div>

        <div className="common-scams__items">
          {scams.map((scam, index) => (
            <CommonScamsItem key={`common-scams-item-${index}`} {...scam} />
          ))}
        </div>

        <div className={`common-scams__buttons ${isFirstPage ? 'first' : ''} ${isLastPage ? 'last' : ''}`}>
          {!isFirstPage && (
            <ButtonBS v2 className="v2--red" content={i18n.prevPage} onClick={() => handlePage(-1)} />
          )}

          {!isLastPage && (
            <ButtonBS v2 className="v2--red scam-screen__button--next" content={i18n.nextPage} onClick={() => handlePage()} />
          )}
        </div>
      </div>
    </ScamLayout>
  )
}

export default CommonScams;