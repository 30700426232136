import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../../../components/common/PageLayout';
import ButtonBS from '../../../components/common/ButtonBS';
import * as clientActions from '../../../actions/clientActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/TellUsAbout/TellUsAbout.css';
import InputFieldBS from '../../../components/common/input-fields/InputFieldBS';
import InputBirthBS from '../../../components/common/input-fields/InputBirthBS';
import moment from 'moment';
import KeyboardBS from '../../../components/KeyboardBS';
import { routerActions } from 'connected-react-router';
import { TellUsAbout as i18n } from '../../../middlewares/i18n';

class TellUsAbout extends PureComponent{
  constructor(props){
    super(props);
    this.state={
      firstName:'',
      lastName:'',
      date:'',
      valid: false,
      message:'',
      inputNode:null
    }
    this.openKeyboard = this.openKeyboard.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleChangeBirth = this.handleChangeBirth.bind(this);
    this.closeKeyboard = this.closeKeyboard.bind(this);
    this.nextField = this.nextField.bind(this);
    this.submit = this.submit.bind(this)
    this.closeAndOpen = this.closeAndOpen.bind(this)
  }

  submit(){
    if (this.state.firstName.length){
      this.setState({message:''});
      if(this.state.lastName.length){
        this.setState({message:''});
        if(this.state.valid){
          this.setState({message:''});
          this.props.actions.client.addName(this.state.firstName,this.state.lastName);
          this.props.actions.client.setDOB(this.state.date);
          this.props.actions.router.push('/enter-address');
        } else
          this.setState({message: i18n[this.props.i18n.language.current].invalidDOB});;
      }else
        this.setState({message: i18n[this.props.i18n.language.current].invalidLN});
    }
    else
      this.setState({message: i18n[this.props.i18n.language.current].invalidFN});
  }

  handleChangeBirth(date){
    this.setState({date: date},()=>this.validDOB(this.state.date))
  }

  handleInput(e){
    this.setState({[e.target.id]: e.target.value})
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  closeKeyboard(){
    this.setState({inputNode: null})
  }

  nextField(){
    if(this.state.inputNode.id==='lastName'){
      this.closeKeyboard();
    } else if (this.state.inputNode.id==='firstName'){
      document.getElementById('lastName').focus();
    }
  }

  closeAndOpen(node){
    if(node !== this.state.inputNode)
      this.setState({
        inputNode: null
      }, ()=> this.openKeyboard(node))
  }

  validDOB(date){
    var dob = moment(date,'MM/DD/YYYY');
    if (dob.isValid())
      if(moment().diff(dob, 'years') > 17)
        this.setState({valid: true, message:''})
      else 
        this.setState({valid: false, message: i18n[this.props.i18n.language.current].underage})
    else
      this.setState({valid: false})
  }

  componentDidMount(){
    document.getElementsByTagName('input')[0].focus();
    this.props.actions.setStage(2)
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        {this.state.message ? <h5 className='message-client-reg'>{this.state.message}</h5> : null}
        <div className='input-name'>
          { this.props.kyc ?
            <div className='or-scan-id'>
              <h6 className="no-margin bs-subtitle">{i18n[this.props.i18n.language.current].or}</h6>
              <ButtonBS
                className='scan-id-button'
                content={i18n[this.props.i18n.language.current].scanID}
                onClick={()=>this.props.actions.router.push('scan_id')}
              />
            </div>
            : null }
          <InputFieldBS 
            id='firstName'
            className='name-input' 
            title={i18n[this.props.i18n.language.current].firstName} 
            onFocus={()=>this.openKeyboard(document.getElementById('firstName'))} 
            onInput={this.handleInput} 
          />
          <InputFieldBS
            id='lastName'
            className='name-input'
            title={i18n[this.props.i18n.language.current].lastName}
            onFocus={()=>this.openKeyboard(document.getElementById('lastName'))}
            onInput={this.handleInput}
          />
        </div>
        <div className='input-container'>
          <h6 className='input-title no-margin'>{i18n[this.props.i18n.language.current].enterDOB}</h6>
          <div className='outer-abs-wrap tell-us-drop'>
            <InputBirthBS date={this.handleChangeBirth} onClick={this.closeKeyboard} language={this.props.i18n.language.current}/>
          </div>
        </div>
        <ButtonBS className='tell-us-button' content={i18n[this.props.i18n.language.current].submit} onClick={this.submit}/>
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.closeKeyboard}
            next={this.nextField}
          /> : null }
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      client: bindActionCreators(clientActions, dispatch),
      router: bindActionCreators(routerActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    kyc: state.machine.kyc,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TellUsAbout);