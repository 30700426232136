export function toDollar(amount) {
  return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

export function toDollar_NoCents(amount) {
  return (parseInt(amount, 10)+'').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function getBs58(input){
  const regex = /[13][a-km-zA-HJ-NP-Z0-9]{26,34}/i
  var exp = input.match(regex);

  if (exp != null)
  	return exp[0]
  else
  	return null


}

export function getBc32(input){
  const regex = /bc1[a-km-zA-HJ-NP-Z0-9l]{1,90}/i
  var exp = input.match(regex);

  if (exp != null)
  	return exp[0]
  else
  	return null
}

export function minimizeToken(token, type = '') {
  if(!token) {
    return `no ${type} token`;
  }

  return `${token.substring(0, 6)}...${token.substring(token.length - 6, token.length)}`;
}

export function truncateBtcAmount(number, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  const truncatedValue = Math.trunc(number * factor) / factor;
  return truncatedValue.toString() + '...';
}
