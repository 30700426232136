export default function cashDispenserMM(action, store, _hub, next){

  switch(action.type){

    case 'DISPENSE_CASH':
      console.log(action.dispense_cash)
      _hub.server.cS_BDS_Dispense(parseInt(action.dispense_cash, 10)).done(res => {
          console.log('BDS res',res)
      })
      .fail(err => {
          console.log('ERROR BDS', err)
      });
      break;
      
    case 'SET_CASSETES':
      _hub.server.cS_BDS_SetBills(action.cassetes).done(res =>{
          console.log('set bill',res)
      }).fail(err =>{
          console.log('Error in setting bills', err)
      });
      break;
    
    default:
      next()
  }
}