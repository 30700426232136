import initialState from './initialState';

export default function qaReducer(state = initialState.qa, action){

  switch(action.type){

    case 'SERIAL_SENT':
      return Object.assign({}, state, {
        serial: action.serial
      })

    case 'SET_QA_STATUS':
      return Object.assign({}, state, {
        start: action.status
      })

    case 'SET_PARTIAL_QA':
      return Object.assign({}, state, {
        qa_partial: true
      })

    case 'CANCEL_OPERATION':
      return initialState.purchase;

    default:
      return state;
  }
}