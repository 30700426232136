import initialState from './initialState';

export default function placesReducer(state = initialState.places, action){

  switch(action.type){

    case 'SET_PLACES':
      return Object.assign({}, state, {
        nearest: action.places
      })

    case 'PLACE_SELECTED':
      return Object.assign({}, state, {
        place: action.place
      })

    case 'CANCEL_OPERATION':
      return initialState.places

    default:
      return state
  }
}