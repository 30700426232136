import { setPrinterDown, printerWorking, exitMaintenance } from '../../../../actions/maintenanceActions';

export default function PrinterHandler(store, status){
  switch(status.PrinterService){
    case 'HEALTHY':
    if(!store.getState().maintenance.printerWork)
        store.dispatch(printerWorking())
      break;

    case 'UNKNOWN':
    case 'FATAL':
      if(store.getState().maintenance.printerWork)
        store.dispatch(setPrinterDown())
      if(store.getState().maintenance.active)
        store.dispatch(exitMaintenance())
      break;

    default:
      console.error('STATUS NOT REGISTERED')
  }
}