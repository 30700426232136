import React from 'react';

import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import ModalBS from '../../../containers/ModalBS';

import Footer from '../../../components/common/Footer/Footer';
import BeingScammedCard from '../../../components/common/BeingScammedCard';

import '../../../styles/common/PageLayout/ScamLayout.css';
import ReminderBS from "../../../containers/ReminderBS";

const ScamLayout = ({ children, showBeingScammed = true }) => (
  <>
    <ReminderBS />
    <ModalBS />
      <div className="scam-layout">
        { children }
        
        {showBeingScammed && (
          <BeingScammedCard />
        )}
      </div>

      <div className="scam-layout__app-footer">
        <Footer phone={false} big={false} />
      </div>
  </>
);

ScamLayout.propsTypes = exact({
  children: PropTypes.node.isRequired,
  showBeingScammed: PropTypes.bool
})

export default ScamLayout;