import toast from '../../helpers/toast';
import translations from '../../middlewares/i18n/SendScamPrevNotification.json';
import createAxe from '../../middlewares/Axe'

import * as types from './actionTypes';

export function setLinkStatus(status) {
    return {
        type: types.SCAM_LINK_STATUS,
        status
    }
}

export function sendScamPreventionLink() {
  return async function(dispatch, getState) {
    const lang = getState().i18n.language.current;
    const i18n = translations[lang];

    try {
        dispatch(setLinkStatus(true));
        let axe = createAxe(getState());
        let res = await axe('/v2/unity/notify/scam_prevention', {
            method: 'POST',
            headers: {
                'Customer-Token': `Bearer ${getState().client.client_id}`,
                'Atm-Token': `Bearer ${getState().machine.atm_id}`
            }
        });

        const status = res.status === 200 ? 'success' : 'error';
        toast({ title: i18n[`${status}Title`], text: i18n[`${status}Message`], status });
    }
    catch(error) {
        toast({ title: i18n.errorTitle, text: i18n.errorMessage, status: 'error' });
    } finally {
       dispatch(setLinkStatus(false));
    }
  }
}