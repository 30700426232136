import React, { memo } from 'react';

import Footer from '../Footer';
import ModalBS from '../../../containers/ModalBS';
import ReminderBS from '../../../containers/ReminderBS';
import ErrorBoundary from '../../../containers/ErrorBoundary';

import '../../../styles/common/layouts/PageLayout5.css';

const DEFAULT_FOOTER_PROPS = {
  big: false,
  phone: false
}

const PageLayout5 = ({ title, children, errorMessage, footerProps = DEFAULT_FOOTER_PROPS }) => (
  <ErrorBoundary>
    <div className="page-layout-5">
      <ReminderBS />
      <ModalBS />
      <div className="check-shield-logo" />
      {title && <div className="page-layout-5__title">{title}</div>}
      <div className="page-layout-5__divider" />
      {errorMessage && <h6 className="page-layout-5__error">{errorMessage}</h6>}
      <div className="page-layout-5__content">
        {children}
      </div>
      <div className="page-layout-5__app-footer">
        <Footer {...footerProps} />
      </div>
    </div>
  </ErrorBoundary>
);

export default memo(PageLayout5);