import initialState from './initialState.js';

export default function i18nReducer(state = initialState.i18n, action) {
    switch(action.type) {
        case "CHANGE_LANGUAGE":
            return {
                ...state,
                language: {
                    ...state.language,
                    current: action.current
                }
            }

        case "SET_DEFAULT_LANGUAGE":
            return {
                ...state,
                language: {
                    ...state.language,
                    defLang: action.defLang
                }
            }

        case "SET_AVAILABLE_LANGUAGES":
            return {
                ...state,
                language: {
                    ...state.language,
                    available: action.available
                }
            }
        default:
            return state
    }
}