import React, { PureComponent } from 'react';
import '../../../../styles/common/input-fields/InputCodeBS.css';
import { isEqual, clone } from 'lodash';

class InputCodeMorty extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      code:['','','','','']
    }
  }

  componentDidUpdate(){
    if(this.props.submited){
      this.reset()
    }
    if(this.props.keyPress === 'CLEAR'){  //No numeric 
      this.reset()
    } else if (!Number.isNaN(parseInt(this.props.keyPress, 10))){
      if(!document.activeElement.id){
        for(let i = 0; i < 5 ; i++){
          if(this.state.code[i].length === 0){
            document.getElementById(i).focus();
            break;
          }
        }
      }
      else{
        let index = parseInt(document.activeElement.id, 10);  //index of the actual field
        let code = clone(this.state.code);                //clone actual code 
        let oldCode = clone(code);                        //Now get other clone of the code that will be the old
        code[index] = this.props.keyPress;                 //add new code to one of the copies
        this.setState({
          code
        }, ()=>{
          if(!isEqual(this.state.code, oldCode)){         //compare both codes, if they are different
            if (index < 4)
              if(!this.state.code[index+1])               //exist next empty field -> go to next
                document.getElementById(String(index+1)).focus();
              else {
                document.getElementById(4).blur()
                this.sendCode();                                //last field fill -> send 
              }
            else {                                      //we are in the last field -> send code to parent
                document.getElementById(4).blur()
                this.sendCode();                                //last field fill -> send 
              }                        
          }
        })
      }
    }
  }

  onFocused(index){
    if(this.state.code[index]){
      let code = clone(this.state.code);
        code[index]='';
      this.setState({
        code
      });
    }
  }

  sendCode(){
    this.props.sendCode(this.state.code)
  }

  reset(){
    this.setState({
      code: ['','','','','']
    },()=>{
      this.sendCode();
      document.getElementById('0').focus();
    })
  }

  render(){
    return(
      <div>
        <div className='input-code'>
          <input type='text' maxLength={1} id='0' className='input-square' value={this.state.code[0]} onFocus={this.onFocused.bind(this,'0')} autoComplete="off"/>
          <input type='text' maxLength={1} id='1' className='input-square' value={this.state.code[1]} onFocus={this.onFocused.bind(this,'1')} autoComplete="off"/> 
          <input type='text' maxLength={1} id='2' className='input-square' value={this.state.code[2]} onFocus={this.onFocused.bind(this,'2')} autoComplete="off"/> 
          <input type='text' maxLength={1} id='3' className='input-square' value={this.state.code[3]} onFocus={this.onFocused.bind(this,'3')} autoComplete="off"/>
          <input type='text' maxLength={1} id='4' className='input-square' value={this.state.code[4]} onFocus={this.onFocused.bind(this,'4')} autoComplete="off"/>  
        </div>
      </div>
    );
  }
}

export default InputCodeMorty;