import { enterMaintenance, exitMaintenance, maintenanceAfterWait } from '../../../../actions/maintenanceActions';

export default function PinPadHandler(store, status){
  switch(status.PinPadService){
    case 'HEALTHY':
      if(store.getState().maintenance.active){
        store.dispatch(exitMaintenance());
      }
      break;

    case 'UNKNOWN':
    case 'FATAL':
      if(!store.getState().maintenance.active){             //if maintenance mode is not activated
        if(store.getState().transaction.init){              //if machine is in a middle of a transaction
          if(store.getState().transaction.stage <= 1){      //If machine is in stage 1
            store.dispatch(maintenanceAfterWait(8))          //Enter in maintenance mode
          }
          else{                                             //If machine already passed stage 1
            //Do not enter to maintenance mode but do...?
          }
        }
        else{                                               //if machine iss not in middle of a transaction
          store.dispatch(enterMaintenance(8));   //enter in maintenance mode
        }
      }
      break;

    default:
      console.error('STATUS NOT REGISTERED')
  }
}