import React, { PureComponent } from 'react';
import PageLayout from '../../../components/common/PageLayout';
import InputTelBS from '../../../components/common/input-fields/InputTelBS'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonBS from '../../../components/common/ButtonBS';
import * as authActions from '../../../actions/authActions';
import * as lightActions from '../../../actions/lightActions';
import { openNewModal } from '../../../actions/modalActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/EnterPhone/EnterPhone.css';
import { addEventsListen, removeEvents } from '../../../helpers/listenFunctions.js';
import { EnterPhone as i18n } from '../../../middlewares/i18n'

import { track } from '../../../helpers/analytics';

class EnterPhone extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      phone:'',
      valid: false,
      key: '',
      clearInput: false
    }

    this.sendCode = this.sendCode.bind(this);
    this.getPhone = this.getPhone.bind(this);
    this.getValid = this.getValid.bind(this);
    this.getSubmit = this.getSubmit.bind(this);
    this.handleSendCode = this.handleSendCode.bind(this);
  }

  handleSendCode(){
    track('Enter Phone Clicked');

    this.sendCode();
  }
  
  sendCode(){
    if(!this.props.qa.qa_partial){
      if(this.state.valid){
        this.setState({
          valid: false
        }, () => {
          // Save the phone number
          this.props.actions.auth.addPhone(this.state.phone);

          // Open the SMS Accept modal
          this.props.actions.openNewModal('smsAccept');
          this.setState({
            clearInput: true
          }, () => this.setState({
            clearInput: false
          }));
        })
      }
    }
    else{
      this.props.actions.auth.validPhone(this.state.phone)
    }
  }

  getPhone(value){
    this.setState({
      phone: value
    })
  }

  getValid(value){
    this.setState({
      valid: value
    })
  }

  getSubmit(value){
    if(value)
      this.sendCode();
  }

  contextMenu(e){
    e.preventDefault();
  }

  componentDidMount(){
    let elem = document.getElementById('no-touch-wrap');
    let listEvents = ['touchstart', 'touchend', 'touchmove', 'contextmenu']

    document.getElementsByTagName('input')[0].focus();
    this.props.actions.setStage(1);
    if(!this.props.mach.tiny)
      this.props.actions.light.setLight(1,"PPS")

    if(this.props.qa.qa_partial)
      this.setState({valid: true})

    addEventsListen(elem, listEvents, this.contextMenu, false)
  }

  componentDidUpdate(prevProps){
    if(this.props.mach.name === 'morty'){
      if (this.props.pinpad.enter && this.props.pinpad.enter !== prevProps.pinpad.enter)
        this.sendCode()
      else if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
        this.setState({key: this.props.pinpad.key}, ()=>this.setState({key:''}))
      else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)           //Both cancel and clear
        this.setState({key: 'CLEAR'}, ()=>this.setState({key:''}))
    }
  }

  componentWillUnmount(){
    let elem = document.getElementById('no-touch-wrap');
    let listEvents = ['touchstart', 'touchend', 'touchmove', 'contextmenu']

    removeEvents(elem, listEvents, this.contextMenu)
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title}>
        <div className='tel-input-cont'>
          <InputTelBS 
            className='tel-input'
            sendTel={this.getPhone}
            sendValid={this.getValid}
            submit={this.getSubmit}
            send={this.handleSendCode}
            keyPress={this.state.key}
            clearInput={this.state.clearInput}
          />
          <h6 className='bs-subtitle no-margin remember'>{i18n[this.props.i18n.language.current].message}</h6>
        </div>
        { !this.props.mach.tiny ?
          <ButtonBS content='Enter' onClick={this.sendCode} disabled={!this.state.valid}/> :
          null
        }
      </PageLayout>
    );
  }

}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      auth: bindActionCreators(authActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      light: bindActionCreators(lightActions, dispatch),
      openNewModal: bindActionCreators(openNewModal, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    mach: state.machine,
    pinpad: state.pinpad,
    qa: state.qa,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterPhone);