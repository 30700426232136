import initialState from './initialState';

export default function transactionReducer(state = initialState.transaction, action){

  switch(action.type){

    case 'START_TRANS':
      return Object.assign({}, state, {
        init: true
      })

    case 'END_TRANS':
      return Object.assign({}, state, {
        init: false
      })

    case 'SET_STAGE':
      return Object.assign({}, state, {
        stage: action.stage
      })

    case 'SET_SCANNING_GENERATOR':
      return Object.assign({}, state, {
        scanningGenerator: action.scanningGenerator,
      })

    case 'CANCEL_OPERATION':
      return initialState.transaction

    default:
      return state;

  }

}