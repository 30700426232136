import React, { PureComponent } from 'react';
import Cleave from 'cleave.js/react';
import '../../../styles/common/CalculatorBS/CalculatorBS.css';
import '../../../styles/TransCard/TransCard.css';
import { clone } from 'lodash';
import { toDollar } from '../../../helpers/helperFunctions';

export default class CalculatorBS extends PureComponent {

  constructor(props){
    super(props);

    this.state={
      amountInserted: '0',
      feeCollected: 0,
      amountWithFee: 0,
      amountBitcoin: 0
    };

    this.focusField = this.focusField.bind(this);
    this.calculateFromAmount = this.calculateFromAmount.bind(this);
  }

  calculateFromAmount(){
    let feeCollected = (parseInt(this.state.amountInserted, 10) * this.props.fee / 100).toFixed(2);
    let amountWithFee =  (parseInt(this.state.amountInserted, 10) - feeCollected).toFixed(2);
    let price = this.props.price.replace(/[,$]/g,'');
    let amountBitcoin = (amountWithFee / price);
    this.setState({
      feeCollected: feeCollected,
      amountWithFee: amountWithFee,
      amountBitcoin: amountBitcoin,
    });
  }

  componentDidUpdate(prevProps, prevState){
    console.log('THIS IS THE COMP DID OF UPDATE MORTY: NEED TO CALCULATE WHILE PRICE CHANGE!!')
    let prevAmount = clone(this.state.amountInserted);             //save prev amount
    if (!Number.isNaN(parseInt(this.props.keyPress, 10))){  //if it is numberic
      if (this.state.amountInserted.length < 4){
        if(this.state.amountInserted === '0'){
          this.setState({
            amountInserted: this.props.keyPress
          }, this.calculateFromAmount)
        } else {
          this.setState({
            amountInserted: prevAmount+this.props.keyPress,           //add the number
          }, this.calculateFromAmount);
        }
      }
    } else if (this.props.keyPress === 'CLEAR' || this.props.keyPress === 'CANCEL'){
      this.delete()
    }
  }

  delete(){
    if(this.state.amountInserted.length===1){
      this.setState({
        amountInserted: '0'
      }, this.calculateFromAmount)
    }
    else{
      this.setState({
        amountInserted: this.state.amountInserted.substring(0,this.state.amountInserted.length-1)
      }, this.calculateFromAmount)
    }
  }

  focusField(){
    document.getElementById('amount-input').focus();
  }

  componentDidMount(){
    this.focusField();
  }

  render(){
    return(
      <div className='calculator-container'>
        <div className='calculator-sub-cont'>
          <div className='amount-container'>
            <h5 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>Cash Inserted</h5>
            <Cleave
              value={this.state.amountInserted}
              placeholder='Insert amount ($)'
              options={{
                numeral: true,
                prefix: '$',
                delimeter: ' ',
                numeralIntegerScale: 4,
                numeralPositiveOnly: true,
              }}
              id='amount-input'
              size={10}
              onBlur={this.focusField}
            />
          </div>
          <div className='amount-container fee-cont'>
            <h5 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>Fee ({this.props.fee}%)</h5>
            <Cleave
              disabled
              value={this.state.feeCollected}
              options={{
                numeral: true,
                prefix: '$',
                delimeter: ' ',
                numeralThousandsGroupStyle: 'thousand'}}
              id='fee-output'
              size={12}
            />
          </div>
        </div>
        <div className='calculator-sub-cont' style={{borderBottom: 'none'}}>
            <div className='amount-container'>
              <h4 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>Total</h4>
              <Cleave
                disabled
                value={this.state.amountWithFee}
                options={{
                  numeral: true,
                  prefix: '$',
                  numeralThousandsGroupStyle: 'thousand'}}
                id='awf-output'
                size={12}
              />
            </div>
            <div className='amount-container'>
              <h4 className='pull-left no-margin btc-total purchased' style={{fontSize: '42px', height:'70px'}}>Purchased</h4>
              <div className='btc-purch-cont'>
                <Cleave
                  disabled
                  value={this.state.amountBitcoin}
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 8}}
                  id='btc-output'
                  size={this.state.amountBitcoin === 0 ? 1 : (this.state.amountBitcoin.toFixed(8).toString().length)+1}
                />
                <h4 className="no-margin" style={{fontSize: '39px'}}>BTC</h4>
              </div>
            </div>
            <div className='amount-container'>
              <h6 className='pull-left no-margin trans-rate' style={{fontSize: '28px'}}>Exchange Rate</h6>
              <h6 className='pull-right no-margin trans-rate' style={{fontSize: '28px'}}>1 BTC = ${toDollar(this.props.price)}</h6>
            </div>
        </div>
      </div>
    );
  }
}
