import initialState from './initialState';

export default function couponReducer(state = initialState.coupon, action){
  switch(action.type){
    case 'SET_COUPON_CODE':
      return Object.assign({}, state, {
        code: action.code
      })
    case 'SET_COUPON_TYPE':
      return Object.assign({}, state, {
        type: action.type
      })
    case 'SET_COUPON_VALUE':
      return Object.assign({}, state, {
        value: action.value
      })
    case 'SET_COUPON_APPLIED':
      return Object.assign({}, state, {
        applied: action.applied
      })
    case 'SET_OLD_FEES':
      return Object.assign({}, state, {
        old_fees: action.old_fees
      })
    case 'SET_NEW_FEES':
      return Object.assign({}, state, {
        new_fees: action.new_fees
      })
    case 'SET_CURR_FEE':
      return Object.assign({}, state, {
        curr_fee: action.curr_fee
      })
    case 'SET_OLD_FEE':
      return Object.assign({}, state, {
        old_fee: action.old_fee
      })
    case 'SET_OLD_CHARGES':
      return Object.assign({}, state, {
        old_charges: action.old_charges
      })
    case 'SET_SAVINGS':
      return Object.assign({}, state, {
        savings: action.savings
      })
    case 'CANCEL_OPERATION':
      return initialState.coupon
    default:
      return state
  }
}