import * as types from './actionTypes';

var interval;
var timeout;

export function setLight(state, service){
  return {
    type: types.SET_LIGHT,
    state,
    service,
    signalR: true
  }
}

export function lightOff(service){
  return function(dispatch){
    clearInterval(interval)           //clear interval
    clearTimeout(timeout)             //clear timeout
    return dispatch(setLight(0, service))
  }
}

export function setLightStat(service){
  return function(dispatch, getState){
    //clean state ----------
    let oldService = getState().light.service
    clearInterval(interval)           //clear prev interval
    clearTimeout(timeout)             //clear prev timeout
    dispatch(setLight(0, oldService)) //set last service to off (we secure that we are clean)
    //----------------------
    dispatch(setLight(1, service))                      //turn on service that we want
    setTimeout(()=>dispatch(setLight(0, service)), 500) //wait .5 second to turn off (because interval triggered at 1 second)
    interval = setInterval(()=>{                        //interval of 1 sec (will be triggered after 1 second)
      dispatch(setLight(1, service))                    //turn on light
      setTimeout(()=>dispatch(setLight(0, service)), 500) //turn off light after .5
    }, 1000)
    timeout = setTimeout(()=>{
      clearInterval(interval)                                    //clear interval for finishing after 4 seconds
      setTimeout(()=>{return dispatch(setLight(1, service))}, 500) // leave light on
    }, 4000)
  }
}

export function turnOFFLights(){
  return function(dispatch, getState){
    clearInterval(interval)           //clear interval
    clearTimeout(timeout)             //clear timeout
    if (getState().machine.name === "rick")
      dispatch(setLight(0,"BCS"))
    else if (getState().machine.name === "morty")
      dispatch(setLight(0,"BDSL"))
    dispatch(setLight(0,"BAS"))
    dispatch(setLight(0,"BDS"))
    dispatch(setLight(0,"PPS"))
    dispatch(setLight(0,"RPS"))
    dispatch(setLight(0,"CRS"))
    return dispatch(allLightsOFF())
  }
}

export function allLightsOFF(){
  return{
    type: types.ALL_LIGHTS_OFF
  }
}