import * as types from './actionTypes.js';
import { QR_CODE, BARCODE } from '../../middlewares/SignalRMM/scanner'

export function startTransaction(){
  return{
    type: types.START_TRANS
  }
}

export function endTransaction(){
  return{
    type: types.END_TRANS
  }
}

export function setStage(stage){
  return{
    type: types.SET_STAGE,
    stage
  }
}

export function startBarcodeScanningGenerator(){
  return function(dispatch) {
    return dispatch(setScanningGenerator(BARCODE))
  }
}

export function startQRScanningGenerator(){
  return function(dispatch) {
    return dispatch(setScanningGenerator(QR_CODE))
  }
}

export function removeScanningGenerator(){
  return function(dispatch) {
    return dispatch(setScanningGenerator(null))
  }
}

// Setting scanning transaction state
function setScanningGenerator(scanningGenerator){
  return{
    type: types.SET_SCANNING_GENERATOR,
    scanningGenerator
  }
}