import { setPrinterDown, printerWorking, exitMaintenance } from '../../../actions/maintenanceActions';

export default function PrinterHandler(store, status){
  if(status.Printer.HardwareResult === 0){              //No errors
    if(!store.getState().maintenance.printerWork)       //if we have the state set in printer down
        store.dispatch(printerWorking())                //Change to working
  }
  else if(status.Printer.HardwareResult > 0){           //There are errors

    //we ignore this ones
    if(store.getState().maintenance.active || store.getState().router.location.pathname === '/maintenance-mode' || store.getState().router.location.pathname === '/enter_serial' || store.getState().router.location.pathname === '/pre-ok'){          //Check if we are in a maintenance mode
      store.dispatch(exitMaintenance());              //Turn off maintenance mode
    }

    if(store.getState().maintenance.printerWork)        //If we have the state set in printer working
      store.dispatch(setPrinterDown())                     //Change to down
  }
  else
    console.error('STATUS NOT REGISTERED')
}