import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import '../../../../styles/common/input-fields/InputTelBS.css';
import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';
import SelectCountry from './countries.jsx';
import Flags from './flags.js';
import KeyboardBS from '../../../KeyboardBS';
import { EnterPhone as i18n } from '../../../../middlewares/i18n';

class InputTelTinyRick extends PureComponent {

  constructor(props){
    super(props);

    this.state={
      phone:'',
      valid: false,
      country: 'US',
      country_code: '1',
      inputNode: null
    }

    this.handleCountry = this.handleCountry.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.openKeyboard = this.openKeyboard.bind(this);
    this.delete = this.delete.bind(this);
    this.format = this.format.bind(this);
  }

  handlePhone(e){
    let value = e.target.value.replace(/\D/g,'');
    this.setState({
      phone: value         //Should never a special caracter
    },()=>{
      this.checkValid();
      this.sendTel();
      let size = document.getElementsByTagName('input')[0].value.length
      setTimeout(()=>{
        document.getElementsByTagName('input')[0].selectionStart = size
        document.getElementsByTagName('input')[0].selectionEnd = size
      }, 50)
    })
  }

  handleCountry(e){
    if(e.value==='NO_COUNTRY'){
      this.setState({
        country: 'NO_COUNTRY',
        country_code: ''
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
    else{
      this.setState({
        country: e.value,
        country_code: getCountryCallingCode(e.value)
      }, ()=>{
        this.checkValid();
        this.sendTel();
        document.getElementsByTagName('input')[0].focus();
      })
    }
  }

  isValidNumber(phone_number, country_code){
    if(phone_number.length < 7) {
      return false
    }
    return true
  }

  checkValid(){
    if(this.state.country_code){
      if(this.state.valid !== this.isValidNumber(this.state.phone, this.state.country)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone, this.state.country)
        }, this.sendValid)
      }
    }
    else
      if(this.state.valid !== this.isValidNumber(this.state.phone)){     //Prevent to change state innecesary
        this.setState({
          valid: this.isValidNumber(this.state.phone)
        }, this.sendValid)
      }
  }

  sendTel(){
    this.props.sendTel(`+${this.state.country_code}${this.state.phone}`);
  }

  sendValid(){
    this.props.sendValid(this.state.valid)
  }

  submit(){
    this.props.submit(true)
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  delete(){
    this.setState({
      phone: this.state.phone.substring(0,this.state.phone.length-1)
    }, ()=>{
      this.checkValid();
      this.sendTel();
      document.getElementsByTagName('input')[0].focus();
    })
  }

  clear(){
    this.setState({
      phone: ''
    }, () => {
      this.checkValid();
      this.sendTel();
      document.getElementsByTagName('input')[0].focus();
    })
  }

  format(){
    const formatter = new AsYouType(this.state.country).input(this.state.phone);
    return formatter;
  }

  checkType(){
    if(this.props.compact){
      document.getElementsByClassName('numeric-keyboard')[0].classList.add('compact-display')
    }
  }

  componentDidMount(){
    this.handleCountry({value: this.props.machine.primary.country})
    this.setState({inputNode: this.inputTel}, this.checkType)
  }

  componentDidUpdate(){
    if (this.props.clearInput) {
      this.clear()
    }
  }

  render(){
    return(
      <div className={'tel-container '+this.props.className}>
        <div className='select-flag-bs'>{ Flags[this.state.country]() }</div>
        <SelectCountry onChange={this.handleCountry} value={this.state.country} className="select-country-bs" countries={this.props.machine.intlphone}/>
        <div id='no-touch-wrap' />
        <input
          id="input-tel"
          onChange={this.handlePhone}
          onInput={this.handlePhone}
          value={this.format()}
          className="input-tel-bs"
          placeholder={i18n[this.props.i18n.language.current].placeholder}
          onClick={()=>this.openKeyboard(this.inputTel)}
          ref={(input) => {this.inputTel = input}}
          autoComplete="off"
        />
        { this.state.inputNode ?
          <KeyboardBS
            inputNode={this.state.inputNode}
            close={this.delete}
            showNumber = {true}
            numeric = {true}
            rightTitle = {"enter"}
            leftTitle = {"delete"}
            next = {this.props.send}
          /> : null }
      </div>
    )
  }
}

InputTelTinyRick.defaultProps={
  className: '',
  compact: false
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(InputTelTinyRick);