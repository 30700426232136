import React, { Component } from 'react';

import { Toaster } from 'react-hot-toast';

import './styles/App.css';
import Routes from './routes.js';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from './containers/ErrorBoundary';

import 'animate.css'

class App extends Component {

  constructor(props){
    super(props);
    this.state={
      location: this.props.location
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({location: nextProps.location})
  }

  componentDidMount(){
    document.body.style.zoom = 0.75
    document.body.style.margin = 0
    document.body.style.width = '1440px'
  }

  render() {
    return (
        <div>
          <ErrorBoundary>
            <Routes location={ this.state.location.pathname }/>
            <Toaster containerClassName="toast-notifications" />
          </ErrorBoundary>
        </div>
    );
  }
}

export default withRouter(App);