import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputCodeRick from './InputCodeRick.jsx';
import InputCodeTinyRick from './InputCodeTinyRick.jsx';
import InputCodeMorty from './InputCodeMorty.jsx';

class InputCode extends PureComponent{
  constructor(props){
    super(props);
  }

  render(){
    if(this.props.machine.name === 'rick')
      if(!this.props.machine.tiny)
        return <InputCodeRick {...this.props}/> 
      else 
        return <InputCodeTinyRick {...this.props}/>
    else
      return <InputCodeMorty {...this.props}/>
  }
}

function mapStateToProps(state){
  return{
    machine: state.machine
  }
}

export default connect(mapStateToProps)(InputCode)