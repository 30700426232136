import moment from 'moment';
import 'moment/locale/es-us';
import { Receipt as i18n } from '../../../middlewares/i18n'
import {
 TIME_FORMAT_EN,
 TIME_FORMAT_ES
} from '../../../helpers/constants'
import { BITSTOP_LOGO } from './assets'
import { toDollar } from '../../../helpers/helperFunctions';
import { generalDisclaimer, illinoisDisclaimer } from './disclaimers';

export function getReceipt(subtotal, fee, totalfee, total, btc, btcPrice, address, moment, txID, qa_partial, serial, flat, language, operatorName, coupon, client, spread, quotedExchange, operatorNMLS, disclaimer, operatorAddress = "", config = {}){
  let header = `<h2 style='margin-bottom: 0; font-family: Courier New; text-align: center'>${i18n[language].titlePrint}</h2> <h4 style='margin-top: 0; font-family: Courier New; text-align: center'>----------</h4> <h5 style='text-align: left'>Transaction ID: ${txID.substring(0, 7)}</h5><h5 style='text-align: left'>${i18n[language].date}: ${moment}</h5>`

  let customer = `<h5 style='margin-bottom: 0; text-align: left'>${i18n[language].customer}:</h5><h5 style='margin-top: 0px; text-align: left'>${client.firstName} ${client.lastName}</h5>`

  let subtotalAndFees = `<table> <tr> <td style='font-weight: bold; width: 170px'>${i18n[language].totalInserted}</td><td style='text-align: right; width: 105px'>$${subtotal}</td></tr>${coupon.applied ? "<tr><td style='font-weight: bold; width: 170px; font-size: small'><s>"+i18n[language].previousFee+" ("+coupon.old_fee+"%) + $"+flat+":</s></td><td style='text-align: right; width: 105px; font-size: small'><s>-$"+coupon.old_charges+"</s></td></tr> <tr><td style='font-weight: bold; width: 100px; font-size: small'>"+i18n[language].savings+":</td><td style='text-align: right; width: 105px; font-size: small'>$"+coupon.savings+"</td></tr>": ""}<tr> <td style='font-weight: bold; width: 170px'>${i18n[language].fee} (${fee}%)${flat ? " + $"+flat : ""}:</td><td style='text-align: right; width: 105px'>-$${totalfee}</td></tr></table>`

  let lineDivider = "<div style='border-bottom: 1px solid black; height: 1px; width: 100%'></div>"

  let totals = `<table style='font-size: large'> <tr> <td style='font-weight: bold; width: 100px'>${i18n[language].total}:</td><td style='width: 175px; text-align: right'>$${total}</td></tr><tr> <td style='font-weight: bold; width: 100px'>${i18n[language].purchased}:</td><td style='width: 175px; text-align: right'>${btc} BTC</td></tr></table>`

  let exchangeRate = `<table> <tr> <td style='font-weight: bold; width: 140px'>${i18n[language].exchangeRate}:</td><td style='text-align: right; width: 135px'>1 BTC=$${btcPrice}</td></tr>${config.showRateSpread ? "<tr> <td style='font-weight: bold; width: 140px'>Quoted Exchange:</td><td style='text-align: right; width: 135px'>"+quotedExchange+"</td></tr><tr> <td style='font-size: small; font-weight: bold; width: 140px'>Quoted Ex. Rate Spread:</td><td style='text-align: right; width: 135px'>+$"+spread+"</td></tr>" : ""}</table>`

  let sentTo = `<table><tr><td style='font-weight: bold'>${i18n[language].sentTo}:</td></tr><tr> <td style='font-size: small; width: 290px; text-align: center'>${address}</td></tr></table>`

  let footer = `<h6 style='text-align: center'>${i18n[language].thanks}</h6> <h6 style='text-align: center'>${i18n[language].visitUs}</h6><h6></h6><h6></h6> <h6 style='text-align: center; margin: 0px'>Kiosk Operated by: ${operatorName}</h6>
  ${ operatorNMLS ? "<h6 style='text-align: center; margin: 0px'>NMLS ID: " + operatorNMLS + "</h6>" : "" }<h6 style='text-align: center; margin: 0px'>${operatorAddress}</h6><h6></h6><h6></h6>`

  let disclaim = `<div style='font-size: 11.5px; text-align: justify; width: 270px; margin: 0 auto;'><span>${disclaimer}</span></div><h6></h6><h6></h6>`

  if(qa_partial)
    return BITSTOP_LOGO + `<h2 style='margin-bottom: 0; font-family: Courier New; text-align: center'>QA SUCCESSFULLY DONE</h2> <h4 style='margin-top: 0; font-family: Courier New; text-align: center'>----------</h4> <h5 style='text-align: left'>Transaction ID: ${txID.substring(0, 7)}</h5><h5 style='text-align: left'>Serial Number: ${serial}</h5><h5 style='text-align: left'>Date: ${moment}</h5> <table> <tr> <td style='font-weight: bold; width: 150px'>Total Inserted:</td><td style='text-align: right; width: 125px'>$${subtotal}</td></tr><tr> <td style='font-weight: bold; width: 150px'>Fee (${fee}%):</td><td style='text-align: right; width: 125px'>-$${totalfee}</td></tr></table> <div style='border-bottom: 1px solid black; height: 1px; width: 100%'></div><table style='font-size: large'> <tr> <td style='font-weight: bold; width: 100px'>Total:</td><td style='width: 175px; text-align: right'>$${total}</td></tr><tr> <td style='font-weight: bold; width: 100px'>Purchased:</td><td style='width: 175px; text-align: right'>${btc} BTC</td></tr></table> <div style='border-bottom: 1px solid black; height: 1px; width: 100%'></div><table> <tr> <td style='font-weight: bold; width: 140px'>Exchange Rate:</td><td style='text-align: right; width: 135px'>1 BTC=$${btcPrice}</td></tr></table> <table> <tr> <td style='font-weight: bold'>Sent to:</td></tr><tr> <td style='font-size: small; width: 290px; text-align: center'>${address}</td></tr></table> <h6 style='text-align: center'>Thank you for shopping with us.</h6> <h6 style='text-align: center'>Visit us at bitstop.co</h6><h6></h6><h6></h6>`
  else {
    let _receipt = BITSTOP_LOGO + header

    if(config.showCustomerInfo) 
      _receipt += customer

    _receipt += subtotalAndFees
    _receipt += lineDivider
    _receipt += totals
    _receipt += lineDivider
    _receipt += exchangeRate
    _receipt += sentTo
    _receipt += footer
    _receipt += disclaim

    return _receipt
  }
}
// --------------------------------------------------------

export function stateToReceipt(state) {
  let { purchase, qa, machine, i18n,  client, coupon } = state
  let { subtotal, fee, totalfee, total, btc, btcprice, address, time_sent, idtx, flat, spread, quotedExchange } = purchase
  let { qa_partial, serial } = qa

  let language = i18n.language.current
  let format_time = TIME_FORMAT_EN
  moment.locale('en')

  if(language === 'es'){
    moment.locale('es-us')
    format_time = TIME_FORMAT_ES
  }

  let operatorAddress = ""
  if(machine.operator_address && machine.operator_address.full_address){
    operatorAddress = machine.operator_address.full_address
  }

  let config = {
    showCustomerInfo: false,
    showRateSpread: false
  }

  let disclaimer = generalDisclaimer()

  switch(machine.local_state){
    case 'CA':
      config.showCustomerInfo = true
      config.showRateSpread = true
      break;
    case 'IL':
      disclaimer = illinoisDisclaimer()
      break;
  }

  return getReceipt(
    subtotal,
    fee,
    totalfee,
    total,
    btc,
    btcprice,
    address,
    moment.parseZone(purchase.date).format(format_time),
    idtx, 
    qa_partial,
    serial,
    flat,
    language,
    machine.operator_name,
    coupon,
    client,
    toDollar(spread),
    quotedExchange,
    machine.operator_nmls,
    disclaimer,
    operatorAddress,
    config
  )
}