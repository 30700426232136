import { errorHardware, printScan } from '../../actions/maintenanceActions';
import { setLightStat, lightOff } from '../../actions/lightActions';
import { restartQRScan, restartBarcodeScan } from '../../actions/scanActions';
import { startBarcodeScanningGenerator, startQRScanningGenerator, removeScanningGenerator } from '../../actions/transactionActions';
import { track } from '../../helpers/analytics';

var intervals = {
  barcodeInterval: null,
  qrInterval: null
}

const qrIntervalKey = "qrInterval"
const barcodeIntervalKey = "barcodeInterval"

export const QR_CODE = 'QR_CODE'
export const BARCODE = 'BARCODE'

function startIntervalScanner(type, ms, dispatch) {
  switch(type){
    case 'QR_CODE':
      dispatch(startQRScanningGenerator())
      intervals.qrInterval = setInterval(() => dispatch(restartQRScan()), ms)
      break;
    case 'BARCODE':
      dispatch(startBarcodeScanningGenerator())
      intervals.barcodeInterval = setInterval(() => dispatch(restartBarcodeScan()), ms)
      break;
    default:
      console.log("Called without a type")
  }
}

function removeInterval(intervalName){
  if(intervals[intervalName]) {
    clearInterval(intervals[intervalName])
    intervals[intervalName] = null
  }
}

export function removeIntervalScanner(type, dispatch) {
  dispatch(removeScanningGenerator())
  switch(type){
    case 'QR_CODE':
      removeInterval(qrIntervalKey)
      break;
    case 'BARCODE':
      removeInterval(barcodeIntervalKey)
      break;
    default:
      console.log("Called without a type")
  }
}

export default function scannerMM(action, store, _hub, next){

  switch(action.type){

    case 'SCAN_BARCODE':
      // If not interval, we are calling it for first time
      if(!intervals.barcodeInterval) {
        // Start Lights
        store.dispatch(setLightStat("BCS"))
        store.dispatch(printScan('Calling scan barcode...(ID)'))

        startIntervalScanner(BARCODE, 7000, store.dispatch)
      }

      _hub.server.cS_BCS_StartScanBarcode().done(response=> {
          //serviceScan(store, response)
      })
      .fail(err=> {
        track("Error Scanning Barcode")
        store.dispatch(errorHardware(err))
      });
      break;

    case 'SCAN_QR_CODE':
      // If not interval, we are calling it for first time
      if(!intervals.qrInterval) {
        // Start Lights
        store.dispatch(setLightStat("BCS"))
        store.dispatch(printScan('Calling scan qrcode...(Wallet)'))

        startIntervalScanner(QR_CODE, 7000, store.dispatch)
      }

      try {
        _hub.server.cS_BCS_ParsedScanBarcode('btc', store.getState().machine.network).done(response=> {
          store.dispatch(errorHardware(response))
        })
        .fail(err=> {
          track("Error Scanning Parsed Barcode")
          store.dispatch(errorHardware(err))
        });
      }
      catch(error) {
        store.dispatch(errorHardware(error))
      }
      break;

    case 'CANCEL_SCAN':
      _hub.server.cS_BCS_CancelScan().done(response=>{
      });
      break;

    case 'SAFE_CANCEL_SCAN':
      // remove any interval set
      removeIntervalScanner(action.scannerType, store.dispatch)

      store.dispatch(lightOff("BCS"))
      _hub.server.cS_BCS_CancelScan().done(response=>{
      });
      break;

    case 'TAKE_PICTURE_SCAN': // need to register action
      _hub.server.cS_BCS_CaptureImage().done(response=>{
      });
      break;

    default:
      next()
      
  }
}