import { isEqual, isEmpty, keys } from 'lodash';
import AnalyzeStatus from '../MaintenanceService/BaseErrorHandler/AnalyzeStatus.jsx';
import AnalyzeStatusGM from '../MaintenanceService/MortyBEH/BaseErrorHandler/AnalyzeStatus.jsx';
import { enterMaintenance, enterPreOk } from '../../actions/maintenanceActions';
import { retrieveInfo } from '../../actions/rateActions';
import { setAtmID, setState } from '../../actions/machineActions';
import { push } from 'connected-react-router';
import { setQAStatus } from '../../actions/qaActions';
import { tokenRetrieved } from '../Initialization';

export default function hardwareStatusMM(action, store, _hub, next){

  let statuses;
  let resJasic;

  switch(action.type){
    
    //Maintenance Mode
    case 'GET_DEVICE_STATUS':
      _hub.server.cS_MAS_GetStatuses().done(response=>{
        if(!store.getState().machine.atm_id || store.getState().machine.atm_id.localeCompare(response.AtmToken)){
          store.dispatch(retrieveInfo(response.AtmToken)) //after getting the atm token we retrieve info
          store.dispatch(setAtmID(response.AtmToken))
          setTimeout(() => tokenRetrieved(store.getState().machine.atm_id), 5000)          //wait until is ready
        }
        if(store.getState().machine.name === 'morty'){
          resJasic = JSON.parse(response)
          statuses = resJasic.Statuses
            if (isEmpty(store.getState().maintenance.statuses)){ //check if we are initializing
              store.dispatch({
                type: "SET_STATUSES",
                statuses
              })
            }
            else{
              let storeStatuses = keys(store.getState().maintenance.statuses);
              let resStatuses = keys(statuses);
              if(!isEqual(storeStatuses, resStatuses)){   //we receive a new set of statuses
                store.dispatch({
                  type: "SET_STATUSES",
                  statuses
                })
              }
              else{
                for (let prop in statuses){
                  if (statuses[prop] !== store.getState().maintenance.statuses[prop]){  //check if there is a change in the statuses
                      store.dispatch({
                          type: "SET_STATUSES",
                          statuses
                      })
                  }
                }
              }
            }
            AnalyzeStatusGM(store)
        }
        else{
          statuses = response.Statuses
          if (!store.getState().maintenance.active && (response.MachineStatus > 0 && response.MachineStatus < 9)){
            switch(response.MachineStatus){ //If it is ok, won't get in because it is 0 and 0 is false
              case 1:
                if(store.getState().machine.state !== 1)
                  return store.dispatch(enterPreOk())
                else
                  break
              case 2:
                if(store.getState().machine.state !== 2)
                  store.dispatch(setState(2))
                if(!store.getState().qa.start)
                  store.dispatch(setQAStatus(true))
                let routeAct = store.getState().router.location.pathname;
                let qa_partial = store.getState().qa.qa_partial;
                if(routeAct !== "/enter_serial" && !qa_partial)
                  return store.dispatch(push('enter_serial'))
                else
                  break
              case 3:
                // console.log("WE ARE BOOTSTRAP")
                break;
              // default:
              //   console.log('not registered status maintenance')
              }
              //NOTE: I will get out when the set statuses is called and Analyze status
          } else if(response.MachineStatus === 9){
            if(store.getState().machine.state !== 9)
              return store.dispatch(enterMaintenance(9))
          } else {
            if (isEmpty(store.getState().maintenance.statuses)){ //check if we are initializing
              store.dispatch({
                type: "SET_STATUSES",
                statuses
              })
            }
            else{
              let storeStatuses = keys(store.getState().maintenance.statuses);
              let resStatuses = keys(statuses);
              if(!isEqual(storeStatuses, resStatuses)){   //we receive a new set of statuses
                store.dispatch({
                  type: "SET_STATUSES",
                  statuses
                })
              }
              else{
                for (var prop in statuses){
                  if (statuses[prop].HardwareResult !== store.getState().maintenance.statuses[prop].HardwareResult){  //check if there is a change in the statuses
                      store.dispatch({
                          type: "SET_STATUSES",
                          statuses
                      })
                  }
                }
              }
            }
            AnalyzeStatus(store)
          }
        }
      })
      .fail(error=>{
      })
      break;

    default:
      next()
  }
}