import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ButtonBS from '../../../components/common/ButtonBS'
import PageLayout from '../../../components/common/PageLayout/PageLayout4'
import { agreeToContact } from '../../../actions/updateActions'
import '../../../styles/AgreeContact/AgreeContact.css'
import { AgreeContact as i18n } from '../../../middlewares/i18n';

const AgreeContact = () => {
  const _i18n = useSelector(state => state.i18n)

  const dispatch = useDispatch()
  const advance = useCallback(
    () => {
      dispatch(agreeToContact())
    }, []
)

  return(
    <PageLayout
        title={i18n[_i18n.language.current].title}
        price
        big
    >
      <div className='agree-contact-container'>
        <div className='paper-plane-red agree-contact-plane' />
        <p>{i18n[_i18n.language.current].instructions}</p>
        <ButtonBS
          className ='agree-contact-button'
          content={i18n[_i18n.language.current].agree}
          onClick={ advance }
        />
      </div>
    </PageLayout>
  )
}

export default AgreeContact