import React from 'react'
import { useSelector } from 'react-redux'

const ErrorCode = () => {

  const maint = useSelector(state => state.maintenance)

  return(
    <h6 className="error-code-num">{maint.errorCode}</h6>
  )
}

export default ErrorCode