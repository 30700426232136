import React, { memo } from 'react';

const CustomToast = ({ title, text, colors }) => (
  <div className="toast-notifications__content" style={{ borderColor: colors.light, backgroundColor: colors.lighter, width: '100%' }}>
    <div className="toast-notifications__content-container" style={{ borderColor: colors.dark }}>
      <div className="toast-notifications__content-title" style={{ color: colors.dark }}>{title}</div>
      <div className="toast-notifications__content-text" style={{ color: colors.dark }}>{text}</div>
    </div>

    <div className="toast-notifications__content-line" style={{ borderColor: colors.main, backgroundColor: colors.line }} />
  </div>
);

export default memo(CustomToast);
