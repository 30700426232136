import React from 'react';
import '../../../styles/ClientCard/ClientCard.css';

const ClientCard = (props) =>{
  return(
    <div className='client-card-container'>
      <div className='profile-icon'/>
      <h5 className='bs-title client-title no-margin'>Name</h5>
      <h6 className='bs-subtitle no-margin'>{props.name}</h6>
      <h5 className='bs-title client-title no-margin'>Address</h5>
      <h6 className='bs-subtitle no-margin'>{props.street}</h6>
      <h6 className='bs-subtitle no-margin'>{props.city}</h6>
    </div>
  )
}

export default ClientCard;