import initialState from './initialState';

export default function lightReducer(state = initialState.light, action){

  switch(action.type){

    case 'SET_LIGHT':
      return Object.assign({}, state, {
        service: action.service,
        state: action.state
      })

    default:
      return state;
  }
}