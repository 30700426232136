import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslations';
import translations from '../../../middlewares/i18n/HelpCenter';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../constants/support';
import '../../../styles/common/HelpCenter/HelpCenter.css';

const HelpCenter = ({ openModal, setOpenModal }) => {
  const isOpen = openModal === 'help';
  const i18n = useTranslations(translations);
  const operator_name = useSelector(state => state.machine.operator_name);
  const operator_address = useSelector(state => state.machine.operator_address.full_address);

  const openHelpCenter = () => setOpenModal('help');
  const closeHelpCenter = () => setOpenModal(null);

  return (
    <div className={`help-center ${isOpen ? 'open' : ''}`}>
      {isOpen && (
        <>
          <div className="help-center__backdrop" onClick={closeHelpCenter} />
          <button className="help-center__button help-center__button--close" onClick={closeHelpCenter}>
            <div className="help-center__icon-close" />
          </button>
          <div className="help-center__menu">
            <div className="help-center__menu-title">{i18n.helpCenter}</div>
            <div className="help-center__menu-subtitle" dangerouslySetInnerHTML={{ __html: i18n.questions }} />
            <ul>
              <li>{SUPPORT_EMAIL}</li>
              <li>{SUPPORT_PHONE}</li>
            </ul>
            <hr />
            <div className="help-center__menu-title-container">
              <div className="help-center__menu-title">{i18n.watchOut}</div>
              <div className="scam-logo" />
            </div>
            <div className="help-center__menu-subtitle">{i18n.beingScammed}</div>
            <Link to={'/common-scams'}>{i18n.completeList}</Link>
            <hr />
            <div className="help-center__menu-title-container">
              <div className="help-center__menu-title">{i18n.atmOperator}</div>
            </div>
            <div className="help-center__menu-atm-operator">
              <ul>
                <li>{operator_name}</li>
                <li>
                  <div>{operator_address}</div>
                </li>
              </ul>
            </div>
            <div className="help-center__menu-footer-container" />
          </div>
        </>
      )}
      {!isOpen && (
        <>
          <button className="help-center__button" onClick={openHelpCenter}>
            <div className="help-center__icon-open" />
          </button>
          <div className="modal-button-label">{i18n.btnLabel}</div>
        </>
      )}
    </div>
  );
};

export default HelpCenter;