import { enterMaintenance, exitMaintenance, maintenanceAfterWait } from '../../../../actions/maintenanceActions';

export default function QRHandler(store, status){
  switch(status.QRService){
    case 'HEALTHY':
      if(store.getState().maintenance.active){
        store.dispatch(exitMaintenance());
      }
      break;

    case 'UNKNOWN':
    case 'FATAL':
      if(!store.getState().maintenance.active){             //if maintenance mode is not activated
        if(store.getState().transaction.init){              //if machine is in a middle of a transaction
          if(store.getState().transaction.stage <= 2){      //If machine is in stage 2 or less
            switch(store.getstate().transaction.stage){     //depending in what stage we are
              case 1:
                //Do something but don't go to maintenance mode because we can solve before
                break;
              case 2:                                           //in current stage
                //Give a chance to solve the problem
                let storeStatus = store.getState().maintenance.statuses.QRService; //Save current state
                  setTimeout(()=>{                               //Give a moment to the mach to solve problem (19 seconds)
                    if (storeStatus === 'FATAL' || storeStatus === 'UNKNOWN'){
                      store.dispatch(maintenanceAfterWait(4))      //Go to maintenance mode
                    }
                    //Else don't do anything because error was solved
                  }, 19000)
                  break;
              default:
                console.error('I should never trigger. Current Stage:',store.getState().transaction.stage)
            }
          }
          else{                                             //If machine already passed stage 2
            //Do not enter to maintenance mode but do...?
          }
        }
        else{                                               //if machine iss not in middle of a transaction
          store.dispatch(enterMaintenance(4));   //enter in maintenance mode
        }
      }
      break;

    default:
      console.error('STATUS NOT REGISTERED')
  }
}