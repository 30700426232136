import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import '../../../styles/SelectMembership/TierCard.css';
import ButtonBS from '../../../components/common/ButtonBS'
import { SelectMembership as i18n } from '../../../middlewares/i18n';
import { toDollar_NoCents } from '../../../helpers/helperFunctions';

const TierCard = ({ id, name, type, description, limit_max, policies, handleSelect }) => {
    const _i18n = useSelector(state => state.i18n)

    const buildPolicies = () => {
        return policies.map((policy, i) => {
            return <li key={`p-${i}`}>{ policy.name }</li>
        })
    }

    const showDescription = () => {
        return <p>{description}</p>
    }

    const trans = i18n[_i18n.language.current]

    useEffect(() => {
        if(_i18n.language.current === 'es') {
            let titles = document.getElementsByClassName("tier-card-title")
            let policies = document.getElementsByClassName("tier-card-policies")
            if(titles.length === policies.length)
                for(let i = 0; i < titles.length; i++){
                    titles[i].style.width = '450px'
                    policies[i].style.width = '340px'
                }
            else {
                for(let i = 0; i < titles.length; i++){
                    titles[i].style.width = '450px'
                }
                for(let i = 0; i < policies.length; i++){
                    policies[i].style.width = '340px'
                }
            }
        }
    }, [])

    return (
        <div className="tier-card-container">
            <div>
                <div className="tier-card-title">
                    <h1>{name}</h1>
                    <h1>{`${trans.buy_up_to} $${toDollar_NoCents(limit_max)} ${trans.per_day}`}</h1>
                </div>
                <div className="tier-card-policies">
                    <div>
                        {/* <h2>{`${trans.requirements}`}</h2> */}
                        { showDescription() }
                    </div>
                </div>
                <div className="tier-card-actions">
                    <ButtonBS
                        className="tier-card-button"
                        content={trans.select}
                        onClick={() => handleSelect(id)}
                    />
                </div>
            </div>
        </div>
    )
}

export default TierCard