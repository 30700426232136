import React, { useMemo } from 'react';
import Header from '../Header/Header2'
import Footer from '../Footer'
import TickerPrice from '../../../containers/Tickers/TickerPrice'
import TickerPower from '../../../containers/Tickers/TickerPower'
import ModalBS from '../../../containers/ModalBS'
import ReminderBS from '../../../containers/ReminderBS'

const PageLayout4 = ({
  children,
  big,
  errorMessage,
  title,
  price,
  power,
  phone,
  noPadHead,
  message,
  messageSubtext,
  selectMemberShip
}) =>{
  const layoutContentVariant = useMemo(() => {
    let variant = 'sm';
    if(big) {
      variant = 'bg-foot';
    } else if(noPadHead) {
      variant = 'bg';
    } else if(selectMemberShip) {
      variant = 'mem';
    }
    return `page-layout-4-cont-${variant}`;
  }, [big, noPadHead, selectMemberShip]);

  return(
    <div className='bs-body'>
      <ReminderBS />
      <ModalBS />
      { price ? <TickerPrice /> : null }
      { power ? <TickerPower /> : null }
      <Header title={title} big={noPadHead} message={message} messageSubtext={messageSubtext}/>
      <h6 className='bs-subtitle no-margin error invalid-pin'>{errorMessage}</h6>
      <div className={layoutContentVariant}>
        { children }
      </div>
      <Footer big={big} phone={phone}/>
    </div>
  )
}

export default PageLayout4