import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';
import { signalRStart } from './middlewares/Kiosk-Middleware';
import { ConnectedRouter } from 'connected-react-router'
import { WSStart, PlacesWSStart } from './middlewares/WebSocket-Middleware';
import { InternetListeners } from './middlewares/InternetConnect';
import { initTokenActions } from './middlewares/Initialization';
import { version } from '../package.json';
import { store, history } from './helpers/store';

import * as Sentry from '@sentry/react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

// Import moment spanish
import 'moment/locale/es-us';

console.info('Environment values debugging:');
console.info('REACT_APP_SENTRY_PROXY', process.env.REACT_APP_SENTRY_PROXY);
console.info('REACT_APP_SENTRY_ENVIRONMENT', process.env.REACT_APP_SENTRY_ENVIRONMENT);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_PROXY, // (req) -> <api-host>/sentry -> sentry servers
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  debug: true,
  release: version,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/192.168.88.40:5001\/sentry/]
});

initTokenActions(store);                     // initializion token needed methods
InternetListeners(store);                   // Add listeners for internet connection

signalRStart(store);
WSStart(store);
PlacesWSStart(store);

const persistor = persistStore(store)

ReactDOM.render((
  <Provider store={store}>
    <PersistGate
      persistor={persistor}>
      <ConnectedRouter history={history}>
        <SentryErrorBoundary fallback={<p>An error has occurred</p>}>
          <App />
        </SentryErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
), document.getElementById('root'));