import React from 'react';
import Checkbox from '../../CustomCheckbox';

const ModalSmsAcceptOptInItem = ({ title, content, onChangeOptIn }) => (
  <div className="modal-sms-accept__opt-in">
    <div className="modal-sms-accept__opt-in-col">
      <Checkbox onChange={onChangeOptIn} />
    </div>

    <div className="modal-sms-accept__opt-in-col">
      <div className="modal-sms-accept__opt-in-title">{title}</div>
      <div className="modal-sms-accept__opt-in-subtitle">{content}</div>
    </div>
  </div>
);

export default ModalSmsAcceptOptInItem;