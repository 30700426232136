import React, { PureComponent } from 'react';
import '../../../styles/DropdownBS/DropdownBS.css'
import Select from 'react-select';

class DropdownBS extends PureComponent {

  constructor(props){
    super(props);

    this.arrowDropdown = this.arrowDropdown.bind(this);
  }

  arrowDropdown(){
    if(!this.props.disabled)
      return(
        <div className='arrow-dropdown'/>
      )
  }

  render(){
    return(
      <Select 
        className={this.props.className}
        onChange={this.props.onChange}
        options={this.props.options}
        placeholder={this.props.placeholder}
        value={this.props.value}
        searchable={false}
        clearable={false}
        arrowRenderer={this.arrowDropdown}
        closeOnSelect={this.props.closeOnSelect}
        menuIsOpen={true}
        disabled={this.props.disabled}
        onOpen={this.props.onClick}
      />
    )
  }
}

DropdownBS.defaultProps={
  closeOnSelect: false,
  disabled: false,
  onClick: function(){}
}

export default DropdownBS;