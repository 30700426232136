import React, { useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectTier } from '../../../actions/tiersActions';
import PageLayout from '../../../components/common/PageLayout/PageLayout4'
import TierCard from './TierCard.jsx';

import { track } from '../../../helpers/analytics';

import translations from '../../../middlewares/i18n/SelectMembership.json';
import useTranslations from '../../../hooks/useTranslations';

const SelectMembership = () => {
  const tierRef = useRef()
  const dispatch = useDispatch()
  const i18n = useTranslations(translations)

  const tiers = useSelector(state => state.tiers.tiers)

  const message = i18n.sh1;

  const selectMembership = useCallback(
    () => {
      dispatch(selectTier(tierRef.current))
    },
    []
  )

  const _selectTier = (tier) => {
    track('Membership Select Clicked');

    tierRef.current = tier
    selectMembership()
  }

  const all_tiers = () => {
    return tiers.map((tier, i) => {
      return (
        <TierCard 
          { ...tier}
          handleSelect={_selectTier}
          key={`tier-card-${i}`}
        />
      )
    })
  }

  return(
    <PageLayout
        title={i18n.title}
        price
        big={false}
        message={message}
        selectMemberShip
    >
      <div className='select-mem-container'>
        {
          all_tiers()
        }
      </div>
    </PageLayout>
  )
}

export default SelectMembership