import { noMoreBills, maintenanceAfterWait, enterMaintenance } from '../../../actions/maintenanceActions';

export function SignalRDown(store){
	if(store.getState().signalr.active) {
		switch(store.getState().transaction.stage){
			case 0:
			case 4:
				store.dispatch({ type: "SIGNALR_DOWN" });
				if(!store.getState().maintenance.active)
					store.dispatch(enterMaintenance(6))
				break;
			case 1:
			case 2:
				store.dispatch({ type: "SIGNALR_DOWN" });
				store.dispatch(maintenanceAfterWait(6))
				break;
			case 3:
				if(store.getState().purchase.subtotal > 0){     //If user already inserted money
					store.dispatch(noMoreBills())  								//No accept more bills
					setTimeout(() => SignalRDown(store), 15000)
				}
				else{                                           //If user didn't insert money
					store.dispatch({ type: "SIGNALR_DOWN" });
					store.dispatch(maintenanceAfterWait(6))
				}
				break;

			default:
				store.dispatch({ type: "SIGNALR_DOWN" });
				if(!store.getState().maintenance.active)
					store.dispatch(enterMaintenance(6))
		}
	}
}