import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../components/common/PageLayout/PageLayout4';
import InputFieldBS from '../../../components/common/input-fields/InputFieldBS';
import KeyboardBS from '../../../components/KeyboardBS';
import { routerActions } from 'connected-react-router';
import { AddCoupon as i18n } from '../../../middlewares/i18n';
import '../../../styles/AddCoupon/AddCoupon.css';
import * as couponActions from '../../../actions/couponActions';
import ButtonBS from '../../../components/common/ButtonBS';
import { track } from '../../../helpers/analytics';

class AddCoupon extends PureComponent{

  constructor(props){
    super(props);
    this.state = {
      coupon_code: '',
      inputNode: null,
      buttonDisabled: true
    }
    this.baseState = this.state;
    this.nextField = this.nextField.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChangeField(e){
    if(e.target.id === 'coupon-code'){
      this.setState({
        coupon_code: e.target.value
      }, () => {
        if (!this.state.coupon_code.length) {
          if(!this.state.buttonDisabled)
            this.setState({
              buttonDisabled: true
            })
        }
        else {
          if(this.state.buttonDisabled)
            this.setState({
              buttonDisabled: false
            })
        }
      });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  }

  openKeyboard(node){
    this.setState({inputNode: node})
  }

  closeKeyboard(){
    this.setState({inputNode: null})
  }

  nextField(){
    if(this.state.inputNode.id==='coupon-code'){
      this.closeKeyboard();
    } else{
      for(let i = 0; i < document.getElementsByTagName('input').length; i++){
        if(document.getElementsByTagName('input')[i].id === this.state.inputNode.id){
          document.getElementsByTagName('input')[i+1].focus();
          break
        }
      }
    }
  }

  submit(){
    let coupon_code = this.state.coupon_code
    track('Applying coupon code', { coupon_code });
    this.setState({
      buttonDisabled: true,
      coupon_code: "",
    }, () => this.props.actions.coupon.applyCouponCode(coupon_code))
  }

  componentDidMount(){
    document.getElementsByTagName('input')[0].focus();
  }

  render(){
    return(
      <PageLayout
        title={i18n[this.props.i18n.language.current].title}
        price={false}
        big={false}
        noPadHead={true}
      >
        <div className="enter-coupon-container">
          {/* { !this.props.coupon_code ?
            <div className='or-scan-coupon'>
              <h2 className="bs-subtitle no-margin">{i18n[this.props.i18n.language.current].skip}</h2>
              <ButtonBS
                className='scan-coupon-button'
                content={i18n[this.props.i18n.language.current].scanButtonContent}
                onClick={this.props.actions.scanCouponCode}
              />
            </div> : null } */}
          <h2 className="bs-subtitle no-margin">
            {i18n[this.props.i18n.language.current].subtitle}
          </h2>
          <InputFieldBS
            id='coupon-code'
            className='coupon-code-input'
            value={this.state.coupon_code}
            onFocus={()=>this.openKeyboard(document.getElementById('coupon-code'))}
            onInput={this.handleChangeField}
          />
          { this.state.inputNode ?
            <KeyboardBS
              inputNode={this.state.inputNode}
              close={this.closeKeyboard.bind(this)}
              showNumber = {true}
              next={this.nextField}
            /> : null }
        </div>
        <div className="coupon-screen-actions">
          <ButtonBS
            className="apply-coupon"
            content={i18n[this.props.i18n.language.current].back}
            onClick={()=> this.props.actions.router.push('/dashboard')}
          />
          <ButtonBS
            className="apply-coupon"
            content={'Apply Coupon'}
            onClick={this.submit}
            disabled={this.state.buttonDisabled}
          />
        </div>
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      // setStage: bindActionCreators(setStage, dispatch),
      coupon: bindActionCreators(couponActions, dispatch),
      router: bindActionCreators(routerActions, dispatch),
    }
  }
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);
