import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageLayout from '../../../components/common/PageLayout';
import TransCard from '../../../components/cards/TransCard';
import ButtonBS from '../../../components/common/ButtonBS';
import * as purchaseActions from '../../../actions/purchaseActions';
import { setStage } from '../../../actions/transactionActions';
import '../../../styles/InsertCash/InsertCash.css';
import videoInsertCash from '../../../styles/assets/DOLLAR_2.webm';
import { InsertCash as i18n } from '../../../middlewares/i18n';

import { track } from '../../../helpers/analytics';

class InsertCash extends Component{

  constructor(props){
    super(props);
    
    this.sendToWallet = this.sendToWallet.bind(this);
  }

  sendToWallet(){
    track('Send To Wallet Clicked');

    this.button1.setAttribute("disabled", "disabled");
    this.props.actions.purch.sendToWallet()
  }

  componentDidMount(){
    this.props.actions.purch.lockPrice();
    // this.props.actions.purch.insertBill();
    this.props.actions.setStage(3);

    //track('Cash Inserted');
  }

  render(){
    return(
      <PageLayout
        power
        big={false}
      >
        { this.props.purchase.subtotal < this.props.rate.min ?
          <div>
            { this.props.coupon.applied ? <h5 style={{
                fontSize: "35px",
                position: "absolute",
                right: "35%",
                top: "-25px"
            }}>🎉 Coupon Applied: {this.props.coupon.value * 100}% 🎉</h5> : null}
            <h6 className='bs-title centered header-title'>{i18n[this.props.i18n.language.current].title}</h6>
            <div className='line'/> 
            <div className='vid-cont'>
              <video controls={false} autoPlay loop width='1200' height='1100'>
                <source src={videoInsertCash} type='video/webm'/>
              </video>
            </div>
            <p id="min-bill" className='bs-subtitle no-margin min-bill animated'>
              {i18n[this.props.i18n.language.current].minBill1}<span className='bs-title'>${this.props.rate.min}</span>{i18n[this.props.i18n.language.current].minBill2}<br/>{i18n[this.props.i18n.language.current].minBill3}
            </p>
          </div> :
          <div>
            <div className='trans-card-out animated fadeInDownBig'>
              { this.props.coupon.applied ? <h5 style={{
                fontSize: "35px",
                position: "absolute",
                right: "35%",
                top: "-25px"
              }}>🎉 Coupon Applied: {this.props.coupon.value * 100}% 🎉</h5> : null}
              <div className='trans-card-middle'>
                <TransCard
                  bills={this.props.purchase.bills}
                  subtotal={this.props.purchase.subtotal}
                  fee={parseFloat(this.props.purchase.fee)}
                  totalFee={this.props.purchase.totalfee}
                  total={this.props.purchase.total}
                  btc={this.props.purchase.btc}
                  btcPrice={this.props.purchase.btcprice}
                  showBills={true}
                  address={this.props.purchase.address}
                  flat={this.props.purchase.flat}
                  language={this.props.i18n.language.current}
                  couponApplied={this.props.coupon.applied}
                  oldFee={this.props.coupon.old_fee}
                  oldTotalFee={this.props.coupon.old_charges}
                  savings={this.props.coupon.savings}
                />
              </div>
              <h6
                id='continue-insert'
                className={
                  'bs-subtitle no-margin animated' + 
                  (this.props.i18n.language.current === 'es' ?
                  ' continue-insertando': '')
                }
              >
                {i18n[this.props.i18n.language.current].continue}
              </h6>
            </div>
            <ButtonBS
              content={i18n[this.props.i18n.language.current].send}
              className='insert-cash-button'
              onClick={this.sendToWallet}
              refButton={(button) => {this.button1 = button}}
            />
          </div>}
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      purch: bindActionCreators(purchaseActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    purchase: state.purchase,
    rate: state.rate,
    client: state.client,
    i18n: state.i18n,
    coupon: state.coupon
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertCash)