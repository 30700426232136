import { push } from 'connected-react-router';
import * as types from './actionTypes.js';

export function nextView(view){
  return{
    type: types.NEXT_VIEW,
    view
  }
}

export function setDirectionList(list) {
  return {
    type: types.SET_DIRECTION_LIST,
    list
  }
}

export function setTitle(title) {
  return {
    type: types.SET_TITLE,
    title
  }
}

export function setContent(content) {
  return {
    type: types.SET_CONTENT,
    content
  }
}

export function getDirectionFromList(){
  return function(dispatch, getState){
    let list = getState().view.list;
    let next_view = list.shift()

    dispatch(setDirectionList(list))
    if(next_view.instructions) {
      dispatch(setTitle(next_view.instructions.title))
      dispatch(setContent(next_view.instructions.content))
    }
    return next_view.direction
  }
}

export function enforceScanID(){
  return function(dispatch, getState) {
    let list = getState().view.list

    let preventScreens = [
      'tell_us_about',
      'enter-address',
      'enter_dob',
      'scan_id'
    ]

    for(let i = 0; i < preventScreens.length; i++) {
      let index = list.findIndex((direction) => direction.direction === preventScreens[i])
      if(index > -1)  {
        list.splice(index, 1)
      }
    }
    dispatch(setDirectionList(list))

    return dispatch(push('scan_id'))
  }
}

export function reqUpgradePersona() {
  return {
    type: types.REQ_UPGRADE_PERSONA
  }
}

export function reqUpgradeManual() {
  return {
    type: types.REQ_UPGRADE_MANUAL
  }
}