import initialState from './initialState';

export default function reportReducer(state = initialState.report, action){

  switch(action.type){

    case 'REPORT_RETRIEVED':
      return action.report

    case 'EXIT_CLEAR_CASH':
      return initialState.report

    default:
      return state;
  }
}