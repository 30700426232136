import createAxe from '../../middlewares/Axe'
import { update, omit, pickBy } from 'lodash';

import * as types from './actionTypes';
import goToVerse from '../../middlewares/Axe/elevator.js';
import { sendToBE } from '../purchaseActions';

export function getTxsMaint(){
  return async function(dispatch, getState){
    try{
      if(!getState().transaction.init){               //Check if we are in a middle of tx (temp fixes https://github.com/bitstopco/nonce_services/issues/117)
        let txs = await goToVerse({ action: 'GET_ALL_TXS' })
        return dispatch(sendAllNotSent(txs.data.data))
      }
      else {
        return dispatch({ type: "IN_TX" })
      }
    } catch(error){
      return dispatch(errorDB('Error getting the table of txs'))
    }
  }
}

export function getAllTx(){
  return async function(dispatch, getState){
    try{
      let txs = await goToVerse({ action: 'GET_ALL_TXS' })
      return dispatch(sendToProxy(txs.data.data, getState().machine.atm_id))
    } catch(error){
      return dispatch(errorDB('Error getting the table of txs'))
    }
  }
}

export function  getTxsByDate(start, end){
  return async function(dispatch, getState){
    try{
      let txs = await goToVerse({ action: 'GET_TXS_BY_DATE', start: start, end: end })
      return dispatch(sendToProxy(txs.data.data, getState().machine.atm_id))
    } catch(error){
      return dispatch(errorDB('Error getting the table of txs'))
    }
  }
}

export function getTx(tx_id){
  return async function(dispatch, getState){
    try{
      let tx = await goToVerse({ action: 'GET_TX', tx_id: tx_id })
      return dispatch(sendToProxy(tx.data.data, getState().machine.atm_id))
    } catch(error){
      dispatch(sendToProxy({ message: `SALE NOT FOUND ${tx_id}`}, getState().machine.atm_id))
      // return dispatch(errorDB('Error getting the tx'))
      return dispatch({ type: "NOT_FOUND" })
    }
  }
}

function checkBills(obj){
  return obj > 0
}

function toString(purch){
  for (var key in purch){
    if (typeof purch[key] === 'number'){
      update(purch, key, ()=>{
        return purch[key].toString()
      })
    }
  }
  return purch
}

export function deleteTx(tx_id){
  return async function(dispatch){
    try{
      let txs = await goToVerse({ action: 'DELETE_TX_BY_ID', tx_id: tx_id })
      return dispatch(txDeleted())
    } catch(error){
      return dispatch(errorDB('Error getting the table of txs'))
    }
  }
}

export function finishTx(tx_id){
  return async function(dispatch){
    try{
      let data = await goToVerse({ action: 'FINISH_TX', tx_id: tx_id })
      return dispatch(txFinished(tx_id))
    } catch(error){
      return dispatch(errorDB('Error finishing tx'))
    }
  }
}

export function sendTxManually(tx_id){
  return async function(dispatch){
    try{
      let tx = await goToVerse({ action: 'GET_TX', tx_id: tx_id })
      let parsed = JSON.parse(tx.data.data.sell)
      if(parsed.btc){                  //we have info at this point and btc purchased
        let idtx = parsed.tx_id
        let purch = omit(parsed, ['address', 'custom_token', 'fee', 'idtx', 'tx_id'])
        let final = toString(purch)
        let manualPurch = Object.assign({}, final, {
          manual: true,
          idtx: idtx
        })
        return dispatch(sendToBE(manualPurch, manualPurch.idtx, parsed.custom_token))
      }
    }
    catch(error){
      return dispatch(errorDB('Error sending manually'))
    }
  }
}

export function sendAllNotSent(txs){
  return function(dispatch){
    for(let i = (txs.length - 1) ; i >= 0 ; i--){
      if(txs[i].tx_id !== null){                  //check tx is not null (this includes 0 while we patch the error)
        if(!txs[i].sent){                         //not sent
          if(!txs[i].sell && !txs[i].finish){     //no sell and not finish
            dispatch(finishTx(txs[i].tx_id))
          }
          else if(txs[i].sell){ // we have a sell that is not sent
            let parsed = JSON.parse(txs[i].sell)
            if(parsed.btc){                  //we have info at this point and btc purchased
              let purch = omit(parsed, ['address', 'custom_token', 'fee'])
              let final = toString(purch)
              let manualPurch = Object.assign({}, final, {
                manual: true
              })
              setTimeout(()=>{
                dispatch(sendToBE(manualPurch, manualPurch.idtx, parsed.custom_token))
              },1000)
            }
            else if(!txs[i].finish){ //no btc purchased and not finished
              dispatch(finishTx(txs[i].tx_id))
            }
          }
        } 
        else if(txs[i].sent && !txs[i].finish){ //sent but not finish
          dispatch(finishTx(txs[i].tx_id))
        }
      }
    }
    return dispatch(txsSent())
  }
}

export function saveDBSell(change){
  return function(dispatch, getState){
    let dbSell = Object.assign({}, getState().db.sell, change)
    let data = goToVerse({ action: 'SET_SELL', sell: dbSell, tx_id: getState().purchase.idtx })
    return dispatch(setSellDB(dbSell))
  }
}

export function txDeleted(tx_id){
  return{
    type: types.TX_DELETED,
    tx_id
  }
}

export function txsSent(tx_id){
  return{
    type: types.TXS_SENT,
    tx_id
  }
}

export function sendToProxy(data, atm_id){
  return async function(dispatch, getState){
    try{
      let axe = createAxe(getState())
      let res = await axe('/report-station/print-tx',{
        method: 'POST',
        data: {
          atm_token: atm_id,
          data: data
        }
      })
    }
    catch(error){}
  }
}

export function errorDB(message){
  return{
    type: types.ERROR_DB,
    message
  }
}

export function setSellDB(sell){
  return{
    type: types.SET_SELL_DB,
    sell
  }
}

export function gotAllTx(txs){
  return{
    type: types.GET_ALL_TXS,
    txs
  }
}

export function gotTx(tx){
  return{
    type: types.GET_TX,
    tx
  }
}

export function gotTxByDate(tx){
  return{
    type: types.GET_TX_BY_DATE,
    tx
  }
}

export function gotTxsByDate(txs){
  return{
    type: types.GET_TXS_BY_DATE,
    txs
  }
}

export function txFinished(tx_id){
  return{
    type: types.TX_FINISHED,
    tx_id
  }
}

// export function testDB(){
//   goToVerse({ action: 'GET_ALL_TXS' })
//     .then((res)=>console.log('Get all txs',res))
//   goToVerse({ action: 'GET_TX', tx_id: "1c1d9f85595576348a791b422e0b3cf4b11fb3c01b79875e896a100a8e57e5e4"})
//   .then((res)=>console.log('Get tx by id', res))
//   goToVerse({ action: 'GET_TX_BY_DATE', date: '8/12/2019 8:26:15 PM' })
//   .then((res)=>console.log('Get tx by date', res))
//   goToVerse({ action: 'GET_TXS_BY_DATE', start: '8/12/2019 8:24:40 PM', end: '8/12/2019 8:26:15 PM' })
//   .then((res)=>console.log('Get txs by date', res))
//   goToVerse({ action: 'GET_LAST_STATUS'})
//   .then((res)=>console.log('Last status', res))
//   goToVerse({ action: 'DELETE_SENT_TXS'})
//   .then((res)=>console.log('Deleted sent txs', res))
//   goToVerse({ action: 'FINISH_LAST_TX'})
//   .then((res)=>console.log('Finish last tx', res))
  // goToVerse({ action: 'BREAK_BATTERY'})
  // .then((res)=>console.log('Finish last tx', res))
// }