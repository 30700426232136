import { MaintenanceHandler } from './MaintenanceBase.jsx';
import { BillAcceptorHandler } from './BillAcceptorHandler.jsx';
import { CardReaderHandler } from './CardReaderHandler.jsx';
import { ImagingDeviceHandler } from './ImagingDeviceHandler.jsx';
import { PrinterHandler } from './PrinterHandler.jsx';
import { QRHandler } from './QRHandler.jsx';

export default function MaintenanceMiddleware(store: any){
  return (next: any) => (action: any) => {
    if (action.maintenance){

        switch(action.hardware_name){

          case 'QRService':
            if (action.code < 0 && action.code >= -10)
              MaintenanceHandler(store, action.code, action.description)
            else
              QRHandler(store, action.code, action.description)
            break;

          case 'BillAcceptorService':
            if (action.code < 0 && action.code >= -10)
              MaintenanceHandler(store, action.code, action.description)
            else
              BillAcceptorHandler(store, action.code, action.description)
            break;

          case 'CardReaderService':
            if (action.code < 0 && action.code >= -10)
              MaintenanceHandler(store, action.code, action.description)
            else
              CardReaderHandler(store, action.code, action.description)
            break;

          case 'PrinterService':
            if (action.code < 0 && action.code >= -10)
              MaintenanceHandler(store, action.code, action.description)
            else
              PrinterHandler(store, action.code, action.description)
            break;

          case 'ImagingDeviceService':
            if (action.code < 0 && action.code >= -10)
              MaintenanceHandler(store, action.code, action.description)
            else
              ImagingDeviceHandler(store, action.code, action.description)
            break;


          default: {
            console.error('Hardware do not exist');
          }

        }
    }
    return next(action);
  }
}