import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputPinMorty from './InputPinMorty.jsx';
import InputPinRick from './InputPinRick.jsx';
import InputPinTinyRick from './InputPinTinyRick.jsx';

class InputPin extends PureComponent{
  constructor(props){
    super(props);
  }

  render(){
    if(this.props.machine.name === 'rick')
      if(!this.props.machine.tiny)
        return <InputPinRick {...this.props}/> 
      else 
        return <InputPinTinyRick {...this.props}/>
    else
      return <InputPinMorty {...this.props}/>
  }
}

function mapStateToProps(state){
  return{
    machine: state.machine
  }
}

export default connect(mapStateToProps)(InputPin)