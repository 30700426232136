import {
	enterMaintenance,
	maintenanceAfterWait,
	exitMaintenance,
	setErrorCode,
	noMoreBills
} from '../../actions/maintenanceActions';

import {
	internetDown
} from '../../actions/machineActions'

export function InternetListeners(store){
	console.log('Attaching internet listeners..')
	// Flag to know that the event already knows
	// that there is no internet
	// This will help us with places with pretty
	// bad internet
	var downCought = false

	window.addEventListener('online', () => {
		// We already cought a down but we are back up
		if (downCought)
			downCought = false
		
		// If we were in maintenance by internet down
		if(store.getState().maintenance.active)
			store.dispatch(exitMaintenance())
	});

	window.addEventListener('offline', () => {
		if(!downCought) {
			downCought = true
			/**
			* This delay is because we can go offline in 2 ways:
			* 1) with this event
			* 2) with check version request that fails because of bad connection
			* We are preventing double fails
			* We also check if internet is back
			*/
			setTimeout(() => {
				// If internet is not back and wasn't cought
				// by the request and we are not maintenance active
				// GO GO GO !
				if(downCought)
					if(!store.getState().maintenance.active){
						store.dispatch(internetDown())
					}
			}, 5000)
		}
	});
}

export default function CheckConnection(store){
	return(next) => (action) => {
		switch(action.type){

			case 'INTERNET_DOWN':
				let state = store.getState()
				let { maintenance, transaction } = state
				if(!maintenance.active){
					if(transaction.init){              //if machine is in a middle of a transaction
						if(transaction.stage <= 3){      //If machine is in stage 3 or less
							switch(transaction.stage){     //depending in what stage we are
								case 1:
								case 2:
									return store.dispatch(maintenanceAfterWait(1))		// Go to maintenance 1
								case 3:                                       			// Inserting bills screen
									if(store.getState().purchase.subtotal > 0)      	// If user already inserted money
										return store.dispatch(noMoreBills())			// Cancel insert bills
									else                                          		// If user didn't insert money
										return store.dispatch(maintenanceAfterWait(1))  // Go to maintenance mode
							}
						}
						// else already in receipt maybe we can do later something like printing the receipt
						// we don't do anything so user doesn't get scare
					}
					else				// Not in tx (red screen)
						return store.dispatch(enterMaintenance(1))
				}
				// if maintenance is already ON
				else if(maintenance.active && maintenance.errorCode !== 1)
					return store.dispatch(setErrorCode(1))						//give priority to internet conn
	
			default:
				return next(action)
		}
	}
}