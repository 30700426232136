import * as types from './actionTypes';
import createAxe from '../../middlewares/Axe'
import { goLoading, outLoading } from '../loadingActions';
import { parsePhoneNumber } from 'libphonenumber-js';
import { triggerError } from '../maintenanceActions';
//HODL REDUCER IN CLIENT
import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';

export function sendWalletLinkUser(device){
  return async function(dispatch, getState){
    try{
      if(getState().client.hodlLink){
        let axe = createAxe(getState())
        let res = await axe('/gas-station/fuel', {
          method: 'POST',
          data:{
            url: '/notify/hodl',
            access_token: getState().client.client_id,
            atm_token: getState().machine.atm_id,
            data:{
              device
            }
          }
        })
        return dispatch(walletLinkSent())
      }
    }
    catch(error){
      return dispatch(errorSendingLink())
    }
  }
}

export function sendWalletLinkPhone(phone, device){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].sendingLink))
    try{
      let phoneNumber = parsePhoneNumber(phone)
      let axe = createAxe(getState())
      let res = await axe('/gas-station/octanage', {
        method: 'POST',
        data:{
          url: '/notify/hodl',
          atm_token: getState().machine.atm_id,
          data:{
            device,
            country_code: phoneNumber.countryCallingCode,
            phone: phoneNumber.nationalNumber
          }
        }
      })
      dispatch(outLoading('/'))
      return dispatch(walletLinkSent())
    }
    catch(error){
      dispatch(outLoading())
      dispatch(triggerError(i18nErrors[lang].sendLinkFailed));
      return dispatch(errorSendingLink())
    }
  }
}

export function walletLinkSent(){
  return{
    type: types.WALLET_LINK_SENT
  }
}

export function errorSendingLink(){
  return{
    type: types.ERROR_SENDING_LINK
  }
}