import initialState from './initialState';

export default function modalReducer(state = initialState.modal, action){

  switch(action.type){

    case 'OPEN_MODAL':
      return Object.assign({}, state, {
        open: true
      })

    case 'CLOSE_MODAL':
      return Object.assign({}, state, {
        open: false
      })

    case 'OPEN_REMINDER':
      return Object.assign({}, state, {
        open_reminder: true
      })

    case 'SET_MODAL_TYPE':
      return Object.assign({}, state, {
        modalType: action.modalType
      })

    case 'CLOSE_REMINDER':
      return Object.assign({}, state, {
        open_reminder: false
      })

    case 'REMINDER_TIMED_OUT':
      return Object.assign({}, state, {
        open_reminder: false
      })

    case 'CANCEL_OPERATION':
      return initialState.modal

    
    default: 
      return state

  }
}