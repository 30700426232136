export const FINISH_TX = 'FINISH_TX';
export const POWER_DEC = 'POWER_DEC';
export const SELECT_RANGE = 'SELECT_RANGE';
export const FUNDS_ADDED = 'FUNDS_ADDED';
export const INSERT_BILL = 'INSERT_BILL';
export const PRINT_RECEIPT = 'PRINT_RECEIPT';
export const RETURN_BILL = 'RETURN_BILL';
export const LOCK_EXIT = 'LOCK_EXIT';
export const UNLOCK_EXIT = 'UNLOCK_EXIT';
export const LOCK_PRICE = 'LOCK_PRICE';
export const SET_ID_TX = 'SET_ID_TX';
export const SET_FEE = 'SET_FEE';
export const SET_DATE = 'SET_DATE';
export const CANCEL_INS_BILL = 'CANCEL_INS_BILL';
export const SET_FLAT = 'SET_FLAT';
export const DONE_FEE_SET = 'DONE_FEE_SET';
export const SET_TIME_SENT = "SET_TIME_SENT";
export const LOCK_SPOT_PRICE = 'LOCK_SPOT_PRICE';
export const SET_SPREAD = 'SET_SPREAD';
export const SET_QUOTED_EXCHANGE = 'SET_QUOTED_EXCHANGE';
export const SET_PURCHASE_RECEIPT = 'SET_PURCHASE_RECEIPT';