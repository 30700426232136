import React, { useEffect, useCallback } from 'react'
import { startCase, toLower } from 'lodash';
import { useSelector, useDispatch } from 'react-redux'
import RecentActivity from './RecentActivity.jsx'
import FeeBreakdown from './FeeBreakdown.jsx'
import CurrentTier from './CurrentTier.jsx'
import ButtonBS from '../../../components/common/ButtonBS'
import PageLayout from '../../../components/common/PageLayout/PageLayout2'
import { push } from 'connected-react-router'
import { takePicture } from '../../../actions/cameraActions'
import '../../../styles/Dashboard/Dashboard.css'
import { Dashboard as i18n } from '../../../middlewares/i18n';
import { getCustomerTierInfo } from '../../../actions/clientActions'
import { track } from '../../../helpers/analytics'

const Dashboard = () => {

  const client = useSelector(state => state.client)
  const rate = useSelector(state => state.rate)
  const _i18n = useSelector(state => state.i18n)

  const dispatch = useDispatch()
  const buyBitcoin = useCallback(
    () => {
      dispatch(takePicture('dashboard_proceed'))
      dispatch(push('/scan_wallet'))
      track('[Dashboard] Buy Bitcoin Clicked');
    },
    []
  )

  const applyCoupon = useCallback(
    () => {
      dispatch(push('/add-coupon'));
      track('[Dashboard] Apply Coupon Clicked');
    },
    []
  )

  const upgrade = useCallback(
    () => {
      dispatch(push('/membership_track'))
      track('[Dashboard] Upgrade Account Clicked');
    },
    []
  )

  const _getCustomerTierInfo = useCallback(
    () => {
      dispatch(getCustomerTierInfo())
    },
    []
  )

  const createMarkup = () => {
    let message = client.dashboard_message
    let { up, down, message1, message2 } = i18n[_i18n.language.current]
    if(_i18n.language.current === 'es'){
      let move = up
      let split = message.split(" ")
      let change = split[6]
      let marketcap = `${split[split.length-2]} ${split[split.length-1]}`
      if(message.includes('decreased')){
        move = down
      }
      message = `${message1} ${move} ${change} ${message2} ${marketcap}`
    }
    return {__html: message}
  }

  useEffect(() => {
    _getCustomerTierInfo()
    track('[Dashboard] Navigated to Dashboard');
  },[])

  return(
    <PageLayout
      price
      power
      big={false}
    >
      <div className='dashboard-container'>
        <div className='w-back-message'>
          <h4 className='no-margin'>{i18n[_i18n.language.current].title}</h4>
          <h4 className='no-margin'>{startCase(toLower(client.firstName))}!</h4>
        </div>
        <div>
          <h5 dangerouslySetInnerHTML={createMarkup()} />
        </div>
        <div className='line' />
        <CurrentTier enrollmentTier={client.tier} isMaxTier={client.max_tier} limit={client.max} handleSelect={upgrade}/>
        <RecentActivity listTransactions={client.listTx}/>
        <FeeBreakdown range_fees={rate.fees} clickable={<ButtonBS className ='apply-coupon-dash' content={i18n[_i18n.language.current].apply_coupon} onClick={ applyCoupon }/>} />
        <ButtonBS className ='dashboard-button' content={i18n[_i18n.language.current].button} onClick={ buyBitcoin }/>
      </div>
    </PageLayout>
  )
}

export default Dashboard