import React, { Component } from 'react';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonBS from '../../components/common/ButtonBS';
import * as modalActions from '../../actions/modalActions';
import { routerActions } from 'connected-react-router';
import '../../styles/Reminder/Reminder.css'
import { Errors as i18n } from '../../middlewares/i18n'

const customStyles = {
   overlay: {
    // z-index hack to overlay all others modals
    zIndex: 10000,
  },

  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-20%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    boxShadow: '0px 0px 25px #BDBDBD',
    borderRadius: 'none',
    fontSize: '60px',
    fontFamily: 'PT-Regular',
    wordWrap: 'break-word',
    padding: '60px'
  },

};

class ReminderBS extends Component{

  constructor(props){
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.goToDipCard = this.goToDipCard.bind(this);
  }

  closeModal(){
    this.props.actions.modal.closeReminder(true);
  }

  goToDipCard(){
    this.props.actions.modal.closeReminder(false);
    this.props.actions.router.push('/card_acceptor');
  }

  render(){
    let translation = i18n[this.props.i18n.language.current]
    return(
      <div>
        <Modal
          isOpen={this.props.modal.open_reminder}
          //onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal BS"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          { this.props.router.location.pathname !== '/scan_id' ?
            <div>
              <div className='bs-logo' style={{ height: '257px', width: '220px' }}/>
              <h5 style={{fontFamily: 'Gotham-Medium', margin: '70px 0px', fontSize: '40px'}}>{translation.stillThere}</h5>
              <ButtonBS onClick={this.closeModal} content={translation.yes}/>
            </div> :
            <div>
              <div className='bs-logo' style={{ height: '257px', width: '220px' }}/>
              <h5 style={{fontFamily: 'Gotham-Medium', margin: '60px 0px', fontSize: '40px'}}>{translation.problemsScanID1}</h5>
              <h5 style={{fontFamily: 'Gotham-Medium', margin: '0px 0px 60px 0px', fontSize: '40px'}}>{translation.problemsScanID2}</h5>
              <ButtonBS onClick={this.goToDipCard} className='reminder-button-scan' content={translation.yes}/>
              <ButtonBS onClick={this.closeModal} className='reminder-button-scan' content={translation.no}/>
            </div>
          }
        </Modal>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions:{
      modal: bindActionCreators(modalActions, dispatch),
      router: bindActionCreators(routerActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    modal: state.modal,
    router: state.router,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReminderBS);