import initialState from './initialState';

export default function purchaseReducer(state = initialState.purchase, action){

  switch(action.type){

    case 'SELECT_RANGE':
      return Object.assign({}, state, {
        tier: action.range,
      })

    case 'SET_TIME_SENT':
      return Object.assign({}, state, {
        time_sent: action.time_sent
      })

    case 'FUNDS_ADDED':
      return Object.assign({}, state, {
        bills: action.funds,
        subtotal: action.subtotal,
        totalfee: action.totalFee,
        total: action.total,
        btc: action.btc,
        num_bills: action.num_bills,
        message:'',
      })

    case 'CANCEL_OPERATION':
      return initialState.purchase;

    case 'ERROR_PURCHASE':
      return Object.assign({}, state, {
        message: action.message,
      })

    case 'ADDRESS_SCANNED':
      return Object.assign({}, state, {
        address: action.address,
      })

    case 'ADD_ADDRESS_ID':
      return Object.assign({}, state, {
        address_id: action.address_id
      })

    case 'LOCK_EXIT':
      return Object.assign({}, state, {
        exit: false,
      })

    case 'UNLOCK_EXIT':
      return Object.assign({}, state, {
        exit: true,
      })

    case 'LOCK_PRICE':
      return Object.assign({}, state, {
        btcprice: action.btcPrice
      })

    case 'SET_ID_TX':
      return Object.assign({}, state, {
        idtx: action.idTx
      })
    
    case 'SET_TRANSACTIONAL':
      return Object.assign({}, state, {
        transactional: action.transactional
      })

    case 'SET_FEE':
      return Object.assign({}, state, {
        fee: action.fee
      })

    case 'SET_DATE':
      return Object.assign({}, state, {
        date: action.date
      })

    case 'SET_FLAT':
      return Object.assign({}, state, {
        flat: action.flat
      })

    case 'LOCK_SPOT_PRICE':
      return Object.assign({}, state, {
        spotPrice: action.spotPrice
      })

    case 'SET_QUOTED_EXCHANGE':
      return Object.assign({}, state, {
        quotedExchange: action.quotedExchange
      })
    
    case 'SET_SPREAD':
      return Object.assign({}, state, {
        spread: action.spread
      })
    
    case 'SET_PURCHASE_RECEIPT':
      return Object.assign({}, state, {
        ...action.purchaseReceipt
      })

    default:
      return state;
  }
}