import * as types from './actionTypes';

export function readCard(){
  return{
    type: types.READ_CARD,
    signalR: true
	}
}
  
export function cancelReadCard(){
  return{
    type: types.CANCEL_READ_CARD,
    signalR: true
  }
}