import React from 'react'
import PageLayout from '../../../components/common/PageLayout/PageLayout2'
import '../../../styles/SendHODL/SendHODL.new.css'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../constants/support';
import translations from '../../../middlewares/i18n/SendHODL.json';
import useTranslations from '../../../hooks/useTranslations';

const SendHODL = () => {

  const i18n = useTranslations(translations)

  return(
    <PageLayout big={false} phone={false}>
      <div className='send-hodl-new'>
        <div className='send-hodl-new__header'>
          <div className='send-hodl-new__header-logo' />
          <h4 className='send-hodl-new__header-title no-margin down-hodl'>{i18n.title}</h4>
          <h5 className='send-hodl-new__header-subtitle no-margin'>{i18n.sh1}</h5>
          <h5 className='send-hodl-new__header-subtitle no-margin'>{i18n.sh2}</h5>
          <h5 className='send-hodl-new__header-subtitle no-margin'>{i18n.sh3}</h5>
          <h5 className='send-hodl-new__header-slogan'>{i18n.sh4}</h5>
        </div>
        <div className="send-hodl-new__content">
          {/* Barcode 1 with title */}
          <div className="send-hodl-new__content-ios">
            <div className="send-hodl-new__content-ios-qr">
            </div>
          </div>

          {/* Barcode 2 with title */}
          <div className="send-hodl-new__content-android">
            <div className="send-hodl-new__content-android-qr">
            </div>
          </div>
        </div>
      </div>
      <div className='send-hodl-new__support'>
        <h6 className='send-hodl-new__support-subtitle--weight no-margin'>{i18n.help}</h6>
        <h6 className='send-hodl-new__support-subtitle no-margin'>{i18n.touch}</h6>
        <ul>
          <li className="send-hodl-new__support-subtitle--weight">{SUPPORT_EMAIL}</li>
          <li className="send-hodl-new__support-subtitle--spacing">{SUPPORT_PHONE}</li>
        </ul>
      </div>
    </PageLayout>
  )
}

export default SendHODL
