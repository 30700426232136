import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../Header'
import Footer from '../Footer'
import TickerPrice from '../../../containers/Tickers/TickerPrice'
import TickerPower from '../../../containers/Tickers/TickerPower'
import ModalBS from '../../../containers/ModalBS'
import ReminderBS from '../../../containers/ReminderBS'

const PageLayout3 = ({ children, big, errorMessage, title, price, power, phone}) =>{

  return(
    <div className='bs-body'>
      <ReminderBS />
      <ModalBS />
      { price ? <TickerPrice /> : null }
      { power ? <TickerPower /> : null }
      <Header title={title}/>
      <h6 className='bs-subtitle no-margin error invalid-pin'>{errorMessage}</h6>
      <div>
        { children }
      </div>
      <Footer big={big} phone={phone}/>
    </div>
  )
}

export default PageLayout3