import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonBS from '../../../../components/common/ButtonBS';
import PageLayout from '../../../../components/common/PageLayout';
import InputPinBS from '../../../../components/common/input-fields/InputPinBS';
import * as authActions from '../../../../actions/authActions';
import { setStage } from '../../../../actions/transactionActions';
import '../../../../styles/Pin/Pin.css';
import * as lightActions from '../../../../actions/lightActions';
import { CreatePin as i18n, Errors as i18nErrors } from '../../../../middlewares/i18n';

import { track } from '../../../../helpers/analytics';

class CreatePin extends PureComponent{

  constructor(props){
    super(props);
    this.state={
      pin:['','','',''],
      submiting: false,
      key: ''
    }
    this.submit = this.submit.bind(this);
    this.getPin = this.getPin.bind(this);
  }

  submit(){
    track('Create Pin Submit Clicked');

    if (this.state.pin.includes('')){
      this.props.actions.auth.invalidInput(i18nErrors[this.props.i18n.language.current].shortPin);
    } else {
      let pin='';
      for (var i=0; i < this.state.pin.length; i++){
        pin=pin+this.state.pin[i];
      }
      this.props.actions.auth.createPin(pin);
    }
    this.setState({ 
      submiting: true
    }, ()=> this.setState({ submiting: false }))
  }

  getPin(value){
    this.setState({
      pin: value
    })
  }
  componentDidMount(){
    if(!this.props.mach.tiny)
      this.props.actions.light.setLight(1,"PPS")
  }

  componentDidUpdate(prevProps){
    if(this.props.mach.name === 'morty'){
      if (this.props.pinpad.enter && this.props.pinpad.enter !== prevProps.pinpad.enter)
        this.submit()
      else if (!Number.isNaN(parseInt(this.props.pinpad.key, 10)))
        this.setState({key: this.props.pinpad.key}, ()=>this.setState({key:''}))
      else if (this.props.pinpad.clear && this.props.pinpad.clear !== prevProps.pinpad.clear)      //Both cancel and clear
        this.setState({key: 'CLEAR'}, ()=>this.setState({key:''}))
    }
  }

  render(){
    return(
      <PageLayout title={i18n[this.props.i18n.language.current].title} errorMessage={this.props.auth.errorMessage}>
        <div className='create-pin-container'>
          <InputPinBS sendPin={this.getPin} submited={this.state.submiting} submit={this.submit} keyPress={this.state.key}/>
          <h6 className='bs-subtitle no-margin remember'>{i18n[this.props.i18n.language.current].message}<br/> {i18n[this.props.i18n.language.current].steps}</h6>
        </div>
        { !this.props.mach.tiny ?
          <ButtonBS content={i18n[this.props.i18n.language.current].submit} onClick={this.submit}/> :
        null }
      </PageLayout>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    actions: {
      auth: bindActionCreators(authActions, dispatch),
      setStage: bindActionCreators(setStage, dispatch),
      light: bindActionCreators(lightActions, dispatch)
    }
  }
}

function mapStateToProps(state){
  return{
    auth: state.auth,
    mach: state.machine,
    pinpad: state.pinpad,
    i18n: state.i18n
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePin)