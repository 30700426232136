import React from 'react';

import baseToast from 'react-hot-toast';

import CustomToast from '../components/CustomToast';

import { COLORS } from '../constants/toast';

/**
 * Triggers a Custom Toast notification
 * props: { title: string; text: string; status: 'success' | 'error' }
 * 
 * @param {*} props
 * @return {*} 
 */
const toast = (props) => {
  try {
    const { title, text, status } = props;
    const _status = status === 'success' ? 'success' : 'error';
    const colors = COLORS[_status];
  
    return baseToast(() => <CustomToast colors={colors} title={title} text={text} />, { position: 'bottom-center' });
  } catch {
    //
  }
}

export default toast;