export const MAINTENANCE_MODE_ON = 'MAINTENANCE_MODE_ON';
export const MAINTENANCE_MODE_OFF = 'MAINTENANCE_MODE_OFF';
export const SET_MAINTENANCE_CODE = 'SET_MAINTENANCE_CODE';
export const SET_HARDWARE_NAME = 'SET_HARDWARE_NAME';
export const SET_DESCRIPTION_ERROR = 'SET_DESCRIPTION_ERROR';
export const SET_STATUSES = 'SET_STATUSES';
export const GET_DEVICE_STATUS = 'GET_DEVICE_STATUS';
export const PRINTER_WORKING = 'PRINTER_WORKING';
export const PRINTER_DOWN = 'PRINTER_DOWN';
export const ERROR_MESSAGE_MODAL = 'ERROR_MESSAGE_MODAL';
export const CLEAR_CASH_METRICS = 'CLEAR_CASH_METRICS';
export const CAMERA_DOWN = 'CAMERA_DOWN';
export const CAMERA_WORKING = 'CAMERA_WORKING';
export const CARD_READER_WORKING = 'CARD_READER_WORKING';
export const CARD_READER_DOWN = 'CARD_READER_DOWN';
export const DISPENSER_WORKING = 'DISPENSER_WORKING'
export const DISPENSER_DOWN = 'DISPENSER_DOWN';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';