import React from 'react';
import './Coupon.css';
import useTranslations from '../../../hooks/useTranslations';
import translations from '../../../middlewares/i18n/RewardsCenterCoupon.json';

function Coupon() {
  const i18n = useTranslations(translations);
  return (
    <div className="coupon">
      <div className="left">
        <div>{i18n.enjoyYourGift}</div>
      </div>
      <div className="center">
        <div>
          <h2>{i18n.discountAmount}</h2>
          <h3>tryit10</h3>
        </div>
      </div>
      <div className="right">
        <div>20090103112518</div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Coupon />
    </div>
  );
}

export default App;