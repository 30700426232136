import React from 'react';

import ButtonBS from '../../../common/ButtonBS';
import AlertCard from '../../AlertCard/AlertCard';

import translations from '../../../../middlewares/i18n/ModalScamAdvise';

import useTranslations from '../../../../hooks/useTranslations';

import { SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../../constants/support';

import '../../../../styles/common/Modals/ModalScamAdvise.css';

const ModalScanAdvise = ({ closeModal }) => {
  const i18n = useTranslations(translations);

  return (
    <div className="modal-scam-advise">
      <div className="scam-logo" />
      <div className="modal-scam-advise__title">{i18n.title}</div>

      <div className="modal-scam-advise__alert-card">
        <AlertCard title={i18n.cardTitle} subContent={i18n.cardContent2} />
      </div>

      <div className="modal-scam-advise__support-container">
        <div className="modal-scam-advise__unsure">{i18n.unsure}</div>
        <div className="modal-scam-advise__support">{SUPPORT_EMAIL}</div>
        <div className="modal-scam-advise__support">{SUPPORT_PHONE}</div>
      </div>

      <ButtonBS v2 className="v2--red modal-scam-advise__button" content={i18n.button} onClick={closeModal} />
    </div>
  );
}

export default ModalScanAdvise;