import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PageLayout from '../../../components/common/PageLayout/PageLayout3'
import ButtonBS from '../../../components/common/ButtonBS'
import { push } from 'connected-react-router'
import { cancelOperation } from '../../../actions/authActions'
import '../../../styles/ForceScanId/ForceScanId.css'
import { ForceScanId as i18n } from '../../../middlewares/i18n'

import { track } from '../../../helpers/analytics';

const ForceScanId = () => {

  const _i18n = useSelector(state => state.i18n)

  const dispatch = useDispatch();

  useEffect(() => {
    track('Force ID Scan Shown');
  }, []);

  const scanID = useCallback(
    () => dispatch(push('/scan_id', { force: true })),
    []
  )
  const exit = useCallback(
    () => dispatch(cancelOperation()),
    []
  )

  return(
    <PageLayout
      power
      price
      big={false}
      title={i18n[_i18n.language.current].title}
    >
    <div className='force-scan-id-cont'>
      <div>
        <h5 className="no-margin">{i18n[_i18n.language.current].bp1}</h5>
        <h5 className="no-margin">{i18n[_i18n.language.current].bp2}</h5>
        <h5 className="no-margin">{i18n[_i18n.language.current].bp3}</h5>
        <br/>
        <h5 className="no-margin">{i18n[_i18n.language.current].bp4}</h5>
      </div>
      <div>
        <ButtonBS className ='force-scan-id-butt' content={i18n[_i18n.language.current].exit} onClick={ exit }/>
        <ButtonBS className ='force-scan-id-butt' content={i18n[_i18n.language.current].ok} onClick={ scanID }/>
      </div>
    </div>
    </PageLayout>
  )
}

export default ForceScanId