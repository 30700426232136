import createAxe from '../../middlewares/Axe'

import * as types from './actionTypes';
import { triggerError, digestErrorCode } from '../maintenanceActions';
import { goLoading, outLoading } from '../loadingActions';
import { addTier, setTransactional } from '../authActions';

import { Loading as i18n, Errors as i18nErrors } from '../../middlewares/i18n';

export function notMe(){
  return {
    type: types.NOT_ME,
  }
}

export function yesMe(){
  return {
    type: types.YES_ME,
  }
}

export function addInfo(first, last, dob){
  return async function(dispatch, getState){
    let lang = getState().i18n.language.current
    dispatch(goLoading(i18n[lang].processing))
    try{
      let axe = createAxe(getState())
      let res = await axe('/gas-station/diesel', {
        method: 'POST',
        data:{
          access_token: getState().client.client_id,
          atm_token: getState().machine.atm_id,
          url: '/customer/update',
          data:{
            first_name: first,
            last_name: last,
            dob: dob
          }
        }
      })
      if (res.status === 200){
        dispatch(addName(first, last));
        return dispatch(outLoading(res.data.data.direction))
      }
      else{
        dispatch(outLoading())
        return dispatch(triggerError(i18nErrors[lang].processFailed));
      }
    }
    catch(error){
      return dispatch(digestErrorCode(error.response.status, () => {
        dispatch(outLoading())
        return dispatch(triggerError(i18nErrors[lang].processFailed));
      }))
    }
  }
}

export function setDOB(dob){
  return{
    type: types.SET_DOB,
    dob
  }
}

export function setFirst(firstName){
  return{
    type: types.SET_FIRST,
    firstName
  }
}

export function setLast(lastName){
  return{
    type: types.SET_LAST,
    lastName
  }
}

export function addName(firstName,lastName){
  return function(dispatch){
    let name = firstName+' '+lastName;
    dispatch(setFirst(firstName))
    dispatch(setLast(lastName))
    return dispatch(setName(name))
  }
}

export function setName(name){
  return{
    type: types.SET_NAME,
    name
  }
}

export function setAddress(address){
  return{
    type: types.SET_ADDRESS,
    address
  }
}

export function setLimit(limit){
  return{
    type: types.SET_LIMIT,
    limit
  }
}

export function setPower(power){
  return{
    type: types.SET_POWER,
    power
  }
}

export function setClientID(client_id){
  return{
    type: types.SET_CLIENT_ID,
    client_id
  }
}

export function setDashMess(dashboard_message){
  return{
    type: types.SET_DASH_MESS,
    dashboard_message
  }
}

export function setFees(range_fees){
  return{
    type: types.SET_FEES,
    range_fees
  }
}

export function setSales(listTx){
  return{
    type: types.SET_SALES,
    listTx
  }
}

export function setHardRest(){
  return{
    type: types.SET_HARD_REST
  }
}

export function setFulfilledPolicies(policies_fulfilled) {
  return {
    type: types.SET_FULFILLED_POLICIES,
    policies_fulfilled
  }
}

export function setMaxTierStatus(max_tier) {
  return {
    type: types.SET_MAX_TIER_STATUS,
    max_tier
  }
}

/**
 * This is what will update new transactional, etc
 */
export function setCustomerTierInfo(
  first_name,
  transactional,
  limit,
  tier,
  policies_fulfilled,
  max_tier
) {
  return function(dispatch) {
    dispatch(setFulfilledPolicies(policies_fulfilled))
    dispatch(setMaxTierStatus(max_tier))
    dispatch(setTransactional(transactional))
    dispatch(setFirst(first_name))
    dispatch(addTier(tier))
    return dispatch(setLimit(limit))
  }
}

export function getCustomerTierInfo(){
  return async function(dispatch, getState) {
    let lang = getState().i18n.language.current
    try{
        let axe = createAxe(getState())
        let res = await axe('/v2/unity/tiers/customers', {
            method: 'GET',
            headers: {
                'Customer-Token': `Bearer ${getState().client.client_id}`,
                'Atm-Token': `Bearer ${getState().machine.atm_id}`
            }
        })
        if(res.status === 200){
            let {
              first_name,
              enrollment_tier,
              max_tier,
              policies_fulfilled,
              limits
            } = res.data.data
            let { transactional, total } = limits
            return dispatch(setCustomerTierInfo(
              first_name,
              transactional,
              total,
              enrollment_tier,
              policies_fulfilled,
              max_tier
            ))
        }
    }
    catch(error){
        return dispatch(triggerError(i18nErrors[lang].tryLater))
    }
  }
}