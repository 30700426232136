import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import '../../styles/common/CalculatorBS/CalculatorBS.css';
import '../../styles/TransCard/TransCard.css';
import { toDollar } from '../../helpers/helperFunctions';
import { Calculator as i18n } from '../../middlewares/i18n';

class CalculatorBS extends PureComponent {

  constructor(props){
    super(props);

    this.state={
      amountInserted: 0,
      feeCollected: 0,
      amountWithFee: 0,
      amountBitcoin: 0,
      price: this.props.price
    };

    this.focusField = this.focusField.bind(this);
    this.calculateFromAmount = this.calculateFromAmount.bind(this);
    this.listenKeyPress = this.listenKeyPress.bind(this);
  }

  calculateFromAmount(event){
    this.setState({
      amountInserted: event.target.value
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.amountInserted !== this.state.amountInserted || prevProps.price !== this.props.price){
      let feeCollected = (this.state.amountInserted.replace(/[,$]/g,'') * this.props.fee / 100).toFixed(2);
      let amountWithFee =  (this.state.amountInserted.replace(/[,$]/g,'') - feeCollected).toFixed(2);
      let price = this.props.price.replace(/[,$]/g,'');
      let amountBitcoin = (amountWithFee / price);
      this.setState({
        feeCollected: feeCollected,
        amountWithFee: amountWithFee,
        amountBitcoin: amountBitcoin,
        price: this.props.price
      })
    }
  }

  listenKeyPress(event){
    switch(event.key){
      case '*':
        if(this.state.amountInserted.length > 0)
          this.setState({
            amountInserted: this.state.amountInserted.substring(0,this.state.amountInserted.length-1)
          });
        break;
    }
  }

  focusField(){
    document.getElementById('amount-input').focus();
  }

  componentDidMount(){
    this.focusField();
    document.addEventListener('keypress', this.listenKeyPress);
  }

  componentWillUnmount(){
    document.removeEventListener('keypress', this.listenKeyPress)
  }

  render(){
    let translations = i18n[this.props.i18n.language.current]

    return(
      <div className='calculator-container'>
        <div className='calculator-sub-cont'>
          <div className='amount-container'>
            <h5 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>{translations.cashInserted}</h5>
            <Cleave
              value={this.state.amountInserted}
              placeholder='Insert amount ($)'
              options={{
                numeral: true,
                prefix: '$',
                delimeter: ' ',
                numeralIntegerScale: 4,
                numeralPositiveOnly: true,
              }}
              onChange={this.calculateFromAmount}
              id='amount-input'
              size={10}
              onBlur={this.focusField}
            />
          </div>
          <div className='amount-container fee-cont'>
            <h5 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>{`${translations.fee} (${this.props.fee}%)`}</h5>
            <Cleave
              disabled
              value={this.state.feeCollected}
              options={{
                numeral: true,
                prefix: '$',
                delimeter: ' ',
                numeralThousandsGroupStyle: 'thousand'}}
              id='fee-output'
              size={12}
            />
          </div>
        </div>
        <div className='calculator-sub-cont' style={{borderBottom: 'none'}}>
            <div className='amount-container'>
              <h4 className='pull-left no-margin trans-font' style={{fontSize: '38px'}}>{translations.total}</h4>
              <Cleave
                disabled
                value={this.state.amountWithFee}
                options={{
                  numeral: true,
                  prefix: '$',
                  numeralThousandsGroupStyle: 'thousand'}}
                id='awf-output'
                size={12}
              />
            </div>
            <div className='amount-container'>
              <h4 className='pull-left no-margin btc-total purchased' style={{fontSize: '42px', height:'70px'}}>{translations.purchased}</h4>
              <div className='btc-purch-cont'>
                <Cleave
                  disabled
                  value={this.state.amountBitcoin}
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 8}}
                  id='btc-output'
                  size={this.state.amountBitcoin === 0 ? 1 : (this.state.amountBitcoin.toFixed(8).toString().length)+1}
                />
                <h4 className="no-margin" style={{fontSize: '39px'}}>BTC</h4>
              </div>
            </div>
            <div className='amount-container'>
              <h6 className='pull-left no-margin trans-rate' style={{fontSize: '28px'}}>{translations.exchangeRate}</h6>
              <h6 className='pull-right no-margin trans-rate' style={{fontSize: '28px'}}>1 BTC = ${toDollar(this.state.price)}</h6>
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(CalculatorBS)