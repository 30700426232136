import React, { memo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useTranslations from '../../../hooks/useTranslations';
import translations from '../../../middlewares/i18n/ScamsListSender';

import { sendScamPreventionLink } from '../../../actions/scamActions'

import '../../../styles/common/ScamsListSender/ScamsListSender.css';

const ScamsListSender = () => {
  const dispatch = useDispatch();
  const i18n = useTranslations(translations);

  const [isLinkClicked, setIsLinkClicked] = useState(false);

  const isProcessing = useSelector(state => state.scam.link_status);

  const handleSend = () => {
    if(isProcessing || isLinkClicked) {
      return;
    }

    setIsLinkClicked(true);
    dispatch(sendScamPreventionLink());
  };

  return (
    <div className="scams-list-sender">
      <div className="scams-list-sender__text">{i18n.text}</div>
      <div className="scams-list-sender__link" role="button" onClick={handleSend}>{i18n.link}</div>
    </div>
  );
};

export default memo(ScamsListSender);