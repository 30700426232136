import React from 'react';
import '../../../styles/common/Header/Header.css'

const Header = ({ big, title, message, messageSubtext}) => {
  let headerWTitleClass = 'header h-y-title'
  let lineClass = 'line'

  if(big)
    headerWTitleClass = 'no-padding header-small ' + headerWTitleClass

  if (title)
    return (
      <div className={headerWTitleClass}>
        <div className='bs-logo'/>
        <div>
          <h6 className='bs-title centered header-title-2'>{title}</h6>
          { message && <h1 className="no-margin header-message">{message}</h1> }
          { messageSubtext && <h1 className="no-margin header-message">{messageSubtext}</h1> }
          <div className='line'/> 
        </div>
      </div>
    )
  else
    return (
      <div className='header h-n-title'>
        <div className='bs-logo' />
      </div>
    )
}

export default Header;