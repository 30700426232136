import React from 'react';
import '../../../styles/common/Header/Header.css'

const Header = (props) => {

  if (props.title)
    return (
      <div className='header h-y-title'>
        <div className='bs-logo'/>
        <div>
          <h6 className='bs-title centered header-title'>{props.title}</h6>
          <div className='line'/> 
        </div>
      </div>
    )
  else
    return (
      <div className='header h-n-title'>
        <div className='bs-logo' />
      </div>
    )
}

export default Header;