import moment from 'moment';
import { finishTx } from '../../actions/purchaseActions';
import { triggerError } from '../../actions/maintenanceActions';
import { goLoading, outLoading } from '../../actions/loadingActions';
import { setLightStat, lightOff } from '../../actions/lightActions';

import { Loading as i18n, Errors as i18nErrors } from '../i18n';

export default function printerMM(action, store, _hub, next){

  switch(action.type){

    case 'PRINT_RECEIPT':
      store.dispatch(setLightStat("RPS"))
      let lang = store.getState().i18n.language.current
      store.dispatch(goLoading(i18n[lang].printing))
      if(store.getState().machine.name === 'rick'){
        _hub.server.cS_RPS_PrintHtml(action.content).done(response=>{
          setTimeout(() => store.dispatch(finishTx()), 2000)
          // store.dispatch(outLoading('finish-screen'));
          store.dispatch(lightOff("RPS"))
        })
      }
      else if(store.getState().machine.name === 'morty'){
        if(!store.getState().maintenance.printerWork){
          store.dispatch(outLoading())
          store.dispatch(lightOff("RPS"))
          store.dispatch(triggerError(i18nErrors[lang].cannotPrint))
        }
        else{
          _hub.server.cS_RPS_PrintFormText(20, 
          `001BITSTOP]&[003Bitcoin Purchase]&[005Date: ${moment().format('MMMM Do YYYY, h:mm:ss a')}]&[007Total Inserted: $${store.getState().purchase.subtotal}]&[008Fee (${store.getState().rate.fee}%): -$${store.getState().purchase.totalfee}]&[010Total: $${store.getState().purchase.total}]&[011Purchased: ${store.getState().purchase.btc} BTC]&[013Exchange Rate: 1 BTC = $${store.getState().purchase.btcprice}]&[014Sent to:]&[015${store.getState().purchase.address}]&[017Email support: support@bitstop.co]&[018Website: bitstop.co`
          ).done(response=>{
            console.log(response)
          })
        }
      }
      break;

    case 'PRINT_CLEAR_CASH':
      _hub.server.cS_RPS_PrintHtml(action.report).done(response=>{
      })
      break;

    default:
      next()
  }
}