import React, { memo } from 'react';

import '../../../styles/common/AlertCard/AlertCard.css';

const AlertCard = ({ title, content, subContent }) => (
  <div className="alert-card">
    <div className="alert-card__title">{title}</div>
    <div className="alert-card__content">{content}</div>
    <div className="alert-card__content" dangerouslySetInnerHTML={{ __html: subContent }} />
  </div>
);

export default memo(AlertCard);